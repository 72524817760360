import CryptoJS from 'crypto-js';
import moment from 'moment';
import { REACT_APP_AES_KEY } from '../service/apiHandler';
import { includes, isNaN, values } from 'lodash';
import { messages } from './messages';
import { CheckpointDetail, CheckpointQuestion } from './types';
import {
  Category,
  CategoryDefect,
  ExtraNoteDefect,
  PreCheckEvaluationResult,
} from '../../preStartChecks/models/enums';
import { PreCheckAnswer } from './model';

export const convertTruckStatusToColor = (status: string) => {
  switch (status) {
    case 'Pumping':
      return 'green';
    case 'Inactive':
      return 'red';
    default:
    case 'Active':
      return 'orange';
  }
};

export const convertUserStatusToColor = (status: string): string => {
  switch (status) {
    case 'Invited':
      return 'orange';
    case 'Inactive':
      return 'red';
    default:
    case 'Active':
      return 'green';
  }
};

export const getFirstLetter = (name: string): string => {
  return name?.substring(0, 1).toUpperCase();
};

export const encrypt = (message: string): string => {
  return CryptoJS.AES.encrypt(message, REACT_APP_AES_KEY).toString();
};

export const decrypt = (cipherMessage: string): string => {
  const bytes = CryptoJS.AES.decrypt(cipherMessage, REACT_APP_AES_KEY);
  const descryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return descryptedData;
};

export const startOfDay = (date: Date): Date => {
  // Set the time to midnight (00:00:00)
  date.setHours(0, 0, 0, 0);

  // Adjust the timezone to "Pacific/Auckland"
  const aucklandTimezoneOffset = 720; // Auckland is 12 hours ahead of UTC (720 minutes)
  date.setMinutes(date.getMinutes() - aucklandTimezoneOffset);

  return date;
};

export const getFinalMomentOfTheDayAsDate = (...args: Date[]): Date =>
  new Date(startOfDay(args[0]).getTime() + 86399999);

export const convertNumberToOrdinal = (numberString: string): string => {
  // This function takes a string representation of a number as input,
  // converts it to an integer, and returns a string that appends the
  // appropriate ordinal indicator ("st", "nd", "rd", or "th") to the number. Eg. `12th`, `13th`, `2nd`, `4th`, `1st`..
  const num = parseInt(numberString, 10);
  if (isNaN(num)) {
    return '';
  }
  let ord = ['st', 'nd', 'rd'];
  let exceptions = [11, 12, 13];
  let nth =
    ord[(num % 10) - 1] === undefined || exceptions.includes(num % 100)
      ? 'th'
      : ord[(num % 10) - 1];
  return num + nth;
};

export const formatDate = (date: Date): string => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatDateToISO = (date: Date): string => {
  return moment(date).format('YYYY-MM-DD');
};

export const getTime = (time: Date): string => {
  return new Date(time).toLocaleTimeString('en-NZ', {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const toHoursAndMinutes = (totalMinutes: number): string => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  let res = '';

  if (hours > 0 || minutes > 0) {
    if (hours === 1) {
      res += '1 hour ';
    } else if (hours > 0) {
      res += hours + ' hour ';
    }
    if (minutes > 0) {
      res += minutes + ' min';
    }
  } else {
    res += '0 min';
  }

  return res;
};

export const emailValidation = (email = ''): string => {
  const trimmed = email.trim();
  if (trimmed === '') {
    return messages.error.ENTER_EMAIL;
  } else if (
    !new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ).test(trimmed)
  ) {
    return messages.error.INVALID_EMAIL;
  }
  return '';
};

export const passwordValidation = (pword: string): string => {
  const trimmed = pword.trim();
  if (trimmed === '') {
    return messages.error.ENTER_PASSWORD;
  }
  return '';
};

export const lengthValidation = (value: string, fieldName: string): string => {
  const trimmed = value.trim();
  if (trimmed === '') {
    return `Please enter ${fieldName}`;
  } else if (trimmed.length < 3) {
    return `${getFirstLetter(fieldName).toUpperCase()}${fieldName.slice(
      1,
    )} must have at least 3 letters`;
  }
  return '';
};

export const newPasswordValidation = (
  password: string,
  confirmPassword: string,
): { password: string; confirmPassword: string } => {
  const trimmedPword = password.trim();
  const trimmedConfirm = confirmPassword.trim();
  const errors = { password: '', confirmPassword: '' };
  if (!trimmedPword) errors.password = messages.error.ENTER_PASSWORD;
  else if (trimmedPword.length < 6) {
    errors.password = messages.error.PASSWORD_TOO_SHORT;
  }

  if (!trimmedConfirm)
    errors.confirmPassword = messages.error.ENTER_CONFIRM_PASSWORD;
  else if (trimmedPword && trimmedConfirm !== trimmedPword) {
    errors.confirmPassword = messages.error.PASSWORD_NOT_MATCH;
  }
  return errors;
};

export const generatePin = (): number => {
  var numbers = Math.floor(1000 + Math.random() * 9000);
  return numbers;
};
export const currentDate = (date: Date): string => {
  let dayName = new Date(date).toLocaleDateString('en-NZ', { weekday: 'long' });
  let monthName = new Date(date).toLocaleDateString('en-NZ', { month: 'long' });
  let day = new Date(date).toLocaleDateString('en-NZ', {
    day: 'numeric',
  });
  let dayFormatTh = convertNumberToOrdinal(day);

  return dayName + ' ' + dayFormatTh + ' ' + monthName;
};

export const get12HourFormatWithMeridiem = (date: Date): string => {
  const hours: number = date.getHours();
  const meridiem: string = hours >= 12 ? 'pm' : 'am';
  const hour12: number = hours % 12 || 12; // the hour '0' should be '12'
  const minutes: string = `${
    date.getMinutes() < 10 ? '0' : ''
  }${date.getMinutes()}`;
  const strTime: string = hour12 + ':' + minutes + ' ' + meridiem;
  return strTime;
};

export const minuteToHour = (minutes: number): number =>
  Math.round(minutes / 60);

export const formatDateToDDMMYYYY = (date: Date): string => {
  if (!date) return '';
  const convertedToDate = new Date(date);
  const day = String(convertedToDate.getDate()).padStart(2, '0');
  const month = String(convertedToDate.getMonth() + 1).padStart(2, '0');
  const year = convertedToDate.getFullYear();
  return `${day}/${month}/${year}`;
};

export const capitaliseFirstLetter = (word: string): string => {
  if (!word) {
    return '';
  }
  const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
  return capitalized;
};

export const convertCodeListToNames = (
  entireList: { value: number; code: number; name: string }[],
  selected: number[],
  categoryText = '',
): string => {
  if (!selected) return 'None';
  if (selected.length === 0) return 'None';

  const filteredList = entireList
    .filter(({ value, code }) => selected.includes(value ?? code))
    .map(({ name }) => name);
  if (filteredList.length === 0) return 'None';
  if (filteredList.length === entireList.length) return `All ${categoryText}`;
  else {
    return filteredList.join(', ');
  }
};

export const countDefectsInCategory = (
  questions: CheckpointDetail['checkpoints'],
): number => {
  if (!questions.length) return 0;
  return questions.reduce(
    (count: number, question: CheckpointQuestion): number => {
      if (question.answer === PreCheckAnswer.DEFECT_FOUND) {
        count++;
      }
      return count;
    },
    0,
  );
};

export const getCategoryDefectDescription = (category: Category): string => {
  const categoryPrefix = {
    [Category.CATEGORY_A]: CategoryDefect.CATEGORY_A_DEFECT,
    [Category.CATEGORY_B]: CategoryDefect.CATEGORY_B_DEFECT,
    [Category.CATEGORY_C]: CategoryDefect.CATEGORY_C_DEFECT,
  };

  return categoryPrefix[category] || ExtraNoteDefect.EXTRA_NOTE_DEFECT;
};

export const isCategoryValid = (category: Category): boolean => {
  return includes(values(Category), category);
};

export const arrayofPreCheckEvaluationResult: PreCheckEvaluationResult[] =
  values(PreCheckEvaluationResult);

export const getStatusIndex = (status: PreCheckEvaluationResult): number => {
  return arrayofPreCheckEvaluationResult.indexOf(status);
};
