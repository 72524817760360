import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styles from './LiveTimer.module.css';
import moment from 'moment';
import { Asset } from '../../models/models';
import { AssetStatus } from '../../utils/constants';
import RefreshSVG from '../../assets/svgs/RefreshSVG';
import { useAssetTrackingContext } from '../../../pages/service/AssetTrackingContext';

type Props = {
  asset: Asset;
};

type AssetTrackingContextType = {
  setAPIRefresh: Dispatch<SetStateAction<number>>;
};

const LiveTimer = ({ asset }: Props) => {
  const [currentTime, setCurrentTime] = useState<string>(
    moment().format('hh:mm:ss A'),
  );
  const [rotate, setRotate] = useState<boolean>(false);
  const { setAPIRefresh } =
    useAssetTrackingContext() as AssetTrackingContextType;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format('hh:mm:ss A'));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleRefreshClick = useCallback(() => {
    setAPIRefresh((prev) => prev + 1);
    setRotate(true);
    // Reset rotation state after animation ends
    setTimeout(() => setRotate(false), 2000); // Match duration of CSS animation
  }, [setAPIRefresh]);

  return (
    <div className={styles.container}>
      {(asset?.Status === AssetStatus.Active ||
        asset?.Status === AssetStatus.Idling) && (
        <div className={styles.live}>LIVE</div>
      )}
      <div className={styles.timer}>
        {asset?.Status === AssetStatus.Active ||
        asset?.Status === AssetStatus.Idling
          ? currentTime
          : asset?.activity[0].startTime
          ? moment
              .unix(asset?.activity[0].startTime)
              .format('DD/MM/YYYY, hh:mm:ss A')
          : '00:00:00'}
      </div>
      <div
        className={`${styles.refresh} ${rotate ? styles.rotate : ''}`}
        onClick={handleRefreshClick}
      >
        <RefreshSVG />
      </div>
    </div>
  );
};

export default LiveTimer;
