import { useContext, useMemo, useState } from 'react';
import UserModal from './UserModal';
import * as toast from '../pages/components/toast';
import { UserContext } from '../pages/service/userContext';
import { API_METHODS, ENDPOINTS, UserType } from '../pages/utils/constants';
import {
  convertUserStatusToColor,
  emailValidation,
  generatePin,
  lengthValidation,
} from '../pages/utils/helper';
import BasicButton from '../pages/components/BasicButton';
import DropdownField from '../pages/components/FormDropdown';
import StatusToggle from '../pages/components/StatusToggle';
import StatusDot from '../pages/components/StatusDot';

const hideEmailField = (userType, selectedUser) => {
  if (!selectedUser) return userType === UserType.operator.value;
  else if (selectedUser.user_type === UserType.operator.value) {
    return userType === UserType.operator.value;
  } else return true;
};

const AddUser = ({
  modalType,
  modalToggle,
  selectedUser,
  showSuccessModal,
}) => {
  const { ApiHandler, userInfo } = useContext(UserContext);
  const { usersRestrictedDepartments, licenses } = useContext(UserContext);
  const [pinRadioButton, setPinRadioButton] = useState('autogenerate');

  const [values, setValues] = useState({
    'First Name': selectedUser?.first_name || '',
    'Last Name': selectedUser?.last_name || '',
    'Pin Number': selectedUser?.pin_number || generatePin(),
    'User Type': selectedUser?.user_type || userInfo.type,
    Department: selectedUser?.departments || [],
    'Equipment License': selectedUser?.licenses || [],
    Status: selectedUser?.status,
  });
  const [errorMessage, setErrorMessage] = useState({});

  const inputFields = useMemo(
    () => [
      {
        name: 'User Type',
        type: 'dropdown',
        options:
          userInfo.type === UserType.admin.value
            ? Object.values(UserType)
            : Object.values(UserType).filter(
                ({ value }) => value !== UserType.admin.value,
              ),
      },
      { name: 'First Name', type: 'text' },
      { name: 'Last Name', type: 'text' },
      {
        name: 'Email',
        type: 'text',
        restrictField: hideEmailField,
      },
      { name: 'Status', type: 'status' },
      {
        name: 'Department',
        type: 'dropdown',
        options: usersRestrictedDepartments.map(({ name, code }) => ({
          name,
          value: code,
        })),
        multiselect: true,
        restrictField: (userType) => userType === UserType.admin.value,
      },
      {
        name: 'Pin Number',
        type: 'pin',
        restrictField: (userType) => userType === UserType.admin.value,
      },
      {
        name: 'Equipment License',
        type: 'dropdown',
        options: licenses.map(({ name, code }) => ({ name, value: code })),
        multiselect: true,
        restrictField: (userType) => userType === UserType.admin.value,
      },
    ],
    [userInfo, licenses, usersRestrictedDepartments],
  );

  const handleChange = (fieldName, value, multiselect) => {
    if (multiselect) {
      setValues((prev) => {
        const updatedUser = { ...prev };
        if (updatedUser[fieldName].includes(value))
          updatedUser[fieldName] = updatedUser[fieldName].filter(
            (selectedValue) => selectedValue !== value,
          );
        else updatedUser[fieldName] = [...updatedUser[fieldName], value];
        return updatedUser;
      });
    } else setValues((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleClick = async () => {
    const emailErrorMsg = emailValidation(values['Email']);
    const firstNameErrorMsg = lengthValidation(
      values['First Name'],
      'first name',
    );
    const lastNameErrorMsg = lengthValidation(values['Last Name'], 'last name');

    let pinErrorMsg = '';
    let isError = false;
    if (!hideEmailField(values['User Type'], selectedUser) && emailErrorMsg) {
      isError = true;
    }
    if (firstNameErrorMsg || lastNameErrorMsg) {
      isError = true;
    }
    if (
      values['Pin Number'].toString().length !== 4 &&
      values['User Type'] !== UserType.admin.value
    ) {
      pinErrorMsg = 'Pin number must be four digits';
      isError = true;
    }
    if (
      values['Pin Number'] < 0 &&
      values['User Type'] === UserType.operator.value
    ) {
      pinErrorMsg = 'Pin number cannot be negative';
      isError = true;
    }

    let departmentsErrorMessage = '';
    if (userInfo.type !== UserType.admin.value) {
      if (!usersRestrictedDepartments.length) {
        departmentsErrorMessage =
          'You cannot add a user until you have been assigned to a department';
        isError = true;
      } else if (!values.Department.length) {
        departmentsErrorMessage =
          'You cannot add a user without assigning them to a department';
        isError = true;
      }
    }

    setErrorMessage({
      Email: emailErrorMsg,
      'First Name': firstNameErrorMsg,
      'Last Name': lastNameErrorMsg,
      'Pin Number': pinErrorMsg,
      Department: departmentsErrorMessage,
    });
    if (isError) return;

    try {
      const data = {
        first_name: values['First Name'].trim(),
        last_name: values['Last Name'].trim(),
        user_type: values['User Type'],
        departments: values.Department,
        licenses: values['Equipment License'],
      };

      if (values['User Type'] !== UserType.operator.value) {
        data.email = values.Email?.trim();
      }
      if (values['User Type'] !== UserType.admin.value) {
        data.pin = values['Pin Number'];
      }

      const editUserData = {
        ...data,
        user_id: selectedUser?._id,
        status: values.Status,
      };
      delete editUserData.password;

      if (selectedUser) {
        await ApiHandler({
          reqParam: editUserData,
          method: API_METHODS.PUT,
          endPoint: ENDPOINTS.editUser,
        });
        toast.success(`Successfully edited a user`);
      } else {
        await ApiHandler({
          reqParam: data,
          method: API_METHODS.POST,
          endPoint: ENDPOINTS.addUser,
        });
        if (values['User Type'] === UserType.operator.value) {
          toast.success(`Successfully added an operator`);
        } else {
          showSuccessModal();
        }
      }

      modalToggle(true);
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const handlePinChange = () => {
    const newPin = generatePin();
    setValues((prev) => ({ ...prev, 'Pin Number': newPin }));
  };

  return (
    <div>
      <UserModal
        onSaveClick={handleClick}
        modalOpen={modalType === 'add'}
        modalToggle={modalToggle}
        headerText={selectedUser ? 'Edit' : 'Add User'}
      >
        <div className="d-flex flex-column gap-2">
          {inputFields.map(
            ({ type, name, options, multiselect, restrictField }) => {
              if (
                restrictField &&
                restrictField(values['User Type'], selectedUser)
              )
                return null;
              else if (type === 'pin') {
                return (
                  <div
                    className="d-flex flex-row justify-content-between field-container"
                    key={name}
                  >
                    <label className="flex-one">PIN Number *</label>
                    <div className="d-flex flex-column add-user-input">
                      <div className="d-flex flex-row gap-4 justify-content-start">
                        <label className="p-2">
                          <input
                            type="radio"
                            value="custom"
                            checked={pinRadioButton === 'custom'}
                            onChange={() => setPinRadioButton('custom')}
                            className="me-2"
                          />
                          Custom
                        </label>

                        <label className="p-2">
                          <input
                            type="radio"
                            value="autogenerate"
                            checked={pinRadioButton === 'autogenerate'}
                            onChange={() => setPinRadioButton('autogenerate')}
                            className="me-2"
                          />
                          Autogenerate
                        </label>
                      </div>
                      <div className="d-flex flex-row gap-2 add-user-input">
                        <input
                          type="number"
                          disabled={pinRadioButton === 'autogenerate'}
                          className="add-user-input"
                          name="Pin Number"
                          value={values['Pin Number']}
                          onChange={(e) => handleChange(name, e.target.value)}
                          min={0}
                        />
                        {pinRadioButton === 'autogenerate' && (
                          <BasicButton outlined onClick={handlePinChange}>
                            Generate New Pin
                          </BasicButton>
                        )}
                      </div>
                      {errorMessage[name] && (
                        <p className="error-msg-manage-operator">
                          {errorMessage[name]}
                        </p>
                      )}
                    </div>
                  </div>
                );
              } else if (type === 'dropdown') {
                return (
                  <DropdownField
                    name={`${name} *`}
                    fieldKey={name}
                    key={name}
                    selectedOptions={values[name]}
                    allOptions={options}
                    multiselect={multiselect}
                    handleChange={handleChange}
                    errorMessage={errorMessage[name]}
                  />
                );
              } else if (type === 'text') {
                return (
                  <div
                    className="d-flex flex-row justify-content-between field-container"
                    key={name}
                  >
                    <label className="flex-one">{name} * </label>
                    <div className="d-flex flex-column add-user-input">
                      <input
                        type="text"
                        name={name}
                        value={values[name]}
                        onChange={(e) => handleChange(name, e.target.value)}
                      />
                      {errorMessage[name] && (
                        <p className="error-msg-manage-operator">
                          {errorMessage[name]}
                        </p>
                      )}
                    </div>
                  </div>
                );
              } else if (type === 'status') {
                return (
                  selectedUser?.status &&
                  (selectedUser.status !== 'Invited' ? (
                    <div
                      className="d-flex flex-row justify-content-between field-container"
                      key={name}
                    >
                      <label className="flex-one">Status</label>
                      <div className="m-auto switch-container">
                        <div className="d-flex align-items-center gap-4">
                          <StatusToggle
                            isActive={values.Status === 'Active'}
                            onChange={() =>
                              handleChange(
                                'Status',
                                values.Status === 'Active'
                                  ? 'Inactive'
                                  : 'Active',
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="d-flex flex-row justify-content-between field-container"
                      key={name}
                    >
                      <label
                        className="flex-one"
                        icon={{ checked: 'Active', unchecked: null }}
                      >
                        Status
                      </label>
                      <div className="status-container d-flex">
                        <StatusDot
                          className={convertUserStatusToColor(
                            selectedUser.status,
                          )}
                        >
                          {selectedUser.status}
                        </StatusDot>
                      </div>
                    </div>
                  ))
                );
              } else return null;
            },
          )}
        </div>
      </UserModal>
    </div>
  );
};

export default AddUser;
