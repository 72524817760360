import ReactDOM from 'react-dom';
import speedIcon from './assets/speed.svg';
import moment from 'moment';

export const SpeedingPopup = ({ data }) => {
  const div = document.createElement('div');
  const popup = (
    <>
      <div key={`popup-${data?.latitude}-${data?.longitude}`}>
        <div className="flyout-asset-gray">
          <div className="d-flex  flex-column align-items-start">
            <div
              className="mb-2"
              style={{
                borderBottom: '1px solid #beb6b6',
                color: '#000000',
                width: '100%',
              }}
            >
              Speeding
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                width={18}
                height={14}
                style={{ objectFit: 'contain' }}
                src={speedIcon}
                alt="speed"
                className="me-2"
              />
              <div className="">
                {' '}
                &gt;{data?.event_speed ?? data?.spd + ' km/h'}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                className="mt-1"
                style={{ opacity: 0.7, fontSize: '0.7rem' }}
              >
                {moment(data.timestamp * 1000).isSame(moment(), 'day')
                  ? moment(data?.timestamp * 1000).format('hh:mm a')
                  : moment(data?.timestamp * 1000).format(
                      'hh:mm a, DD/MM/yyyy',
                    )}
              </div>
            </div>
          </div>
        </div>
        <div className="asm_popup-tip-container-gray"></div>
      </div>
    </>
  );

  ReactDOM.render(popup, div);
  return div;
};
