import React, { useCallback, useEffect, useState } from 'react';
import SettingsModal from './SettingsModal';
import SettingsIcon from '../../../../assets/icons/SettingsIcon.svg';
import SettingsIconSelected from '../../../../assets/icons/SettingsIconSelected.svg';
import '../styles.scss';

const Settings = (props) => {
  const { toShowFilter, openModal, openFilterModal } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onClick={openModal}
      className={`${
        toShowFilter ? 'd-flex' : 'd-none'
      } align-items-center ms-3 filter-div cursor-pointer`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={
          openFilterModal
            ? SettingsIcon
            : isHovered
            ? SettingsIconSelected
            : SettingsIcon
        }
        alt="settings"
      />
      <span
        style={{ color: isHovered ? '#377FF2' : 'black' }}
        className="asm-hover-settings p-2"
      >
        Settings
      </span>
    </div>
  );
};

const SettingsFilter = (props) => {
  const { toShowFilter, onSaveClick = () => {} } = props;

  const [openFilterModal, setOpenFilterModal] = useState(false);

  const saveAndClose = (filterData, hasFiltered) => {
    setOpenFilterModal(false);
    onSaveClick(filterData, hasFiltered);
  };
  return (
    <div className="test-class">
      <Settings
        userFilter={props.userFilter}
        toShowFilter={toShowFilter}
        openFilterModal={openFilterModal}
        openModal={() => setOpenFilterModal(true)}
      />
      {openFilterModal && SettingsModal ? (
        <SettingsModal
          modalOpen={openFilterModal}
          modalToggle={() => setOpenFilterModal(false)}
          saveHandler={saveAndClose}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
export default SettingsFilter;
