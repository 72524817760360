import LocationIconSVG from './Components/LocationIconSVG';
export const legendDropDownData = [
  {
    title: 'Status',
    subtitle: ' (Ongoing day)',
    legends: [
      {
        icon: (
          <div className="asm-legend-box2" style={{ background: '#CFFD69' }} />
        ),
        label: 'Active (Engine on)',
      },
      {
        icon: (
          <div className="asm-legend-box2" style={{ background: '#ED5151' }} />
        ),
        label: 'Disconnected',
      },
      {
        icon: (
          <div className="asm-legend-box2" style={{ background: '#BEB6B6' }} />
        ),
        label: 'Parked',
      },
    ],
  },
  {
    title: 'Alerts',
    legends: [
      {
        icon: (
          <div
            className="asm-legend-box1"
            style={{ background: '#73bf63', width: '0.5rem' }}
          />
        ),
        label: 'Speeding (30 - 40 km/h)',
      },
      {
        icon: (
          <div
            className="asm-legend-box1"
            style={{ background: '#181cf5', width: '0.5rem' }}
          />
        ),
        label: 'Speeding (40 - 55 km/h)',
      },
      {
        icon: (
          <div
            className="asm-legend-box1"
            style={{ background: '#e23123', width: '0.5rem' }}
          />
        ),
        label: 'Speeding (> 55km/h)',
      },
      {
        icon: (
          <div
            className="asm-legend-box1"
            style={{ background: '#ea9336', width: '0.5rem' }}
          />
        ),
        label: 'Idle time',
      },
    ],
  },
];

export const legendDropDownDataMap = [
  {
    title: 'Alerts',
    legends: [
      {
        icon: <LocationIconSVG color="#73bf63" />,
        label: 'Speeding (30 - 40 km/h)',
      },
      {
        icon: <LocationIconSVG color="#181cf5" />,
        label: 'Speeding (40 - 55 km/h)',
      },
      {
        icon: <LocationIconSVG color="#e23123" />,
        label: 'Speeding (> 55 km/h)',
      },
    ],
  },
  {
    legends: [
      {
        icon: <LocationIconSVG color="#ea9336" />,
        label: 'Idle Time',
      },
    ],
  },
];
