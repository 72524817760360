// export const REACT_APP_API_ENDPOINT = "https://bathurst-shift-api.imos.co.nz";
// export const BASE_URL = "https://bathurst-shift-api.imos.co.nz";
// const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const REACT_APP_API_ENDPOINT = '/';
// export const REACT_APP_API_ENDPOINT = "http://localhost:3002/";
export const BASE_URL = '/material-movements/api';

// export const REACT_APP_API_ENDPOINT = "http://bathurst-shift-api.imos.co.nz:3000";
// export const BASE_URL = "http://bathurst-shift-api.imos.co.nz:3000";

export const SEND_OTP = `${BASE_URL}/send-otp`; // POST
export const VERIFY_OTP = `${BASE_URL}/verify-otp`; // POST
export const LOGIN_API_URL = `${BASE_URL}/signin`; //POST

export const GET_USER_DETAILS = (id) => `${BASE_URL}/viewsingleuser?id=${id}`; //GET
export const GEt_ALL_USER = `${BASE_URL}/viewalluser`; // GET query param ?NUMBER_OF_ITEMS=20&PAGE_NUMBER=1
export const GEt_ALL_USER_WITH_FILTER = `${BASE_URL}/searchuser`; // POST

export const GET_PIN = `${BASE_URL}/generatepin`; //GET

export const ADD_USER = `${BASE_URL}/adduser`; //POST
export const ADD_SUPER_ADMIN = `${BASE_URL}/addsuperadmin`; //POST

export const UPDATE_USER = (id) => `${BASE_URL}/edituser/${id}`; //POST
export const UPDATE_SUPER_ADMIN = `${BASE_URL}/addsuperadmin`; //POST

export const GET_USER_ACCOUNT_DETAILS = `${BASE_URL}/getmyaccount`; //GET

export const DELETE_MULTIPLE_USER = `${BASE_URL}/deletemultipleuser`; //POST
export const DEACTIVATE_MULTIPLE_USER = `${BASE_URL}/deactivateuser`; //POST

export const DELETE_USER = (id) => `${BASE_URL}/deletesingleuser?id=${id}`; //POST
export const PIN_GENERATE = `${BASE_URL}/generatepin`; //POST
export const CHANGE_PASSWORD = `${BASE_URL}/adminchangepassword`; //POST

export const CHANGE_PASSWORD_ADMIN = `${BASE_URL}/updatePassword`; //POST

export const forgot_password = `${BASE_URL}/forgotpassword`; //POST
export const EDIT_ADMIN_USER = `${BASE_URL}/editadminuser`; //PUT

export const SHIFT_LISTING_DETAILS = `${BASE_URL}/getshiftdetail`; //POST
export const GET_REPORT_CODE = `${BASE_URL}/generaterepotcode`; //GET
export const GET_MINES = `${BASE_URL}/getmines`; //GET
export const GET_DEPARTMENTS = `${BASE_URL}/getdepartments`; //GET
export const GET_CREW = `${BASE_URL}/getcrewtypes`; //GET
export const GET_EQUIMENTS = `${BASE_URL}/geteuipments`; //POST

export const GET_HULK_TRUCK = `${BASE_URL}/getequipmenttype`; //POST
export const GET_AREA = `${BASE_URL}/getarea`; //GET
export const GET_MATERIALS = `${BASE_URL}/getmaterialdetails`; //GET
export const GET_MINE_PRIORITY = `${BASE_URL}/getmines`; //GET
export const GET_OPERATORS = `${BASE_URL}/getoperators`; //GET
export const GET_OPERATORS_SHIFT_DETAILS = `${BASE_URL}/getoperatorshiftdetails`; //GET

export const SAVE_AND_PUBLISH = `${BASE_URL}/publishshiftplan`; //POST
export const EDIT_AND_PUBLISH = `${BASE_URL}/editdraftplan`; //POST
export const GET_SHIFT_DETAILS = `${BASE_URL}/getsingleshiftdetail`; //GET

export const GET_MINE_PRIORITYS = `${BASE_URL}/getminepriorities`; //GET
export const DUPLICATE_SHIFT_PLAN = `${BASE_URL}/duplicateshiftplan`; //POST
export const DELETE_SHIFT_PLAN = `${BASE_URL}/deleteshiftplan`; //POST
export const PUBLISH_SHIFT_PLAN = `${BASE_URL}/changestatus`; //POST
export const SHIFT_DASHBOARD_LISTING_DETAILS = `${BASE_URL}/getShiftDetailDashboard`; //POST
export const GET_ASSET_PERFORMANCE = `${BASE_URL}/getAssetPerformance`; //POST
export const GET_DUMP_CYCLE_DATA = `${BASE_URL}/getDumpCycleData`; //POST
export const SHIFT_HOUR_DATA = `${BASE_URL}/getHourShiftData`; //POST
export const SHIFT_DATA = `${BASE_URL}/getShiftData`; //POST
export const GET_ALL_TRUCKS_EXCAVATORS = `${BASE_URL}/getAllTrucksAndExcevators`; //GET

export const GET_ALL_SOURCE_AND_DUMPS = `${BASE_URL}/getAllSourceAndDumpPoint`; //GET

export const GET_ALL_OPERATORS = `${BASE_URL}/getAllOperators`; //GET

export const SAVE_LOGIN_LOGOUT_TIME = `${BASE_URL}/availabletime`; //POST
export const GET_PRESENT_TOMMOROW_DATA = `${BASE_URL}/openweather/get-current-weather`; //GET
export const GET_PRESENT_FORECAST_DATA = `${BASE_URL}/openweather/forecast`; //GET

// Data from Webhook
export const GET_WEBHOOK_DATA = `${BASE_URL}/fetchWeatherData`; //GET

// Generate PDF
export const GENERATE_PDF = `${BASE_URL}/generatepdf`; //POST

//dumPoints
export const SAVE_SOURCE_DUMP = `${BASE_URL}/source-dump/save`; //POST
export const GET_SOURCE_DUMPS = `${BASE_URL}/getsourcedumps`; //GET
export const EDIT_SOURCE_DUMP = `${BASE_URL}/source-dump/edit`; //POST
export const DELETE_SOURCE_DUMP = `${BASE_URL}/source-dump/delete`; //POST

// dumpEvents
export const GET_DUMP_EVENTS = `${BASE_URL}/get-all-dumps`; // GET
export const GET_VEHICLE_EVENTS = `${BASE_URL}/get-vehicle-dumps`; // GET
export const GET_ALL_OPERATOR_FOR_SHIFTS = `${BASE_URL}/get-operator-for-shifts`; // GET

export const GET_HISTORY = `${BASE_URL}/history`; // GET
export const GET_SOURCE_HISTORY = `${BASE_URL}/source-history`; // GET
// shift history
export const GET_SHIFT_HISTORY = `${BASE_URL}/shift-history`; // GET
// export const END_ALL_SHIFTS = `${BASE_URL}/end-shifts`; // PUT
export const END_SHIFT = `${BASE_URL}/end-shift`; // PUT
