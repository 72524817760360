// @ts-nocheck
import { useRef, FunctionComponent } from 'react';
import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Sector,
} from 'recharts';
import { DefectColor } from '../../helper/constants';
import { PiechartGraphProps } from '../../helper/types';

const defaultPieData = [{ value: 1, color: '#d2d2d2' }];

const PieChartGraph: FunctionComponent<PiechartGraphProps> = (props) => {
  const smallPieSegments = useRef([false, false]);

  const allValuesAreZero = props.chartData?.every((obj) => obj.value === 0);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
      percent,
      name,
    } = props;

    if (value === 0) return;
    const zoneNumber = name.at(-1);
    let valueTextPosition = 16;

    const smallPieSegmentsCopy = [...smallPieSegments.current];
    if (percent < 0.02) {
      smallPieSegments.current[zoneNumber] = zoneNumber;
      const multiplier = //cannot be 1 because default multiplier is 1
        smallPieSegments.current
          .filter(Boolean)
          .findIndex((segment) => segment === zoneNumber) === 5
          ? 0
          : smallPieSegments.current
              .filter(Boolean)
              .findIndex((segment) => segment === zoneNumber) + 2;
      valueTextPosition = multiplier * valueTextPosition;
    } else smallPieSegmentsCopy[zoneNumber] = false;
    smallPieSegments.current = smallPieSegmentsCopy;

    // use ex and mx to affect how they are spaced
    // ideally in intervals of three so they don't overlap eachtoher
    const sin: number = Math.sin(-RADIAN * midAngle);
    const cos: number = Math.cos(-RADIAN * midAngle);
    const sx: number = cx + (outerRadius - 20) * cos;
    const sy: number = cy + (outerRadius - 20) * sin;
    const mx: number = cx + (outerRadius + 11) * cos;
    const my: number = cy + (outerRadius + valueTextPosition) * sin;
    let ex: number = mx + (cos >= 0 ? 1 : -1) * valueTextPosition;
    let ey: number = my;
    const textAnchor: 'start' | 'end' = cos >= 0 ? 'start' : 'end';
    const graphLinePath: string = `M${sx},${sy}L${mx},${my}L${ex},${ey}`;

    return (
      <g key={props?.key} id={props?.key}>
        {/* pie slice with color */}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />

        {props.showLabel ? (
          <>
            {/* Inner dot */}
            <circle cx={sx} cy={sy} r={2} fill="#2B2D2F" stroke="none" />

            {/* line */}
            <path d={graphLinePath} stroke="#2B2D2F" fill="none" />

            {/* litres text */}
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 2}
              y={ey}
              textAnchor={textAnchor}
              fill="#333"
              fontSize={13}
              fontWeight={600}
            >
              {value.toLocaleString('en-US')}
            </text>
          </>
        ) : null}
      </g>
    );
  };

  return (
    <ResponsiveContainer>
      {allValuesAreZero ? (
        <PieChart
          width={'100%'}
          height={'100%'}
          key={props?.key}
          id={props?.key}
        >
          <Pie
            outerRadius={'5.6rem'}
            isAnimationActive={false}
            dataKey="value"
            data={defaultPieData}
          >
            {defaultPieData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
                stroke={entry.color}
              />
            ))}
          </Pie>
        </PieChart>
      ) : (
        <PieChart key={props?.key} id={props?.key}>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={props?.chartData}
            cx="50%"
            cy="50%"
            nameKey={props?.key}
            outerRadius={props?.outerRadius ? props?.outerRadius : 70}
            activeShape={(args) => renderActiveShape({ ...args, ...props })}
            activeIndex={[0, 1, 2, 3, 4, 5]}
          >
            {props.chartData?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={DefectColor[entry.name]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      )}
    </ResponsiveContainer>
  );
};

export default PieChartGraph;
