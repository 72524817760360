import { useEffect, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';

const CreatePointTable = ({
  coordinates = [{}, {}, {}],
  setFieldChanged = () => {},
  setSelectedPoint = () => {},
  onChange,
}) => {
  const [focusedIndex, setFocusedIndex] = useState(-1);

  useEffect(() => {
    console.log({ coordinates, focusedIndex });
    if (JSON.stringify(coordinates) != JSON.stringify([{}, {}, {}]))
      setSelectedPoint({
        coordinate: coordinates[focusedIndex],
        index: focusedIndex,
      });
  }, [focusedIndex, coordinates]);

  useEffect(() => {
    console.log(coordinates);
    if (coordinates.length < 3) {
      let temp = [];
      if (coordinates[0]?.lat) {
        if (coordinates[0]?.lng) {
          temp.push({ lat: coordinates[0].lat, lng: coordinates[0].lng });
        } else {
          temp.push({ lat: coordinates[0].lat });
        }
      } else {
        temp.push({});
      }
      if (coordinates[1]?.lat) {
        if (coordinates[1]?.lng) {
          temp.push({ lat: coordinates[1].lat, lng: coordinates[1].lng });
        } else {
          temp.push({ lat: coordinates[1].lat });
        }
      } else {
        temp.push({});
      }
      temp.push({});
      onChange(temp);
    }
  }, [coordinates]);

  return (
    <div style={{ width: '100%' }}>
      <>
        <div
          style={{
            marginBottom: '0.7rem',
            width: '100%',
            display: 'flex',
          }}
        >
          <div
            style={{ alignSelf: 'center', width: '20%', height: '90%' }}
          ></div>
          <div
            style={{
              font: 'normal normal 600 14px/19px Open Sans',
              fontSize: '1rem',
              alignSelf: 'center',
              width: '35%',
              height: '90%',
              color: '#000',
              fontWeight: 600,
            }}
          >
            Latitude
          </div>
          <div
            style={{
              font: 'normal normal 600 14px/19px Open Sans',
              fontSize: '1rem',
              alignSelf: 'center',
              width: '35%',
              height: '90%',
              color: '#000',
              fontWeight: 600,
            }}
          >
            Longitude
          </div>
          <div
            style={{
              fontSize: '1rem',
              alignSelf: 'center',
              width: '10%',
              height: '90%',
              fontWeight: 600,
            }}
          ></div>
        </div>
        {coordinates?.map((source, index) => {
          return (
            <div
              style={{
                color: '#000',
                width: '100%',
                display: 'flex',
              }}
            >
              <div style={{ alignSelf: 'center', width: '20%', height: '90%' }}>
                <span
                  style={{
                    font: ' normal normal 600 14px/19px Open Sans',

                    color: '#000',
                    fontWeight: 600,
                    fontSize: '1rem',
                  }}
                >
                  <div
                    style={{
                      fontSize: '1.15rem',
                    }}
                  >
                    {' '}
                    POINT {1 + index}{' '}
                  </div>
                </span>
              </div>

              <div
                style={
                  index == 0
                    ? {
                        alignSelf: 'center',
                        width: '35%',
                        height: '90%',
                        borderTopLeftRadius: '4px',
                        borderColor: '#EBEBEB',
                        border:
                          index == focusedIndex
                            ? '1px solid #05795F '
                            : '1px solid #EBEBEB ',
                        backgroundColor:
                          index == focusedIndex ? '#05795F1A' : '',
                      }
                    : index == coordinates.length - 1
                    ? {
                        alignSelf: 'center',
                        width: '35%',
                        height: '90%',
                        borderBottomLeftRadius: '4px',
                        borderColor: '#EBEBEB',
                        border:
                          index == focusedIndex
                            ? '1px solid #05795F '
                            : '1px solid #EBEBEB',
                        backgroundColor:
                          index == focusedIndex ? '#05795F1A' : '',
                      }
                    : {
                        alignSelf: 'center',
                        width: '35%',
                        height: '90%',
                        border:
                          index == focusedIndex
                            ? '1px solid #05795F '
                            : '1px solid #EBEBEB',
                        backgroundColor:
                          index == focusedIndex ? '#05795F1A' : '',
                      }
                }
                className=" py-2 px-2 mm-create-source-table"
              >
                <input
                  type="number"
                  onFocus={() => setFocusedIndex(index)}
                  style={{
                    border: 'none',
                    width: '100%',

                    font: 'normal normal normal 12px/17px Open Sans',
                    fontSize: '1rem',
                    backgroundColor: index == focusedIndex ? '#05795F00' : '',
                  }}
                  onBlur={() => setFocusedIndex(-1)}
                  value={source?.lat}
                  onChange={(event) => {
                    setFieldChanged(true);
                    event.preventDefault();
                    const newValue = Number(
                      parseFloat(event?.target?.value).toFixed(6),
                    );
                    const newData = [...coordinates];
                    newData[index] = { ...source, lat: newValue };
                    onChange(newData);
                  }}
                />
              </div>

              <div
                style={
                  index == 0
                    ? {
                        alignSelf: 'center',
                        width: '35%',
                        height: '90%',
                        borderTopRightRadius: '4px',
                        borderColor: '#EBEBEB',
                        border:
                          index == focusedIndex
                            ? '1px solid #05795F '
                            : '1px solid #EBEBEB',
                        backgroundColor:
                          index == focusedIndex ? '#05795F1A' : '',
                      }
                    : index == coordinates.length - 1
                    ? {
                        alignSelf: 'center',
                        width: '35%',
                        height: '90%',
                        borderBottomRightRadius: '4px',
                        borderColor: '#EBEBEB',
                        border:
                          index == focusedIndex
                            ? '1px solid #05795F '
                            : '1px solid #EBEBEB',
                        backgroundColor:
                          index == focusedIndex ? '#05795F1A' : '',
                      }
                    : {
                        alignSelf: 'center',
                        width: '35%',
                        height: '90%',
                        border:
                          index == focusedIndex
                            ? '1px solid #05795F '
                            : '1px solid #EBEBEB',
                        backgroundColor:
                          index == focusedIndex ? '#05795F1A' : '',
                      }
                }
                className=" py-2 px-2 mm-create-source-table"
              >
                <input
                  type="number"
                  style={{
                    border: 'none',
                    width: '100%',

                    font: 'normal normal normal 12px/17px Open Sans',
                    fontSize: '1rem',
                    backgroundColor: index == focusedIndex ? '#05795F00' : '',
                  }}
                  value={source?.lng}
                  onFocus={(v) => {
                    console.log(v);
                    setFocusedIndex(index);
                  }}
                  onChange={(event) => {
                    event.preventDefault();
                    const newValue = Number(
                      parseFloat(event?.target?.value).toFixed(6),
                    );

                    setFieldChanged(true);
                    const newData = [...coordinates];
                    newData[index] = { ...source, lng: newValue };
                    onChange(newData);
                  }}
                  onBlur={() => setFocusedIndex(-1)}
                />
              </div>

              <div
                className="cursor-pointer"
                style={{
                  fontSize: '1rem',
                  alignSelf: 'center',
                  width: '10%',
                  height: '90%',
                  textAlign: 'center',
                }}
              >
                <AiFillDelete
                  onClick={() => {
                    let newData = coordinates?.slice();
                    newData.splice(index, 1);
                    onChange(newData);
                  }}
                />
              </div>
            </div>
          );
        })}
      </>

      <div className="mt-2 mb-3">
        <div
          style={{
            fontSize: '1rem',
            lineHeight: '1.5',
            color: '#707070',
            opacity: 1,
            margin: 0,
          }}
        >
          Follow this format : <br /> 60.000000
        </div>
      </div>
      <button
        className="btn save btn-focus"
        onClick={() => {
          onChange([...coordinates, {}]);
        }}
        style={{
          width: '9.2rem',
          height: '2.5rem',
          font: 'normal normal bold 12px/17px Open Sans',
          border: ' 1px solid black',
          background: 'white 0% 0% no-repeat padding-box',
          borderRadius: '4px',
          color: '#2B2D2F',
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'center',
          marginBottom: '20px',
          fontSize: '1rem',
        }}
      >
        Add Point
      </button>
    </div>
  );
};
export default CreatePointTable;
