import ReactDOM from 'react-dom';
import MarkerFlyout from './FLyouts/MarkerFlyout';
import './index.css';

export const AssetPopup = (coordinate, { data }) => {
  const div = document.createElement('div');
  div.style.position = 'fixed'; // Use fixed or absolute depending on your layout
  div.style.top = '-430px'; // Adjust this value as needed
  div.style.left = '50%'; // Center horizontally
  div.style.transform = 'translateX(-55%)'; // Adjust for centering
  const popup = (
    <>
      <div key={`popup-${coordinate.lat}-${coordinate.lng}`}>
        <MarkerFlyout data={data} />
      </div>
      <div className="asm_popup-tip-container"></div>
    </>
  );

  ReactDOM.render(popup, div);
  return div;
};
