import 'react-toastify/dist/ReactToastify.css';
import './pages/assets/styles/app.scss';
import { Route, Routes } from 'react-router-dom';
import ManageUser from './manageUser';
import Login from './pages/Login';
import { UserState } from './pages/service/userContext';
import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {
  ProtectRoute,
  RedirectToLandingPage,
} from './pages/service/protectRoute';
import PreStartChecks from './preStartChecks';
import MaterialMovements from './materialMovements/index';
import Summary from './preStartChecks/Summary';
import Report1 from './preStartChecks/Components/reports/report-1';
import Spinners from './pages/components/spinner';
import { links } from './pages/utils/constants';
import DmuDashboard from './dmu';
import AssetTracking from './assetTracking/AssetTracking';
import { SocketProvider } from './assetTracking/AssetTracking/utils/socket';
import Maintenance from './maintenance';
import { GlobalProvider } from './pages/service/GlobalContext';
import { AssetTrackingProvider } from './pages/service/AssetTrackingContext';

const Unauthorized = React.lazy(() => import('./pages/unauthorized'));
const PasswordChanged = React.lazy(() =>
  import('./pages/profile/set-password/password-set-message'),
);
const InvalidLink = React.lazy(() =>
  import('./pages/profile/set-password/Invalid-password-reset-link'),
);
const SetPassword = React.lazy(() => import('./pages/profile/set-password'));
const LandingPage = React.lazy(() => import('./pages/landingPage'));
const ForgotPassword = React.lazy(() =>
  import('./pages/profile/ForgotPassword'),
);

const AppRoutes = () => {
  return (
    <>
      <div style={{ zIndex: 1, height: '100%' }}>
        <Suspense fallback={<Spinners />}>
          <Routes>
            {/* protected routes -- must be logged in */}
            <Route element={<ProtectRoute />}>
              <Route path="/landing-page" element={<LandingPage />}></Route>
              <Route
                path="/user-management"
                element={<ManageUser code={700} />}
              />
              <Route
                path="/pre-start-check"
                element={<PreStartChecks code={600} />}
              />
              <Route
                path="/material-movements"
                element={<MaterialMovements code={200} />}
              />
              <Route
                path="/asset-tracking"
                element={
                  <AssetTrackingProvider>
                    <AssetTracking code={800} />
                  </AssetTrackingProvider>
                }
              />
              <Route path="/dmu" element={<DmuDashboard code={400} />} />
              <Route path="/summary" element={<Summary code={200} />} />
              <Route path="/prestart-report" element={<Report1 code={200} />} />
              <Route path="/maintenance" element={<Maintenance code={300} />} />

              {[links[500], links[100]].map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                      <h2 className="p-2">Coming soon!</h2>
                      <Link to="/landing-page">Go back</Link>
                    </div>
                  }
                />
              ))}
              <Route
                path="*"
                element={
                  <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                    <h1 className="display-1">404</h1>
                    <h2>Ooops, page not found!</h2>
                    <Link to="/landing-page">Go back</Link>
                  </div>
                }
              />
            </Route>

            {/* protected routes -- must not be logged in */}
            <Route element={<RedirectToLandingPage />}>
              <Route path="/" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </Route>

            {/* public routes */}
            <Route path="/set-password" element={<SetPassword />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/password-changed" element={<PasswordChanged />} />
            <Route path="/invalid-link" element={<InvalidLink />} />
          </Routes>
        </Suspense>
      </div>
    </>
  );
};

const App = () => {
  return (
    <div className="app">
      <SocketProvider>
        <UserState>
          <GlobalProvider>
            <AppRoutes />
          </GlobalProvider>
        </UserState>
      </SocketProvider>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;
