import React, { useEffect, useState } from 'react';
import styles from './AssetList.module.css';
import Search from '../../pages/components/Search/Search';
import Button from '../../pages/components/Button/Button';
import { useGlobalContext } from '../../pages/service/GlobalContext';
import { useAssetTrackingContext } from '../../pages/service/AssetTrackingContext';
import LoaderSpinner from '../../dmu/assets/LoaderSpinner';
import AssetTable from './AssetTable';
import { AssetStatus } from '../utils/constants';

const AssetList = ({ showAssetTablePopUp, setShowAssetTablePopUp }) => {
  const { setShowLiveAssetTracking, showLiveAssetTracking } =
    useGlobalContext();
  const { assetsData, loading, selectedAssets, setSelectedAssets } =
    useAssetTrackingContext();

  const [filteredAssets, setFilteredAssets] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [search, setSearch] = useState('');
  const [tempSelectedAssets, setTempSelectedAssets] = useState([]);

  useEffect(() => {
    setFilteredAssets(
      assetsData
        .filter((asset) =>
          asset.title.toLowerCase().includes(search.toLowerCase()),
        )
        .filter((asset) => {
          if (selectedStatus === '') return true;

          if (selectedStatus === AssetStatus.Active) {
            return (
              asset.Status === AssetStatus.Active ||
              asset.Status === AssetStatus.Idling
            );
          }

          return asset.Status === selectedStatus;
        }),
    );
  }, [search, selectedStatus, assetsData]);

  useEffect(() => {
    setTempSelectedAssets(selectedAssets);
  }, [selectedAssets]);

  const handleLiveClick = () => {
    setShowLiveAssetTracking(!showLiveAssetTracking);
  };

  const handleApply = () => {
    setSelectedAssets(tempSelectedAssets);
    setShowAssetTablePopUp(false);
  };

  const handleClearAll = () => {
    setTempSelectedAssets([]);
  };

  return (
    <div className={styles.main}>
      {!showAssetTablePopUp && (
        <div className={styles.header}>
          <Search query={search} onChange={(value) => setSearch(value)} />
        </div>
      )}
      <div
        className={styles.container}
        style={{ height: showAssetTablePopUp ? 'calc(60vh)' : '' }}
      >
        {loading ? (
          <LoaderSpinner />
        ) : (
          <AssetTable
            search={search}
            filteredAssets={filteredAssets}
            setFilteredAssets={setFilteredAssets}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            showAssetTablePopUp={showAssetTablePopUp}
            setSelectedAssets={
              showAssetTablePopUp ? setTempSelectedAssets : setSelectedAssets
            }
            selectedAssets={
              showAssetTablePopUp ? tempSelectedAssets : selectedAssets
            }
          />
        )}
      </div>
      <div className={styles.footer}>
        {showAssetTablePopUp ? (
          <>
            <Button
              title={'Clear All'}
              onClick={handleClearAll}
              color="#0F63DA"
              style={{
                color: '#0F63DA',
                border: '1px solid #E2E2E2',
                backgroundColor: 'transparent',
              }}
            />
            <Button
              isDisabled={
                JSON.stringify(selectedAssets) ===
                JSON.stringify(tempSelectedAssets)
              }
              title={'Apply'}
              onClick={handleApply}
            />
          </>
        ) : (
          <>
            <div className={styles.footerText}>
              Displaying {filteredAssets.length}/{assetsData.length} assets
            </div>
            <Button
              isDisabled={selectedAssets.length === 0}
              title={'View live tracking'}
              onClick={handleLiveClick}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AssetList;
