import StatusDot from '../../pages/components/StatusDot';
import styles from './Timeline.module.css';
import { getValueForTooltipAnalysis } from './helper';
import { TimelineGranularity, TimelineTypes } from './models';
import { TruckType } from '../model';
import { TooltipType } from 'recharts/types/util/types';
import { ReactNode } from 'react';
import { MESSAGES } from '../constants';
import { convertMinutesToHHMM } from '../helper';

interface CustomTooltipProps {
  active?: boolean;
  label?: number;
  payload?: {
    type?: TooltipType;
    color?: string;
    name: string;
    value: string | number;
    unit?: ReactNode;
    dataKey?: string | number;
    payload?: any;
    chartType?: string;
    stroke?: string;
    strokeDasharray?: string | number;
    strokeWidth?: number | string;
    className?: string;
    hide?: boolean;
  }[];
  granuality: TimelineGranularity;
  timelineType: TimelineTypes;
  assetType: TruckType | undefined;
}

const CustomTooltip: React.FC<CustomTooltipProps> = (props) => {
  const hideMiddleSection: boolean =
    props.timelineType === TimelineTypes.Fuel ||
    props.timelineType === TimelineTypes.Productivity ||
    (props.timelineType === TimelineTypes.TimeUse &&
      props.granuality === TimelineGranularity.Hourly);

  if (props.active && props.label && props.payload) {
    return (
      <div className={styles.customTooltip}>
        <div
          className={`${styles.xTick} ${
            hideMiddleSection ? '' : styles.bottomBorder
          }`}
        >
          {props.granuality === TimelineGranularity.Hourly
            ? props.label + ' h'
            : props.label}
        </div>
        {!hideMiddleSection && (
          <div className={styles.totalVal}>
            <div className={styles.toolVal}>
              {props.timelineType === TimelineTypes.TimeUse
                ? convertMinutesToHHMM(
                    (Number(props.payload[0].value) +
                      Number(props.payload[1].value)) *
                      60,
                  ) + ' h'
                : props.timelineType === TimelineTypes.Utilisation
                ? convertMinutesToHHMM(
                    Number(props.payload[0]?.value ?? 0) +
                      Number(props.payload[1]?.value ?? 0),
                  ) + ' h'
                : ''}
            </div>
            <div className={styles.toolSubTxt}>
              {props.timelineType === TimelineTypes.TimeUse
                ? MESSAGES.totalTimeSite
                : props.timelineType === TimelineTypes.Utilisation
                ? MESSAGES.totalEngineOnTime
                : ''}
            </div>
          </div>
        )}
        <div className={styles.analysis}>
          Analysis <div></div>
        </div>
        <div className={styles.statusDots}>
          {props.payload.map((tooltipPayload, index) => {
            return (
              <StatusDot key={index} color={tooltipPayload.color}>
                {getValueForTooltipAnalysis(
                  tooltipPayload,
                  props.granuality,
                  props.timelineType,
                )}
              </StatusDot>
            );
          })}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default CustomTooltip;
