import React from 'react';

interface CloseIconProps {
  height?: number;
  width?: number;
  color?: string;
}

const CloseIcon: React.FC<CloseIconProps> = ({
  height = 30,
  width = 30,
  color = 'black',
}) => (
  <svg
    id="close_icon"
    data-name="close icon"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 30 30"
  >
    <rect
      id="Rectangle_97"
      data-name="Rectangle 97"
      width="30"
      height="30"
      rx="4"
      fill="none"
    />
    <g
      id="Group_79"
      data-name="Group 79"
      transform="translate(144.401 -782.318) rotate(45)"
    >
      <line
        id="Line_23"
        data-name="Line 23"
        y2="17.577"
        transform="translate(472.289 646.5)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Line_24"
        data-name="Line 24"
        y2="17.577"
        transform="translate(481.077 655.289) rotate(90)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default CloseIcon;
