import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import downArrow from './downArrow.svg';

export default function TimeSelect({
  uniqueName,
  onChange,
  value,
  disabled = false,
  defaultValue,
  inputButtonStyle = null,
  className = '',
  showBorder = true,
  dropDownStyle = {},
  background = '#FAFAFA',
}) {
  const [dropdown, setDropdown] = useState(false);
  const nameWithoutWhiteSpace = uniqueName
    ? uniqueName.split(' ').join('')
    : '';
  const [digitValue, setDigitValue] = useState({
    hour: defaultValue?.hour,
    minute: defaultValue?.minute,
    second: defaultValue?.second,
  });
  const [isAM, setIsAM] = useState(defaultValue?.isAM);

  function formatTime(hour, minute, second, isAM, options) {
    if (
      !(
        hour &&
        second &&
        minute &&
        0 < hour &&
        hour <= 12 &&
        0 <= minute &&
        minute < 60 &&
        0 <= second &&
        second < 60
      )
    ) {
      return '00:00 am';
    }

    let timeString = `${String(hour).padStart(2, '0')}:${String(
      minute,
    ).padStart(2, '0')}`;
    timeString += isAM ? ' am' : ' pm';
    return timeString;
  }

  useEffect(() => {
    if (
      (0 < digitValue.hour &&
        digitValue.hour <= 12 &&
        0 <= digitValue.minute &&
        digitValue.minute < 60 &&
        0 <= digitValue.second &&
        digitValue.second < 60) ||
      !digitValue.hour ||
      !digitValue.minute ||
      digitValue.second
    ) {
      onChange({ ...digitValue, isAM });
    }
  }, [digitValue, isAM]);

  useEffect(() => {
    const clickListener = (e) => {
      if (e.target.id !== `keepDropdownOpen${nameWithoutWhiteSpace}`) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', clickListener);

    return () => {
      document.removeEventListener('mousedown', clickListener);
    };
  }, [nameWithoutWhiteSpace]);

  const renderTimeInput = (label, max, min) => (
    <div className="d-flex flex-column align-items-start">
      <span
        id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
        className="py-2"
        style={{ font: 'normal normal normal 1rem Open Sans' }}
      >
        {label}
      </span>
      <input
        id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
        max={max}
        min={min}
        maxLength={2}
        value={digitValue[label?.toLowerCase()]}
        className="px-2"
        onChange={(e) => {
          const val = ['hour', 'minute', 'second'].reduce((acc, element) => {
            if (label.toLowerCase() === element) {
              acc[element] = e.target.value;
            } else {
              let localDigitVal = digitValue[element];
              if (
                localDigitVal === null ||
                localDigitVal === undefined ||
                localDigitVal === ''
              ) {
                acc[element] = '00';
              }
            }
            return acc;
          }, digitValue);

          setDigitValue((prev) => ({
            ...prev,
            ...val,
          }));
        }}
        type="number"
        style={{
          background: '#FAFAFA',
          border: '1px solid #EBEBEB',
          borderRadius: '0.688rem',
          backgroundColor: '#FAFAFA',
          width: '3.5rem',
          height: '3.3rem',
        }}
      />
    </div>
  );

  return (
    <div style={{ position: 'relative', width: '80%' }}>
      <div
        className={`d-flex align-items-center cursor-pointer ${className}`}
        onClick={() => setDropdown(!dropdown)}
      >
        <button
          id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
          disabled={disabled}
          style={{
            ...(inputButtonStyle
              ? inputButtonStyle
              : {
                  flex: 1,
                  height: '1.2rem',
                  border: showBorder
                    ? `1px solid ${dropdown ? 'rgb(84, 125, 84)' : '#EBEBEB'}`
                    : 'none',
                  cursor: 'pointer',
                  borderRadius: '100rem',
                  outline: 'none',
                  backgroundColor: background,
                  maxWidth: '90px',
                  justifyContent: 'center',
                }),
          }}
          className="d-flex align-items-center px-2"
        >
          <span
            id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
            style={{
              font:
                inputButtonStyle?.font ||
                'normal normal 600 0.6rem "Open Sans"',
            }}
          >
            {formatTime(
              digitValue?.hour,
              digitValue?.minute,
              digitValue?.second,
              isAM,
            )}
          </span>
        </button>
        <Image
          onClick={() => setDropdown(!dropdown)}
          src={downArrow}
          className="pl-2 mb-1 ms-3"
          style={{
            height: '0.9rem',
            width: '0.9rem',
            display: 'inline',
            marginTop: 5,
            marginLeft: 0,
          }}
          alt="down"
        />
      </div>
      {dropdown && (
        <div
          id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
          className="d-flex align-items-end justify-content-between pb-3 mt-1 px-4"
          style={{
            background: '#FFFFFF',
            zIndex: 400,
            boxShadow: '1px 6px 10px #00000029',
            border: '1px solid #EBEBEB',
            borderRadius: 4,
            position: 'absolute',
            ...dropDownStyle,
          }}
        >
          {renderTimeInput('Hour', 12, 0)}
          <div className="px-3 mb-2">:</div>
          {renderTimeInput('Minute', 59, 0)}
          <div className="px-3 mb-2">:</div>
          {renderTimeInput('Second', 59, 0)}
          <div
            className="ms-3 "
            style={{
              padding: '0.1rem 0',
              background: '#FAFBFD',
              border: '1px solid #EBEBEB',
              borderRadius: '1.25rem',
            }}
          >
            <div
              id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
              className=" px-3"
              onClick={() => setIsAM(true)}
              style={{
                padding: '0.1rem 0',
                borderRadius: '1.875rem 1.875rem 1.563rem 1.563rem',
                cursor: 'pointer',
                font: 'normal normal 600 12px/22px Open Sans',
                backgroundColor: !isAM ? 'transparent' : '#14233C',
                color: !isAM ? 'black' : 'white',
              }}
            >
              AM
            </div>
            <div
              id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
              className=" px-3"
              onClick={() => setIsAM(false)}
              style={{
                padding: '0.1rem 0',
                borderRadius: '1.563rem 1.563rem 1.875rem 1.875rem ',
                cursor: 'pointer',
                font: 'normal normal 600 12px/22px Open Sans',
                backgroundColor: isAM ? 'transparent' : '#14233C',
                color: isAM ? 'black' : 'white',
              }}
            >
              PM
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
