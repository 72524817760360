import React, { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import ProfileModal from '../../pages/profile/ProfileModal';
import Bell from '../assets/bell.png';
import { UserContext } from '../service/userContext';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../assets/logo';
import DropdownField from '../components/FormDropdown';
import { links, sections } from '../utils/constants';
import { getFirstLetter } from '../utils/helper';
import { isEmpty } from 'lodash';
import back from '../assets/back.svg';

const Header = ({
  selectedSubText,
  selectedTab,
  showLogo,
  title,
  showDropDown,
  code,
  showBack = false,
  onBack,
}) => {
  const navigate = useNavigate();
  const { userInfo } = useContext(UserContext);
  const [visible, setVisible] = useState(false);
  const [values, setValues] = useState({
    Department: code,
  });
  const getAllOptions = () => {
    if (!userInfo.departments) return [];
    return userInfo.departments.map((code) => ({
      name: sections[code],
      value: code,
    }));
  };
  const handleChange = (fieldName, value, multiselect) => {
    setValues({ Department: value });

    return navigate(links[value]);
  };
  return (
    <>
      <Navbar className={`${title === 'Asset Tracking' ? 'mx-3' : ''}`}>
        <Container fluid>
          <Navbar.Toggle />

          <div className={`dust-header w-100`}>
            <Navbar.Collapse className={`align-items-start navbar-collapse`}>
              {showLogo ? (
                <Navbar.Brand>
                  <Logo />
                </Navbar.Brand>
              ) : null}
              <Navbar.Text
                className="navbar-text text-dark"
                style={{ display: 'flex' }}
              >
                {showBack && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '0px 5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => onBack()}
                  >
                    <img src={back} height={18} alt="back" />
                  </div>
                )}
                {selectedSubText ? selectedSubText : title}
              </Navbar.Text>

              <div className="d-flex gap-2 justify-content-end dropdown-icon-container">
                {showDropDown ? (
                  <div className="header-dropdown" style={{ zIndex: 5000 }}>
                    <DropdownField
                      selectedOptions={values.Department}
                      allOptions={getAllOptions()}
                      multiselect={false}
                      handleChange={handleChange}
                    />
                  </div>
                ) : null}

                <div className="avatar-circle small-avatar">
                  <img src={Bell} alt="Notification" className="text-white" />
                </div>
                <div
                  className="avatar-circle small-avatar color-white"
                  onClick={() => setVisible(!visible)}
                >
                  {!isEmpty(userInfo)
                    ? `${getFirstLetter(userInfo.first_name)}${getFirstLetter(
                        userInfo.last_name,
                      )}`
                    : null}
                </div>
              </div>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
      {visible && (
        <ProfileModal setVisible={setVisible} className="profilemodal" />
      )}
    </>
  );
};

export default Header;
