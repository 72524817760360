import { useState } from 'react';
import TimelineGraphChart from './TimelineGraphChart';
import TimelineGraphHeader from './TimelineGraphHeader';
import TimelineGraphSubHeader from './TimelineGraphSubHeader';

export default function TimelineGraph({
  allCoordinates,
  title,
  modalToggle,
  selectedSpeed,
  speedUnit = 'km/h',
  selectedTime,
  setSelectedTime,
}) {
  const maxTimeOnXAxis = 5400; //determines the max time on x-axis.
  const maxXAxisGap = 300; //determines the max difference between 2 consecutive  x-axis labels.
  const minXAxisGap = 60; //determines the min difference between 2 consecutive  x-axis labels.
  const [xAxisGap, setXAxisGap] = useState(maxXAxisGap);
  const [rows, setRows] = useState(maxTimeOnXAxis);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);

  return (
    <div>
      <div>
        <TimelineGraphHeader
          title={title}
          modalToggle={(v) => modalToggle(v)}
        />
      </div>
      <div>
        <TimelineGraphSubHeader
          selectedSpeed={selectedSpeed}
          speedUnit={speedUnit}
          timestamp={selectedTime}
          xAxisGap={xAxisGap}
          setXAxisGap={(v) => {
            setXAxisGap(v);
            setRows(v * 18);
          }}
          maxXAxisGap={maxXAxisGap}
          minXAxisGap={minXAxisGap}
          rows={rows}
          isLastPage={isLastPage}
          isFirstPage={isFirstPage}
        />
      </div>
      <div>
        <TimelineGraphChart
          setSelectedTime={setSelectedTime}
          speedUnit={speedUnit}
          allCoordinates={allCoordinates}
          xAxisGap={xAxisGap}
          rows={rows}
          setIsLastPage={setIsLastPage}
          setIsFirstPage={setIsFirstPage}
        />
      </div>
    </div>
  );
}
