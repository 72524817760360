import { ReactNode, useEffect, useState } from 'react';
import CloseIcon from '../../../pages/assets/CloseIcon';
import { getColorByStatus } from '../../AssetList/AssetTable';
import { Asset, Equipment } from '../../models/models';
import styles from './Speedometer.module.css';
import speedometer from '../../assets/images/speedometer.png';
import {
  formatSecondstoHHMMSS,
  formatUnixDateToTodayHHMMSS,
  getDegFromSpeed,
  padNumber,
} from '../../utils/helper';
import Fuel from '../../assets/svgs/Fuel';
import ProgressBar from '../ProgressBar/ProgressBar';
import Tooltip from '../Tooltip/Tooltip';
import moment from 'moment';
import { AssetStatus } from '../../utils/constants';
import { useAssetTrackingContext } from '../../../pages/service/AssetTrackingContext';

type Props = {
  asset: Asset;
  equipment: Equipment;
};

const Speedometer = ({ asset, equipment }: Props) => {
  const [minimized, setMinimized] = useState<boolean>(false);
  const showFuel: boolean = false;
  const [values, setValues] = useState<
    {
      label: string;
      value: string | number | undefined;
      tooltipContent: ReactNode;
    }[]
  >([]);
  const { currentDateUnix } = useAssetTrackingContext();

  useEffect(() => {
    setValues([
      {
        label: 'Engine Hours',
        value: `${
          equipment?.currentEngineStatus === 'OFF'
            ? '00'
            : padNumber(Math.round(asset?.Current.tripEngineHours / 3600), 2)
        } h`,
        tooltipContent: (
          <div className={styles.tooltipContainer}>
            <div className={styles.tooltipHeader}>
              <div className={styles.tooltipTitle}>Engine Hours</div>
              <div
                className={styles.pill}
                style={{
                  backgroundColor:
                    equipment?.currentEngineStatus === 'OFF'
                      ? getColorByStatus(AssetStatus.Parked)
                      : '',
                  color:
                    equipment?.currentEngineStatus === 'OFF' ? 'black' : '',
                }}
              >
                {equipment?.currentEngineStatus === 'OFF'
                  ? '00:00:00'
                  : formatSecondstoHHMMSS(
                      currentDateUnix - asset?.activity[0]?.startTime,
                    )}{' '}
                h
              </div>
            </div>
            <div className={styles.tooltipMacroContainer}>
              <div className={styles.tooltipMacro}>
                <div className={styles.tooltipLabel}>Since</div>
                <div className={styles.tooltipValue}>
                  {formatUnixDateToTodayHHMMSS(
                    moment.utc(equipment?.lastUpdatedTime).unix(),
                  )}
                </div>
              </div>
              <div className={styles.tooltipMacro}>
                <div className={styles.tooltipLabel}>Duration</div>
                <div className={styles.tooltipValue}>
                  {formatSecondstoHHMMSS(
                    currentDateUnix - asset?.activity[0]?.startTime,
                  )}{' '}
                  h
                </div>
              </div>
              <div className={styles.tooltipMacro}>
                <div className={styles.tooltipLabel}>
                  Time Since Last Updated
                </div>
                <div className={styles.tooltipValue}>
                  {asset && asset.lastSocketTime
                    ? formatSecondstoHHMMSS(
                        (currentDateUnix ?? moment().unix()) -
                          asset.lastSocketTime >
                          0
                          ? (currentDateUnix ?? moment().unix()) -
                              asset.lastSocketTime
                          : 0,
                      )
                    : asset && asset.Current?.tripLastUpdate
                    ? formatSecondstoHHMMSS(
                        (currentDateUnix ?? moment().unix()) -
                          asset.Current?.tripLastUpdate >
                          0
                          ? (currentDateUnix ?? moment().unix()) -
                              asset.Current?.tripLastUpdate
                          : 0,
                      )
                    : '00:00:00'}{' '}
                  h
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        label: 'Distance',
        value: `${
          equipment?.currentEngineStatus === 'OFF'
            ? '00'
            : asset?.Current.tripOdoStart
            ? padNumber(asset?.odometer - asset?.Current.tripOdoStart, 2)
            : '00'
        } KM`,
        tooltipContent: (
          <div className={styles.tooltipContainer}>
            <div className={styles.tooltipHeader}>
              <div className={styles.tooltipTitle}>
                Distance -{' '}
                {equipment?.currentEngineStatus === 'OFF' ? 'Last' : 'Current'}{' '}
                Trip
              </div>
              <div
                className={styles.pill}
                style={{
                  backgroundColor:
                    equipment?.currentEngineStatus === 'OFF'
                      ? getColorByStatus(AssetStatus.Parked)
                      : '',
                  color:
                    equipment?.currentEngineStatus === 'OFF' ? 'black' : '',
                }}
              >
                {asset?.Current.tripOdoStart
                  ? padNumber(asset?.odometer - asset?.Current.tripOdoStart, 2)
                  : '00'}{' '}
                KM
              </div>
            </div>
            <div className={styles.tooltipValueContainer}>
              <div className={styles.tooltipMacro}>
                <div className={styles.tooltipLabel}>Start</div>
                <div className={styles.tooltipValue}>
                  {asset?.Current.tripStartTime
                    ? formatUnixDateToTodayHHMMSS(asset?.Current.tripStartTime)
                    : '-'}
                </div>
              </div>
              <div className={styles.tooltipMacro}>
                <div className={styles.tooltipLabel}>From</div>
                <div className={styles.tooltipValue}>
                  {asset?.Current.tripFrom?.latitude
                    ? asset?.Current.tripFrom?.latitude + ', '
                    : '-'}{' '}
                  {asset?.Current.tripFrom?.longitude}
                </div>
              </div>
              <div className={styles.tooltipMacro}>
                <div className={styles.tooltipLabel}>End</div>
                <div className={styles.tooltipValue}>
                  {equipment?.currentEngineStatus === 'OFF'
                    ? asset?.Current.tripEndTime
                      ? formatUnixDateToTodayHHMMSS(asset?.Current.tripEndTime)
                      : '-'
                    : 'In Progress'}
                </div>
              </div>
              <div className={styles.tooltipMacro}>
                <div className={styles.tooltipLabel}>Duration</div>
                <div className={styles.tooltipValue}>
                  {equipment?.currentEngineStatus === 'OFF'
                    ? asset?.Current.tripEndTime
                      ? formatSecondstoHHMMSS(
                          asset?.Current.tripEndTime -
                            asset?.Current.tripStartTime,
                        )
                      : '00:00:00'
                    : asset?.Current.tripStartTime
                    ? formatSecondstoHHMMSS(
                        currentDateUnix - asset?.Current.tripStartTime > 0
                          ? currentDateUnix - asset?.Current.tripStartTime
                          : 0,
                      )
                    : '00:00:00'}{' '}
                  h
                </div>
              </div>
              <div className={styles.tooltipMacro}>
                <div className={styles.tooltipLabel}>Max Speed</div>
                <div className={styles.tooltipValue}>
                  {padNumber(asset?.Current.tripMaxSpeed ?? 0, 2)} km/h
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        label: 'Battery',
        value: (asset?.Current.batteryHealth ?? 0) + ' %',
        tooltipContent: (
          <div className={styles.tooltipContainer}>
            <div className={styles.tooltipHeader}>
              <div className={styles.tooltipTitle}>Battery</div>
            </div>
            <div className={styles.tooltipValueContainer}>
              <div className={styles.tooltipMacro}>
                <div className={styles.tooltipLabel}>
                  Current Starlink voltage
                </div>
                <div className={styles.tooltipValue}>
                  {asset?.Current.batteryStarlinkVoltage ?? 0} V
                </div>
              </div>
              <div className={styles.tooltipMacro}>
                <div className={styles.tooltipLabel}>
                  Current Vehicle Voltage
                </div>
                <div className={styles.tooltipValue}>
                  {asset?.Current.vehicleVoltage ?? 0} V
                </div>
              </div>
              <div className={styles.tooltipMacro}>
                <div className={styles.tooltipLabel}>Battery Life</div>
                <div className={styles.tooltipValue}>
                  {asset?.Current.batteryHealth ?? 0} %
                </div>
              </div>
              {equipment?.currentEngineStatus === 'OFF' && (
                <div className={styles.tooltipMacro}>
                  <div className={styles.tooltipLabel}>Charging Percentage</div>
                  <div className={styles.tooltipValue}>
                    {asset?.Current.batteryCharge ?? 0} %
                  </div>
                </div>
              )}
            </div>
          </div>
        ),
      },
    ]);
  }, [asset, equipment, currentDateUnix]);

  return (
    <div className={styles.speedometer}>
      <div style={{ position: 'relative' }}>
        {!minimized && (
          <div className={styles.close} onClick={() => setMinimized(true)}>
            <CloseIcon height={14} />
          </div>
        )}
        <div
          className={styles.main}
          style={{
            padding: minimized ? '18px' : '10px',
            cursor: minimized ? 'pointer' : '',
          }}
          onClick={() => setMinimized(false)}
        >
          <div
            className={styles.title}
            style={{ background: getColorByStatus(asset?.Status) }}
          >
            {asset?.title}
          </div>
          {minimized ? (
            <div className={styles.oddometer}>
              {padNumber(equipment?.totalEngineHoursInMinutes / 60, 6)}
            </div>
          ) : (
            <div
              className={styles.container}
              style={{ height: showFuel ? '178px' : '158px' }}
            >
              <div className={styles.top}>
                <div className={styles.left}>
                  <div className={styles.speed}>
                    {padNumber(asset?.Current.speed, 2)}
                    <span>KM/H</span>
                  </div>
                  <img
                    className={styles.image}
                    src={speedometer}
                    alt="speedometer"
                  />
                  <div className={styles.speedLimit}>
                    <div
                      className={styles.limitNeedle}
                      style={{
                        transform: `rotate(${getDegFromSpeed(30)}deg)`,
                      }}
                    >
                      <div className={styles.needle}></div>
                    </div>
                  </div>
                  <div className={styles.speedIndicator}>
                    <div
                      className={styles.speedNeedle}
                      style={{
                        transform: `rotate(${getDegFromSpeed(
                          asset?.Current.speed,
                        )}deg)`,
                      }}
                    >
                      <div className={styles.needle}></div>
                    </div>
                  </div>
                </div>
                <div className={styles.right}>
                  <div
                    className={styles.oddometer}
                    style={{ marginBottom: '4px' }}
                  >
                    {padNumber(equipment?.totalEngineHoursInMinutes / 60, 6)}
                  </div>
                  {values.map((item) => (
                    <div key={item.label} className={styles.values}>
                      <Tooltip
                        toolTipStyle={{ width: 327 }}
                        child={
                          <div
                            className={styles.label}
                            style={{ position: 'relative' }}
                          >
                            {item.label}
                          </div>
                        }
                        tooltipContent={item.tooltipContent}
                      />

                      {item.value && (
                        <div className={styles.value}>{item.value}</div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {showFuel && (
                <div className={styles.footer}>
                  <Fuel height={14} />
                  <ProgressBar progress={80} max={100} width={'50%'} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Speedometer;
