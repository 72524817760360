import React, { useEffect, useState } from 'react';
import { SpeedingPopup } from '../../../AssetTracking/Map/SpeedingPopup';
import { IdlePopup } from '../../../AssetTracking/Map/IdlePopup';
import './dropdowns.css';
import LocationIconSVG from '../../../AssetTracking/Components/LocationIconSVG';

const EventList = ({
  selectedAsset,
  data = [],
  onSelectEvent = () => {},
  selectedEvents,
  setSelectedEvents,
}) => {
  const [events, setEvents] = useState([]);

  const handleChange = (e) => {
    try {
      const name = e.target.name,
        checked = e.target.checked;
      if (checked) {
        const newEvents = [...selectedEvents];
        newEvents.push(name);
        setSelectedEvents([...newEvents]);
      } else {
        const newEvents = selectedEvents.filter((event) => event !== name);
        setSelectedEvents([...newEvents]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setEvents(data);
  }, [JSON.stringify(data)]);

  const isSelected = (event, event_data) => {
    const selectedAssetLat = selectedAsset?.coordinates?.latitude;
    const eventLat = event_data?.coordinates?.latitude;
    const selectedAssetLon = selectedAsset?.coordinates?.longitude;
    const eventLon = event_data?.coordinates?.longitude;
    return selectedAssetLat == eventLat && selectedAssetLon == eventLon;
  };

  return (
    <div id="accordionExample" className="asm-details-dropdown">
      {[
        { event_data: events.speeding, event_name: 'Speeding' },
        { event_data: events.idle, event_name: 'Idle Time' },
      ].map((event, index) => (
        <div>
          {event?.event_data?.length ? (
            <div key={index} style={{ marginBottom: '0.7rem' }}>
              <div className="d-flex align-items-start align-items-center">
                <div className="" style={{}}>
                  <input
                    checked={selectedEvents.includes(event?.event_name)}
                    onChange={handleChange}
                    name={event?.event_name}
                    type="checkbox"
                    style={{
                      transform: 'scale(1)',
                      height: '1rem',
                      width: '1rem',
                    }}
                  />
                </div>
                <div
                  className="details-value"
                  style={{
                    marginBottom: '0.65rem',
                    marginTop: '0.7rem',
                    marginLeft: '0.6rem',
                  }}
                >
                  {event?.event_name} ({event?.event_data.length})
                </div>
              </div>
              <div>
                {event?.event_data?.map((event_data, i) => {
                  const speed = parseFloat(
                    event_data?.event_speed
                      ?.replace(/\s/g, '')
                      .replace('km/h', ''),
                  );
                  return (
                    <div
                      onClick={() => {
                        if (event_data?.coordinates) {
                          onSelectEvent({
                            ...event_data,
                            title: event?.event_name,
                            url:
                              event?.event_name === 'Speeding'
                                ? speed < 40
                                  ? './assets/images/trip-icon-speeding-30.svg'
                                  : speed >= 40 && speed < 55
                                  ? './assets/images/trip-icon-speeding-40.svg'
                                  : './assets/images/trip-icon-speeding-55.svg'
                                : './assets/images/idle.svg',
                            type:
                              event?.event_name === 'Speeding'
                                ? 'speeding'
                                : 'idle',
                            coordinates: event_data?.coordinates,
                            select: true,
                            popup:
                              event?.event_name === 'Speeding'
                                ? () => SpeedingPopup({ data: event_data })
                                : () => IdlePopup({ data: event_data }),
                          });
                        }
                      }}
                      style={{
                        borderRadius: '1.2rem',
                        backgroundColor: isSelected(event, event_data)
                          ? '#E1E8F4'
                          : 'transparent',
                      }}
                      className="d-flex align-items-start px-2 pt-2 "
                      key={i}
                    >
                      <div className="w-100 timeline-details-key d-flex">
                        <div className="col-1" style={{ paddingLeft: '0rem' }}>
                          <div className="mr-0 ">
                            {speed < 40 && <LocationIconSVG color="#73bf63" />}
                            {speed >= 40 && speed < 55 && (
                              <LocationIconSVG color="#181cf5" />
                            )}
                            {speed >= 55 && <LocationIconSVG color="#e23123" />}

                            {event?.event_name !== 'Speeding' && (
                              <LocationIconSVG color="#ea9336" />
                            )}
                          </div>
                        </div>
                        <div className="col-11">
                          <div
                            style={{
                              marginBottom: '0.5rem',
                              marginLeft: '0.6rem',
                            }}
                          >
                            {event_data?.event_start_time} -{' '}
                            {event_data?.event_end_time}
                          </div>
                          <div>
                            <div style={{ marginBottom: '0.5rem' }}>
                              {event?.event_name === 'Speeding' && (
                                <div className="row m-0 p-0 w-100">
                                  <div className="col-4">Speed: </div>
                                  <div className="col-8 details-value">
                                    {' '}
                                    {event_data?.event_speed}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div style={{ marginBottom: '0.5rem' }}>
                              <div className="row m-0 p-0 w-100">
                                <div className="col-4">Duration: </div>
                                <div className="col-8 details-value">
                                  {' '}
                                  {event_data?.event_duration}
                                </div>
                              </div>
                            </div>
                            <div style={{ marginBottom: '0.5rem' }}>
                              {event?.event_name === 'Speeding' && (
                                <div className="row m-0 p-0 w-100">
                                  <div className="col-4">Distance: </div>
                                  <div className="col-8 details-value">
                                    {' '}
                                    {event_data?.event_distance}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div style={{ marginBottom: '0.5rem' }}>
                              {event?.event_name === 'Speeding' && (
                                <div className="row m-0 p-0 w-100">
                                  <div className="col-4">From: </div>
                                  <div className="col-8 details-value">
                                    {event_data?.coordinatesFrom?.latitude},{' '}
                                    {event_data?.coordinatesFrom?.longitude}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div style={{ marginBottom: '0.5rem' }}>
                              {event?.event_name === 'Speeding' && (
                                <div className="row m-0 p-0 w-100">
                                  <div className="col-4">To: </div>
                                  <div className="col-8 details-value">
                                    {event_data?.coordinatesTo?.latitude},{' '}
                                    {event_data?.coordinatesTo?.longitude}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div
                              style={{
                                marginBottom: '0.5rem',
                                borderRadius: '0.64rem',
                              }}
                            >
                              {event?.event_name === 'Idle Time' && (
                                <div className="d-flex align-items-start row m-0 p-0 w-100">
                                  <div className="col-4">Location: </div>
                                  <div className="col-8 details-value">
                                    {event_data?.coordinates?.latitude},{' '}
                                    {event_data?.coordinates?.longitude}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ))}
    </div>
  );
};

export default EventList;
