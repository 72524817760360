import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
  useMemo,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import AddUser from './AddUser';
import * as toast from '../pages/components/toast';
import UserDetail from './userDetail';
import SmallAlertModal from '../pages/components/SmallAlertModal';
import LoadingScreen from '../pages/components/loading';
import { UserContext } from '../pages/service/userContext';
import {
  dropdownOptions,
  UserActionDropdown,
} from '../pages/components/UserActionsDropdown';
import { withAccessControl } from '../pages/service/with-access-control';
import { useDebounce } from '../hooks/useDebounce';
import {
  API_METHODS,
  ENDPOINTS,
  UserStatus,
  UserType,
  headerText,
  userType,
} from '../pages/utils/constants';
import {
  convertCodeListToNames,
  convertUserStatusToColor,
} from '../pages/utils/helper';
import BasicButton from '../pages/components/BasicButton';
import SubHeader from '../pages/components/SubHeader';
import StatusDot from '../pages/components/StatusDot';
const skip = 'SKIP';

const defaultModalState = {
  onClick: () => {},
  visible: false,
  text: ['', '', ''],
  btnTxt: '',
};
const userCount = 0;

const hasUsersWithStatus = (users, selectedStatus) => {
  if (users.filter(({ status }) => status === selectedStatus)?.length) {
    return true;
  } else return false;
};

export const emptyFilters = {
  startDate: '',
  endDate: '',
  status: [],
  user_type: [],
  departments: [],
  licenses: [],
};

const ManageUser = () => {
  const { ApiHandler, departments, licenses } = useContext(UserContext);
  const fetchedStartDate = useRef(moment());
  const fetchedEndDate = useRef(moment());
  const [checkedUserList, setCheckedUserList] = useState([]);
  const [modalType, setModalType] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userData, setUserData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [smallModal, setSmallModal] = useState(defaultModalState);
  const [filteredData, setFilteredData] = useState(emptyFilters);
  const query = useRef('');
  const selectedUser = selectedUserId
    ? userData.find(({ _id }) => _id === selectedUserId)
    : undefined;

  const tableRows = useMemo(
    () => [
      {
        key: 'name',
        name: 'view',
        child: (userValue) => userValue.first_name + ' ' + userValue.last_name,
      },
      {
        key: 'status',
        name: 'view',
        child: (userValue) => (
          <StatusDot className={convertUserStatusToColor(userValue.status)}>
            {userValue.status}
          </StatusDot>
        ),
      },
      {
        key: 'user_type',
        child: ({ user_type }) => userType[user_type],
      },
      {
        key: 'email',
        name: 'view',
        child: (userValue) => (userValue.email ? userValue.email : 'N/A'),
      },
      {
        key: 'departments',
        child: (userValue) =>
          userValue.user_type === UserType.admin.value
            ? 'N/A'
            : convertCodeListToNames(
                departments.filter(({ is_restricted }) => is_restricted),
                userValue.departments,
              ),
      },
      {
        key: 'licences',
        child: (userValue) =>
          userValue.user_type === UserType.admin.value
            ? 'N/A'
            : convertCodeListToNames(licenses, userValue.licenses),
      },
      {
        key: 'pin_number',
        child: (userValue) => (
          <span
            color="blue"
            disabled={userValue.pin_number ? false : true}
            className={userValue.pin_number ? 'table_text_blue' : 'unset'}
          >
            {userValue.pin_number
              ? userValue.pinView
                ? userValue.pin_number
                : 'View PIN'
              : 'N/A'}
          </span>
        ),
      },
      {
        key: 'added_by',
        name: 'view',
        child: (userValue) =>
          userValue.added_by
            ? `${userValue.added_by?.first_name} ${
                userValue.added_by?.last_name
              }, ${moment(userValue.added_by.added_at).format('DD/MM/YYYY')}`
            : 'N/A',
      },
    ],
    [departments, licenses],
  );

  const userInfo = useCallback(
    async (appliedFilters = emptyFilters) => {
      try {
        setLoading(true);

        fetchedStartDate.current = moment(
          appliedFilters?.startDate
            ? appliedFilters.startDate
            : new Date(1970, 0, 1),
        );
        fetchedEndDate.current = moment(
          appliedFilters?.endDate || appliedFilters?.startDate || new Date(),
        );

        if (fetchedStartDate.current > fetchedEndDate.current) {
          toast.error("End date can't be less than start date");
          return;
        }
        const reqParam = {
          startDate: fetchedStartDate.current,
          endDate: fetchedEndDate.current,
          status: appliedFilters.status,
          search: query.current,
          user_type: appliedFilters.user_type,
          departments: appliedFilters.departments,
          licenses: appliedFilters.licenses,
        };
        if (!query.current) {
          delete reqParam.search;
        }
        let response = await ApiHandler({
          endPoint: ENDPOINTS.getAllUsers,
          method: API_METHODS.POST,
          reqParam: reqParam,
        });
        if (response.status === 200) {
          setUserData(response?.data);
        }
      } catch (error) {
        toast.error(error?.message);
      }
      setLoading(false);
    },
    [ApiHandler],
  );

  useEffect(() => {
    userInfo();
  }, [userInfo]);

  const onFilterChange = (filterData) => {
    setFilteredData(filterData);
    userInfo(filterData);
  };

  const debouncedRequest = useDebounce(() => {
    userInfo(filteredData);
  });

  const onSearchChange = (e) => {
    if (filteredData.startDate) {
      fetchedStartDate.current = filteredData.startDate;
    } else {
      fetchedStartDate.current = moment(new Date());
    }
    if (filteredData.endDate) {
      fetchedEndDate.current = filteredData.endDate;
    } else {
      fetchedEndDate.current = new Date();
    }
    query.current = e.target.value;
    debouncedRequest();
  };

  const deactivateDelete = async (action, userId) => {
    let ids = userId ? [userId] : checkedUserList.map(({ _id }) => _id);
    try {
      await ApiHandler({
        method: API_METHODS.PUT,
        endPoint: ENDPOINTS.deactivateDeleteUsers,
        reqParam: { action, ids },
      });
      toast.success(
        `User(s) succesfully ${
          action === 'Deleted'
            ? 'deleted'
            : action === 'Active'
            ? 'activated'
            : 'deactivated'
        }`,
      );
    } catch (err) {
      if (err.status === 401) {
        setSmallModal({
          visible: true,
          btnTxt: 'OK',
          onClick: () => setSmallModal(defaultModalState),
          text: [`${err.message}: ${err.data}`, '', ''],
        });
      } else {
        toast.error(err.message);
      }
    }
    userInfo(filteredData);
    getDataForRequired({ target: { checked: false } }, '', 'all');
  };

  const cancelInvitation = async (userId) => {
    try {
      await ApiHandler({
        endPoint: ENDPOINTS.cancelInvitation,
        method: API_METHODS.POST,
        reqParam: { userId },
      });
      toast.success('Successfully canceled invitation');
    } catch (err) {
      toast.error(err?.message);
    }
    userInfo(filteredData);
    getDataForRequired({ target: { checked: false } }, '', 'all');
  };

  const changeViewPinStatus = (index) => {
    let users = [...userData];
    users[index].pinView = users[index].pinView ? false : true;
    setUserData(users);
  };

  const handleDropdownOptions = async (action, user) => {
    if (action === dropdownOptions.view) {
      setModalType('view');
      setSelectedUserId(user._id);
    }
    if (action === dropdownOptions.edit) {
      setModalType('add');
      setSelectedUserId(user._id);
    }
    if (action === dropdownOptions.changestatus) {
      if (user.status === UserStatus.active) {
        await deactivateDelete('Inactive', user._id);
      } else if (user.status === UserStatus.inactive) {
        await deactivateDelete('Active', user._id);
      }
    }
    if (action === dropdownOptions.delete) {
      setModalType('');
      if (user.status === UserStatus.invited) {
        await cancelInvitation(user._id);
      } else {
        await deactivateDelete('Deleted', user._id);
      }
    }
    if (action === dropdownOptions.newPin) {
      try {
        const body = {
          userId: user._id,
        };
        setLoading(true);
        await ApiHandler({
          endPoint: ENDPOINTS.generatePin,
          method: API_METHODS.PUT,
          reqParam: body,
        });
        await userInfo(filteredData);

        toast.success("Successfully updated user's PIN");
      } catch (err) {
        toast.error(err?.message);
      } finally {
        setLoading(false);
      }
    }
    if (action === dropdownOptions.resendInvite) {
      try {
        const body = {
          email: user.email,
        };
        setLoading(true);
        await ApiHandler({
          endPoint: ENDPOINTS.resendInvitation,
          method: API_METHODS.POST,
          reqParam: body,
        });
        toast.success('Successfully resent invitation');
      } catch (err) {
        toast.error(err?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const getDataForRequired = (e, data, type = '') => {
    let list = [];
    if (type) {
      const filteredUsers = userData.filter(
        ({ status }) => status !== 'Invited',
      );
      if (e.target.checked) {
        list = userData?.length > 0 ? filteredUsers : [];
        const clist = document.getElementsByTagName('input');
        for (let i = 0; i < clist?.length; ++i) {
          clist[i].checked = true;
        }
      } else {
        list = [];
        const clist = document.getElementsByTagName('input');
        for (let i = 0; i < clist?.length; ++i) {
          clist[i].checked = false;
        }
      }
    } else {
      list = [...checkedUserList];
      if (e.target.checked) {
        if (list?.length > 0 && list.indexOf(data) > -1) {
        } else {
          list.push(data);
        }
      } else {
        if (list?.length > 0 && list.indexOf(data) > -1) {
          list.splice(list.indexOf(data), 1);
        } else {
          list.splice(list.indexOf(data), 1);
        }
      }
    }

    setCheckedUserList(list);
  };

  const fetchMoreData = () => {
    if (userData.length >= userCount) {
      setHasMore(false);
      return;
    }
  };
  const modalToggle = (refreshData) => {
    setModalType('');
    setSelectedUserId(null);
    if (refreshData === true) userInfo(filteredData);
  };

  const clickDeactivateDelete = (action, id) => {
    const modalState = {
      visible: true,
      btnTxt: 'Confirm',
      onClick: () =>
        action === 'Invited'
          ? cancelInvitation(id)
          : deactivateDelete(action, id),
    };
    modalState.text = [
      'Are you sure you want to ',
      action === 'Deleted'
        ? 'delete'
        : action === 'Invited'
        ? 'cancel invitation for'
        : action === 'Active'
        ? 'activate'
        : 'deactivate',
      ' the selected user(s)?',
    ];
    setSmallModal(modalState);
  };

  const showInviteSuccess = () => {
    setSmallModal({
      visible: true,
      btnTxt: 'OK',
      onClick: () => setSmallModal(defaultModalState),
      text: ['An invitation email has been sent!', '', ''],
    });
  };

  return (
    <div>
      {modalType === 'add' && (
        <AddUser
          modalType={modalType}
          modalToggle={modalToggle}
          selectedUser={selectedUser}
          showSuccessModal={showInviteSuccess}
        />
      )}
      {modalType === 'view' && (
        <UserDetail
          selectedUser={selectedUser}
          modalType={modalType}
          modalToggle={modalToggle}
          handleDropdownOptions={handleDropdownOptions}
        />
      )}

      <div className="manage-user-button-container">
        {checkedUserList?.length > 0 ? (
          <div className="buttons-container">
            {hasUsersWithStatus(checkedUserList, 'Inactive') && (
              <BasicButton onClick={() => clickDeactivateDelete('Active')}>
                Activate
              </BasicButton>
            )}
            {hasUsersWithStatus(checkedUserList, 'Active') && (
              <BasicButton onClick={() => clickDeactivateDelete('Inactive')}>
                Deactivate
              </BasicButton>
            )}
            <BasicButton
              outlined
              onClick={() => clickDeactivateDelete('Deleted')}
            >
              Delete
            </BasicButton>
          </div>
        ) : (
          <div className="buttons-container">
            <BasicButton name="add" onClick={() => setModalType('add')}>
              Add User
            </BasicButton>
          </div>
        )}
        {checkedUserList?.length > 0 ? (
          <div className="d-flex align-items-center dropdown-text">
            {checkedUserList?.length} User
            {checkedUserList?.length === 1 ? '' : 's'} Selected
            <button
              type="button"
              className="btn-close small-btn-close"
              aria-label="Close"
              onClick={() =>
                getDataForRequired({ target: { checked: false } }, '', 'all')
              }
            ></button>
          </div>
        ) : (
          <SubHeader
            userFilter
            SubHeaderVisible={true}
            toShowFilter={true}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            onSearchChange={onSearchChange}
            onSaveClick={onFilterChange}
          />
        )}
      </div>
      <div className="table_manage_user users_header">
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th align="left" valign="top">
                <input
                  type="checkbox"
                  name="required"
                  onChange={(e) => getDataForRequired(e, '', 'all')}
                />
              </th>
              {headerText.map((header) => (
                <th align="left" valign="top" key={header}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
        </table>
      </div>

      <div className="table_manage_user flex-fill" id="user-list-table">
        <InfiniteScroll
          dataLength={userData?.length}
          next={fetchMoreData}
          hasMore={hasMore}
          style={
            userData?.length < 4
              ? { paddingBottom: '80px' }
              : userData?.length === 4
              ? { paddingBottom: '40px' }
              : {}
          }
        >
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={headerText.length}>
                    <LoadingScreen />
                  </td>
                </tr>
              ) : !userData.length ? (
                <tr>
                  <td>No Data found</td>
                </tr>
              ) : (
                userData.map((userValue, userIndex) => (
                  <tr
                    className={
                      checkedUserList.find(({ _id }) => _id === userValue._id)
                        ? 'selected'
                        : ''
                    }
                    key={userIndex}
                  >
                    <td align="left" valign="top">
                      {userValue.status !== 'Invited' && (
                        <input
                          type="checkbox"
                          name="required"
                          className={skip}
                          onChange={(e) => getDataForRequired(e, userValue, '')}
                          id="toggle3"
                        />
                      )}
                    </td>
                    {tableRows.map(({ key, child }) => (
                      <td
                        align="left"
                        valign="top"
                        key={key}
                        onClick={() => {
                          if (key === 'pin_number')
                            changeViewPinStatus(userIndex);
                          else {
                            handleDropdownOptions(
                              dropdownOptions.view,
                              userValue,
                            );
                          }
                        }}
                      >
                        {child(userValue)}
                      </td>
                    ))}
                    <td>
                      <UserActionDropdown
                        selectedUser={userValue}
                        handleDropdownOptions={handleDropdownOptions}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
      {smallModal.visible && (
        <SmallAlertModal
          onClose={() => setSmallModal(defaultModalState)}
          onClick={smallModal.onClick}
          btnTxt={smallModal.btnTxt}
        >
          <div>
            {smallModal.text[0]}
            <b>{smallModal.text[1]}</b>
            {smallModal.text[2]}
          </div>
        </SmallAlertModal>
      )}
    </div>
  );
};

export default withAccessControl(ManageUser);
