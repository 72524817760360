import moment from 'moment';
import ReactDOM from 'react-dom';

export const ParkPopup = ({ data }) => {
  const div = document.createElement('div');
  function calculateEndTime(timestamp, activityDuration) {
    // Ensure that both timestamp and activityDuration are provided
    if (!timestamp || !activityDuration) {
      return 'N/A'; // Return 'N/A' or some default value if inputs are missing
    }

    // Parse the duration (assuming activityDuration is in "hh:mm:ss" format)
    const [hours, minutes, seconds] = activityDuration.split(':').map(Number);
    const duration = moment.duration({ hours, minutes, seconds });

    // Calculate the end timestamp by adding the duration to the initial timestamp
    const endTime = moment.unix(timestamp).add(duration);

    // Format the end time for display (e.g., "05:30, PM 16/02/2024")
    return endTime.format('hh:mm a, DD/MM/yyyy');
  }
  const popup = (
    <div>
      <div
        className="park_popup "
        style={{
          border: '2px solid #000',
          position: 'relative',
        }}
      >
        <img
          style={{ position: 'absolute', right: -18, top: -12 }}
          src="./assets/images/close.svg"
        />
        <div className="pe-0 me-0" style={{ lineHeight: 1.5 }}>
          <div className=" row m-0 p-0 w-100 d-flex">
            <div className="col-4 me-0 pe-0">Start :</div>
            <div className="col-8 ms-0 ps-0" style={{ fontWeight: 600 }}>
              {moment(data?.startTime * 1000).format('hh:mm a, DD/MM/yyyy')}
            </div>
          </div>
          <div className=" row m-0 p-0 w-100 d-flex">
            <div className="col-4 me-0 pe-0">End :</div>
            <div className="col-8 ms-0 ps-0" style={{ fontWeight: 600 }}>
              {moment(data?.endTime * 1000).format('hh:mm a, DD/MM/yyyy')}
            </div>
          </div>
          <div className=" row m-0 p-0 w-100 d-flex">
            <div className="col-4 pe-0">Duration:</div>
            <div className="col-8 ps-0" style={{ fontWeight: 600 }}>
              {data?.activity_duration}
            </div>
          </div>
        </div>

        <div className="asm_popup-tip-container_park_popup_border" />
        <div className="asm_popup-tip-container_start_popup" />
      </div>
    </div>
  );

  ReactDOM.render(popup, div);
  return div;
};
