import moment from 'moment';
import { capitaliseFirstLetter } from '../../pages/utils/helper';
import { MESSAGES, monthNames } from '../constants';
import { TruckType } from '../model';
import {
  FuelForDate,
  FuelForMonth,
  ProductivityDataArray,
  ProductivityForDate,
  ProductivityForHour,
  ProductivityForMonth,
  ProductivityTimeline,
  TimelineData,
  TimelineGranularity,
  TimelineKeys,
  TimelineTimeUseAPIData,
  TimelineTypes,
  TimeUseForDate,
  TimeUseForMonth,
  TimeUseTimeline,
  UtilisationDataArray,
  UtilisationForDate,
  UtilisationForMonth,
  UtilisationTimeline,
} from './models';
import { DateRange } from 'react-day-picker';
import { isUndefined, orderBy } from 'lodash';
import {
  convertMinutesToHHMM,
  currentDateNZ,
  formatAsNDigitNumber,
  getHoursInRange,
  minDate,
} from '../helper';

export const getMostLeastText = (
  type: TimelineTypes,
  granularity: TimelineGranularity,
): [string, string] => {
  let granularityText;
  switch (type) {
    case TimelineTypes.Productivity:
      granularityText =
        granularity === TimelineGranularity.Daily ? 'day' : 'month';
      return [
        `${MESSAGES.mostProductive} ${granularityText}:`,
        `${MESSAGES.leastProductive} ${granularityText}:`,
      ];
    case TimelineTypes.TimeUse:
      granularityText =
        granularity === TimelineGranularity.Daily ? 'day' : 'month';
      return [
        `${MESSAGES.mostContact} ${granularityText}:`,
        `${MESSAGES.leastContact} ${granularityText}:`,
      ];
    case TimelineTypes.Utilisation:
      granularityText =
        granularity === TimelineGranularity.Daily ? 'day' : 'month';
      return [
        `${MESSAGES.highestIdleTime} ${granularityText}:`,
        `${MESSAGES.lowestIdleTime} ${granularityText}:`,
      ];
    case TimelineTypes.Fuel:
      granularityText =
        granularity === TimelineGranularity.Daily ? 'day' : 'month';
      return [
        `${capitaliseFirstLetter(granularityText)} ${
          MESSAGES.mostConsumption
        }:`,
        `${capitaliseFirstLetter(granularityText)} ${
          MESSAGES.leastConsumption
        }:`,
      ];
    default:
      granularityText =
        granularity === TimelineGranularity.Daily ? 'day' : 'month';
      return [
        `${MESSAGES.mostProductive} ${granularityText}:`,
        `${MESSAGES.leastProductive} ${granularityText}:`,
      ];
  }
};

export const getGraphKeys = (
  type: TimelineTypes,
  assetType: TruckType | undefined,
): TimelineKeys[] => {
  switch (type) {
    case TimelineTypes.Productivity:
      if (assetType === TruckType.DMU) {
        return [
          {
            name: MESSAGES.fedIntoMill,
            color: '#1C4E80',
            colorClass: 'fedIntoMill',
          },
          {
            name: MESSAGES.overburden,
            color: '#E67F5A',
            colorClass: 'overburden',
          },
        ];
      } else {
        return [
          { name: MESSAGES.pushes, color: '#7638D9', colorClass: 'push' },
          {
            name: MESSAGES.averageSpeed,
            color: '#7E8593',
            colorClass: 'speed',
          },
        ];
      }

    case TimelineTypes.TimeUse:
      return [
        {
          name: MESSAGES.contactTime,
          color: '#3BC6B3',
          colorClass: 'contactTime',
        },
        {
          name: MESSAGES.nearbyTime,
          color: '#F9AA04',
          colorClass: 'nearbyTime',
        },
      ];
    case TimelineTypes.Utilisation:
      return [
        {
          name: MESSAGES.transmissionTime,
          color: '#1A4C7E',
          colorClass: 'contactTime',
        },
        {
          name: MESSAGES.idleTime,
          color: '#69D8E3',
          colorClass: 'nearbyTime',
        },
        {
          name: MESSAGES.inactiveTime,
          color: '#E3E3E3',
          colorClass: 'nearbyTime',
        },
        {
          name: MESSAGES.tonnes,
          color: '#7638D9',
          colorClass: 'tonnage',
        },
      ];
    case TimelineTypes.Fuel:
      return [
        {
          name: MESSAGES.fuel,
          color: '#D938B4',
          colorClass: 'Fuel',
        },
      ];
    default:
      return [
        { name: MESSAGES.pushes, color: '#7638D9', colorClass: 'push' },
        {
          name: MESSAGES.averageSpeed,
          color: '#7E8593',
          colorClass: 'speed',
        },
      ];
  }
};

const addLeadingZero = (number: number): string => {
  if (number < 10) {
    return `0${number}`;
  } else {
    return number.toString();
  }
};

export const hoursToHHMM = (hours: number): string => {
  return `${addLeadingZero(hours)}:00 - ${addLeadingZero(hours)}:59`;
};

export const formatHour = (inputHour: number): string => {
  const hour = inputHour.toString().padStart(2, '0');
  const formattedTime = `${hour}:00`;
  return formattedTime;
};

export const getUnits = (
  name?: string,
  granuality?: TimelineGranularity,
): string => {
  if (!name || !granuality) {
    return '';
  }
  if (name === 'fedIntoMill' || name === 'overburden' || name === 'tonnage') {
    return ' t';
  } else if (name === 'pushes') {
    return ' p';
  } else if (name === 'speed') {
    return ' km/h';
  } else if (
    name === 'contactTime' ||
    name === 'nearbyTime' ||
    name === 'transmissionTime' ||
    name === 'idleTime' ||
    name === 'inactiveTime'
  ) {
    return ' h';
  } else if (name === 'fuel') {
    return ' ltrs';
  }
  return '';
};

//This function is being used to generate dummy data for utilization and fuel timeline graphs
export const generateData = (
  assetType: TruckType,
  timelineType: TimelineTypes,
  granuality: TimelineGranularity,
): Object[] | undefined => {
  const currentHour = new Date().getHours();
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  );
  const firstDayOfFirstMonth = new Date(currentDate.getFullYear(), 0, 1); // January is represented by 0
  if (assetType === TruckType.DMU) {
    if (granuality === TimelineGranularity.Hourly) {
      return Array.from({ length: currentHour + 1 }, (_, hour) => ({
        hour,
        fedIntoMill: Math.floor(Math.random() * 121),
        overburden: Math.floor(Math.random() * 121),
      }));
    } else if (granuality === TimelineGranularity.Daily) {
      return Array.from({ length: currentDate.getDate() }, (_, index) => {
        const date = new Date(firstDayOfMonth);
        date.setDate(index + 1); // Set the date from 1 to the last day of the current month

        // Check if the current date is greater than the index (current or past date)
        const isCurrentOrPastDate = currentDate.getDate() >= index + 1;

        return {
          date: moment(date).format('DD/MM/YYYY'),
          fedIntoMill: isCurrentOrPastDate
            ? Math.floor(Math.random() * 601)
            : 0,
          overburden: isCurrentOrPastDate ? Math.floor(Math.random() * 601) : 0,
        };
      });
    } else {
      return Array.from({ length: 12 }, (_, index) => {
        const date = new Date(firstDayOfFirstMonth);
        date.setMonth(date.getMonth() + index);

        // Check if the current month is greater than the index (current or past month)
        const isCurrentOrPastMonth = currentDate.getMonth() >= index;

        return {
          month: monthNames[date.getMonth()],
          fedIntoMill: isCurrentOrPastMonth
            ? Math.floor(Math.random() * 601)
            : 0,
          overburden: isCurrentOrPastMonth
            ? Math.floor(Math.random() * 601)
            : 0,
        };
      });
    }
  } else {
    if (granuality === TimelineGranularity.Hourly) {
      if (timelineType === TimelineTypes.Productivity) {
        return Array.from({ length: currentHour + 1 }, (_, hour) => ({
          hour,
          pushes: Math.floor(Math.random() * 11), // Random number between 0 and 10
          speed: Math.floor(Math.random() * 26), // Random number between 0 and 25
        }));
      } else if (timelineType === TimelineTypes.TimeUse) {
        return Array.from({ length: currentHour + 1 }, (_, hour) => ({
          hour,
          contactTime: Math.floor(Math.random() * 61), // Random number between 0 and 10
          nearbyTime: Math.floor(Math.random() * 61), // Random number between 0 and 25
        }));
      } else if (timelineType === TimelineTypes.Utilisation) {
        return Array.from({ length: currentHour + 1 }, (_, hour) => ({
          hour,
          transmissionTime: Math.floor(Math.random() * 61),
          idleTime: Math.floor(Math.random() * 61),
          inactiveTime: Math.floor(Math.random() * 61),
        }));
      } else {
        return Array.from({ length: currentHour + 1 }, (_, hour) => ({
          hour,
          fuel: Math.floor(Math.random() * 25),
        }));
      }
    } else if (granuality === TimelineGranularity.Daily) {
      if (timelineType === TimelineTypes.Productivity) {
        return Array.from({ length: currentDate.getDate() }, (_, index) => {
          const date = new Date(firstDayOfMonth);
          date.setDate(index + 1); // Set the date from 1 to the last day of the current month

          // Check if the current date is greater than the index (current or past date)
          const isCurrentOrPastDate = currentDate.getDate() >= index + 1;

          return {
            date: moment(date).format('DD/MM/YYYY'),
            pushes: isCurrentOrPastDate ? Math.floor(Math.random() * 21) : 0,
            speed: isCurrentOrPastDate ? Math.floor(Math.random() * 26) : 0,
          };
        });
      } else if (timelineType === TimelineTypes.TimeUse) {
        return Array.from({ length: currentDate.getDate() }, (_, index) => {
          const date = new Date(firstDayOfMonth);
          date.setDate(index + 1); // Set the date from 1 to the last day of the current month

          // Check if the current date is greater than the index (current or past date)
          const isCurrentOrPastDate = currentDate.getDate() >= index + 1;

          return {
            date: moment(date).format('DD/MM/YYYY'),
            contactTime: isCurrentOrPastDate
              ? Math.floor(Math.random() * 61)
              : 0,
            nearbyTime: isCurrentOrPastDate
              ? Math.floor(Math.random() * 61)
              : 0,
          };
        });
      } else if (timelineType === TimelineTypes.Utilisation) {
        return Array.from({ length: currentDate.getDate() }, (_, index) => {
          const date = new Date(firstDayOfMonth);
          date.setDate(index + 1); // Set the date from 1 to the last day of the current month

          // Check if the current date is greater than the index (current or past date)
          const isCurrentOrPastDate = currentDate.getDate() >= index + 1;

          return {
            date: moment(date).format('DD/MM/YYYY'),
            transmissionTime: isCurrentOrPastDate
              ? Math.floor(Math.random() * 61)
              : 0,
            idleTime: isCurrentOrPastDate ? Math.floor(Math.random() * 61) : 0,
            inactiveTime: isCurrentOrPastDate
              ? Math.floor(Math.random() * 61)
              : 0,
          };
        });
      } else {
        return Array.from({ length: currentDate.getDate() }, (_, index) => {
          const date = new Date(firstDayOfMonth);
          date.setDate(index + 1); // Set the date from 1 to the last day of the current month

          // Check if the current date is greater than the index (current or past date)
          const isCurrentOrPastDate = currentDate.getDate() >= index + 1;

          return {
            date: moment(date).format('DD/MM/YYYY'),
            fuel: isCurrentOrPastDate ? Math.floor(Math.random() * 25) : 0,
          };
        });
      }
    } else if (granuality === TimelineGranularity.Monthly) {
      if (timelineType === TimelineTypes.Productivity) {
        return Array.from({ length: 12 }, (_, index) => {
          const date = new Date(firstDayOfFirstMonth);
          date.setMonth(date.getMonth() + index);

          // Check if the current month is greater than the index (current or past month)
          const isCurrentOrPastMonth = currentDate.getMonth() >= index;

          return {
            month: monthNames[date.getMonth()],
            pushes: isCurrentOrPastMonth ? Math.floor(Math.random() * 21) : 0,
            speed: isCurrentOrPastMonth ? Math.floor(Math.random() * 26) : 0,
          };
        });
      } else if (timelineType === TimelineTypes.TimeUse) {
        return Array.from({ length: 12 }, (_, index) => {
          const date = new Date(firstDayOfFirstMonth);
          date.setMonth(date.getMonth() + index);

          // Check if the current month is greater than the index (current or past month)
          const isCurrentOrPastMonth = currentDate.getMonth() >= index;

          return {
            month: monthNames[date.getMonth()],
            contactTime: isCurrentOrPastMonth
              ? Math.floor(Math.random() * 61)
              : 0,
            nearbyTime: isCurrentOrPastMonth
              ? Math.floor(Math.random() * 61)
              : 0,
          };
        });
      } else if (timelineType === TimelineTypes.Utilisation) {
        return Array.from({ length: 12 }, (_, index) => {
          const date = new Date(firstDayOfFirstMonth);
          date.setMonth(date.getMonth() + index);

          // Check if the current month is greater than the index (current or past month)
          const isCurrentOrPastMonth = currentDate.getMonth() >= index;

          return {
            month: monthNames[date.getMonth()],
            transmissionTime: isCurrentOrPastMonth
              ? Math.floor(Math.random() * 61)
              : 0,
            idleTime: isCurrentOrPastMonth ? Math.floor(Math.random() * 61) : 0,
            inactiveTime: isCurrentOrPastMonth
              ? Math.floor(Math.random() * 61)
              : 0,
          };
        });
      } else {
        return Array.from({ length: 12 }, (_, index) => {
          const date = new Date(firstDayOfFirstMonth);
          date.setMonth(date.getMonth() + index);

          // Check if the current month is greater than the index (current or past month)
          const isCurrentOrPastMonth = currentDate.getMonth() >= index;

          return {
            month: monthNames[date.getMonth()],
            fuel: isCurrentOrPastMonth ? Math.floor(Math.random() * 25) : 0,
          };
        });
      }
    }
  }
};

export const generateProductivityData = (
  granularity: TimelineGranularity,
  pushes: TimelineData[],
  speed: TimelineData[],
): ProductivityTimeline => {
  const pushesKeyMap: { [key: string]: number } = pushes.reduce(
    (acc: { [key: string]: number }, item) => {
      acc[item._id] = item.yAxis;
      return acc;
    },
    {},
  );

  const speedKeyMap: { [key: string]: number } = speed.reduce(
    (acc: { [key: string]: number }, item) => {
      acc[item._id] = item.yAxis;
      return acc;
    },
    {},
  );

  const pushesSpeedKeyUnion: Set<string> = new Set([
    ...Object.keys(pushesKeyMap),
    ...Object.keys(speedKeyMap),
  ]);

  if (granularity === TimelineGranularity.Hourly) {
    const productivityResult: ProductivityTimeline = Array.from(
      pushesSpeedKeyUnion,
    ).map((pushesSpeedKey: string) => ({
      hour: pushesSpeedKey,
      pushes: pushesKeyMap[pushesSpeedKey] || 0,
      speed:
        Number(
          formatAsNDigitNumber(Number(speedKeyMap[pushesSpeedKey] ?? 0), 2),
        ) || 0,
    }));

    const sortedProductivityResult: ProductivityTimeline = orderBy(
      productivityResult,
      [
        (productivity: ProductivityForHour) => {
          const [hour] = productivity.hour.split(':').map(Number);
          return hour;
        },
        (productivity: ProductivityForHour) => {
          const [, minutes] = productivity.hour.split(':').map(Number);
          return minutes;
        },
      ],
      ['asc', 'asc'],
    );
    return sortedProductivityResult;
  } else if (granularity === TimelineGranularity.Daily) {
    const productivityResult: ProductivityTimeline = Array.from(
      pushesSpeedKeyUnion,
    ).map((pushesSpeedKey: string) => ({
      date: pushesSpeedKey,
      pushes: pushesKeyMap[pushesSpeedKey] || 0,
      speed:
        Number(
          formatAsNDigitNumber(Number(speedKeyMap[pushesSpeedKey] ?? 0), 2),
        ) || 0,
    }));

    const sortedProductivityResult = productivityResult.sort(
      (
        productivity1: ProductivityForDate,
        productivity2: ProductivityForDate,
      ) =>
        moment(productivity1.date, 'DD/MM/YYYY').diff(
          moment(productivity2.date, 'DD/MM/YYYY'),
        ),
    );

    return sortedProductivityResult;
  } else if (granularity === TimelineGranularity.Monthly) {
    const productivityResult: ProductivityTimeline = Array.from(
      pushesSpeedKeyUnion,
    ).map((pushesSpeedKey: string) => ({
      month: pushesSpeedKey,
      pushes: pushesKeyMap[pushesSpeedKey] || 0,
      speed:
        Number(
          formatAsNDigitNumber(Number(speedKeyMap[pushesSpeedKey] ?? 0), 2),
        ) || 0,
    }));

    return productivityResult.sort(
      (
        productivity1: ProductivityForMonth,
        productivity2: ProductivityForMonth,
      ) =>
        moment(productivity1.month, 'MMMM YYYY').diff(
          moment(productivity2.month, 'MMMM YYYY'),
        ),
    );
  } else return [];
};

export const generateTimeUseData = (
  data: { xAxis: string; yAxis1: number; yAxis2: number }[],
  granularity: TimelineGranularity,
): TimeUseTimeline => {
  if (granularity === TimelineGranularity.Hourly) {
    const timeUseData: TimeUseTimeline = data
      .map(
        (timeUseItem: { xAxis: string; yAxis1: number; yAxis2: number }) => ({
          hour: timeUseItem.xAxis,
          contactTime: roundOff2(timeUseItem.yAxis1 / 60),
          nearbyTime: roundOff2(timeUseItem.yAxis2 / 60),
        }),
      )
      .sort(
        (
          timeUseItem1: {
            hour: string;
            contactTime: number;
            nearbyTime: number;
          },
          timeUseItem2: {
            hour: string;
            contactTime: number;
            nearbyTime: number;
          },
        ) => {
          const [timeUseItem1Hours, timeUseItem1Minutes] = timeUseItem1.hour
            .split(':')
            .map(Number);
          const [timeUseItem2Hours, timeUseItem2Minutes] = timeUseItem2.hour
            .split(':')
            .map(Number);

          if (timeUseItem1Hours === timeUseItem2Hours) {
            return timeUseItem1Minutes - timeUseItem2Minutes;
          }

          return timeUseItem1Hours - timeUseItem2Hours;
        },
      );

    return timeUseData;
  } else if (granularity === TimelineGranularity.Daily) {
    const timeUseData: TimeUseTimeline = data
      .map(
        (timeUseItem: { xAxis: string; yAxis1: number; yAxis2: number }) => ({
          date: timeUseItem.xAxis,
          contactTime: roundOff2(timeUseItem.yAxis1 / 3600),
          nearbyTime: roundOff2(timeUseItem.yAxis2 / 3600),
        }),
      )
      .sort(
        (
          timeUseItem1: {
            date: string;
            contactTime: number;
            nearbyTime: number;
          },
          timeUseItem2: {
            date: string;
            contactTime: number;
            nearbyTime: number;
          },
        ) =>
          moment(timeUseItem1.date, 'DD/MM/YYYY').diff(
            moment(timeUseItem2.date, 'DD/MM/YYYY'),
          ),
      );
    return timeUseData;
  } else if (granularity === TimelineGranularity.Monthly) {
    const timeUseData: TimeUseTimeline = data
      .map((item: { xAxis: string; yAxis1: number; yAxis2: number }) => ({
        month: item.xAxis,
        contactTime: roundOff2(item.yAxis1 / 3600),
        nearbyTime: roundOff2(item.yAxis2 / 3600),
      }))
      .sort(
        (
          timeUseItem1: {
            month: string;
            contactTime: number;
            nearbyTime: number;
          },
          timeUseItem2: {
            month: string;
            contactTime: number;
            nearbyTime: number;
          },
        ) =>
          moment(timeUseItem1.month, 'MMMM YYYY').diff(
            moment(timeUseItem2.month, 'MMMM YYYY'),
          ),
      );
    return timeUseData;
  } else {
    return [];
  }
};

export const roundOff2 = (data: number | undefined): number => {
  if (!data) {
    return 0;
  }
  return Math.round((data + Number.EPSILON) * 100) / 100;
};

export const isDateInRange = (
  from: string,
  to: string | undefined,
  date: string,
): boolean => {
  const fromDate: moment.Moment = moment(from, 'DD/MM/YYYY');
  const toDate: moment.Moment | undefined = to
    ? moment(to, 'DD/MM/YYYY')
    : undefined;
  const dateToCheck: moment.Moment = moment(date, 'DD/MM/YYYY');

  if (toDate) {
    return (
      dateToCheck.isSameOrAfter(fromDate) && dateToCheck.isSameOrBefore(toDate)
    );
  } else {
    return dateToCheck.isSame(fromDate);
  }
};

export const isMonthInRange = (
  from: string,
  to: string | undefined,
  month: string,
): boolean => {
  // Parse the 'from' date and get the start of the month
  const fromDate: moment.Moment = moment(from, 'DD/MM/YYYY').startOf('month');
  // Parse the month to check (assuming month is in the format 'MMMM, YYYY')
  const monthToCheck: moment.Moment = moment(month, 'MMMM, YYYY').startOf(
    'month',
  );

  if (to) {
    const toDate: moment.Moment = moment(to, 'DD/MM/YYYY').endOf('month');

    return monthToCheck.isBetween(fromDate, toDate, 'month', '[]');
  } else {
    return monthToCheck.isSame(fromDate, 'month');
  }
};

export const isDateRangeLessThan18Days = (from: Date, to: Date): boolean => {
  const msInADay: number = 24 * 60 * 60 * 1000;
  const diffInMs: number = to.getTime() - from.getTime();
  const diffInDays: number = diffInMs / msInADay;

  return diffInDays < 18;
};

export const subtractDays = (date: Date, days: number): Date => {
  const subtractedDate: Date = new Date(date);
  subtractedDate.setDate(subtractedDate.getDate() - days);
  if (moment(subtractedDate).isBefore(minDate)) {
    return minDate.toDate();
  }
  return subtractedDate;
};

export const addDays = (date: Date, days: number): Date => {
  const addedDate: Date = new Date(date);
  addedDate.setDate(addedDate.getDate() + days);

  if (addedDate > currentDateNZ) {
    return currentDateNZ;
  }

  return addedDate;
};

export const getFirstDayOfMonth = (date: Date): Date => {
  const firstDate = moment(new Date(date.getFullYear(), date.getMonth(), 1));
  if (firstDate.isBefore(minDate)) {
    return minDate.toDate();
  }
  return firstDate.toDate();
};

export const getLastDayOfMonth = (date: Date): Date => {
  if (
    date.getFullYear() === currentDateNZ.getFullYear() &&
    date.getMonth() === currentDateNZ.getMonth()
  ) {
    return currentDateNZ;
  }
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const containsSixMonths = (start: Date, end: Date): boolean => {
  return moment(end).diff(moment(start), 'months', true) >= 6;
};

export const addThreeMonths = (date: Date): Date => {
  const resultDate = moment(date).add(3, 'months').toDate();

  if (resultDate > currentDateNZ) {
    return currentDateNZ;
  }

  return resultDate;
};

export const subtractThreeMonths = (date: Date): Date => {
  const momentDate = moment(date);

  const newDate = momentDate.subtract(3, 'months').toDate();

  if (moment(newDate).isBefore(minDate)) {
    return minDate.toDate();
  }

  return newDate;
};

export const getMaxPushesDaily = (data: Object[]): string => {
  if (data.length === 0) {
    return '';
  }

  let maxPushesObject: ProductivityForDate = data[0] as ProductivityForDate;

  for (const item of data) {
    const itemData: ProductivityForDate = item as ProductivityForDate;
    if (itemData.pushes > maxPushesObject.pushes) {
      maxPushesObject = itemData;
    }
  }

  return `${maxPushesObject.date} | ${maxPushesObject.pushes} pushes`;
};

export const getMaxPushesMonthly = (data: Object[]) => {
  if (data.length === 0) {
    return '';
  }

  let maxPushesObject: ProductivityForMonth = data[0] as ProductivityForMonth;

  for (const item of data) {
    const itemData: ProductivityForMonth = item as ProductivityForMonth;
    if (itemData.pushes > maxPushesObject.pushes) {
      maxPushesObject = itemData;
    }
  }
  return `${maxPushesObject.month} | ${maxPushesObject.pushes} pushes`;
};

export const getMaxContactDaily = (data: Object[]): string => {
  if (data.length === 0) {
    return '';
  }

  let maxObject: TimeUseForDate = data[0] as TimeUseForDate;

  for (const item of data) {
    const itemData: TimeUseForDate = item as TimeUseForDate;
    if (itemData.contactTime > maxObject.contactTime) {
      maxObject = itemData;
    }
  }

  return `${maxObject.date} | ${convertMinutesToHHMM(
    maxObject.contactTime * 60,
  )} h`;
};

export const getMaxContactMonthly = (data: Object[]): string => {
  if (data.length === 0) {
    return '';
  }

  let maxObject: TimeUseForMonth = data[0] as TimeUseForMonth;

  for (const item of data) {
    const itemData: TimeUseForMonth = item as TimeUseForMonth;
    if (itemData.contactTime > maxObject.contactTime) {
      maxObject = itemData;
    }
  }

  return `${maxObject.month} | ${convertMinutesToHHMM(
    maxObject.contactTime * 60,
  )} h`;
};

export const getMaxUtilisationDaily = (data: Object[]): string => {
  if (data.length === 0) {
    return '';
  }

  let maxObject: UtilisationForDate = data[0] as UtilisationForDate;

  for (const item of data) {
    const itemData: UtilisationForDate = item as UtilisationForDate;
    if (itemData.idleTime > maxObject.idleTime) {
      maxObject = itemData;
    }
  }

  return `${maxObject.date} | ${convertMinutesToHHMM(maxObject.idleTime)} h`;
};

export const getMaxUtilisationMonthly = (data: Object[]): string => {
  if (data.length === 0) {
    return '';
  }

  let maxObject: UtilisationForMonth = data[0] as UtilisationForMonth;

  for (const item of data) {
    const itemData: UtilisationForMonth = item as UtilisationForMonth;
    if (itemData.idleTime > maxObject.idleTime) {
      maxObject = itemData;
    }
  }

  return `${maxObject.month} | ${convertMinutesToHHMM(maxObject.idleTime)} h`;
};

export const getMaxFuelDaily = (data: Object[]): string => {
  let maxFuel: number = -1;
  let maxFuelDate: string = '';

  for (const entry of data) {
    const data: FuelForDate = entry as FuelForDate;
    if (data.fuel > maxFuel) {
      maxFuel = data.fuel;
      maxFuelDate = data.date;
    }
  }

  return `${moment(maxFuelDate).format('DD/MM/YYYY')} | ${maxFuel} ltrs`;
};

export const getMaxFuelMonthly = (data: Object[]): string => {
  let maxFuel: number = -1;
  let maxFuelMonth: string = '';

  for (const entry of data) {
    const data: FuelForMonth = entry as FuelForMonth;
    if (data.fuel > maxFuel) {
      maxFuel = data.fuel;
      maxFuelMonth = data.month;
    }
  }

  return `${maxFuelMonth} | ${maxFuel} ltrs`;
};

export const CheckIsLive = (dateRange: DateRange | undefined): boolean => {
  if (!dateRange) return false;
  const fromDate: moment.Moment = moment(dateRange.from).startOf('day');
  const toDate: moment.Moment | undefined = dateRange.to
    ? moment(dateRange.to).startOf('day')
    : undefined;
  if (isUndefined(toDate)) {
    if (moment(currentDateNZ).isSame(fromDate, 'date')) {
      return true;
    }
  } else {
    if (
      moment(currentDateNZ).isSame(fromDate, 'date') &&
      moment(currentDateNZ).isSame(toDate, 'date')
    ) {
      return true;
    }
  }
  return false;
};

export const calculateTransmissionTime = (
  engineHours: TimelineData[],
  idleTime: TimelineData[],
): TimelineData[] => {
  const idleTimeMap: Map<string, number> = new Map(
    idleTime.map((item: TimelineData) => [item._id, item.yAxis]),
  );

  const transmissionTime: TimelineData[] = engineHours.map((engineHour) => {
    const idleYAxis: number = idleTimeMap.get(engineHour._id) || 0;
    return {
      _id: engineHour._id,
      yAxis: engineHour.yAxis - idleYAxis,
    };
  });

  return transmissionTime;
};

export const getMinutesInMonth = (month: string, year: number): number => {
  const date: Date = new Date(
    year,
    new Date(Date.parse(month + ' 1, ' + year)).getMonth() + 1,
    0,
  );
  const daysInMonth: number = date.getDate();
  return daysInMonth * 24 * 60;
};

export const calculateInactiveTime = (
  engineHours: TimelineData[],
  granularity: TimelineGranularity,
) => {
  return engineHours.map((engineHour: TimelineData) => {
    let minutes: number = 0;
    if (granularity === TimelineGranularity.Hourly) {
      minutes = 60;
    } else if (granularity === TimelineGranularity.Daily) {
      if (engineHour._id === moment(currentDateNZ).format('DD/MM/YYYY')) {
        minutes = getHoursInRange({ from: currentDateNZ, to: undefined }) * 60;
      } else {
        minutes = 24 * 60;
      }
    } else if (granularity === TimelineGranularity.Monthly) {
      if (engineHour._id === moment(currentDateNZ).format('MMMM, YYYY')) {
        minutes =
          getHoursInRange({
            from: getFirstDayOfMonth(currentDateNZ),
            to: currentDateNZ,
          }) * 60;
      } else {
        const [month, year] = engineHour._id.split(', ');
        minutes = getMinutesInMonth(month, parseInt(year));
      }
    }
    return {
      _id: engineHour._id,
      yAxis: minutes - engineHour.yAxis,
    };
  });
};

export const generateUtilisationData = (
  granularity: TimelineGranularity,
  transmissionTime: TimelineData[],
  idleTime: TimelineData[],
  inactiveTime: TimelineData[],
  tonnage: TimelineData[],
): UtilisationTimeline => {
  const transmissionTimeKeyMap: { [key: string]: number } =
    transmissionTime.reduce((acc: { [key: string]: number }, item) => {
      let timeKey: string = item._id;
      if (granularity === TimelineGranularity.Daily) {
        timeKey = timeKey.slice(0, -5); // remove the year so that it matches the format in tonnage API
      }
      acc[timeKey] = item.yAxis;
      return acc;
    }, {});

  const idleTimeKeyMap: { [key: string]: number } = idleTime.reduce(
    (acc: { [key: string]: number }, item) => {
      let timeKey: string = item._id;
      if (granularity === TimelineGranularity.Daily) {
        timeKey = timeKey.slice(0, -5); // remove the year so that it matches the format in tonnage API
      }
      acc[timeKey] = item.yAxis;
      return acc;
    },
    {},
  );

  const inactiveTimeKeyMap: { [key: string]: number } = inactiveTime.reduce(
    (acc: { [key: string]: number }, item) => {
      let timeKey: string = item._id;
      if (granularity === TimelineGranularity.Daily) {
        timeKey = timeKey.slice(0, -5); // remove the year so that it matches the format in tonnage API
      }
      acc[timeKey] = item.yAxis;
      return acc;
    },
    {},
  );

  const intervalKeyName: 'hour' | 'date' | 'month' =
    granularity === TimelineGranularity.Hourly
      ? 'hour'
      : granularity === TimelineGranularity.Daily
      ? 'date'
      : 'month';

  /* Use the tonnage array as the base
      because API-side it has all of the date intervals added and sorted */
  const utilisationResult: UtilisationTimeline = tonnage.map(
    (tonnageInterval: TimelineData) => {
      let timeKey: string = tonnageInterval._id;
      if (granularity === TimelineGranularity.Hourly) {
        const hours = timeKey.split(':')[0];
        if (hours.length === 1) timeKey = '0' + timeKey;
      }
      // need to add year
      return {
        [intervalKeyName]: tonnageInterval._id,
        transmissionTime: transmissionTimeKeyMap[tonnageInterval._id] || 0,
        idleTime: idleTimeKeyMap[tonnageInterval._id] || 0,
        inactiveTime: inactiveTimeKeyMap[tonnageInterval._id] || 0,
        tonnage: tonnageInterval?.yAxis || 0,
      };
    },
  ) as UtilisationTimeline;
  return utilisationResult;
};

export const aggregateProductivityData = (
  productivityDataArray: ProductivityDataArray[],
  assetCount: number,
): ProductivityDataArray => {
  const aggregatedPushesData: {
    [key: string]: TimelineData;
  } = {};
  const aggregatedSpeedData: {
    [key: string]: TimelineData;
  } = {};

  productivityDataArray.forEach(({ pushes, speed }) => {
    pushes.forEach(({ _id, yAxis }) => {
      if (!aggregatedPushesData[_id]) {
        aggregatedPushesData[_id] = { _id, yAxis: 0 };
      }
      aggregatedPushesData[_id].yAxis += Number(yAxis);
    });

    speed.forEach(({ _id, yAxis }) => {
      if (!aggregatedSpeedData[_id]) {
        aggregatedSpeedData[_id] = { _id, yAxis: 0 };
      }
      aggregatedSpeedData[_id].yAxis += Number(yAxis);
    });
  });

  return {
    pushes: Object.values(aggregatedPushesData),
    speed: Object.values(aggregatedSpeedData).map(({ _id, yAxis }) => ({
      _id,
      yAxis: yAxis / assetCount,
    })),
  };
};

export const aggregateTimeUseData = (
  timeUseDataArray: {
    timeUse: TimelineTimeUseAPIData[];
  }[],
): TimelineTimeUseAPIData[] => {
  const aggregatedResults: {
    [xAxis: string]: { yAxis1: number; yAxis2: number };
  } = {};

  timeUseDataArray.forEach((timeUseData) => {
    timeUseData.timeUse.forEach((data) => {
      if (!aggregatedResults[data.xAxis]) {
        aggregatedResults[data.xAxis] = { yAxis1: 0, yAxis2: 0 };
      }
      aggregatedResults[data.xAxis].yAxis1 += data.yAxis1;
      aggregatedResults[data.xAxis].yAxis2 += data.yAxis2;
    });
  });

  return Object.keys(aggregatedResults).map((xAxis) => ({
    xAxis,
    yAxis1: aggregatedResults[xAxis].yAxis1,
    yAxis2: aggregatedResults[xAxis].yAxis2,
  }));
};

export const aggregateUtilisationData = (
  utilisationDataArray: UtilisationDataArray[],
): UtilisationDataArray => {
  const aggregatedTransmissionData: {
    [key: string]: TimelineData;
  } = {};
  const aggregatedIdleData: {
    [key: string]: TimelineData;
  } = {};
  const aggregatedInactiveData: {
    [key: string]: TimelineData;
  } = {};

  utilisationDataArray.forEach(
    (utilisationData: {
      transmissionTime: TimelineData[];
      idleTime: TimelineData[];
      inactiveTime: TimelineData[];
    }) => {
      const { transmissionTime, idleTime, inactiveTime } = utilisationData;
      transmissionTime.forEach((transmissionData: TimelineData) => {
        const { _id, yAxis } = transmissionData;
        if (!aggregatedTransmissionData[_id]) {
          aggregatedTransmissionData[_id] = { _id, yAxis: 0 };
        }
        aggregatedTransmissionData[_id].yAxis += Number(yAxis);
      });

      idleTime.forEach((idleData: TimelineData) => {
        const { _id, yAxis } = idleData;
        if (!aggregatedIdleData[_id]) {
          aggregatedIdleData[_id] = { _id, yAxis: 0 };
        }
        aggregatedIdleData[_id].yAxis += Number(yAxis);
      });

      inactiveTime.forEach((inactiveData: TimelineData) => {
        const { _id, yAxis } = inactiveData;
        if (!aggregatedInactiveData[_id]) {
          aggregatedInactiveData[_id] = { _id, yAxis: 0 };
        }
        aggregatedInactiveData[_id].yAxis += Number(yAxis);
      });
    },
  );

  return {
    transmissionTime: Object.values(aggregatedTransmissionData),
    idleTime: Object.values(aggregatedIdleData),
    inactiveTime: Object.values(aggregatedInactiveData),
  };
};

export const getValueForTooltipAnalysis = (
  payload: { name?: string; value: string | number },
  granularity: TimelineGranularity,
  timelineType: TimelineTypes,
): string => {
  let value: string | number = payload.value;
  if (
    timelineType === TimelineTypes.Utilisation &&
    payload.name !== 'tonnage'
  ) {
    value = convertMinutesToHHMM(Number(payload.value));
  } else if (timelineType === TimelineTypes.TimeUse) {
    value = convertMinutesToHHMM(
      granularity === TimelineGranularity.Hourly
        ? Number(payload.value)
        : Number(payload.value) * 60,
    );
  }
  const units: string = getUnits(payload.name, granularity);

  return value + ' ' + units;
};
