import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

const SpeedingIcon: React.FC<Props> = ({ width = 17, height = 17 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 17 17"
  >
    <g
      id="Group_64219"
      data-name="Group 64219"
      transform="translate(8715.023 -12941)"
    >
      <g
        id="Rectangle_3583"
        data-name="Rectangle 3583"
        transform="translate(-8715.023 12941)"
        fill="#fff"
        stroke="#ebebeb"
        strokeWidth="1"
      >
        <rect width="17" height="17" rx="4" stroke="none" />
        <rect x="0.5" y="0.5" width="16" height="16" rx="3.5" fill="none" />
      </g>
      <g
        id="Group_60709"
        data-name="Group 60709"
        transform="translate(-10410.443 12675.26)"
      >
        <g id="speedometer_3564796" transform="translate(1697.746 268.74)">
          <path
            id="Path_34967"
            data-name="Path 34967"
            d="M89.419,35.154a6.131,6.131,0,0,0-3.746,1.552l1.614,1.614a3.869,3.869,0,0,1,2.131-.883Z"
            transform="translate(-83.607 -35.154)"
            fill="#ed3736"
          />
          <path
            id="Path_34968"
            data-name="Path 34968"
            d="M3.179,122.336l-1.614-1.614a6.13,6.13,0,0,0-1.554,3.746H2.294A3.869,3.869,0,0,1,3.179,122.336Z"
            transform="translate(-0.011 -118.659)"
            fill="#ed3736"
          />
          <path
            id="Path_34969"
            data-name="Path 34969"
            d="M381.5,124.468h2.283a6.13,6.13,0,0,0-1.554-3.746l-1.614,1.614A3.869,3.869,0,0,1,381.5,124.468Z"
            transform="translate(-371.433 -118.659)"
            fill="#ed3736"
          />
          <path
            id="Path_34970"
            data-name="Path 34970"
            d="M400.864,306.055a3.852,3.852,0,0,1-.4,1.394c.183.283.336.522.437.681a1.086,1.086,0,0,1-.446,1.561l.376.376a.362.362,0,0,0,.512,0,6.139,6.139,0,0,0,1.8-4.012Z"
            transform="translate(-390.801 -299.522)"
            fill="#ed3736"
          />
          <path
            id="Path_34971"
            data-name="Path 34971"
            d="M2.284,306.055H0a6.138,6.138,0,0,0,1.8,4.012.362.362,0,0,0,.512,0l1.1-1.1a.362.362,0,0,0,0-.512,3.883,3.883,0,0,1-1.127-2.4Z"
            transform="translate(0 -299.522)"
            fill="#ed3736"
          />
          <path
            id="Path_34972"
            data-name="Path 34972"
            d="M274.746,36.706A6.131,6.131,0,0,0,271,35.154v2.283a3.868,3.868,0,0,1,2.131.883Z"
            transform="translate(-264.465 -35.154)"
            fill="#ed3736"
          />
          <path
            id="Path_34973"
            data-name="Path 34973"
            d="M210.831,244.527a1.137,1.137,0,1,0-1.607,1.607,41.512,41.512,0,0,0,3.623,2.515.362.362,0,0,0,.5-.5A41.511,41.511,0,0,0,210.831,244.527Z"
            transform="translate(-203.854 -239.153)"
            fill="#ed3736"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SpeedingIcon;
