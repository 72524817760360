import moment from 'moment';
import { Col, Image, Row, Tooltip } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import info from '../assets/images/info.svg';
import point from '../assets/images/point.svg';
import source from '../assets/images/source-tooltip-icon.svg';
const allLoads = [
  {
    name: 'HT 214',
    loads: [
      {
        material: 'Overburden',
        lat: -37.20072998197126,
        lng: 174.7367804202146,
        timestamp: 1689292800,
      },
      {
        material: 'Overburden',
        lat: -37.18847150464672,
        lng: 174.73471347792656,
        timestamp: 1689292800,
      },
      {
        material: 'Overburden',
        lat: -37.197484861898104,
        lng: 174.73613291838035,
        timestamp: 1689292800,
      },
      {
        material: 'Overburden',
        lat: -37.19948538362046,
        lng: 174.7347992082831,
        timestamp: 1689292800,
      },
    ],
  },
];
export const HiddenPopup = (
  coordinate,
  { title = '', data = {}, flag, materials = [] },
) => {
  const div = document.createElement('div');
  const dumpEvent = data?.dumpEvent;
  let dumpPoint = data;

  function getMaterialColor(materialType) {
    console.log('_________________________', materialType);
    const material = materials.find(
      (item) => item?.MaterialType === materialType,
    );

    return material ? material.color : '#000000'; // Replace "#000000" with your desired default color
  }

  // console.log(data,"datatat in HiddenPopup")

  // const updatedDumpEvent=ongoingDumpEvents?.find((e)=>e?.name===)

  const Loader = () => {
    return (
      <>
        <>
          <tr style={{ backgroundColor: '#EBEBEB' }}>
            <td
              style={{
                paddingLeft: '1rem',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                textAlign: 'center',
              }}
            >
              Loading data...
            </td>
          </tr>
          <tr>
            <td style={{ paddingBottom: '1rem' }} />
          </tr>
        </>
      </>
    );
  };
  const DumpPopup = () => {
    return (
      <div
        style={{
          font: 'normal normal normal 12px/27px Open Sans',
          fontSize: '1rem',
          color: '#000000',
        }}
      >
        <div style={{ width: '15rem', borderTop: '1px solid #EBEBEB' }}>
          <div className=" d-flex flex-column px-4 mt-1">
            <Row className="w-100 mb-1">
              {' '}
              <Col
                style={{ paddingRight: 0, color: '#707070' }}
                className="col-7"
              >
                <span style={{ color: '#707070' }}> Total Loads: </span>
              </Col>
              <Col style={{ paddingLeft: 0 }} className="col-5">
                <strong> {data?.totalLoads} </strong>
              </Col>
            </Row>

            <div>
              {data?.totalLoads !== 0 ? (
                data?.materials?.length > 0 ? (
                  <ul>
                    {data?.materials?.map((e, i) => (
                      <li key={i} className="my-1">
                        <span
                          className={
                            e?.value === 'Coal' ? 'mm-dot-1' : 'mm-dot-2'
                          }
                          style={{
                            backgroundColor: getMaterialColor(e?.value),
                          }}
                        ></span>
                        <span className="mx-1"> {e?.value}</span>
                      </li>
                    ))}
                  </ul>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
        <div style={{ width: '15rem', borderTop: '1px solid #EBEBEB' }}>
          <div className=" d-flex flex-column px-4 mt-1">
            <Row className="w-100 mb-1">
              {' '}
              <Col style={{ paddingRight: 0 }} className="col-7 pe-0">
                <span style={{ color: '#707070' }}> Trucks: </span>
              </Col>
              <Col style={{ paddingLeft: 0 }} className="col-5">
                <strong> {data?.totalTrucks}</strong>
              </Col>
            </Row>

            <div>
              <ul>
                {data?.allLoads.map((truck, index) => {
                  return (
                    <li key={truck.id}>
                      <span className="">
                        {truck.name}{' '}
                        <span style={{ color: '#707070' }}>
                          {' '}
                          ({truck.loads.length})
                        </span>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        <tr>
          <td style={{ paddingBottom: '1rem' }} />
        </tr>

        <Tooltip
          id="mm-my-tooltip-data-html"
          place={'bottom'}
          style={{
            width: '138px',
            boxShadow: '1px 0.5rem 10px #00000029',
            backgroundColor: '#FFFFFF',
            color: 'black',
            border: '1px solid #EBEBEB',
            borderRadius: '4px',
          }}
        />
      </div>
    );
  };

  const SourcePopup = () => {
    // console.log(data,dumpPoint,"data inisde souce popup")
    return (
      <>
        <div
          style={{
            font: 'normal normal normal 12px/27px Open Sans',
            fontSize: '1rem',
            color: '#000000',
          }}
        >
          <div style={{ borderTop: '1px solid #EBEBEB' }}>
            <div className=" d-flex flex-column ps-4 pe-2 mt-3">
              <Row className="w-100 mb-2">
                {' '}
                <Col className="col-6">
                  <span style={{ color: '#707070' }}> Total Loads: </span>
                </Col>
                <Col className="col-6">
                  <strong> {data?.excavators?.loads} </strong>
                </Col>
              </Row>

              <Row className="w-100 mb-2">
                {' '}
                <Col className="col-6">
                  <span style={{ color: '#707070' }}> Materials: </span>
                </Col>
                <Col className="col-6">
                  {data?.excavators?.loads !== 0 ? (
                    dumpPoint?.materials?.length > 1 ? (
                      <a
                        href="#"
                        data-tooltip-id="mm-my-tooltip-data-html"
                        data-tooltip-html={`<ul style="width:max-content">
                  ${dumpPoint?.materials
                    ?.map(
                      (e, i) => `<li style="font-size:1.1rem" key=${i}>
                    <span class=${
                      e?.value === 'Coal' ? 'mm-dot-1' : 'mm-dot-2'
                    } style="background-color:${getMaterialColor(
                      e?.value,
                    )} ></span>
                  ${e?.value}</li>`,
                    )
                    ?.join('')}
                  </ul>`}
                      >
                        {dumpPoint?.materials?.length ?? '-'}
                      </a>
                    ) : (
                      dumpPoint?.materials?.map((e) => e?.value)
                    )
                  ) : (
                    '-'
                  )}
                </Col>
              </Row>
              <Row className="w-100 mb-2">
                {' '}
                <Col className="col-6">
                  <span style={{ color: '#707070' }}> Excavators: </span>
                </Col>
                <Col className="col-6">
                  <strong> {data?.excavators?.count}</strong>
                </Col>
              </Row>
              <Row className="w-100 mb-2">
                {' '}
                <Col className="col-6">
                  <span style={{ color: '#707070' }}> Last Activity: </span>
                </Col>
                <Col className="col-6" style={{ padding: 0 }}>
                  <strong> No records yet </strong>
                </Col>
              </Row>
            </div>
          </div>
          {dumpEvent?.allLoads.map((truck, index) => {
            return (
              index % 2 === 0 && (
                <tr key={truck.id}>
                  <td
                    style={{
                      paddingLeft: '1rem',
                    }}
                  >
                    <strong>{truck.name}</strong> ({truck.loads.length})
                  </td>
                  {index === dumpEvent.allLoads.length - 1 ? (
                    <td
                      style={{
                        paddingRight: '1rem',
                      }}
                    />
                  ) : (
                    <td
                      style={{
                        paddingRight: '1rem',
                      }}
                    >
                      <strong>{dumpEvent.allLoads[index + 1].name}</strong> (
                      {dumpEvent.allLoads[index + 1].loads.length})
                    </td>
                  )}
                </tr>
              )
            );
          })}
          <tr>
            <td style={{ paddingBottom: '1rem' }} />
          </tr>
        </div>
      </>
    );
  };
  const DetailsPopup = () => {
    return (
      <>
        <table
          className="dumpPointPopup mm-popup-width-3"
          key={`popup-${coordinate.lat}-${coordinate.lng}`}
        >
          <thead>
            <tr>
              <th
                colSpan={2}
                style={{
                  paddingTop: '0.5rem',
                  width: '100%',
                  textAlign: 'left',
                }}
              >
                <div
                  className="d-flex"
                  style={{ justifyContent: 'space-between' }}
                >
                  <div className="d-flex align-items-center  px-4">
                    <div className="">
                      <Image src={info} style={{ height: '1.3rem' }} />
                    </div>
                    <div
                      className="pb-1 px-2"
                      style={{
                        font: ' normal normal 600 12px/27px Open Sans',
                        fontSize: '1rem',
                        padding: 0,
                        margin: 0,
                        color: 'black',
                      }}
                    >
                      {dumpPoint?.name?.toUpperCase()} DETAILS
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {true ? (
              <>
                <div>
                  <div
                    style={{
                      font: 'normal normal normal 12px/27px Open Sans',
                      fontSize: '1rem',
                      color: '#000000',
                    }}
                  >
                    <div style={{ borderTop: '1px solid #EBEBEB' }}>
                      <div className=" d-flex flex-column px-4 mt-3">
                        <Row className="w-100 mb-2">
                          {' '}
                          <Col className="col-6">
                            <span style={{ color: '#707070' }}> Name :</span>
                          </Col>
                          <Col className="col-6">
                            <strong> {dumpPoint?.name} </strong>
                          </Col>
                        </Row>
                        <Row className="w-100 mb-2">
                          {' '}
                          <Col className="col-6">
                            <span style={{ color: '#707070' }}> Type :</span>
                          </Col>
                          <Col className="col-6">
                            <strong>
                              {' '}
                              {dumpPoint?.type === 'source'
                                ? 'Source Point'
                                : 'Dump Point'}{' '}
                            </strong>
                          </Col>
                        </Row>
                        <Row className="w-100 mb-2">
                          {' '}
                          <Col className="col-6">
                            <span style={{ color: '#707070' }}>
                              {' '}
                              Materials :
                            </span>
                          </Col>
                          <Col className="col-6">
                            {dumpPoint?.materials?.length > 1 ? (
                              <a
                                href="#"
                                data-tooltip-id="mm-my-tooltip-data-html"
                                data-tooltip-html={`<ul style="width:max-content">
                      ${dumpPoint?.materials
                        ?.map(
                          (e, i) => `<li style="font-size:1.1rem" key=${i}>
                        <span class=${
                          e?.value === 'Coal' ? 'mm-dot-1' : 'mm-dot-2'
                        } style="background-color:${getMaterialColor(
                          e?.value,
                        )} ></span>
                      ${e?.value}</li>`,
                        )
                        ?.join('')}
                      </ul>`}
                              >
                                {dumpPoint?.materials?.length ?? '-'}
                              </a>
                            ) : (
                              dumpPoint?.materials?.map((e) => e?.value)
                            )}
                          </Col>
                        </Row>
                        <Row className="w-100 mb-2">
                          {' '}
                          <Col className="col-6">
                            <span style={{ color: '#707070' }}>
                              {' '}
                              Description :
                            </span>
                          </Col>
                          <Col className="col-6">
                            <strong> {dumpPoint?.description}</strong>
                          </Col>
                        </Row>
                        <Row className="w-100 mb-2">
                          {' '}
                          <Col className="col-6">
                            <span style={{ color: '#707070' }}>
                              {' '}
                              Created On :
                            </span>
                          </Col>
                          <Col className="col-6">
                            <strong>
                              {' '}
                              {moment(dumpPoint?.createdAt).format(
                                'DD/MM/YYYY',
                              )}
                            </strong>
                          </Col>
                        </Row>
                        <Row className="w-100 mb-2">
                          {' '}
                          <Col className="col-6">
                            <span style={{ color: '#707070' }}>
                              {' '}
                              Created By :
                            </span>
                          </Col>
                          <Col className="col-6">
                            <strong> {'Username'} </strong>
                          </Col>
                        </Row>
                        <Row className="w-100 mb-2">
                          {' '}
                          <Col className="col-6">
                            <span style={{ color: '#707070' }}>
                              {' '}
                              First Activity :
                            </span>
                          </Col>
                          <Col className="col-6">
                            <strong>
                              {' '}
                              {moment(dumpPoint?.createdAt).format(
                                'DD/MM/YYYY',
                              )}{' '}
                            </strong>
                          </Col>
                        </Row>
                        <Row className="w-100 mb-2">
                          {' '}
                          <Col className="col-6">
                            <span style={{ color: '#707070' }}>
                              {' '}
                              Last Activity :
                            </span>
                          </Col>
                          <Col className="col-6">
                            <strong>
                              {moment(dumpPoint?.createdAt).format(
                                'DD/MM/YYYY',
                              )}
                            </strong>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <tr>
                      <td style={{ paddingBottom: '1rem' }} />
                    </tr>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Loader />
              </>
            )}
          </tbody>
        </table>
      </>
    );
  };
  const Showpopup = () => {
    return (
      <>
        <table
          className={`${
            data?.type === 'source' ? 'dumpPointPopup' : 'dumpPointPopup2'
          }  mm-popup-width-3`}
          key={`popup-${coordinate.lat}-${coordinate.lng}`}
        >
          <thead>
            <tr>
              <th
                colSpan={2}
                style={{
                  paddingTop: '0.5rem',
                  width: '100%',
                  textAlign: 'left',
                }}
              >
                <div className="d-flex px-4 mt-2 mb-3 align-items-start">
                  <div className="d-flex align-items-start">
                    <Image
                      src={data?.type === 'source' ? source : point}
                      style={{ height: '1rem' }}
                    />
                  </div>
                  <div
                    className="px-2 d-flex align-items-start"
                    style={{
                      font: ' normal normal 600 12px/27px Open Sans',
                      fontSize: '1rem',
                      lineHeight: '1rem',
                    }}
                  >
                    <div>
                      {title.length > 0 ? title.toUpperCase() : 'Unknown'}{' '}
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {data?.materials ? (
              data?.type === 'source' ? (
                <SourcePopup />
              ) : (
                <DumpPopup />
              )
            ) : (
              <>{title.length > 0 && <Loader />}</>
            )}
          </tbody>
        </table>
      </>
    );
  };

  // console.log("Insid e DumpPOint Popup", "dumpEvent", {dumpPoint,dumpEvent});
  // console.log("==================", flag)
  // console.log(data?.details,"detail data i ise hiddenn popup")
  const popup = (
    <>
      {flag != 0 && (
        <div>
          {/* <div style={{textAlign:'center', width:'81px',background:'white',height:'14px',font: 'normal normal 600 10px/27px Open Sans'}}>Hello Dipesh</div> */}
          {data?.details ? (
            <>
              <DetailsPopup />
            </>
          ) : (
            <>
              <Showpopup />
            </>
          )}

          <Tooltip
            id="mm-my-tooltip-data-html"
            place={'bottom'}
            style={{
              width: '11.5rem',
              boxShadow: '1px 0.5rem 10px #00000029',
              backgroundColor: '#FFFFFF',
              color: 'black',
              border: '1px solid #EBEBEB',
              borderRadius: '0.33rem',
            }}
          />
          {/* <div className="mm-popup-tip-container"></div> */}
        </div>
      )}
    </>
  );

  ReactDOM.render(popup, div);
  return div;
};
