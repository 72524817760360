import { useState } from 'react';
import FilterIcon from '../../../assets/icons/Filter.svg';
import TimelineFilterModal from './TimelineFilterModal';
const Filter = (props) => {
  const { toShowFilter, openModal } = props;

  return (
    <div
      onClick={openModal}
      style={{ marginLeft: '15px' }}
      className={`${
        toShowFilter ? 'd-flex' : 'd-none'
      } align-items-center ms-3 filter-div cursor-pointer`}
    >
      <img src={FilterIcon} alt="filters" />
      <span className="font-13 p-1 fw-bold">Filter</span>
    </div>
  );
};
const TimelineFilter = (props) => {
  const {
    toShowFilter,
    from,
    to,
    filteredData = {},
    setFilteredData = () => {},
    saveHandler,
    fetchTrucks,
    userFilter,
    onSaveClick = () => {},
  } = props;

  const [openFilterModal, setOpenFilterModal] = useState(false);

  const saveAndClose = (filterData, hasFiltered) => {
    setOpenFilterModal(false);
    onSaveClick(filterData, hasFiltered);
  };

  return (
    <div className="test-class" style={{ borderRadius: '4px 0px 0px 0px' }}>
      <Filter
        userFilter={props.userFilter}
        filteredData={filteredData}
        toShowFilter={toShowFilter}
        openModal={() => setOpenFilterModal(true)}
      />

      {openFilterModal ? (
        <TimelineFilterModal
          modalOpen={openFilterModal}
          modalToggle={() => setOpenFilterModal(false)}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          saveHandler={saveAndClose}
          from={from}
          to={to}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default TimelineFilter;
