import { MouseEventHandler } from 'react';
import styles from './Button.module.css';

type Props = {
  title: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  color?: string;
  style?: React.CSSProperties;
  isDisabled?: boolean;
  className?: string;
  active?: boolean;
};

const Button = ({
  title,
  onClick,
  color = '#3880F5',
  style = {},
  isDisabled = false,
  className = '',
  active = false,
}: Props) => {
  const hoverFocusClassName = `button-hover-focus-${color.replace('#', '')}`;

  const buttonStyle = {
    backgroundColor: color,
    ...style,
    ...(active ? { boxShadow: `0px 0px 10px ${color}` } : {}),
  };

  const hoverFocusStyle = `
    .${hoverFocusClassName}:hover, .${hoverFocusClassName}:focus {
      box-shadow: 0px 0px 10px ${color};
    }
  `;

  return (
    <>
      <style>{hoverFocusStyle}</style>
      <button
        className={`${styles.button} ${hoverFocusClassName} ${
          isDisabled ? styles.disabled : ''
        } ${className}`}
        style={buttonStyle}
        disabled={isDisabled}
        onClick={onClick}
      >
        {title}
      </button>
    </>
  );
};

export default Button;
