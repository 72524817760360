import { useEffect, useState } from 'react';
import { withAccessControl } from '../pages/service/with-access-control';
import './AssetTracking/css/bootstrap.min.css';
import TripsHistory from './AssetTracking/TripsHistory/TripsHistory';
import './asset.css';
import { AssetTrackingSections } from './utils/constants';
import AssetList from './AssetList/AssetList';
import TabSelect from '../pages/components/TabSelect/TabSelect';
import { useGlobalContext } from '../pages/service/GlobalContext';
import AssetTrackingLive from './AssetTrackingLive/AssetTrackingLive';
import { useNavigate } from 'react-router-dom';

const AssetTracking = (props) => {
  const params = new URLSearchParams(window.location.search);
  const paramValue = params.get('type');
  const [selectedSection, setSelectedSection] = useState(
    paramValue === 'history'
      ? AssetTrackingSections.TripHistory
      : AssetTrackingSections.AssetList,
  );
  const navigate = useNavigate();
  const { showLiveAssetTracking } = useGlobalContext();
  const tabList = [
    {
      label: AssetTrackingSections.AssetList,
      id: AssetTrackingSections.AssetList,
      onClick: () => setSelectedSection(AssetTrackingSections.AssetList),
      component: <AssetList />,
    },
    {
      label: AssetTrackingSections.TripHistory,
      id: AssetTrackingSections.TripHistory,
      onClick: () => setSelectedSection(AssetTrackingSections.TripHistory),
      component: <TripsHistory />,
    },
  ];

  useEffect(() => {
    if (
      selectedSection === AssetTrackingSections.AssetList &&
      paramValue === 'history'
    ) {
      navigate('/asset-tracking');
    }
  }, [selectedSection]);

  return (
    <div className="asm-asset-tracking px-0">
      {showLiveAssetTracking ? (
        <AssetTrackingLive />
      ) : (
        <TabSelect tabList={tabList} selectedTab={selectedSection} />
      )}
    </div>
  );
};

export default withAccessControl(AssetTracking);
