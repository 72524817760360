import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import ArrowDown from './arrowDown.png';
import SearchIcon from './Search.svg';
import './styles.css';

const DropdownField = ({
  title,
  name,
  fieldKey,
  selectedOptions,
  allOptions,
  multiselect,
  handleChange,
  upperLevelClassName = 'd-flex flex-row justify-content-between asm-field-container asm-filter-dropdown-options',
  lowerLevelClassName = '',
  errorMessage,
  showSelectAll = true,
  wrapperclas,
}) => {
  const [isDropdownVisible, setDropdown] = useState(false);
  const [dropdownSearch, setDropdownSearch] = useState('');

  const nameWithoutWhiteSpace = name ? name.split(' ').join('') : '';
  const allChosenOptions = multiselect
    ? allOptions?.filter(({ value }) => selectedOptions?.includes(value))
    : [];

  const convertCodeListToNames = (entireList, selected) => {
    if (!selected) return 'None';
    if (selected.length === 0) return 'None';

    const filteredList = entireList
      .filter(({ value, name }) => selected?.includes(value ?? name))
      .map(({ name }) => name);
    if (filteredList?.length === 0) return 'None';
    if (filteredList?.length === entireList?.length) return 'All ';
    else {
      return filteredList.join(', ');
    }
  };

  useEffect(() => {
    const clickListener = (e) => {
      if (e.target.id !== `keepDropdownOpen${nameWithoutWhiteSpace}`) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', clickListener);

    return () => {
      document.removeEventListener('mousedown', clickListener);
    };
  }, [nameWithoutWhiteSpace]);

  const selectAll = () => {
    if (allChosenOptions.length === allOptions.length) {
      // deselect all
      handleChange([]);
    } else {
      // select any that are not currently selected
      handleChange(
        allOptions.map((option) => {
          return option?.value;
        }),
      );
    }
  };

  return (
    <div className={upperLevelClassName}>
      {/* {name ? <label className="flex-one " style={{ marginLeft: "0.5rem" }}>{name}</label> : null} */}

      <div className="d-flex flex-column asm-add-user-input">
        <div className="position-relative width-fit-content">
          {/* open dropdown field */}
          <div className="asm-date-input-categories d-flex align-items-center justify-content-between mt-1">
            <div className="asm-text-1 me-2">{name ? name : 'Categories'}:</div>

            <button
              className={`asm-rounded-borders-and-padding pr-2 d-flex align-items-center  ${lowerLevelClassName} `}
              id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
              type="button"
              style={{
                backgroundColor: isDropdownVisible
                  ? 'rgb(10 128 47 / 6%)'
                  : null,
                // outline: isDropdownVisible ? " 0.063rem solid #547d54" : "0rem solid #547d54",
                height: '1.2rem',
                fontSize: '0.815rem',
                width: '5.4rem',
                border: `1px solid ${
                  isDropdownVisible ? 'rgb(84, 125, 84)' : '#EBEBEB'
                }`,
                borderRadius: '100rem',
                justifyContent: 'center',
                background: '#FAFAFA',
              }}
              onClick={() => {
                setDropdown(!isDropdownVisible);
              }}
            >
              <div
                className="d-flex justify-content-start px-1 position-relative"
                id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                style={{
                  alignItems: 'center',
                  width: '6rem',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  fontWeight: 'bold !important',
                  font: 'solid solid 0.8rem Open Sans',
                  fontSize: '0.6rem',
                  justifyContent: 'center',
                }}
              >
                {multiselect
                  ? convertCodeListToNames(allOptions, selectedOptions)
                  : allOptions.find(({ value }) => value === selectedOptions)
                      ?.name}
                {/* <div style={{ font: "normal normal 60 0rem/1.5rem Open Sans", }} id={`keepDropdownOpen${nameWithoutWhiteSpace}`}>

                </div> */}
              </div>
            </button>
            <div className="">
              <Image
                id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                src={ArrowDown}
                className=""
                style={{ height: '0.4rem', width: '0.5rem', cursor: 'pointer' }}
                alt="down"
                onClick={() => {
                  setDropdown(!isDropdownVisible);
                }}
              />
            </div>
          </div>

          {isDropdownVisible && (
            <div
              className="asm-hover-card pb-2 asm-dropdown-position"
              id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
            >
              {/* Search field */}
              <div className="border-bottom d-flex align-items-center px-2 ps-3 pt-1 pb-1">
                <img
                  src={SearchIcon}
                  style={{ width: '1rem', height: '1rem' }}
                  alt="search"
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                />
                <input
                  style={{ marginLeft: '0.3rem', fontSize: '0.8rem' }}
                  placeholder="Search"
                  className="asm-no-focus-border border border-0 ps-1 w-75"
                  onChange={({ target }) => setDropdownSearch(target.value)}
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                  value={dropdownSearch}
                />
              </div>

              {/* select all checkbox */}
              {multiselect && !dropdownSearch && showSelectAll ? (
                <div
                  style={{ marginTop: '0.5rem' }}
                  className="asm_option-row"
                  onClick={selectAll}
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                >
                  {multiselect && (
                    <input
                      type="checkbox"
                      checked={allChosenOptions.length === allOptions.length}
                      readOnly
                      style={{
                        transform: 'scale(0)',
                      }}
                      id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                      className="styled-checkbox"
                    />
                  )}
                  <label
                    style={{
                      color: '#2B2D2F',
                      fontSize: '0.8rem',
                      fontWeight: '400',
                    }}
                    id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                  >
                    Select All
                  </label>
                </div>
              ) : null}

              {/* dropdown options */}
              <div className="asm-options">
                {allOptions
                  ?.filter(({ name: n }) =>
                    n?.toLowerCase()?.includes(dropdownSearch?.toLowerCase()),
                  )
                  .map(({ name: optionName, value: optionValue }) => (
                    <div
                      className="asm_option-row"
                      key={optionValue}
                      onClick={() => {
                        let finalRes = [];
                        const found = selectedOptions?.filter(
                          (option) => option !== optionValue,
                        );
                        if (selectedOptions.length === 0) {
                          finalRes = [optionValue];
                        } else if (
                          !found?.length &&
                          selectedOptions.length > 1
                        ) {
                          finalRes = [...selectedOptions, optionValue];
                        } else {
                          if (!selectedOptions?.includes(optionValue)) {
                            finalRes = [...selectedOptions, optionValue];
                          } else {
                            finalRes = [...found];
                          }
                        }
                        handleChange(finalRes);

                        // handleChange(
                        //   [...selectedOptions, ...(!selectedOptions?.includes(optionValue) ? [optionValue] : [])]
                        // );
                        if (!multiselect) setDropdown(false);
                      }}
                      id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                    >
                      {multiselect && (
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(optionValue)}
                          readOnly
                          id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                          className="styled-checkbox"
                        />
                      )}
                      <label
                        style={{ fontSize: '0.8rem', fontWeight: '400' }}
                        id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                      >
                        {optionName}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
        {errorMessage && (
          <p className="error-msg-manage-operator">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default DropdownField;
