import { BulldozerSummary } from '../models';

export const MESSAGES = {
  timeline: 'TIMELINE',
  daily: 'DAILY',
  hourly: 'HOURLY',
  pushes: 'Pushes',
  speed: 'Speed',
  tonnes: 'Tonnes',
  mins: 'Minutes',
  hrs: 'Hours',
  ltrs: 'Litres',
  contactTime: 'Contact time',
  nearbyTime: 'Nearby time',
  transmissionTime: 'Transmission time',
  idleTime: 'Idle time',
  inactiveTime: 'Inactive time',
  fuel: 'Fuel',
  fedIntoMill: 'Fed into mill',
  overburden: 'Overburden',
  averageSpeed: 'Average speed',
  mostProductive: 'Most Productive',
  leastProductive: 'Least Productive',
  mostContact: 'Most Contact',
  leastContact: 'Least Contact',
  highestIdleTime: 'Highest Idle Time',
  lowestIdleTime: 'lowest Idle Time',
  mostConsumption: 'with the most consumption',
  leastConsumption: 'with the least consumption',
  totalTonnesProcessed: 'Total tonnes processed',
  totalTonnesPushed: 'Total tonnes pushed',
  totalTimeSite: 'Total time at site',
  totalEngineOnTime: 'Total Engine On Time',
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const maxHeightBreakPoint = 900;

export const bulldozerSummaryInitialData: BulldozerSummary = {
  currentPushes: 0,
  maximumPushes: 0,
  averageSpeed: 0,
  contactTime: 0,
  nearbyTime: 0,
  engineHours: 0,
  fuel: 0,
  idleTime: 0,
  inactiveTime: 0,
  transmissionTime: 0,
  totalEngineHours: 0,
};
