import { AxiosResponse } from 'axios';
import { RequestConfig } from '../../dmu/model';
import { getAuthToken } from '../../dmu/services';
import { API } from '../../pages/service/apiHandler';
import { NZSTEEL_API_BASE_URL } from '../../pages/utils/constants';
import { ENDPOINTS } from '../utils/constants';

export const fetchEngineHours = async (
  assets: string[],
  abortSignal: AbortSignal,
  logout: () => void,
) => {
  const requestConfig: RequestConfig = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    signal: abortSignal,
  };

  const response: AxiosResponse = await API.post(
    NZSTEEL_API_BASE_URL + ENDPOINTS.getEngineHoursMulti,
    {
      truckNames: assets,
    },
    requestConfig,
  );

  if (response.status === 200) {
    return JSON.parse(response.data.message);
  } else if (response.status === 403) {
    logout();
    return undefined;
  } else {
    return undefined;
  }
};
