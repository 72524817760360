import React, { useEffect } from 'react';
import ThreeDotIcon from './3dot.svg';

export default function ThreeDotMenu({
  menuVisible,
  setMenuVisible,
  onMenuSelect,
  menuOptions,
  uniqueName,
}) {
  const nameWithoutWhiteSpace = uniqueName
    ? uniqueName.split(' ').join('')
    : '';

  useEffect(() => {
    const clickListener = (e) => {
      if (e.target.id !== `keepDropdownOpen${nameWithoutWhiteSpace}`) {
        setMenuVisible(false);
      }
    };
    document.addEventListener('mousedown', clickListener);

    return () => {
      document.removeEventListener('mousedown', clickListener);
    };
  }, [nameWithoutWhiteSpace]);
  return (
    <div
      id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
      style={{ position: 'relative' }}
    >
      <div
        id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
        className={`px-3 py-1 d-flex align-items-center justify-content-end  ${
          menuVisible ? 'rounded' : ''
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setMenuVisible(!menuVisible);
        }}
        style={{
          cursor: 'pointer',
          // backgroundColor: menuVisible ? "#E2ECFC" : "transparent"
        }}
      >
        <img
          id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
          src={ThreeDotIcon}
          alt=""
        />
      </div>
      {menuVisible && (
        <div
          id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
          className="py-2 d-flex flex-column mt-1 text-center-align"
          style={{
            right: '0',
            backgroundColor: 'white',
            position: 'absolute',
            minWidth: 'max-content',
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
            border: '1px solid #E1E8F4',
            borderRadius: '0.5rem',
            cursor: 'pointer',
            zIndex: '2000',
            width: '200px',
            textAlign: 'center',
          }}
        >
          {menuOptions?.map((option, idx) => (
            <div
              className="asm-hover-blue py-2 d-flex align-items-center "
              style={{ paddingLeft: '20px' }}
              id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
              onClick={(e) => {
                e.stopPropagation();
                setMenuVisible(false);
                onMenuSelect(idx);
              }}
            >
              <img
                id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                style={{
                  height: '0.6rem',
                  width: '0.9rem',
                  objectFit: 'contain',
                }}
                src={option.icon}
                alt=""
              />
              <span
                id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                style={{
                  textAlign: 'left',
                  fontWeight: '600',
                  fontSize: '1rem',
                  paddingLeft: '.6rem',
                }}
              ></span>
              {option.displayName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
