import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { getBulldozerPushProgress, getContactTimePercentage } from './helper';
import { BulldozerSummary, TruckCategory } from '../../../models';
import styles from '../Summary.module.css';
import messages from '../../../constants/messages';
import DonutProgress from '../../../pages/components/PieChart/Donut/DonutProgress';
import colours from '../../../constants/colours';
import { upperFirst } from 'lodash';
import soil from '../../../assets/soil.png';
import { DateRange } from 'react-day-picker';
import { useAssetContext } from '../../../pages/service/assetContext';
import {
  convertMinutesToHHMM,
  formatAsNDigitNumber,
  getFirstAndLastDayOfMonth,
  subtractOneMonth,
} from '../../helper';
import { getContactNearby, getPushes } from '../../services';
import { subtractDays } from '../../timeline/helper';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { maxHeightBreakPoint } from '../../constants';
import Speedometer from '../../assets/Speedometer';
import { AuthContext } from '../../../pages/service/auth';
import { SelectedAsset } from '../../model';

type ProductivitySummaryProps = {
  truckCategory: TruckCategory;
  range: DateRange | undefined;
  data: BulldozerSummary;
};

const ProductivitySummary: FunctionComponent<ProductivitySummaryProps> = (
  props: ProductivitySummaryProps,
) => {
  const pushProgress: number = getBulldozerPushProgress(
    props.data.currentPushes,
    props.data.maximumPushes,
  );
  const [pushesMacro, setPushesMacro] = useState<number>(0);
  const [timeMacro, setTimeMacro] = useState<number>(0);
  const { selectedAssets } = useAssetContext();

  const abortControllers = useRef(new Map<string, AbortController>());

  const createAbortController = (key: string): AbortController => {
    const abortController = new AbortController();
    abortControllers.current.set(key, abortController);
    return abortController;
  };

  const abortPreviousRequests = () => {
    abortControllers.current.forEach((controller) => controller.abort());
    abortControllers.current.clear();
  };

  const { logOut } = useContext(AuthContext);

  const fetchData = async (): Promise<void> => {
    if (props.range?.from && props.range?.to) {
      const newRange: DateRange = getFirstAndLastDayOfMonth(
        subtractOneMonth(props.range.from),
      );

      const fetch = async (): Promise<void> => {
        try {
          abortPreviousRequests(); // Abort any previous requests
          setTimeMacro(0);
          setPushesMacro(0);
          let totalPushes: number = 0;
          let totalContactTime: number = 0;

          // Fetch data for each selected asset
          await Promise.all(
            selectedAssets.map(async (asset: SelectedAsset) => {
              const pushes: number | undefined = await getPushes(
                newRange,
                asset.name,
                createAbortController('pushes').signal,
                logOut,
              );

              const contactNearby:
                | { contact: number; nearby: number }
                | undefined = await getContactNearby(
                newRange,
                asset.name,
                createAbortController('contactNearby').signal,
                logOut,
              );

              // Aggregate pushes
              totalPushes += pushes ?? 0;
              totalContactTime += contactNearby
                ? contactNearby.contact ?? 0
                : 0;
            }),
          );

          setPushesMacro(
            totalPushes
              ? props.data.currentPushes - totalPushes
              : props.data.currentPushes,
          );
          setTimeMacro(
            totalContactTime
              ? props.data.contactTime - totalContactTime
              : props.data.contactTime,
          );
        } catch (error: unknown) {
          console.error(error);
        }
      };
      fetch();
    } else if (props.range?.from) {
      const newRange: DateRange = {
        from: subtractDays(props.range.from, 1),
        to: undefined,
      };

      const fetch = async (): Promise<void> => {
        try {
          abortPreviousRequests(); // Abort any previous requests
          setTimeMacro(0);
          setPushesMacro(0);
          let totalPushes: number = 0;
          let totalContactTime: number = 0;

          // Fetch data for each selected asset
          await Promise.all(
            selectedAssets.map(async (asset: SelectedAsset) => {
              const pushes: number | undefined = await getPushes(
                newRange,
                asset.name,
                createAbortController('pushes').signal,
                logOut,
              );

              const contactNearby:
                | { contact: number; nearby: number }
                | undefined = await getContactNearby(
                newRange,
                asset.name,
                createAbortController('contactNearby').signal,
                logOut,
              );

              // Aggregate pushes
              totalPushes += pushes ?? 0;
              totalContactTime += contactNearby
                ? contactNearby.contact ?? 0
                : 0;
            }),
          );

          setPushesMacro(
            totalPushes
              ? props.data.currentPushes - totalPushes
              : props.data.currentPushes,
          );
          setTimeMacro(
            totalContactTime
              ? props.data.contactTime - totalContactTime
              : props.data.contactTime,
          );
        } catch (error: unknown) {
          console.error(error);
        }
      };
      fetch();
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      abortPreviousRequests(); // Abort requests on component unmount
    };
  }, [props.range, selectedAssets]);

  // useEffect(() => {
  //   const interval = setInterval(
  //     () => {
  //       fetchData();
  //     },
  //     Number(process.env.REACT_APP_API_REFRESH_INTERVAL_IN_MINUTES ?? 5) *
  //       60 *
  //       1000,
  //   );
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [props.range, selectedAsset]);

  const contactTimePercentage: number = isNaN(
    getContactTimePercentage(props.data),
  )
    ? 0
    : getContactTimePercentage(props.data);

  const nearbyTimePercentage: number =
    contactTimePercentage === 0
      ? props.data.nearbyTime === 0
        ? 0
        : 100
      : 100 - contactTimePercentage;

  const { height } = useMediaQuery();

  return (
    <div className={styles.sectionContainer}>
      <div className={styles['summary-section-title']}>
        {messages.DMU.DASHBOARD.PRODUCTIVITY}
      </div>
      {props.truckCategory === TruckCategory.BULLDOZER ? (
        <section className={styles['sections-container']}>
          {/* {props.range && props.range.to === undefined ? ( */}
          {false ? (
            <>
              <div id="pushes" className={styles.pushes}>
                <DonutProgress
                  values={{
                    primary: pushProgress,
                    secondary: 100 - pushProgress,
                  }}
                  colorScheme={{
                    primary: colours.purple,
                    secondary: colours.darkGray,
                  }}
                  textContent={`Pushes`}
                />
                <span className={styles['summary-message-key']}>
                  00 -/+ {messages.DMU.DASHBOARD.THAN_PREVIOUS_DAY}
                </span>
              </div>
              <div className={styles['non-chart-content']}>
                <div className={styles.metric}>
                  <div
                    className={styles.colorCode}
                    style={{ background: colours.purple }}
                  ></div>
                  <div className={styles.valueBox}>
                    <div className={styles.value}>
                      {props.data.currentPushes}
                    </div>
                    <div className={styles.subText}>Pushes done</div>
                    <div className={styles.val2}>{`(0000 t aprox)`}</div>
                  </div>
                </div>
                <div className={styles.metric}>
                  <div
                    className={styles.colorCode}
                    style={{ background: colours.darkGray }}
                  ></div>
                  <div className={styles.valueBox}>
                    <div className={styles.value}>
                      {props.data.maximumPushes - props.data.currentPushes}
                    </div>
                    <div className={styles.subText}>To reach target</div>
                  </div>
                </div>
                <div className={styles.metric}>
                  <Speedometer />
                  <div className={styles.valueBox}>
                    <div className={styles.value}>
                      {props.data.averageSpeed}{' '}
                      {messages.DMU.DASHBOARD.SPEED_UNIT}
                    </div>
                    <div className={styles.subText}>
                      {messages.DMU.DASHBOARD.AVERAGE_SPEED}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={styles.monthlySummary}>
              <div className={styles.monthlyPushes}>
                <div className={styles.monthlyMetric}>
                  <img src={soil} alt="" /> {props.data.currentPushes}
                </div>
                <div className={styles.monthlySubtext}>Pushes done</div>
                <div className={styles.monthlyPushesLow}>
                  {pushesMacro >= 0 ? '+' : ''}
                  {pushesMacro}{' '}
                  {props.range && props.range.to === undefined
                    ? messages.DMU.DASHBOARD.THAN_PREVIOUS_DAY
                    : messages.DMU.DASHBOARD.THAN_PREVIOUS_MONTH}
                </div>
              </div>
              <div className={styles.monthlyPushes}>
                <div
                  className={styles.monthlyMetric}
                  style={{ background: 'rgba(112, 112, 112, 0.28)' }}
                >
                  <Speedometer />{' '}
                  {formatAsNDigitNumber(props.data.averageSpeed ?? 0, 2)}{' '}
                  {messages.DMU.DASHBOARD.SPEED_UNIT}
                </div>
                <div className={styles.monthlySubtext}>
                  {messages.DMU.DASHBOARD.AVERAGE_SPEED}
                </div>
              </div>
            </div>
          )}

          <div id="contact-time" className={styles.pushes}>
            <DonutProgress
              values={{
                primary: contactTimePercentage,
                secondary: nearbyTimePercentage,
                tertiary:
                  contactTimePercentage === 0 && nearbyTimePercentage === 0
                    ? 100
                    : 0,
              }}
              colorScheme={{
                primary: colours.green,
                secondary: colours.orange,
                tertiary: colours.lightGray,
              }}
              small={height <= maxHeightBreakPoint}
              textContent={`Time Use`}
              inShadow
            />
            <span className={`${styles['summary-message-key']}`}>
              {timeMacro >= 0 ? '+' : '-'}
              {convertMinutesToHHMM(Math.abs(timeMacro)) + ' h'}{' '}
              {messages.DMU.DASHBOARD.CONTACT_TIME}{' '}
              {props.range && props.range.to === undefined
                ? messages.DMU.DASHBOARD.THAN_PREVIOUS_DAY
                : messages.DMU.DASHBOARD.THAN_PREVIOUS_MONTH}
            </span>
          </div>
          <div
            className={styles['non-chart-content']}
            style={{ justifyContent: 'center', paddingBottom: '15px' }}
          >
            <div className={styles.metric}>
              <div
                className={styles.colorCode}
                style={{ background: colours.green }}
              ></div>
              <div className={styles.valueBox}>
                <div className={styles.value}>
                  {convertMinutesToHHMM(props.data.contactTime) ?? '00:00'} h
                </div>
                <div className={styles.subText}>
                  {upperFirst(messages.DMU.DASHBOARD.CONTACT_TIME)}
                </div>
                <div className={styles.val2}>{`(${Math.round(
                  contactTimePercentage,
                )} %)`}</div>
              </div>
            </div>
            <div className={styles.metric}>
              <div
                className={styles.colorCode}
                style={{ background: colours.orange }}
              ></div>
              <div className={styles.valueBox}>
                <div className={styles.value}>
                  {convertMinutesToHHMM(props.data.nearbyTime) ?? '00:00'} h
                </div>
                <div className={styles.subText}>
                  {upperFirst(messages.DMU.DASHBOARD.NEARBY_TIME)}
                </div>
                <div className={styles.val2}>{`(${Math.round(
                  nearbyTimePercentage,
                )} %)`}</div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className={styles['sections-container']}>
          {props.range && props.range.to === undefined ? (
            <>
              <div id="pushes" className={styles.pushes}>
                <DonutProgress
                  values={{
                    primary: pushProgress,
                    secondary: 100 - pushProgress,
                  }}
                  colorScheme={{
                    primary: colours.purple,
                    secondary: colours.darkGray,
                  }}
                  textContent={`Tonnes processed`}
                  large
                />
                <span className={styles['summary-message-key']}>
                  00 -/+ {messages.DMU.DASHBOARD.THAN_PREVIOUS_DAY}
                </span>
              </div>
              <div
                className={styles['non-chart-content']}
                style={{ justifyContent: 'space-evenly' }}
              >
                <div className={styles.metric}>
                  <div
                    className={styles.colorCode}
                    style={{ background: colours.purple }}
                  ></div>
                  <div className={styles.valueBox}>
                    <div className={styles.value}>00000</div>
                    <div className={styles.subText}>Tonnes processed</div>
                  </div>
                </div>
                <div className={styles.metric}>
                  <div
                    className={styles.colorCode}
                    style={{ background: colours.darkGray }}
                  ></div>
                  <div className={styles.valueBox}>
                    <div className={styles.value}>0000</div>
                    <div className={styles.subText}>To reach target</div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={styles.monthlySummary}>
              <div className={styles.monthlyPushes}>
                <div className={styles.monthlyMetric}>0000</div>
                <div className={styles.monthlySubtext}>Tonnes processed</div>
                <div
                  className={styles.monthlyPushesLow}
                  style={{ paddingTop: '100px' }}
                >
                  00 -/+ {messages.DMU.DASHBOARD.THAN_PREVIOUS_MONTH}
                </div>
              </div>
            </div>
          )}

          <div id="contact-time" className={styles.pushes}>
            <DonutProgress
              values={{
                primary: contactTimePercentage,
                secondary: 100 - contactTimePercentage,
              }}
              colorScheme={{
                primary: colours.darkBlue,
                secondary: colours.dirtyOrange,
              }}
              textContent={`Tonnes Usage`}
              large
            />
            <span className={`${styles['summary-message-key']}`}>
              00 -/+ overburden{' '}
              {props.range && props.range.to === undefined
                ? messages.DMU.DASHBOARD.THAN_PREVIOUS_DAY
                : messages.DMU.DASHBOARD.THAN_PREVIOUS_MONTH}
            </span>
          </div>
          <div
            className={styles['non-chart-content']}
            style={{ justifyContent: 'space-evenly' }}
          >
            <div className={styles.metric}>
              <div
                className={styles.colorCode}
                style={{ background: colours.darkBlue }}
              ></div>
              <div className={styles.valueBox}>
                <div className={styles.value}>000 t</div>
                <div className={styles.subText}>Fed into the mill</div>
                <div className={styles.val2}>{`(00 %)`}</div>
              </div>
            </div>
            <div className={styles.metric}>
              <div
                className={styles.colorCode}
                style={{ background: colours.dirtyOrange }}
              ></div>
              <div className={styles.valueBox}>
                <div className={styles.value}>000 t</div>
                <div className={styles.subText}>Overburden</div>
                <div className={styles.val2}>{`(00 %)`}</div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default ProductivitySummary;
