import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';

import Header from './Header';
import Sidebar from './Sidebar';
import useAuth from '../hooks/useAuth';

export default function AuthLayout({ children, pageName }) {
  const { auth } = useAuth();
  const location = useLocation();

  // if (!auth.isAuthenticated) {
  //   return <Navigate to="/" state={{ from: location }} replace />;
  // }
  //For Registration it will
  // if(auth.isAuthenticated && !auth?.userData?.isDelete){
  //   return <Navigate to="/changepassword" state={{ from: location }} replace />
  // }else{
  //   // return <Navigate to="/manageuser" state={{ from: location }} replace />
  // }

  return (
    <section id="polygon">
      <div className="mm-right_sidebar">{children}</div>
    </section>
  );
}
