import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import LoadingScreen from '../../../component/LoadingScreen';
import {
  getDurationOfCurrentDayInHHMMSS,
  getDurationOfCurrentDayInSeconds,
  secondsToHhMm,
} from '../../../utils/helper';
import { downloadReportFromServer } from '../../utils/helper';
import Downloadfortable from '../components/Downloadfortable';
import ThreeDotMenu from '../components/ThreeDotMenu';
import DownloadIcon from '../components/download.svg';
import emailIcon from '../components/email.svg';
import EyeIcon from '../components/eye.svg';
import Report from './components/Report';
import Timeline from './components/Timeline';
import './index.css';

export const Table = ({
  tripHistoryData,
  loading,
  activityType,
  categoryType,
  date,
  totalLength,
  filteredDataLength,
  startTime,
  endTime,
  filteredValue,
}) => {
  const [data, setData] = useState(tripHistoryData);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const tableHeaders = [
    { title: 'Activity', subTitle: '' },
    { title: 'Timestamp', subTitle: '' },
    { title: 'Duration', subTitle: '' },
    { title: 'Location', subTitle: '' },
    { title: 'Distance', subTitle: '' },
    { title: 'Idle time', subTitle: '' },
    { title: 'Max Speed', subTitle: '' },
  ];
  const source = useRef(axios.CancelToken.source());
  const [selectedRowData, setSelectedRowData] = useState(null);
  const tableHeadingRef = useRef(null);
  const [activeAssetIndex, setActiveAssetIndex] = useState(null);
  const [menuIdentifier, setMenuIdentifier] = useState({
    asset_ID: '',
    activeIndex: '',
  });
  const [downloadMenuIdentifier, setDownloadMenuIdentifier] = useState({
    asset_ID: '',
    activeIndex: '',
  });
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [apiDataLoading, setApiDataLoading] = useState(false);
  const [selectedRowForMenu, setSelectedRowForMenu] = useState('');
  const [isFiltered, setIsFiltered] = useState(false);
  const subTextColor = '#707070';

  const tripRowReportOptions = [
    { displayName: 'View Report', icon: EyeIcon },
    { displayName: 'Download Report (CSV)', icon: DownloadIcon },
    { displayName: 'Email Report (CSV)', icon: emailIcon },
  ];

  const assetRowReportOptions = () => {
    return [
      { displayName: 'Download Report (CSV)', icon: DownloadIcon },
      { displayName: 'Email Report (CSV)', icon: emailIcon },
    ];
  };

  const isAllFiltersEmpty = (filteredValue) => {
    // Function to check if an object is empty (for 'from', 'to', and similar objects)
    const isEmptyObject = (obj) => {
      for (let key in obj) {
        // For 'from' and 'to' objects, check if time is not set
        if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== null) {
          return false;
        }
      }
      return true;
    };

    // Iterate over each key in filteredValue
    for (let key in filteredValue) {
      const value = filteredValue[key];

      // Check for empty strings and non-default values
      if (typeof value === 'string' && value.trim() !== '') {
        return false;
      }

      // Check for arrays with elements
      if (Array.isArray(value) && value.length > 0) {
        return false;
      }

      // Check for non-empty objects (e.g., 'from', 'to')
      if (
        typeof value === 'object' &&
        value !== null &&
        !isEmptyObject(value)
      ) {
        return false;
      }

      // Special case for 'date', assuming you want to exclude the current date as a filter
      if (key === 'date' && value !== moment().format('YYYY-MM-DD')) {
        return false;
      }
    }

    // If none of the values are considered "non-empty", return true
    return true;
  };

  const FilterDisplay = ({ filteredValue }) => {
    // Check if a value is considered empty
    const isEmptyValue = (value) => {
      if (typeof value === 'string') return !value.trim();
      if (Array.isArray(value)) return value.length === 0;
      if (typeof value === 'object' && value !== null) {
        return Object.values(value).every((val) => !val || isEmptyValue(val)); // Recursively check for empty object values
      }
      return !value; // Fallback for other falsy values
    };

    // Format value for display
    const formatValue = (value) => {
      if (Array.isArray(value)) {
        return value.join(', ');
      } else if (typeof value === 'object' && value !== null) {
        return Object.entries(value)
          .map(([key, val]) => `${key}:${val}`)
          .join(', ');
      }
      return value;
    };
  };

  // Checks if the provided value is considered "empty"
  const isEmptyValue = (value) => {
    if (typeof value === 'string') return !value.trim();
    if (Array.isArray(value)) return value.length === 0;
    if (typeof value === 'object' && value !== null) {
      return Object.entries(value).every(([_, val]) => isEmptyValue(val)); // Recursively check for empty values
    }
    return !value; // Fallback for falsy values like null, undefined, false
  };

  useEffect(() => {
    const isEmpty = isAllFiltersEmpty(filteredValue);
    setIsFiltered(isEmpty);
  }, [filteredValue]);
  useEffect(() => {
    setData(tripHistoryData);
  }, [tripHistoryData]);

  const handleRowClick = ({ asset, index, activity_type }) => {
    setSelectedRowData({ ...asset, activeIndex: index, date, activity_type });
    setIsMapModalOpen(true);
  };

  useEffect(() => {
    setSelectedRowData(null);
    setIsMapModalOpen(false);
    // setfilteredTimeline([])
  }, [date]);

  const menuSelectHandler = async (
    optionNo,
    asset_ID,
    trip_id,
    tripSubName,
  ) => {
    if (optionNo == 0) {
      setIsReportModalOpen(true);
    } else if (optionNo == 1) {
      setApiDataLoading(true);
      source?.current?.cancel();
      source.current = axios.CancelToken.source();
      await downloadReportFromServer(
        date,
        undefined,
        undefined,
        trip_id,
        source.current.token,
        [asset_ID],
        `ASSET ID ${tripSubName} REPORT`,
        'TRIP',
        false,
      );
      setApiDataLoading(false);
    } else if (optionNo == 2) {
      setApiDataLoading(true);
      source?.current?.cancel();
      source.current = axios.CancelToken.source();
      await downloadReportFromServer(
        date,
        undefined,
        undefined,
        trip_id,
        source.current.token,
        [asset_ID],
        `ASSET ID ${tripSubName} REPORT`,
        'TRIP',
        true,
      );
      setApiDataLoading(false);
    }
  };

  const downloadMenuSelectHandler = async (optionNo, asset_ID) => {
    if (optionNo == 0) {
      setApiDataLoading(true);
      source?.current?.cancel();
      source.current = axios.CancelToken.source();
      await downloadReportFromServer(
        date,
        startTime,
        endTime,
        undefined,
        source.current.token,
        [asset_ID],
        `ASSET ID TRIPS REPORT`,
        'ASSET',
        false,
      );
      setApiDataLoading(false);
    } else if (optionNo == 1) {
      setApiDataLoading(true);
      source?.current?.cancel();
      source.current = axios.CancelToken.source();
      await downloadReportFromServer(
        date,
        startTime,
        endTime,
        undefined,
        source.current.token,
        [asset_ID],
        `ASSET ID TRIPS REPORT`,
        'ASSET',
        true,
      );
      setApiDataLoading(false);
    }
  };

  useEffect(() => {}, [selectedRowForMenu]);

  const calculateAssetInformation = (asset, index) => {
    let totalTrips = 0;
    let totalParks = 0;
    let totalTripTime = 0;
    let totalParkingTime = 0;
    let totalIdleTime = 0;
    let events = 0;
    let speeding_events = 0;
    let idle_events = 0;
    let maxSpeed = 0;
    asset?.activity?.forEach((activity) => {
      {
        activity.activity_type === 'Trip'
          ? (totalTrips += 1)
          : (totalParks += 1);
      }
      {
        activity.activity_type === 'Trip'
          ? (totalTripTime += getDurationOfCurrentDayInSeconds(
              activity.endTime,
              activity.startTime,
              date,
            ))
          : (totalParkingTime += getDurationOfCurrentDayInSeconds(
              activity.endTime,
              activity.startTime,
              date,
            ));
      }
      if (
        activity.activity_idle_time > 0 &&
        activity.activity_type === 'Trip'
      ) {
        idle_events += 1;
        totalIdleTime += activity?.activity_idle_time;
      }
      if (activity?.maxSpeed > maxSpeed) {
        maxSpeed = activity?.maxSpeed;
      }
      if (activity?.maxSpeed >= 30 && activity.activity_type === 'Trip') {
        speeding_events += 1;
      }
      events = idle_events + speeding_events;
    });
    return {
      totalTrips,
      totalParks,
      totalTripTime,
      totalParkingTime,
      totalIdleTime,
      events,
      speeding_events,
      idle_events,
      maxSpeed,
    };
  };

  const asssetRowComponent = ({
    asset,
    totalTrips,
    totalParks,
    totalTripTime,
    totalParkingTime,
    totalIdleTime,
    events,
    speeding_events,
    idle_events,
    maxSpeed,
  }) => {
    return (
      <div className="d-flex">
        <div style={{ width: '4rem' }}>{asset?.asset_ID}</div>
        <div>
          {isFiltered && asset?.activity?.length === 0 ? (
            <span className="ms-5">No Trip with Applied Filters </span>
          ) : !isFiltered && asset?.activity?.length === 0 ? (
            <span className="ms-5">No Trips Found </span>
          ) : (
            //   {isFiltered && asset?.activity?.length === 0 ? (
            //   <span className='ms-5'>No Trip with {Object.entries(filteredValue).map(([key, value]) => (
            //     <span key={key} style={{ marginRight: '10px' }}>
            //       <strong>{key}</strong>: {FilterDisplay(filteredValue)}
            //     </span>
            //   ))}</span>
            // ) : !isFiltered && asset?.activity?.length === 0 ? (
            //   <span className='ms-5'>No Trip with {Object.entries(filteredValue).map(([key, value]) => {
            //     // Only display if value is not empty
            //     if (!isEmptyValue(value)) {
            //       return (
            //         <span key={key} style={{ marginRight: '10px' }}>
            //           <strong>{key}</strong>: {FilterDisplay(value)}
            //         </span>
            //       );
            //     }
            //     return null; // Return null for empty values to skip rendering
            //   })}
            //   </span>
            <>
              <span style={{ marginLeft: '0.625rem' }}></span>
              Trips: {totalTrips}
              <span style={{ color: '#707070', fontWeight: 400 }}>
                {' '}
                ({secondsToHhMm(totalTripTime)})
              </span>
              <span style={{ marginRight: '1rem', marginLeft: '1rem' }}>|</span>
              Parked:
              <span style={{ color: '#707070' }}> </span>
              {totalParks}{' '}
              <span style={{ color: '#707070', fontWeight: 400 }}>
                ({secondsToHhMm(totalParkingTime)}){' '}
              </span>
              <span
                style={{
                  color: '#707070',
                  marginRight: '1rem',
                  marginLeft: '1rem',
                }}
              >
                |
              </span>
              Alerts:{' '}
              <span style={{ color: '#000', fontWeight: 400 }}>Idle time</span>:{' '}
              {idle_events}{' '}
              <span style={{ color: '#707070', fontWeight: 400 }}>
                ({secondsToHhMm(totalIdleTime)})
              </span>
              <span
                style={{
                  color: '#707070',
                  marginRight: '1rem',
                  marginLeft: '1rem',
                }}
              >
                -
              </span>
              <span style={{ color: '#000', fontWeight: 400 }}>Speeding</span>:{' '}
              {speeding_events}{' '}
              <span style={{ color: '#707070', fontWeight: 400 }}>
                (Max: {maxSpeed} km/h)
              </span>
            </>
          )}
        </div>
      </div>
    );
  };

  const parkRowComponent = ({ asset, trip, tripIndex }) => {
    return (
      <tr
        style={{
          height: '2.6rem',
          font: 'normal normal normal 0.8rem Open Sans',
          backgroundColor:
            tripIndex === selectedRowData?.activeIndex &&
            asset?.asset_ID === selectedRowData?.asset_ID
              ? '#EAF2FE'
              : 'transparent',
          borderTop:
            tripIndex === selectedRowData?.activeIndex &&
            asset?.asset_ID === selectedRowData?.asset_ID
              ? '1px solid #377FF2'
              : ' 1px solid #EBEBEB ',
          borderBottom:
            tripIndex === selectedRowData?.activeIndex &&
            asset?.asset_ID === selectedRowData?.asset_ID
              ? '1px solid #377FF2'
              : ' 0px solid #EBEBEB ',
        }}
        onClick={(e) => {
          handleRowClick({
            asset: asset,
            index: tripIndex,
            activity_type: trip.activity_type,
          });
        }}
      >
        <td></td>
        <td></td>
        <td>
          <b>Parked</b>
        </td>
        <td>
          <div className="ml-2">
            {moment(trip.startTime * 1000).isSame(
              moment(trip.endTime * 1000),
              'day',
            ) &&
            moment(trip.startTime * 1000).format('YYYY-MM-DD') == date &&
            moment(trip.endTime * 1000).format('YYYY-MM-DD') == date ? (
              ` ${moment(trip.startTime * 1000).format('hh:mm a')} - ${moment(
                trip.endTime * 1000,
              ).format('hh:mm a')} `
            ) : (
              <div>
                {' '}
                {moment(trip.startTime * 1000).format('DD/MM/YY hh:mm a')} -
                <br /> {moment(trip.endTime * 1000).format('DD/MM/YY hh:mm a')}
              </div>
            )}
          </div>
        </td>
        <td>
          {' '}
          <div className="ml-2">
            {getDurationOfCurrentDayInHHMMSS(
              trip.endTime,
              trip.startTime,
              date,
            )}{' '}
            hrs
          </div>
        </td>
        <td>
          <div className="d-flex">
            <div style={{ minWidth: '2.7rem' }}>
              <span className="py-1 pe-2" style={{ color: '#000' }}>
                <b>At:</b>
              </span>
            </div>
            <div>
              {trip?.lastLocation?.latitude}, {trip.lastLocation?.longitude}
            </div>
          </div>
        </td>
        <td>
          <div className="ml-2"> - </div>
        </td>
        <td>
          <div className="ml-2"> - </div>
        </td>
        <td>
          <div className="ml-2"> - </div>
        </td>
      </tr>
    );
  };

  const tripRowComponent = ({ tripIndex, asset, trip, events, index }) => {
    return (
      <tr
        style={{
          height: '2.6rem',
          font: 'normal normal normal 0.8rem Open Sans',
          backgroundColor:
            (tripIndex === menuIdentifier.activeIndex &&
              asset?.asset_ID === menuIdentifier.asset_ID) ||
            (tripIndex === selectedRowData?.activeIndex &&
              asset?.asset_ID === selectedRowData?.asset_ID)
              ? '#EAF2FE'
              : 'transparent',
          borderTop:
            (tripIndex === menuIdentifier.activeIndex &&
              asset?.asset_ID === menuIdentifier.asset_ID) ||
            (tripIndex === selectedRowData?.activeIndex &&
              asset?.asset_ID === selectedRowData?.asset_ID)
              ? '1px solid #377FF2'
              : '1px solid #EBEBEB',
          borderBottom:
            (tripIndex === menuIdentifier.activeIndex &&
              asset?.asset_ID === menuIdentifier.asset_ID) ||
            (tripIndex === selectedRowData?.activeIndex &&
              asset?.asset_ID === selectedRowData?.asset_ID)
              ? '1px solid #377FF2'
              : '0px solid #EBEBEB',
        }}
        onClick={() => {
          handleRowClick({
            asset: asset,
            index: tripIndex,
            events: events,
            activity_type: trip.activity_type,
          });
        }}
      >
        <div>
          {trip.activity_idle_time > 0 && (
            <div
              className="asm-legend-box1"
              style={{
                position: 'absolute',
                background: '#EA9336',
                width: '0.5rem',
                left: '1.1rem',
              }}
            />
          )}
          {trip?.maxSpeed >= 30 && trip?.maxSpeed < 40 ? (
            <div
              className="asm-legend-box1"
              style={{
                position: 'absolute',
                background: '#73bf63',
                width: '0.5rem',
                left: trip.activity_idle_time > 0 ? '2rem' : 0,
              }}
            />
          ) : trip?.maxSpeed >= 40 && trip?.maxSpeed < 55 ? (
            <div
              className="asm-legend-box1"
              style={{
                position: 'absolute',
                background: '#181cf5',
                width: '0.5rem',
                left: trip.activity_idle_time > 0 ? '2rem' : 0,
              }}
            />
          ) : (
            trip?.maxSpeed > 55 && (
              <div
                className="asm-legend-box1"
                style={{
                  position: 'absolute',
                  background: '#e23123',
                  width: '0.5rem',
                  left: trip.activity_idle_time > 0 ? '2rem' : 0,
                }}
              />
            )
          )}
        </div>
        <td></td>
        <td
          style={{
            font: 'normal normal bold 0.8rem Open Sans',
          }}
        >
          {trip.activity_id}{' '}
        </td>

        <td>
          <div className="ml-2">
            {moment(trip.startTime * 1000).isSame(
              moment(trip.endTime * 1000),
              'day',
            ) &&
            moment(trip.startTime * 1000).format('YYYY-MM-DD') == date &&
            moment(trip.endTime * 1000).format('YYYY-MM-DD') == date ? (
              ` ${moment(trip.startTime * 1000).format('hh:mm a')} - ${moment(
                trip.endTime * 1000,
              ).format('hh:mm a')} `
            ) : (
              <div>
                {' '}
                {moment(trip.startTime * 1000).format('DD/MM/YY hh:mm a')} -
                <br /> {moment(trip.endTime * 1000).format('DD/MM/YY hh:mm a')}
              </div>
            )}
          </div>
        </td>

        <td>
          {' '}
          <div className="ml-2">
            {' '}
            {getDurationOfCurrentDayInHHMMSS(
              trip.endTime,
              trip.startTime,
              date,
            )}{' '}
            hrs
          </div>
        </td>

        <td>
          <div className="d-flex">
            <div style={{ minWidth: '2.7rem' }}>
              <span className="py-1 pe-2" style={{ color: '#000' }}>
                <b>From:</b>
              </span>
              <br />
              <span className="py-1 pe-2" style={{ color: '#000' }}>
                <b>To:</b>
              </span>
            </div>
            <div>
              {trip?.firstLocation?.latitude}, {trip.firstLocation?.longitude}
              <br />
              {trip?.lastLocation?.latitude}, {trip?.lastLocation?.longitude}
            </div>
          </div>
        </td>
        <td>
          {' '}
          <div className="ml-2">
            {(trip?.lastLocation?.odo - trip?.firstLocation?.odo)?.toFixed(2)}{' '}
            km
          </div>
        </td>
        <td>
          {' '}
          <div className="ml-2">{secondsToHhMm(trip.activity_idle_time)} </div>
        </td>
        <td>
          {' '}
          <div className="ml-2"> {trip.maxSpeed} km/h </div>
        </td>
        <td>
          <ThreeDotMenu
            uniqueName={
              menuIdentifier.asset_ID + 'menu' + menuIdentifier.activeIndex
            }
            menuOptions={tripRowReportOptions}
            menuVisible={
              asset.asset_ID == menuIdentifier.asset_ID &&
              tripIndex == menuIdentifier.activeIndex
            }
            setMenuVisible={(value) =>
              value
                ? setMenuIdentifier({
                    asset_ID: asset.asset_ID,
                    activeIndex: tripIndex,
                  })
                : setMenuIdentifier('')
            }
            onMenuSelect={(optionNo) => {
              menuSelectHandler(
                optionNo,
                asset.asset_ID,
                trip._id,
                trip?.activity_id,
              );
              setSelectedRowForMenu({
                ...asset,
                trip,
                activeIndex: index,
                trip_id: trip._id,
                tripIndex,
                tripNo: trip.activity_id,
              });
            }}
          />
        </td>
      </tr>
    );
  };
  return (
    <>
      {(loading || apiDataLoading) && <LoadingScreen />}
      <div>
        <table className="trips-custom-table">
          <thead>
            <tr style={{ borderLeft: '1.375' }}>
              <th></th>
              <th className="pb-3 pt-2" ref={tableHeadingRef}>
                Asset
              </th>
              {tableHeaders.map((header) => (
                <th className="pb-3 pt-2">
                  {header.title}{' '}
                  {header.subTitle ? (
                    <span style={{ fontWeight: 400 }}>{header.subTitle}</span>
                  ) : (
                    ``
                  )}
                </th>
              ))}
              <th></th>
            </tr>
          </thead>
        </table>
        <div
          className="asm-scroll-div"
          style={{
            position: 'relative',
            maxHeight: 'calc(100vh - 13.5rem)',
            overflowY: 'scroll',
          }}
        >
          <table className="trips-custom-table mt-0">
            <tbody>
              {Object.entries(data).map(([key, value], index) => {
                return (
                  <React.Fragment key={key + index}>
                    <tr
                      align="left"
                      valign="top"
                      style={{ borderTop: '1px solid rgb(235, 235, 235)' }}
                    >
                      <td className="px-2 py-2" colSpan="11">
                        <strong style={{ fontSize: '0.8rem' }}>{key}</strong>
                      </td>
                    </tr>
                    {Object.entries(value).map(([asset, activities, index]) => (
                      <React.Fragment key={asset + index}>
                        <tr align="left" valign="top">
                          <td className="px-2 py-2" colSpan="11">
                            <i
                              style={{
                                fontSize: '0.9rem',
                                color: subTextColor,
                              }}
                            >
                              {asset}
                            </i>
                          </td>
                        </tr>
                        {activities?.map((asset, index) => {
                          const {
                            totalTrips,
                            totalTripTime,
                            totalParkingTime,
                            totalIdleTime,
                            events,
                            speeding_events,
                            idle_events,
                            maxSpeed,
                          } = calculateAssetInformation(asset, index);
                          return (
                            <React.Fragment key={index}>
                              <tr
                                style={{
                                  backgroundColor:
                                    activeAssetIndex === index
                                      ? '#9FE3C4'
                                      : '#FAFAFA',
                                }}
                                className="w-100"
                              >
                                {date === moment().format('YYYY-MM-DD') ? (
                                  <td
                                    style={{
                                      maxWidth: 10,
                                      backgroundColor:
                                        asset?.activity_status === 'Active' ||
                                        asset?.activity_status === 'Idling'
                                          ? '#CFFD69'
                                          : asset?.activity_status ===
                                            'Disconnected'
                                          ? '#ED5151'
                                          : asset?.activity_status === 'Parked'
                                          ? '#BEB6B6'
                                          : '',
                                    }}
                                  >
                                    {/* <div className="w-50">
                                                                            </div> */}
                                  </td>
                                ) : (
                                  <td colSpan="1" style={{ maxWidth: 10 }}></td>
                                )}
                                <td
                                  colSpan="9"
                                  className="px-2 w-100"
                                  style={{
                                    font: 'normal normal 600 0.8rem Open Sans',
                                  }}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    {asssetRowComponent({
                                      asset,
                                      totalTrips,
                                      // totalParks,
                                      totalTripTime,
                                      totalParkingTime,
                                      totalIdleTime,
                                      events,
                                      speeding_events,
                                      idle_events,
                                      maxSpeed,
                                    })}
                                    <div className="mr-2">
                                      <Downloadfortable
                                        uniqueName={
                                          downloadMenuIdentifier.asset_ID +
                                          'download' +
                                          downloadMenuIdentifier.activeIndex
                                        }
                                        menuOptions={assetRowReportOptions()}
                                        menuVisible={
                                          asset.asset_ID ==
                                            downloadMenuIdentifier.asset_ID &&
                                          index ==
                                            downloadMenuIdentifier.activeIndex
                                        }
                                        setMenuVisible={(value) =>
                                          value
                                            ? setDownloadMenuIdentifier({
                                                asset_ID: asset.asset_ID,
                                                activeIndex: index,
                                              })
                                            : setDownloadMenuIdentifier('')
                                        }
                                        onMenuSelect={(optionNo) => {
                                          setSelectedRowData({ ...asset });
                                          downloadMenuSelectHandler(
                                            optionNo,
                                            asset.asset_ID,
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              {asset?.activity?.map((trip, tripIndex) => {
                                return (
                                  <React.Fragment key={`${index}-${tripIndex}`}>
                                    {trip.activity_type === 'Trip' &&
                                      tripRowComponent({
                                        tripIndex,
                                        asset,
                                        trip,
                                        events,
                                        index,
                                      })}
                                    {trip.activity_type === 'Park' &&
                                      parkRowComponent({
                                        asset,
                                        trip,
                                        tripIndex,
                                      })}
                                  </React.Fragment>
                                );
                              })}
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                );
              })}
              <div style={{ marginBottom: '3rem' }}></div>
            </tbody>
          </table>
        </div>

        <Timeline
          date={date}
          selectedRowData={selectedRowData}
          setSelectedRowData={setSelectedRowData}
          tableHeadingRef={tableHeadingRef}
          isMapModalOpen={isMapModalOpen}
          setIsMapModalOpen={setIsMapModalOpen}
        />

        {isReportModalOpen && (
          <Report
            setSelectedRowForMenu={setSelectedRowForMenu}
            date={date}
            setIsReportModalOpen={setIsReportModalOpen}
            asset_ID={selectedRowForMenu?.asset_ID}
            tripStartTime={selectedRowForMenu?.trip?.startTime}
            tripEndTime={selectedRowForMenu?.trip?.endTime}
            trip_id={selectedRowForMenu?.trip_id}
          />
        )}

        {tripHistoryData?.length == 0 && (
          <div className="text-center text-muted ">No Record Found</div>
        )}

        <div style={{ position: 'relative', bottom: '-0.7rem', left: 0 }}>
          <div className="table-footer pt-2">
            Displaying {filteredDataLength}/{totalLength ?? '0'} assets
          </div>
        </div>
      </div>
    </>
  );
};
