import { toast } from 'react-toastify';

const error = (message) => {
  toast.error(message, {
    autoClose: false,
    position: 'top-right',
  });
};

const success = (message) => {
  toast.success(message, {
    autoClose: 4000,
    hideProgressBar: false,
    position: 'top-right',
  });
};

const info = (message) => {
  toast.info(message, {
    autoClose: 4000,
    hideProgressBar: false,
    position: 'top-right',
  });
};

export { error, success, info };
