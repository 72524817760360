import React from 'react';

interface PerformanceRedProps {
  height?: number;
  width?: number;
}

const PerformanceRed: React.FC<PerformanceRedProps> = ({
  height = 12.52,
  width = 16.475,
}) => {
  return (
    <svg
      viewBox="0 0 16.475 12.52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#808080"
      height={height}
      width={width}
    >
      <line
        id="Line_2277"
        data-name="Line 2277"
        x1="6"
        y2="10"
        transform="translate(9.237 1.502)"
        fill="none"
        stroke="#983131"
        stroke-linecap="round"
        stroke-width="1"
      />
      <line
        id="Line_2308"
        data-name="Line 2308"
        x1="3.386"
        y2="4.965"
        transform="translate(1.237 6.537)"
        fill="none"
        stroke="#983131"
        stroke-linecap="round"
        stroke-width="1"
      />
      <line
        id="Line_2307"
        data-name="Line 2307"
        x2="4.035"
        y2="5.375"
        transform="translate(5.202 6.127)"
        fill="none"
        stroke="#983131"
        stroke-linecap="round"
        stroke-width="1"
      />
      <g
        id="Ellipse_3238"
        data-name="Ellipse 3238"
        transform="matrix(0.985, -0.174, 0.174, 0.985, 0, 10.415)"
        fill="#fff"
        stroke="#983131"
        stroke-width="0.5"
      >
        <circle cx="1.068" cy="1.068" r="1.068" stroke="none" />
        <circle cx="1.068" cy="1.068" r="0.818" fill="none" />
      </g>
      <g
        id="Ellipse_3513"
        data-name="Ellipse 3513"
        transform="matrix(0.985, -0.174, 0.174, 0.985, 8, 10.415)"
        fill="#fff"
        stroke="#983131"
        stroke-width="0.5"
      >
        <circle cx="1.068" cy="1.068" r="1.068" stroke="none" />
        <circle cx="1.068" cy="1.068" r="0.818" fill="none" />
      </g>
      <g
        id="Ellipse_3515"
        data-name="Ellipse 3515"
        transform="matrix(0.985, -0.174, 0.174, 0.985, 13.999, 0.371)"
        fill="#fff"
        stroke="#983131"
        stroke-width="0.5"
      >
        <circle cx="1.068" cy="1.068" r="1.068" stroke="none" />
        <circle cx="1.068" cy="1.068" r="0.818" fill="none" />
      </g>
      <g
        id="Ellipse_3514"
        data-name="Ellipse 3514"
        transform="matrix(0.985, -0.174, 0.174, 0.985, 3.695, 4.893)"
        fill="#fff"
        stroke="#983131"
        stroke-width="0.5"
      >
        <circle cx="1.068" cy="1.068" r="1.068" stroke="none" />
        <circle cx="1.068" cy="1.068" r="0.818" fill="none" />
      </g>
    </svg>
  );
};

export default PerformanceRed;
