import { useEffect, useRef, useState } from 'react';
import minusIcon from './../AssetTracking/assets/svgs/minus.svg';
import plusIcon from './../AssetTracking/assets/svgs/plus.svg';
import { AssetPopup } from './AssetPopup';
import { AssetStatus } from '../utils/constants';

const CAMERA_DISTANCE = 2200; // increasing will take view more farther from ground
const MAP_CENTER_LAT = -37.352101;
const MAP_CENTER_LNG = 174.719986;

const getMarkerImageColor = (identifier, isSelected) => {
  if (isSelected) {
    return '#fff';
  }
  switch (identifier) {
    case 'Parked':
      return '#BEB6B6';
    case AssetStatus.Idling:
    case AssetStatus.Active:
      return '#CFFD69';
    default:
      return '#ED5151';
  }
};

const getMarkerBorderColor = (identifier, isSelected) => {
  if (isSelected) {
    if (
      identifier === AssetStatus.Idling ||
      identifier === AssetStatus.Active
    ) {
      return '#CFFD69';
    } else if (identifier === AssetStatus.Parked) {
      return '#BEB6B6';
    } else if (identifier === AssetStatus.Disconnected) {
      return '#ED5151';
    }
  } else {
    return '#fff';
  }
};

const getMarkerBackgorundByStatus = (status) => {
  if (status === AssetStatus.Active || status === AssetStatus.Idling) {
    return '#E2FEB7';
  } else if (status === AssetStatus.Parked) {
    return '#F4F4F4';
  }
  return '#FFDBD8';
};

let map;
let mapkit = undefined;
let currentassetsFlyout = null;
export const Map = ({
  mapRef,
  markers,
  onMarkerClick,
  setSelectedAsset,
  selectedAsset,
}) => {
  const [setupCompleted, setSetupCompleted] = useState(false);
  const markersRef = useRef([]);
  const selectedMarkerRef = useRef(null);

  const setup = async () => {
    await setupMapKitJs();
    mapkit = window.mapkit;
    setupMap({ mapRef, onMarkerClick });
    setSetupCompleted(true);
    return () => {};
  };
  useEffect(() => {
    setup();
  }, []);

  const ActiveMarkerSvg = (index, marker, showTitle = false) => {
    return `<?xml version="1.0" encoding="utf-8"?>
  <svg viewBox="0 -100 150 500" width="74" height="165" 
    xmlns="http://www.w3.org/2000/svg">
    <!-- Increased Text Width: Adjust width to 450px and border radius to 35px -->
    ${
      showTitle &&
      `<rect x="-35" y="-100" width="220" height="90" style="fill: ${getMarkerBackgorundByStatus(
        AssetStatus.Active,
      )}; stroke: #EBEBEB; stroke-width: 1" rx="50" ry="50"/>
    <text x="75" y="-45" text-anchor="middle" font-family="Open Sans" font-size="40px" font-weight="bold" fill="#000000">${
      marker?.title
    }</text>`
    }
    <ellipse style="fill: rgba(255, 255, 255, 1 ); stroke-width: 4; stroke: rgb(255, 255, 255 );" cx="76.913" cy="74.565" rx="68.056" ry="69.967" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
    <ellipse style="fill: rgb(207, 253, 105);" cx="78.256" cy="73.399" rx="45.282" ry="46.933" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
    <ellipse style="fill: rgb(255, 255, 255); stroke-width: 8; stroke: rgb(255, 255, 255 );" cx="80.129" cy="295.92" rx="0.127" ry="140.63" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
    <ellipse style="fill: rgb(0, 0, 0); stroke-width: 4; stroke: rgb(0, 0, 0 );" cx="80.129" cy="${
      index * 100 + 230
    }" rx="0.127" ry="80.63" transform="matrix(0.9999999999999999, 0, 0, 0.1999999999999999, 0, -7.105427357601002e-15)"/>
  </svg>`;
  };

  const svgContent = (status, active, title, showTitle = false) => {
    return `<?xml version="1.0" encoding="utf-8"?>
    <svg viewBox="0 -100 150 500" width="74" height="165" xmlns="http://www.w3.org/2000/svg" overflow="visible">
        ${
          showTitle &&
          `<rect x="-35" y="-100" width="220" height="90" style="fill: ${getMarkerBackgorundByStatus(
            status,
          )}; stroke: #EBEBEB; stroke-width: 1" rx="50" ry="50"/> 
              <text x="75" y="-45" text-anchor="middle" font-family="Open Sans" font-size="40px" font-weight="bold" fill="#000000">${title}</text>`
        }
        <ellipse style="fill: ${getMarkerBorderColor(
          status,
          active,
        )}; stroke-width: 4; stroke: rgb(255, 255, 255);" cx="76.913" cy="74.565" rx="68.056" ry="69.967"/>
        <ellipse style="fill:  ${getMarkerImageColor(
          status,
          active,
        )};" cx="78.256" cy="73.399" rx="45.282" ry="46.933"/>
        <ellipse style="fill: rgb(255, 255, 255); stroke-width: 8; stroke: rgb(255, 255, 255);" cx="80.129" cy="295.92" rx="0.127" ry="140.63"/>
    </svg>`;
  };

  const removePopUpAnnotation = () => {
    if (currentassetsFlyout?.visible) {
      currentassetsFlyout.visible = false;
      map.removeAnnotation(currentassetsFlyout);
      currentassetsFlyout = null;
    }
  };

  const createPopUpAnnotation = async (marker, fromUpdate = false) => {
    const { title, coordinate } = marker;

    removePopUpAnnotation();
    const markerCoord = new mapkit.Coordinate(
      coordinate.latitude,
      coordinate.longitude,
    );
    const options = {
      title: title ?? 'title',
      data: marker,
      visible: false,
      anchorOffset: new DOMPoint(-13, fromUpdate ? -109 : -277),
      clusteringIdentifier: null,
    };
    let annotation = new mapkit.Annotation(markerCoord, AssetPopup, options);
    map.addAnnotation(annotation);
    currentassetsFlyout = annotation;
    annotation.visible = true;
  };

  const updateMarkerData = (existingMarker, markerData, isSelected) => {
    const { coordinate, Status, title } = markerData;

    // Update the marker's coordinate
    const newCoord = new mapkit.Coordinate(
      coordinate?.latitude,
      coordinate?.longitude,
    );
    existingMarker.coordinate = newCoord;

    // Update the marker's data
    existingMarker.data = { ...existingMarker.data, Status };

    // Update the marker's appearance (if necessary)
    existingMarker.url = {
      1: `data:image/svg+xml;base64,${btoa(
        svgContent(Status, isSelected, title, existingMarker.isFocused),
      )}`,
    };

    // Remove existing event listeners to avoid duplication
    const removeEventListeners = () => {
      if (existingMarker.selectListener) {
        existingMarker.removeEventListener(
          'select',
          existingMarker.selectListener,
        );
        existingMarker.selectListener = null;
      }
      if (existingMarker.invertListener) {
        existingMarker.removeEventListener(
          'invert',
          existingMarker.invertListener,
        );
        existingMarker.invertListener = null;
      }
      if (existingMarker.deselectListener) {
        existingMarker.removeEventListener(
          'deselect',
          existingMarker.deselectListener,
        );
        existingMarker.deselectListener = null;
      }
      if (markerData?.Current.speed === 0 && existingMarker.animationInterval) {
        clearInterval(existingMarker.animationInterval);
        existingMarker.animationInterval = null;
      }
    };

    removeEventListeners();

    function updateMarkerIcon(index, marker) {
      existingMarker.url = {
        1: `data:image/svg+xml;base64,${btoa(
          ActiveMarkerSvg(index, marker, existingMarker.isFocused),
        )}`,
      };
    }

    function animateMarker() {
      let index = 0;
      existingMarker.animationInterval = setInterval(() => {
        updateMarkerIcon(index, markerData);
        index = (index + 1) % 4;
      }, 400); // Change the interval as needed (in milliseconds)
    }

    if (
      markerData?.Current.speed > 0 &&
      !isSelected &&
      existingMarker.animationInterval === null
    ) {
      animateMarker();
    }

    // Define new event listeners
    const selectListener = (event) => {
      createPopUpAnnotation(markerData);
      selectedMarkerRef.current = markerData;
      onMarkerClick({ marker: markerData, select: true });
      markerData.center = true;
      existingMarker.url = {
        1: `data:image/svg+xml;base64,${btoa(
          svgContent(markerData?.Status, true, title),
        )}`,
      };
      if (existingMarker.animationInterval) {
        clearInterval(existingMarker.animationInterval);
      }
    };

    const invertListener = (event) => {
      existingMarker.url = {
        1: `data:image/svg+xml;base64,${btoa(
          svgContent(markerData?.Status, false, title),
        )}`,
      };
      if (markerData?.Status === AssetStatus.Active) {
        animateMarker();
      }
    };

    const deselectListener = () => {
      selectedMarkerRef.current = null;
      onMarkerClick({ marker: {}, select: false });
      existingMarker.url = {
        1: `data:image/svg+xml;base64,${btoa(
          svgContent(markerData?.Status, false, title),
        )}`,
      };
      if (markerData?.Status === AssetStatus.Active) {
        animateMarker();
      }
    };

    const focusMarker = () => {
      existingMarker.url = {
        1: `data:image/svg+xml;base64,${btoa(
          svgContent(markerData?.Status, false, title, true),
        )}`,
      };
      existingMarker.isFocused = true;
    };

    const deFocusMarker = () => {
      existingMarker.url = {
        1: `data:image/svg+xml;base64,${btoa(
          svgContent(markerData?.Status, false, title, false),
        )}`,
      };
      existingMarker.isFocused = false;
    };

    // Attach new event listeners
    existingMarker.addEventListener('select', selectListener);
    existingMarker.addEventListener('invert', invertListener);
    existingMarker.addEventListener('deselect', deselectListener);

    // Store listeners and interval on the marker for future reference
    existingMarker.selectListener = selectListener;
    existingMarker.invertListener = invertListener;
    existingMarker.deselectListener = deselectListener;

    existingMarker.focusMarker = focusMarker;
    existingMarker.deFocusMarker = deFocusMarker;

    if (
      currentassetsFlyout &&
      currentassetsFlyout.data.title === title &&
      currentassetsFlyout
    ) {
      removePopUpAnnotation();
      createPopUpAnnotation(markerData, true);
    }
  };

  const createOrUpdateMarker = ({ marker, isSelected }) => {
    const { coordinate, marker_id, title } = marker;

    if (!coordinate) return;

    const markerCoord = new mapkit.Coordinate(
      coordinate?.latitude,
      coordinate?.longitude,
    );

    const existingMarker = markersRef.current.find(
      (m) => m.data.title === title,
    );

    if (existingMarker) {
      // Update the existing marker's data
      updateMarkerData(existingMarker, marker, isSelected);
    } else {
      // Create a new marker if it doesn't exist
      const markerAnnotation = new mapkit.ImageAnnotation(markerCoord, {
        data: { marker_id: marker_id, Status: marker?.Status, title: title },
        url: {
          1: `data:image/svg+xml;base64,${btoa(
            svgContent(marker?.Status, isSelected, title),
          )}`,
        },
        anchorOffset: new DOMPoint(0, 0),
      });

      function updateMarkerIcon(index, marker) {
        markerAnnotation.url = {
          1: `data:image/svg+xml;base64,${btoa(
            ActiveMarkerSvg(index, marker, markerAnnotation.isFocused),
          )}`,
        };
      }

      let animationInterval = null;
      function animateMarker() {
        let index = 0;
        animationInterval = setInterval(() => {
          updateMarkerIcon(index, marker);
          index = (index + 1) % 4;
        }, 400); // Change the interval as needed (in milliseconds)
      }

      if (marker?.Current.speed > 0 && !isSelected) {
        animateMarker();
      }

      const selectListener = (event) => {
        createPopUpAnnotation(marker);
        selectedMarkerRef.current = marker;
        onMarkerClick({ marker: marker, select: true });
        marker.center = true;
        markerAnnotation.url = {
          1: `data:image/svg+xml;base64,${btoa(
            svgContent(marker?.Status, true, title),
          )}`,
        };
        if (animationInterval) {
          clearInterval(animationInterval);
        }
      };

      const invertListener = (event) => {
        markerAnnotation.url = {
          1: `data:image/svg+xml;base64,${btoa(
            svgContent(marker?.Status, false, title),
          )}`,
        };
        if (marker?.Status === AssetStatus.Active) {
          animateMarker();
        }
      };

      const deselectListener = () => {
        selectedMarkerRef.current = null;
        onMarkerClick({ marker: {}, select: false });
        markerAnnotation.url = {
          1: `data:image/svg+xml;base64,${btoa(
            svgContent(marker?.Status, false, title),
          )}`,
        };
        if (marker?.Status === AssetStatus.Active) {
          animateMarker();
        }
      };

      const focusMarker = () => {
        markerAnnotation.url = {
          1: `data:image/svg+xml;base64,${btoa(
            svgContent(marker?.Status, false, title, true),
          )}`,
        };
        markerAnnotation.isFocused = true;
      };

      const deFocusMarker = () => {
        markerAnnotation.url = {
          1: `data:image/svg+xml;base64,${btoa(
            svgContent(marker?.Status, false, title, false),
          )}`,
        };
        markerAnnotation.isFocused = false;
      };

      // Attach new event listeners and store them
      markerAnnotation.addEventListener('select', selectListener);
      markerAnnotation.addEventListener('invert', invertListener);
      markerAnnotation.addEventListener('deselect', deselectListener);

      // Store listeners on the marker for future reference
      markerAnnotation.selectListener = selectListener;
      markerAnnotation.invertListener = invertListener;
      markerAnnotation.deselectListener = deselectListener;
      markerAnnotation.animationInterval = animationInterval;

      markerAnnotation.isFocused = false;
      markerAnnotation.focusMarker = focusMarker;
      markerAnnotation.deFocusMarker = deFocusMarker;

      map.addAnnotation(markerAnnotation);
      markersRef.current.push(markerAnnotation);
    }
  };

  const removeMarker = (marker) => {
    // Find and remove the marker annotation
    const markerAnnotation = markersRef.current.find(
      (m) => m.data.title === marker.title,
    );
    if (markerAnnotation) {
      map.removeAnnotation(markerAnnotation);
      markersRef.current = markersRef.current.filter(
        (m) => m !== markerAnnotation,
      );
    }

    // Remove associated AssetPopup if any
    if (
      currentassetsFlyout &&
      currentassetsFlyout.data.title === marker.title
    ) {
      map.removeAnnotation(currentassetsFlyout);
      currentassetsFlyout = null;
    }
  };

  // Function to handle updating the markers
  const updateMarkers = (newMarkers) => {
    // Remove markers that are no longer in the new list
    markersRef.current.forEach((existingMarker) => {
      const markerData = newMarkers.find(
        (m) => m.title === existingMarker.data.title,
      );
      if (!markerData) {
        removeMarker(existingMarker.data);
      }
    });

    // Add or update markers
    newMarkers.forEach((markerData) => {
      createOrUpdateMarker({
        marker: markerData,
        isSelected: markerData.title === selectedMarkerRef.current?.title,
      });
    });
  };

  useEffect(() => {
    if (setupCompleted) {
      updateMarkers(markers);
    }
  }, [setupCompleted, markers]);

  useEffect(() => {
    if (selectedAsset?.coordinate) {
      const mapCenter = new mapkit.Coordinate(
        selectedAsset.coordinate.latitude,
        selectedAsset.coordinate.longitude,
      );
      map.setCenterAnimated(mapCenter);
    }
    if (
      selectedAsset === undefined ||
      (currentassetsFlyout &&
        selectedAsset?.title !== currentassetsFlyout?.data?.title)
    ) {
      if (currentassetsFlyout) {
        const selectedMarker = markersRef.current.find(
          (m) => m.data.title === currentassetsFlyout?.data?.title,
        );
        if (selectedMarker.deselectListener) {
          selectedMarker.deselectListener();
        }
      }
      removePopUpAnnotation();
    }
  }, [selectedAsset]);

  useEffect(() => {
    if (selectedAsset && currentassetsFlyout === null) {
      const focusedMarker = markersRef.current.find(
        (m) => m.isFocused === true,
      );
      if (focusedMarker) {
        focusedMarker.deFocusMarker();
      }
      const selectedMarker = markersRef.current.find(
        (m) => m.data.title === selectedAsset?.title,
      );
      selectedMarker.focusMarker();
    } else {
      const focusedMarker = markersRef.current.find(
        (m) => m.isFocused === true,
      );
      if (focusedMarker) {
        focusedMarker.deFocusMarker();
      }
    }
  }, [selectedAsset, currentassetsFlyout]);

  const setupMapKitJs = async () => {
    if (!window.mapkit || window.mapkit.loadedLibraries?.length === 0) {
      await new Promise((resolve) => {
        window.initMapKit = resolve;
      });
      delete window.initMapKit;
    }

    const jwt = process.env.REACT_APP_APPLE_MAP_TOKEN;
    window.mapkit.init({
      authorizationCallback: (done) => {
        done(jwt);
      },
      language: 'en',
    });
  };

  const handleZoom = (isZoomOut) => {
    const currentRegion = mapRef.current.region;
    const factor = isZoomOut ? 2 : 0.5;
    const newSpan = new mapkit.CoordinateSpan(
      currentRegion.span.latitudeDelta * factor,
      currentRegion.span.longitudeDelta * factor,
    );

    const newRegion = new mapkit.CoordinateRegion(
      mapRef.current.center,
      newSpan,
    );
    mapRef.current.setRegionAnimated(newRegion, true);
  };

  const setupMap = ({ mapRef, onMarkerClick }) => {
    const mapCenter = new mapkit.Coordinate(MAP_CENTER_LAT, MAP_CENTER_LNG);
    const mapRegion = new mapkit.CoordinateRegion(
      mapCenter,
      new mapkit.CoordinateSpan(0.06, 0.06),
    );
    map = new mapkit.Map('map-container', {
      center: mapCenter,
      mapType: mapkit.Map.MapTypes.Satellite,
      region: mapRegion,
      showsCompass: mapkit.FeatureVisibility.Hidden,
      showsZoomControl: false, // Hide the zoom controls
      showsMapTypeControl: false, // Hide the map type switcher
    });
    mapRef.current = map;
    map.cameraZoomRange = new mapkit.CameraZoomRange(20, 6000);
    map.cameraBoundary = mapRegion.toMapRect();
    map.cameraDistance = CAMERA_DISTANCE;
    mapRef.current._impl.zoomLevel = 1;

    map.addEventListener('single-tap', () => {
      removePopUpAnnotation();
      onMarkerClick({ marker: {}, select: false });
    });

    const tileOverlay = new mapkit.TileOverlay(
      (x, y, z, scale) => {
        return `/api/o/map-tile?x=${x}&y=${y}&z=${z}&scale=${scale}&image=large`;
      },
      {
        minimumZ: 0,
        maximumZ: 30,
        opacity: 1,
        data: {},
      },
    );
    map.addTileOverlay(tileOverlay);
  };

  return (
    <>
      <section id="map-container" style={{ height: '100%', width: '100%' }} />
      <div
        style={{
          bottom: '-1.5rem',
          marginBottom: '2rem',
          position: 'absolute',
          zIndex: 1,
          right: '1rem',
          backgroundColor: 'white',
          borderRadius: '15px',
        }}
      >
        <div
          className="d-flex align-items-center justify-content-center cursor-pointer px-2 py-3"
          onClick={() => handleZoom(0)}
        >
          <img
            src={plusIcon}
            alt="Zoom In"
            style={{ width: '1.2rem', height: '1.2rem' }}
          />
        </div>
        <div style={{ border: '0.5px solid #C6C6C6' }} />
        <div
          className="d-flex align-items-center justify-content-center cursor-pointer px-2 py-3"
          onClick={() => handleZoom(1)}
        >
          <img
            src={minusIcon}
            alt="Zoom Out"
            style={{ width: '1.2rem', height: '0.8rem' }}
          />
        </div>
      </div>

      <div
        style={{
          bottom: '-1.5rem',
          marginBottom: '2rem',
          position: 'absolute',
          zIndex: 1,
          right: '4.7rem',
          backgroundColor: 'white',
          borderRadius: '15px ',
          fontSize: '12px',
          padding: '7px 23px',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.preventDefault();
          mapRef.current._impl.zoomLevel = 1;
        }}
      >
        Fit Screen
      </div>
    </>
  );
};
