import { REACT_APP_API_ENDPOINT } from '../action/apiPath';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
  // timeout: 15000,
  headers: { 'content-type': 'application/json' },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // console.log("interceptor request sent config: \n", config)
    return config;
  },
  function (error) {
    console.log('error in request interceptor:--\n', error);
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Only handle successful responses here
    return response;
  },
  function (error) {
    // Handle error responses here
    if (error.response) {
      // Handle known errors
      if (
        error.response.status === 403 &&
        error.response.data.errors === 'jwt expired'
      ) {
        // Handle token refresh here
        // For example:
        // refreshToken().then(() => {
        //   // Retry the failed request
        //   return axiosInstance(error.config);
        // });

        // For now, just return the error
        return Promise.reject(error);
      }

      // Handle other response errors
      console.log('error in response interceptor:--\n', error);
    } else if (error.request) {
      // Handle request without response errors
      console.log('error in response interceptor (no response):--\n', error);
    } else {
      // Handle other errors
      console.log('error in response interceptor (other):--\n', error);
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
