/* eslint-disable no-console */
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';
import {
  Asset,
  CurrentTonnage,
  DMU,
  EngineStatus,
  SelectedAsset,
  TrafficLightColor,
} from '../../dmu/model';
import { fetchAssets, fetchDMU } from '../../dmu/services';
import { formatAsNDigitNumber, sortDevices } from '../../dmu/helper';
import { isUndefined } from 'lodash';
import { useSocket } from '../../hooks/useSocket';
import { AuthContext } from './auth';

type AssetContextType = {
  assets: Asset[];
  setAssets: React.Dispatch<React.SetStateAction<Asset[]>>;
  isLoading: boolean;
  pushesLive: { truckName: string; value: number } | undefined;
  averageSpeedLive: { truckName: string; value: number } | undefined;
  currentSpeedLive: { truckName: string; value: number } | undefined;
  engineHoursLive: { truckName: string; value: number } | undefined;
  totalEngineHoursLive: { truckName: string; value: number } | undefined;
  contactTimeLive: { truckName: string; value: number } | undefined;
  nearbyTimeLive: { truckName: string; value: number } | undefined;
  idleTimeLive: { truckName: string; value: number } | undefined;
  engineStatusLive: { truckName: string; value: EngineStatus } | undefined;
  tonnageLive: { truckName: string; value: number } | undefined;
  setSelectedAssets: Function;
  selectedAssets: SelectedAsset[];
};

const AssetContext: React.Context<AssetContextType | undefined> = createContext<
  AssetContextType | undefined
>(undefined);

export const useAssetContext = (): AssetContextType => {
  const context = useContext(AssetContext);
  if (!context) {
    throw new Error('useAssetContext must be used within an AssetProvider');
  }
  return context;
};

type PushesMessage = {
  truckName: string;
  pushCount: number;
};

type SpeedMessage = {
  truckName: string;
  averageSpeed?: number;
  currentSpeed?: number;
};

export type EngineHoursMessage = {
  truckName: string;
  engineStatus: 'ON' | 'OFF';
  engineHours?: {
    calculatedEngineMinutes: number;
    totalEngineHoursInMinutes: number;
  };
};

type ContactMessage = {
  truckName: string;
  contactTime: number;
};

type NearbyMessage = {
  truckName: string;
  nearbyTime: number;
};

type IdleTimeMessage = {
  truckName: string;
  idleTimeInMinutes: number;
};

export const AssetProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pushesLive, setPushesLive] = useState<
    { truckName: string; value: number } | undefined
  >();
  const [averageSpeedLive, setAverageSpeedLive] = useState<
    { truckName: string; value: number } | undefined
  >();
  const [currentSpeedLive, setCurrentSpeedLive] = useState<
    { truckName: string; value: number } | undefined
  >();
  const [engineHoursLive, setEngineHoursLive] = useState<
    { truckName: string; value: number } | undefined
  >();
  const [totalEngineHoursLive, setTotalEngineHoursLive] = useState<
    { truckName: string; value: number } | undefined
  >();
  const [contactTimeLive, setContactTimeLive] = useState<
    { truckName: string; value: number } | undefined
  >();
  const [nearbyTimeLive, setNearbyTimeLive] = useState<
    { truckName: string; value: number } | undefined
  >();
  const [idleTimeLive, setIdleTimeLive] = useState<
    { truckName: string; value: number } | undefined
  >();
  const [engineStatusLive, setEngineStatusLive] = useState<
    { truckName: string; value: EngineStatus } | undefined
  >();
  const [tonnageLive, setTonnageLive] = useState<
    { truckName: string; value: number } | undefined
  >();

  const [selectedAssets, setSelectedAssets] = useState<SelectedAsset[]>([]);

  const { logOut } = useContext(AuthContext);

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      try {
        setIsLoading(true);

        const [dozerResult, dmuResult] = await Promise.allSettled([
          fetchAssets(),
          fetchDMU(logOut),
        ]);

        let data =
          dozerResult.status === 'fulfilled' ? dozerResult.value : undefined;
        const dmuData =
          dmuResult.status === 'fulfilled' ? dmuResult.value : undefined;

        if (!isUndefined(data) && !isUndefined(dmuData)) {
          data = sortDevices(data);
          data.unshift(dmuData);
          if (
            !isUndefined(data[1]?.type) &&
            !isUndefined(data[1]?._id) &&
            !isUndefined(data[1]?.name)
          ) {
            setSelectedAssets([{ name: data[1].name, type: data[1].type }]);
          }
          setAssets(data);
        }
      } catch (error) {
        // Handle any unexpected errors here
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
  }, []);

  //Pushes Socket
  const handlePushesMessage = useCallback(
    (message: PushesMessage) => {
      console.log('Handling pushes message:', message);
      setPushesLive({
        truckName: message.truckName,
        value: Number(message.pushCount),
      });
    },
    [setPushesLive],
  );

  useSocket<PushesMessage>('pushes', handlePushesMessage);

  // DMU traffic light socket
  const handleTrafficLightMessage = useCallback(
    (message: { trafficLightColor: TrafficLightColor }) => {
      console.log('Handling traffic light message:', message);
      const dmuStatus =
        message.trafficLightColor === TrafficLightColor.GREEN
          ? EngineStatus.ACTIVE
          : EngineStatus.INACTIVE;

      setEngineStatusLive({ truckName: DMU.name, value: dmuStatus });
    },
    [setEngineStatusLive],
  );

  useSocket<{ trafficLightColor: TrafficLightColor }>(
    'traffic_light',
    handleTrafficLightMessage,
  );

  // DMU tonnage
  const handleTonnageMessage = useCallback(
    (message: CurrentTonnage) => {
      console.log('Handling tonnage message:', message);
      setTonnageLive({
        truckName: DMU.name,
        value: Math.round(message.tonnage),
      });
    },
    [setTonnageLive],
  );

  useSocket<CurrentTonnage>('tonnage', handleTonnageMessage);

  //Speed socket
  const handleSpeedMessage = useCallback(
    (message: SpeedMessage) => {
      console.log('Handling speed message:', message);
      if (message.truckName) {
        setAverageSpeedLive({
          truckName: message.truckName,
          value: +formatAsNDigitNumber(Number(message.averageSpeed ?? 0), 2),
        });
        setCurrentSpeedLive({
          truckName: message.truckName,
          value: +formatAsNDigitNumber(Number(message.currentSpeed ?? 0), 2),
        });
      }
    },
    [setAverageSpeedLive, setCurrentSpeedLive],
  );

  useSocket<SpeedMessage>('speed', handleSpeedMessage);

  //EngineHour Socket
  const handleEngineHoursMessage = useCallback(
    (message: EngineHoursMessage) => {
      console.log('Handling engine_hours message:', message);
      setEngineStatusLive({
        truckName: message.truckName,
        value:
          message.engineStatus === 'ON'
            ? EngineStatus.ACTIVE
            : EngineStatus.INACTIVE,
      });
      if (message.engineStatus === 'OFF') {
        setEngineHoursLive({
          truckName: message.truckName,
          value: Number(message.engineHours?.calculatedEngineMinutes),
        });
        setTotalEngineHoursLive({
          truckName: message.truckName,
          value: Number(message.engineHours?.totalEngineHoursInMinutes) / 60,
        });
      }
    },
    [setEngineStatusLive, setEngineHoursLive, setTotalEngineHoursLive],
  );

  useSocket<EngineHoursMessage>('engine_hours', handleEngineHoursMessage);

  //Contact Socket
  const handleContactMessage = useCallback(
    (message: ContactMessage) => {
      console.log('Handling contact message:', message);
      setContactTimeLive({
        truckName: message.truckName,
        value: Number(message.contactTime) / 60,
      });
    },
    [setContactTimeLive],
  );

  useSocket<ContactMessage>('contact', handleContactMessage);

  //NearBy Socket
  const handleNearbyMessage = useCallback(
    (message: NearbyMessage) => {
      console.log('Handling nearby message:', message);
      setNearbyTimeLive({
        truckName: message.truckName,
        value: Number(message.nearbyTime) / 60,
      });
    },
    [setNearbyTimeLive],
  );

  useSocket<NearbyMessage>('nearby', handleNearbyMessage);

  //Idle Socket
  const handleIdleTimeMessage = useCallback(
    (message: IdleTimeMessage) => {
      console.log('Handling idle time message:', message);
      setIdleTimeLive({
        truckName: message.truckName,
        value: Number(message.idleTimeInMinutes),
      });
    },
    [setIdleTimeLive],
  );

  useSocket<IdleTimeMessage>('idle_time', handleIdleTimeMessage);

  return (
    <AssetContext.Provider
      value={{
        assets,
        setAssets,
        isLoading,
        pushesLive,
        averageSpeedLive,
        currentSpeedLive,
        engineHoursLive,
        totalEngineHoursLive,
        contactTimeLive,
        nearbyTimeLive,
        idleTimeLive,
        engineStatusLive,
        tonnageLive,
        setSelectedAssets,
        selectedAssets,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
};
