import styles from './TabSelect.module.css';

type Props = {
  tabList: {
    label: string;
    id: string | number;
    onClick: Function;
    component?: JSX.Element;
  }[];
  selectedTab: string | number;
};

const TabSelect = ({ tabList, selectedTab }: Props) => {
  return (
    <div className={styles.main}>
      <div className={styles.tabsListContainer}>
        {tabList.map((tab, index) => {
          return (
            <div
              key={index}
              className={`${styles.label} ${
                selectedTab === tab.id ? styles.activeTab : ''
              }`}
              onClick={() => tab.onClick()}
            >
              {tab.label}
            </div>
          );
        })}
      </div>
      <div className={styles.tabContainer}>
        {tabList.map((tab) => {
          if (tab.component && tab.id === selectedTab) {
            return tab.component;
          } else {
            return <></>;
          }
        })}
      </div>
    </div>
  );
};

export default TabSelect;
