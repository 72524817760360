import { useCallback, useState } from 'react';
import LegendDropDown from '../../Components/LegendDropDown';
import { useLocation, useNavigate } from 'react-router-dom';
import SettingsFilter from './FilterSettings/SettingsFilter';
import searchIcon from '../../../assets/images/search-input.svg';
import emailIcon from '../components/email.svg';
import { legendDropDownData } from '../../constData';
import ReportDownloadModal from '../Table/components/ReportDownloadModal';
import DownloadMenu from './DownloadMenu';
import HistoryFilter from './HistoryFilter';
import TimeSelect from './TimeSelect';
import moment from 'moment';
import './styles.css';
export default function FilterBar({
  setFilteredValue,
  filteredValue,
  dropdownData,
  loading,
  downloadMenu,
  dropdownStyle,
  setDownloadMenu,
  mainDownloadHandler,
  allEquipments,
}) {
  const location = useLocation();
  const navigation = useNavigate();

  const addQueryParam = (assetNo) => {
    const { pathname } = location;
    const queryParams = new URLSearchParams(location?.search);
    if (assetNo !== '') {
      queryParams.set('asset', assetNo);
    } else {
      queryParams.delete('asset');
    }
    navigation({
      pathname,
      search: `?${queryParams.toString()}`,
      replace: true,
    });
  };

  const [reportDownloadFilterModal, setReportDownloadFilterModal] =
    useState(false);

  const onApply = useCallback((filter) => {
    setReportDownloadFilterModal(false);
    mainDownloadHandler(0, filter);
  }, []);

  const onCancel = useCallback(() => {
    setReportDownloadFilterModal(false);
  }, []);

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex align-items-center justify-content-center">
        <div className="asm-history-date-input d-flex align-items-center  mt-1">
          <div className="asm-history-date-filter-text-label mx-2">Date:</div>
          <input
            type="date"
            value={filteredValue?.date}
            className="asm-history-date-filter-text-input "
            max={moment().format('YYYY-MM-DD')}
            onChange={(e) => {
              setFilteredValue((prev) => ({
                ...prev,
                date:
                  e?.target?.value === ''
                    ? moment().format('YYYY-MM-DD')
                    : e?.target?.value,
              }));
            }}
          />
        </div>

        <div className="asm-history-date-input d-flex align-items-center  mt-1 mx-2 ">
          <div className="asm-history-date-filter-text-label mx-2 w-25">
            From:
          </div>
          <TimeSelect
            disabled={loading}
            uniqueName={'from'}
            defaultValue={{
              hour: '00',
              minute: '00',
              second: '00',
              isAM: true,
            }}
            inputButtonStyle={{
              font: "normal normal 600 0.75rem/0.875rem 'Open Sans'",
              color: '#1A1A1A',
              height: '1.5rem',
              width: '7rem',
              border: '1px solid #EBEBEB',
              borderRadius: '3.063rem',
              backgroundColor: '#FAFAFA',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '0.188rem',
              paddingBottom: '0.188rem',
              marginTop: '0.063rem',
              marginBottom: '0.063rem',
              marginRight: '-0.75rem',
            }}
            onChange={(value) => {
              setFilteredValue((prev) => ({ ...prev, from: value }));
            }}
          />
        </div>
        <div className="asm-history-date-input d-flex align-items-center  mt-1 ">
          <div className="asm-history-date-filter-text-label mx-2 w-25">
            To:
          </div>
          <TimeSelect
            disabled={loading}
            uniqueName={'to'}
            defaultValue={{
              hour: '11',
              minute: '59',
              second: '59',
              isAM: false,
            }}
            inputButtonStyle={{
              font: "normal normal 600 0.75rem/0.875rem 'Open Sans'",
              color: '#1A1A1A ',
              height: '1.5rem ',
              width: '7rem',
              border: '1px solid #EBEBEB',
              borderRadius: '3.063rem',
              backgroundColor: '#FAFAFA',
              paddingTop: '0.188rem',
              paddingBottom: '0.188rem',
              marginTop: '0.063rem',
              marginBottom: '0.063rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '-0.75rem',
            }}
            onChange={(value) => {
              setFilteredValue((prev) => ({ ...prev, to: value }));
            }}
          />
        </div>

        <div className="">
          <div className="asm-search-asset">
            <input
              type="search"
              className="shadow-none"
              value={filteredValue.searchQuery}
              placeholder="Search for an asset"
              style={{
                border: 'none',
                font: 'normal normal normal 600 0.7rem Open Sans',
                background: '#FFFFFF 0% 0 % no - repeat padding- box',
                color: '#6F7070',
              }}
              onChange={(e) => {
                addQueryParam(e?.target?.value);
                setFilteredValue((prev) => ({
                  ...prev,
                  searchQuery: e?.target?.value,
                }));
              }}
            />
            <img
              style={{ height: '1.2rem', width: '1.5rem' }}
              src={searchIcon}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center ms-2">
        <div className="d-flex align-items-center mr-1 ms-1">
          <div className="mr-2 ">
            <LegendDropDown data={legendDropDownData} />
          </div>
          <div className="ms-2">
            <SettingsFilter toShowFilter />
          </div>
          <div className="ms-2">
            {/* <div

                            style={{ marginLeft: '15px' }}
                            className="d-flex
                        align-items-center ms-2 filter-div cursor-pointer"
                        >
                            <img src={FilterIcon} alt="filters" />
                            <span className="font-13 p-1">Filter</span>
                        </div> */}
            <HistoryFilter
              toShowFilter
              setFilteredData={setFilteredValue}
              filteredData={filteredValue}
            />
          </div>

          <div className="ms-3 ">
            <DownloadMenu
              uniqueName={'reportDownloadAll'}
              menuOptions={[
                {
                  displayName: 'Email Report (CSV)',
                  icon: emailIcon,
                },
              ]}
              color={'#E2ECFC'}
              menuVisible={downloadMenu}
              setMenuVisible={(value) => {
                setDownloadMenu(value);
              }}
              // onMenuSelect={(optionNo) => mainDownloadHandler(optionNo)}
              onMenuSelect={(optionNo) => setReportDownloadFilterModal(true)}
            />
          </div>
        </div>
      </div>
      <ReportDownloadModal
        onApply={onApply}
        onCancel={onCancel}
        filterData={filteredValue}
        visible={reportDownloadFilterModal}
        allEquipments={allEquipments}
      />
    </div>
  );
}
