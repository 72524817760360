import React from 'react';

interface SpeedometerProps {
  height?: number;
  width?: number;
}

const Speedometer: React.FC<SpeedometerProps> = ({
  height = 14.257,
  width = 16.516,
}) => {
  return (
    <svg
      viewBox="0 0 16.516 14.257"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#808080"
      height={height}
      width={width}
    >
      <path
        id="Icon_ionic-ios-speedometer"
        data-name="Icon ionic-ios-speedometer"
        d="M10.158,4.5a7.905,7.905,0,0,0-5.92,13.147c.039.042.074.085.113.124a.889.889,0,0,0,1.31,0,6.112,6.112,0,0,1,9,0,.889.889,0,0,0,1.31,0l.113-.124A7.905,7.905,0,0,0,10.158,4.5ZM9.664,6.12a.494.494,0,1,1,.989,0V7.391a.494.494,0,0,1-.989,0ZM5.145,12.9H3.874a.494.494,0,0,1,0-.989H5.145a.494.494,0,0,1,0,.989ZM6.963,9.21h0a.5.5,0,0,1-.7,0l-.9-.9a.5.5,0,0,1,0-.7h0a.5.5,0,0,1,.7,0l.9.9A.494.494,0,0,1,6.963,9.21Zm5.67,1.218-1.677,2.665a1.094,1.094,0,0,1-.247.247,1.063,1.063,0,1,1-1.236-1.73l2.665-1.677a.361.361,0,0,1,.413,0A.355.355,0,0,1,12.633,10.427ZM14.052,9.21a.5.5,0,0,1-.7,0h0a.5.5,0,0,1,0-.7l.9-.9a.5.5,0,0,1,.7,0h0a.5.5,0,0,1,0,.7Zm2.39,3.689H15.171a.494.494,0,1,1,0-.989h1.271a.494.494,0,0,1,0,.989Z"
        transform="translate(-1.9 -4.15)"
        fill="none"
        stroke="#5b5b5b"
        stroke-width="0.7"
      />
    </svg>
  );
};

export default Speedometer;
