export const ReportModal = ({ header, children }) => (
  <div className="position-fixed asm-report-modal d-flex justify-content-center align-items-center px-2">
    <div
      onClick={(e) => e.stopPropagation()}
      className="asm-modal-body rounded-2 position-relative"
      style={{ maxWidth: '450px' }}
    >
      {header ? (
        <h4
          style={{ fontWeight: 'bold', fontSize: '16px' }}
          className="mt-4 mb-2"
        >
          {header}
        </h4>
      ) : null}

      <div className="my-2 d-flex flex-column align-items-center">
        {children}
      </div>
    </div>
  </div>
);
