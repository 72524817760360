import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

const RefreshSVG: React.FC<Props> = ({ width = 23, height = 23 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 23 23"
  >
    <g
      id="Group_62145"
      data-name="Group 62145"
      transform="translate(-1649.147 -235.168)"
    >
      <rect
        id="Rectangle_21449"
        data-name="Rectangle 21449"
        width="23"
        height="23"
        rx="11.5"
        transform="translate(1649.147 235.168)"
        fill="#fff"
        opacity="0.95"
      />
      <path
        id="refresh-cw-alt-1-svgrepo-com"
        d="M8.137,13.273A5.137,5.137,0,0,1,4.308,4.712L5.854,3M8.137,3a5.137,5.137,0,0,1,3.829,8.561l-1.546,1.712M3,3H5.854m0,0V5.854m7.419,7.419H10.419m0,0V10.419"
        transform="translate(1652.595 238.447)"
        fill="none"
        stroke="#646464"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default RefreshSVG;
