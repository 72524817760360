import React, { useEffect, useRef, useState } from 'react';
import Hide from '../../assets/hide.png';
import Show from '../../assets/show.png';
const FloatingInput = (props) => {
  const { type, value, setValue, label, style, isError, disabled, id } = props;
  const [isActive, setIsActive] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordIcon = () => {
    setPasswordShown(!passwordShown);
  };
  const inpRef = useRef(null);
  useEffect(() => {
    inpRef.current.addEventListener('animationstart', (e) => {
      setIsActive(true);
    });
    setTimeout(() => {
      // removing the event listener this way as on unmount we cannot read the element using ref
      inpRef.current &&
        inpRef.current.removeEventListener('animationstart', () => {});
    }, 1000);
  }, []);

  return (
    <div style={{ ...style }} id="float-label">
      <input
        disabled={disabled}
        type={
          type === 'password' ? (passwordShown ? 'text' : 'password') : type
        }
        value={value}
        onFocus={() => setIsActive(true)}
        onBlur={() => {
          setIsActive(false);
        }}
        onChange={(e) => setValue(e.target.value)}
        style={{ borderColor: isError ? '#C13E3D' : '#ccc' }}
        ref={inpRef}
        id={id}
      />
      {type === 'password' && (
        <img
          src={passwordShown ? Show : Hide}
          className="hide-show-icon"
          alt={'password icon'}
          onClick={togglePasswordIcon}
        />
      )}
      <label
        className={isActive || value ? 'Active' : ''}
        id="floatingLabel"
        htmlFor={type}
      >
        {label}
      </label>
    </div>
  );
};

export default FloatingInput;
