import { Dispatch, SetStateAction } from 'react';
import CloseIcon from '../../pages/assets/CloseIcon';
import styles from './AssetsTableModal.module.css';
import AssetList from '../AssetList/AssetList';

type Props = {
  setShowAssetTablePopUp: Dispatch<SetStateAction<boolean>>;
  showAssetTablePopUp: boolean;
};

const AssetsTableModal = ({
  setShowAssetTablePopUp,
  showAssetTablePopUp,
}: Props) => {
  return (
    <div className={styles.Modal}>
      <div className={styles.modalHeader}>
        Select Assets{' '}
        <div
          onClick={() => setShowAssetTablePopUp(false)}
          className={styles.close}
        >
          <CloseIcon height={21} />
        </div>
      </div>
      <div className={styles.modalContent}>
        <AssetList
          showAssetTablePopUp={showAssetTablePopUp}
          setShowAssetTablePopUp={setShowAssetTablePopUp}
        />
      </div>
    </div>
  );
};

export default AssetsTableModal;
