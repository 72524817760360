import moment from 'moment';
import styles from '../summary/Summary.module.css';
import { DateRange, DayPicker } from 'react-day-picker';
import { useState } from 'react';
import {
  currentDateNZ,
  customLocale,
  getFirstAndLastDayOfMonth,
  minDate,
} from '../helper';

type Props = {
  range: DateRange | undefined;
  setRange: Function;
  finalRange: DateRange | undefined;
  setFinalRange: Function;
  setShowDatePicker: Function;
  wholeMonth: boolean;
  setWholeMonth: Function;
};

const CustomDatePicker = ({
  range, //This state shows/highlights the selection on the date picker
  setRange,
  finalRange, //This is the main range which is used everywhere, it is updated only when apply is clicked
  setFinalRange,
  setShowDatePicker,
  wholeMonth,
  setWholeMonth,
}: Props) => {
  const [month, setMonth] = useState<Date | undefined>(finalRange?.from); //Takes the month from the startDate

  const handleWholeMonth = () => {
    if (wholeMonth) {
      //If already true then it toggles back to false keeping the 1st day of the selected month as startDate and endDate is undefined
      setRange({ from: range?.from, to: undefined });
      setWholeMonth(false);
    } else {
      //gets the range of the month as startDate being 1st Day and endDate being last Dat of the month state
      setRange(getFirstAndLastDayOfMonth(month ?? range?.from ?? new Date()));
      setWholeMonth(true);
    }
  };

  const handleMonthChange = (monthChange: Date) => {
    setMonth(monthChange);
  };

  return (
    <div className={styles.dateContainer}>
      <div className={styles.fromTo}>
        <div className={styles.from}>
          <div>From</div>
          <div className={styles.dateVal}>
            {moment(range?.from).format('DD/MM/YYYY')}
          </div>
        </div>
        <div className={styles.from}>
          <div>To</div>
          <div className={styles.dateVal}>
            {range?.to ? moment(range?.to).format('DD/MM/YYYY') : ''}
          </div>
        </div>
      </div>
      <DayPicker
        mode="range"
        selected={range}
        onSelect={(e) => {
          setRange(e);
        }}
        defaultMonth={new Date()}
        month={month ?? finalRange?.from}
        onMonthChange={handleMonthChange}
        disabled={{ after: currentDateNZ, before: minDate.toDate() }}
        fromMonth={minDate.toDate()} // Restricts the earliest filteration month to the month sepecified in REACT_APP_DMU_MIN_AVAILABLE_FILTER_DATE
        toMonth={currentDateNZ} // Restricts navigation to the current month
        locale={customLocale}
      />
      <div className={styles.btnContainer}>
        <div style={{ display: 'flex' }}>
          <label
            style={{
              color: '#3093FD',
              fontSize: '12px',
              textWrap: 'nowrap',
              display: 'flex',
              padding: '0px',
              alignItems: 'center',
              gap: '10px',
              cursor: 'pointer',
            }}
          >
            <input
              type="checkbox"
              name="wholeMonth"
              id="wholeMonth"
              checked={wholeMonth}
              onChange={handleWholeMonth}
            />
            Whole month
          </label>
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <button
            className={styles.btn}
            onClick={() => {
              setRange(finalRange);
              setShowDatePicker(false);
            }}
          >
            Cancel
          </button>
          <button
            className={`${styles.btn} ${styles.fill}`}
            onClick={() => {
              setFinalRange(range);
              setShowDatePicker(false);
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomDatePicker;
