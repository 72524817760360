import React, { useEffect, useRef, useState, useContext } from 'react';
import axios from 'axios';
import { CgClose } from 'react-icons/cg';
import { toast } from 'react-toastify';
import AssestInfoDrawer from '../../../../AssetTrackingLive/Buttons/AssestInfoDrawer/MapInfoDrawer';
import { CallWithAuth } from '../../../../action/apiActions';
import { TRUCK_EVENTS } from '../../../../action/apiPath';
import LoadingScreen from '../../../../component/LoadingScreen';
import LegendDropDown from '../../../Components/LegendDropDown';
import { Map } from '../../../Map/map';
import fullScreen from '../../../assets/svgs/full-screen.svg';
import { legendDropDownDataMap } from '../../../constData';
import { formatMapData } from '../../../utils/helper';
import { UserContext } from '../../../../../pages/service/userContext';

export default function Timeline({
  date,
  selectedRowData,
  setSelectedRowData,
  tableHeadingRef,
  isMapModalOpen,
  setIsMapModalOpen,
}) {
  const { minIdleTime } = useContext(UserContext);
  const [selectedAsset, setSelectedAsset] = useState({});
  const [isFullScreenMode, setFullScreenMode] = useState(false);
  const [showTimelineGraph, setShowTimelineGraph] = useState(false);
  const [timeline, setTimeLine] = useState([]);
  const [filteredTimeline, setfilteredTimeline] = useState([]);
  const [allCoordinates, setAllCoordinates] = useState([]);
  const [mapLoading, setMapLoading] = useState(false);
  const [eventsData, setEventsData] = useState({
    speeding: [],
    idle: [],
  });
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [endPoints, setEndPoints] = useState([]);
  const mapRef = useRef(null);
  const source = useRef(axios.CancelToken.source());

  const isSelected = (type, selectedEvents) => {
    return (
      type === 'start' ||
      type === 'end' ||
      (type === 'speeding'
        ? selectedEvents?.includes('Speeding')
        : selectedEvents?.includes('Idle Time') ||
          selectedEvents?.includes('Idle') ||
          selectedEvents?.includes('Idle time'))
    );
  };
  useEffect(() => {
    if (selectedEvents?.length && timeline?.length) {
      let filteredData = timeline?.filter((marker) => {
        return !marker.onlyMarker || isSelected(marker?.type, selectedEvents);
      });
      setfilteredTimeline([...filteredData]);
    } else if (selectedEvents?.length == 0 && timeline.length) {
      setfilteredTimeline(
        timeline?.filter(
          (marker) =>
            marker.type === 'start' ||
            marker.type === 'end' ||
            !marker.onlyMarker,
        ),
      );
    }
  }, [JSON.stringify(selectedEvents), timeline]);

  const closeModal = () => {
    setIsMapModalOpen(false);
    setFullScreenMode(false);
    setSelectedRowData(null);
  };

  const modalStyle =
    isMapModalOpen && selectedRowData
      ? {
          top: `${tableHeadingRef.current.getBoundingClientRect().bottom}px`,
          right: -0.77,
          transform: 'translateX(0%)',
          boxShadow: '-1px 1px 6px #00000029',
          border: '1px solid #EBEBEB',
        }
      : {
          right: '-20000px',
        };

  const fetchTripshistoryCoordinates = async (
    asset_ID,
    activity_start_time,
    activity_end_time,
    speed,
    cancelToken,
  ) => {
    setMapLoading(true);
    try {
      const payload = {
        asset_ID: asset_ID,
        activity_start_time: activity_start_time,
        activity_end_time: activity_end_time,
        date,
      };
      const response = await CallWithAuth(
        'POST',
        TRUCK_EVENTS,
        payload,
        cancelToken,
      );
      if (response?.res?.status == 200) {
        const { markers, events, endPoints_markers } = formatMapData(
          response?.res?.data?.data,
          speed,
          minIdleTime,
        );
        setTimeLine(markers);
        setAllCoordinates(
          response?.res?.data?.data?.map((e) => {
            return {
              latitude: e.latitude,
              longitude: e.longitude,
              head: e.head,
              spd: e.spd,
              css: e.css,
              timestamp: e.timestamp,
            };
          }),
        );
        setfilteredTimeline(markers);
        setEventsData(events);
        setMapLoading(false);
        setEndPoints(endPoints_markers);
      }
    } catch (error) {
      toast.error(error);
      setMapLoading(false);
    }
  };

  useEffect(() => {
    if (selectedRowData?.activeIndex || selectedRowData?.activeIndex == 0) {
      setMapLoading(true);

      source?.current?.cancel();
      source.current = axios.CancelToken.source();
      fetchTripshistoryCoordinates(
        selectedRowData?.asset_ID,
        selectedRowData?.activity[selectedRowData?.activeIndex]?.startTime,
        selectedRowData?.activity[selectedRowData?.activeIndex]?.endTime,
        selectedRowData?.speed,
        source.current.token,
      );
    }
  }, [selectedRowData?.activeIndex, selectedRowData?.asset_ID]);

  return (
    <div
      className={` ${
        !isFullScreenMode
          ? 'row-details'
          : showTimelineGraph
          ? 'asm-timeline-graph-modal'
          : 'full-screen-modal'
      }`}
      style={modalStyle}
    >
      <div className="modal-heading">
        <div className="px-3 d-flex w-100 justify-content-between align-items-center">
          <div
            className="justify-content-between align-items-center"
            style={{
              marginLeft: '0.3rem',
              font: 'normal normal bold 1.4rem/1.8rem Open Sans',
              color: '#1A1A1A',
              padding: '0.5rem 0rem 0.7rem 0rem',
            }}
          >
            <span>
              <span
                style={{
                  font: 'normal normal bold 0.8rem/1.4rem Open Sans',
                  color: '#1A1A1A',
                }}
              >
                {' '}
                {selectedRowData?.asset_ID}
                {!isFullScreenMode ? (
                  <span>
                    {' '}
                    -{' '}
                    <span style={{ color: '#707070' }}>
                      {selectedRowData?.activity[
                        selectedRowData?.activeIndex
                      ]?.activity_id.toUpperCase()}{' '}
                    </span>
                  </span>
                ) : (
                  ''
                )}
              </span>

              {isFullScreenMode && (
                <span
                  style={{
                    font: 'normal normal 0.915rem Open Sans',
                    color: '#1A1A1A',
                  }}
                >
                  {/* <span>
                    {' '}
                    <span className="mx-3">|</span>{' '}
                    {selectedRowData?.Department}
                  </span>
                  <span>
                    {' '}
                    <span className="mx-3">|</span>{' '}
                    {selectedRowData?.EquipmentType}
                  </span> */}
                  <span
                    style={{
                      font: 'normal normal bold 0.8rem/1.4rem Open Sans',
                      color: '#707070',
                    }}
                  >
                    {' '}
                    <span className="mx-3">| </span>
                    {new Date(selectedRowData?.date).toLocaleDateString(
                      'en-NZ',
                      {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        timeZone: 'Pacific/Auckland',
                      },
                    )}
                  </span>

                  {/* <span>
                    {' '}
                    <span className="mx-3">| </span>
                    {selectedRowData?.activity[selectedRowData?.activeIndex]
                      ?.activity_type === 'Trip' ? (
                      <>
                        {
                          selectedRowData?.activity[
                            selectedRowData?.activeIndex
                          ]?.activity_id
                        }
                        <span style={{ color: '#6F7070' }}>
                          {' '}
                          /{' '}
                          {
                            selectedRowData?.activity?.reduce(
                              (result, assetActivity) =>
                                assetActivity?.activity_type === 'Trip'
                                  ? { trips: result.trips + 1 }
                                  : result,
                              { trips: 0 },
                            )?.trips
                          }
                        </span>
                      </>
                    ) : (
                      <span> PARKED</span>
                    )}
                  </span> */}
                </span>
              )}
            </span>
          </div>
          {/* {isFullScreenMode && <div style={{ marginLeft: "-20rem", }} >{selectedRowData?.activity[selectedRowData?.activeIndex]?.date.toUpperCase()}</div>} */}
          <CgClose
            style={{ justifyContent: 'flex-end', cursor: 'pointer' }}
            onClick={closeModal}
            size={'2rem'}
            className="px-2"
          />
        </div>
      </div>

      <div className="modal-map">
        {mapLoading && <LoadingScreen />}
        <Map
          isTrip={selectedRowData?.activity_type === 'Trip'}
          isFullScreenMode={isFullScreenMode}
          showTimelineGraph={showTimelineGraph}
          showTimelineGraphToggle={(v) => setShowTimelineGraph(v)}
          selectedMarker={selectedAsset}
          markers={filteredTimeline}
          onMarkerClick={(v) => {
            if (v?.select) {
              setSelectedAsset({
                ...v?.marker,
                fromMap: v?.fromMap,
                select: v?.select,
              });
            } else {
              setSelectedAsset({});
            }
          }}
          mapRef={mapRef}
          showDefaultMarkers={isFullScreenMode}
          endPoints={endPoints}
          allCoordinates={allCoordinates}
          onSelectMarker={() => {}}
        />
        {!isFullScreenMode && (
          <div
            style={{
              top: '3rem',
              right: '0.6rem',
              position: 'absolute',
              zIndex: 1,
              cursor: 'pointer',
            }}
          >
            <div
              className="d-flex align-items-center justify-content-center cursor-pointer p-2"
              onClick={() => {
                setFullScreenMode(true);
              }}
            >
              <img
                src={fullScreen}
                alt="Zoom In"
                style={{ width: '2rem', height: '2rem' }}
              />
            </div>
          </div>
        )}

        {isFullScreenMode && (
          <div
            style={{
              top: '3.6rem',
              right: 10,
              position: 'absolute',
              zIndex: 1,
            }}
          >
            <LegendDropDown
              style={{ backgroundColor: '#377FF2', border: 'none' }}
              data={legendDropDownDataMap}
              align="start"
            />
          </div>
        )}

        {isFullScreenMode && (
          <div
            style={{ top: '2rem', left: 0, position: 'absolute', zIndex: 1 }}
          >
            <AssestInfoDrawer
              onSelect={(v) => {
                setSelectedAsset(v);
              }}
              selectedAsset={selectedAsset}
              onToggle={(index) => {
                setSelectedRowData({ ...selectedRowData, activeIndex: index });
              }}
              assets={selectedRowData}
              events={eventsData}
              selectedEvents={selectedEvents}
              setSelectedEvents={setSelectedEvents}
            />
          </div>
        )}
      </div>
    </div>
  );
}
