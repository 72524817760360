import { FunctionComponent, useEffect, useState } from 'react';
import {
  LeastMostSummaryProps,
  TimelineGranularity,
  TimelineTypes,
} from './models';
import {
  getMaxContactDaily,
  getMaxContactMonthly,
  getMaxFuelDaily,
  getMaxFuelMonthly,
  getMaxPushesDaily,
  getMaxPushesMonthly,
  getMaxUtilisationDaily,
  getMaxUtilisationMonthly,
  getMostLeastText,
} from './helper';
import moment from 'moment';
import useMediaQuery from '../../hooks/useMediaQuery';
import { maxHeightBreakPoint } from '../constants';
import Performance from '../assets/Performance';
import PerformanceRed from '../assets/PerfomanceRed';

const LeastMostSummary: FunctionComponent<LeastMostSummaryProps> = (props) => {
  const [mostText] = getMostLeastText(props.type, props.granularity);
  const [macroString, setMacroString] = useState('');

  useEffect(() => {
    if (props.type === TimelineTypes.Productivity) {
      if (props.granularity === TimelineGranularity.Daily) {
        setMacroString(getMaxPushesDaily(props.data));
      } else if (props.granularity === TimelineGranularity.Monthly) {
        setMacroString(getMaxPushesMonthly(props.data));
      }
    } else if (props.type === TimelineTypes.TimeUse) {
      if (props.granularity === TimelineGranularity.Daily) {
        setMacroString(getMaxContactDaily(props.data));
      } else if (props.granularity === TimelineGranularity.Monthly) {
        setMacroString(getMaxContactMonthly(props.data));
      }
    } else if (props.type === TimelineTypes.Utilisation) {
      if (props.granularity === TimelineGranularity.Daily) {
        setMacroString(getMaxUtilisationDaily(props.data));
      } else if (props.granularity === TimelineGranularity.Monthly) {
        setMacroString(getMaxUtilisationMonthly(props.data));
      }
    } else if (props.type === TimelineTypes.Fuel) {
      if (props.granularity === TimelineGranularity.Daily) {
        setMacroString(getMaxFuelDaily(props.data));
      } else if (props.granularity === TimelineGranularity.Monthly) {
        setMacroString(getMaxFuelMonthly(props.data));
      }
    }
  }, [props.data, props.granularity, props.type]);

  const { height } = useMediaQuery();

  return (
    <div
      className="d-flex gap-4"
      style={{ fontSize: height <= maxHeightBreakPoint ? '10px' : '12px' }}
    >
      {props.granularity === TimelineGranularity.Hourly && (
        <div className="d-flex gap-1" style={{ alignItems: 'center' }}>
          <span
            style={{
              fontWeight: '600',
              fontStyle: 'italic',
              color: '#707070',
            }}
          >
            {props.finalRange
              ? props.finalRange.to
                ? `${moment(props.finalRange.from).format(
                    'DD/MM/YYYY',
                  )} - ${moment(props.finalRange.to).format('DD/MM/YYYY')}`
                : moment(props.finalRange.from).format('DD/MM/YYYY')
              : moment(new Date()).format('DD/MM/YYYY')}
          </span>
        </div>
      )}
      {props.granularity === TimelineGranularity.Daily && (
        <div className="d-flex gap-1" style={{ alignItems: 'center' }}>
          <div className="most-least-image">
            {props.type === TimelineTypes.Utilisation ||
            props.type === TimelineTypes.Fuel ? (
              <PerformanceRed />
            ) : (
              <Performance />
            )}
          </div>
          {mostText} <span className="fw-bold">{macroString}</span>
        </div>
      )}
      {props.granularity === TimelineGranularity.Monthly && (
        <div className="d-flex gap-1" style={{ alignItems: 'center' }}>
          <div className="most-least-image">
            {props.type === TimelineTypes.Utilisation ||
            props.type === TimelineTypes.Fuel ? (
              <PerformanceRed />
            ) : (
              <Performance />
            )}
          </div>
          {mostText} <span className="fw-bold">{macroString}</span>
        </div>
      )}
    </div>
  );
};

export default LeastMostSummary;
