import axios from 'axios';
import jwt_decode from 'jwt-decode';
import PolygonComponent from './Polygon';
import axiosInstance from './utils/axios';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import './css/allcss.css';
import './style.scss';
import './App.css';
import { withAccessControl } from '../pages/service/with-access-control';
import ErrorBoundary from './ErrorBoundary';

const MaterialMovements = () => {
  const isValidToken = async (accessToken) => {
    if (!accessToken) {
      return false;
    }
    const decoded = jwt_decode(accessToken);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const currentTime = Date.now() / 1000;

    if (decoded.exp < Math.ceil(currentTime)) {
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        };
        const res = await axios.get('/api/auth/refresh-token', {
          headers,
        });
        if (res.status === 200) {
          const userData = res.data;
          const [token, departments] = userData.accessToken?.split('||');
          userData.departments = departments;
          localStorage.setItem('accessToken', token);
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
          localStorage.setItem('refreshToken', userData.refreshToken);
        }
      } catch (error) {
        // localStorage.clear();
        return false;
      }
    }
    return true;
  };

  return (
    <div className="mm-body ">
      {isValidToken(localStorage.getItem('accessToken')) ? (
        <PolygonComponent />
      ) : (
        <div>MaterialBreakdown</div>
      )}
    </div>
  );
};

export default withAccessControl(MaterialMovements);
