import { LatLng, LatLngBounds } from 'leaflet';
import { useEffect, useState } from 'react';
import { DumpEventPopup } from './DumpEventPopup';
import { DumpPointPopup2 } from './DumpPointPopup2';
import { HiddenPopup } from './HiddenPopup';

let globalCreateMode;
let globalCreated;
let map;
let mapkit = undefined;
let markerAnnotation = undefined;
var points = [];
var polylineOverlays = [];
var polygonOverlay;
let vehicleEventOverlays = [];
let selectedPoints = [];
let dumpPointPopups = [];
let visiblePopups = [];
let hiddenPopups = [];
let polylineStyle;
let dumpPointOverlays = [];
let vehicleEventPopups = [];
let globalViewDumpPoints;

function getMaterialColor(materialType, materials) {
  const material = materials.find((item) => item.MaterialType === materialType);

  return material ? material.color : '#000000'; // Replace "#000000" with your desired default color
}

function approximateRegionValue(x) {
  if (x <= 14.1) {
    return 0.045;
  }
  if (x <= 15) {
    return 0.065;
  } else if (x <= 16) {
    return 0.077;
  } else if (x <= 17) {
    return 0.085;
  } else if (x <= 18) {
    return 0.09;
  } else if (x <= 19) {
    return 0.095;
  }
}
const createDumpEventMarker = (
  coordinates,
  name,
  dumpEvent,
  dumpPointName,
  material = '',
  selectedtruck = { visible: false },
  materials,
  mapRef,
  zoom,
) => {
  const style = {
    fillColor:
      name === 'Unknown' ? 'red' : getMaterialColor(material, materials),
    fillOpacity: 1,
    strokeColor: 'white',
    height: '1px',

    visible: globalViewDumpPoints ? false : true,
    zPosition: 999,
  };

  const initialStyle = new mapkit.Style(style);
  const selectedStyle1 = new mapkit.Style({
    fillColor: 'transparent',
    fillOpacity: 1,
    strokeColor: 'transparent',
    lineWidth: 2,
    visible: false,
    zIndex: 999,
    zPosition: 999,
  });
  const selectedStyle2 = new mapkit.Style({
    fillOpacity: 1,
    fillColor: 'transparent',
    strokeColor: 'white',
    lineWidth: 2,
    visible: false,
    zIndex: 999,
    zPosition: 999,
  });
  const options = {
    title: name,
    data: {
      dumpEvent: dumpEvent,
      dumpPointName: dumpPointName,
      selectedtruck: selectedtruck,
    },
    visible: false,
  };

  let loadMarker = new mapkit.MarkerAnnotation(coordinates, {
    color: name === 'Unknown' ? 'red' : getMaterialColor(material, materials),
    glyphColor:
      name === 'Unknown' ? 'red' : getMaterialColor(material, materials),
  });

  let annotation = new mapkit.Annotation(coordinates, DumpEventPopup, options);
  vehicleEventPopups.push(map.addAnnotation(annotation));
  annotation.visible = selectedtruck?.visible;

  loadMarker.addEventListener('select', (event) => {
    //  hiddenPopups.forEach((popup) => {
    //    popup.visible = false;
    //  });
    loadMarker.style = selectedStyle2;
    annotation.visible = true;
  });
  loadMarker.addEventListener('deselect', (event) => {
    loadMarker.style = selectedStyle1;
    annotation.visible = false;
  });
  // annotation.visible = true;

  return { loadMarker };
  // return { icon };
};

const createPoint = (coordinates, zoom) => {
  let dumpEventMarker = new mapkit.MarkerAnnotation(coordinates, {
    color: '#53D7FE',
    glyphColor: '#53D7FE',
  });

  return { dumpEventMarker };
};

const resetPolygons = () => {
  if (!polygonOverlay) return;
  map.removeOverlay(polygonOverlay);
  polygonOverlay = undefined;
};

const createDumpPointPolygon = ({
  selectedPoly,
  data,
  selected,
  onSelectPoly,
  type,
  materials = [],
  onlyHighlight = false,
}) => {
  let coordinates;
  let t = new mapkit.Coordinate(
    data?.coordinates[0]?.latitude
      ? data?.coordinates[0]?.latitude
      : data?.coordinates[0]?.lat
      ? data?.coordinates[0]?.lat
      : -37.190076,
    data?.coordinates[0]?.longitude
      ? data?.coordinates[0]?.longitude
      : data?.coordinates[0]?.lng
      ? data?.coordinates[0]?.lng
      : 174.725014,
  );
  if (type == 5) {
    // onSelectPoly({});
    coordinates = data?.coordinates[0].map((coordinate) => {
      if (coordinate?.latitude && coordinate?.longitude) {
        t = new mapkit.Coordinate(coordinate?.latitude, coordinate?.longitude);
        return new mapkit.Coordinate(
          coordinate?.latitude,
          coordinate?.longitude,
        );
      } else {
        return t;
      }
    });
  } else
    coordinates = data?.coordinates?.map((coordinate) => {
      if (coordinate.lat && coordinate.lng) {
        t = new mapkit.Coordinate(coordinate.lat, coordinate.lng);
        return new mapkit.Coordinate(coordinate.lat, coordinate.lng);
      } else {
        return t;
      }
    });

  const initialStyle = new mapkit.Style({
    fillColor: '#FDC93A66',
    fillOpacity: 0.5,
    lineWidth: 2,
    lineJoin: 'round',
    strokeColor: '#FFBF10',
  });

  const selectedStyle = new mapkit.Style({
    fillColor: '#346FD2',
    fillOpacity: 0.5,
    lineWidth: 2,
    lineJoin: 'round',
    strokeColor: '#3151FF',
  });

  const bounds = new LatLngBounds(
    coordinates?.map((p) => {
      if (p?.latitude && p?.longitude) {
        return new LatLng(p.latitude, p.longitude);
      } else return new LatLng(-37.186454, 174.718004);
    }),
  );
  const center = new mapkit.Coordinate(
    bounds.getCenter().lat,
    bounds.getCenter().lng,
  );
  const northPoint = new mapkit.Coordinate(
    bounds.getNorth(),
    bounds.getCenter().lng,
  );

  const hiddenOptions = {
    title: data?.name,
    data,
    visible: onlyHighlight ? false : selected,
    flag: type === 5 ? 0 : 1,
    materials: materials,
  };

  const visibleOptions = {
    title: data?.name,
    data: data,
    visible: true,
    flag: type === 5 ? 0 : 1,
    materials: materials,
    select: (event, select) => {
      if (selectedPoly?.name !== data?.name) {
        dumpPointPopups.forEach((popup) => {
          popup.visible = false;
        });
        vehicleEventPopups.forEach((popup) => {
          popup.visible = false;
        });

        type === 5 ? onSelectPoly({}) : onSelectPoly(data);
        event.target.style = selectedStyle;
        map.setCenterAnimated(center);
        hiddenAnnotation.visible = type == 5 || onlyHighlight ? false : true;
      } else {
        event.target.style = initialStyle;
        hiddenAnnotation.visible = false;
        onSelectPoly({});
      }
    },
  };

  let hiddenAnnotation = new mapkit.Annotation(
    northPoint,
    HiddenPopup,
    hiddenOptions,
  );
  let visibleAnnotation = new mapkit.Annotation(
    northPoint,
    DumpPointPopup2,
    visibleOptions,
  );

  dumpPointPopups.push(map.addAnnotation(hiddenAnnotation));
  hiddenPopups.push(map.addAnnotation(hiddenAnnotation));
  dumpPointPopups.push(map.addAnnotation(visibleAnnotation));
  visiblePopups.push(map.addAnnotation(visibleAnnotation));

  let polygon = new mapkit.PolygonOverlay(coordinates, {
    style:
      type == 5 || onlyHighlight
        ? selectedStyle
        : selected
        ? selectedStyle
        : initialStyle,
  });

  return polygon;
};

const resetPolylines = () => {
  markerAnnotation && map.removeAnnotation(markerAnnotation);
  if (!polylineOverlays || !map) return;
  map.removeOverlays(polylineOverlays);
  points = [];
  polylineOverlays = [];
};

const setupMapKitJs = async () => {
  if (!window.mapkit || window.mapkit.loadedLibraries?.length === 0) {
    await new Promise((resolve) => {
      window.initMapKit = resolve;
    });

    delete window.initMapKit;
  }

  const jwt = process.env.REACT_APP_APPLE_MAP_TOKEN;
  window.mapkit.init({
    authorizationCallback: (done) => {
      done(jwt);
    },
    language: 'en',
  });
};

const setupMap = (props) => {
  const mapCenter = new mapkit.Coordinate(-37.354439, 174.71803);
  const mapRegion = new mapkit.CoordinateRegion(
    mapCenter,
    new mapkit.CoordinateSpan(0.06, 0.06),
  );

  map = new mapkit.Map(props?.type, {
    center: mapCenter,
    mapType: mapkit?.Map?.MapTypes?.Satellite,
    region: mapRegion,
    showsCompass: mapkit?.FeatureVisibility?.Hidden,
  });

  props.mapRef.current = map;
  map.cameraZoomRange = new mapkit.CameraZoomRange(100, 6000);
  map.cameraBoundary = mapRegion?.toMapRect();
  map.cameraDistance = 3300;

  map.addEventListener('single-tap', (event) => {
    singleTapEvent({
      event,
      onSelectPoly: props.onSelectPoly,
      materials: props?.materials,
      selectedPoly: props?.selectedPoly,
    });
  });

  const tileOverlay = new mapkit.TileOverlay(
    (x, y, z, scale) => {
      return `/api/o/map-tile?x=${x}&y=${y}&z=${z}&scale=${scale}`;
    },
    {
      minimumZ: 0,
      maximumZ: 20,
      opacity: 1,
      data: {},
    },
  );

  map.addTileOverlay(tileOverlay);

  // map.addEventListener("single-tap", (event) => {
  //   editPointEvent({
  //     event,
  //     setSelectedPoint: props?.setSelectPoint,
  //     point: props?.selectedPoint,
  //   });
  // });
};

const editPointEvent = ({ event, setSelectedPoint, point }) => {
  var coordinates;
  coordinates = map.convertPointOnPageToCoordinate(event.pointOnPage);

  setSelectedPoint({
    ...point,
    coordinate: { lat: coordinates?.latitude, lng: coordinates?.longitude },
  });
};

const singleTapEvent = ({
  event,
  onSelectPoly,
  materials,
  selectedPoly,
  isPolygonClicked,
  latlng,
}) => {
  if (!globalCreateMode || globalCreated) return;
  var coordinates;
  isPolygonClicked
    ? (coordinates = latlng)
    : (coordinates = map.convertPointOnPageToCoordinate(event.pointOnPage));

  points.push(coordinates);

  if (points.length === 1) {
    var marker = new mapkit.MarkerAnnotation(
      map.convertPointOnPageToCoordinate(event.pointOnPage),
      { color: 'red' },
    );

    markerAnnotation = map.addAnnotation(marker);
    return;
  }

  map.removeAnnotation(markerAnnotation);
  var polyline = new mapkit.PolylineOverlay(
    [points[points.length - 2], points[points.length - 1]],
    { style: polylineStyle },
  );
  polyline.addEventListener('select', function (event) {
    singleTapEvent({
      onSelectPoly,
      materials,
      selectedPoly,
      latlng: event.target._impl?._points[0],
      isPolygonClicked: true,
    });

    // Do whatever you want with the clickedCoordinates
  });

  var overlay = map.addOverlay(polyline);
  polylineOverlays.push(overlay);

  const transformedArr = points
    .slice(0, points.length - 1)
    .map(({ latitude, longitude }) => {
      return {
        lat:
          typeof latitude == 'number' ? Number(latitude?.toFixed(6)) : latitude,
        lng:
          typeof longitude == 'number'
            ? Number(longitude?.toFixed(6))
            : longitude,
      };
    });

  console.log({ transformedArr, points });
  const isIntersecting = points.some((point, i) => {
    if (i < points.length - 3) {
      const currentLine = [points[i], points[i + 1]];
      return lineIntersects(currentLine, [
        points[points.length - 2],
        points[points.length - 1],
      ]);
    }
    return false;
  });

  onSelectPoly({
    coordinates: transformedArr,
    visible: false,
    isIntersecting: isIntersecting,
  });
  // console.log("^^^^^^^&&&&&&&&&***********O((((((((((", isIntersecting);

  if (!isIntersecting) return;

  let polygon = createDumpPointPolygon({
    selectedPoly: selectedPoly,
    data: { coordinates: [points.slice(0, points.length - 1)] },
    selected: false,
    type: 5,
    materials,
  });
  dumpPointOverlays.push(map.addOverlay(polygon));
  polygonOverlay = map.addOverlay(polygon);
  resetPolylines();
};

const lineIntersects = (line1, line2) => {
  const { latitude: x1, longitude: y1 } = line1[0];
  const { latitude: x2, longitude: y2 } = line1[1];
  const { latitude: x3, longitude: y3 } = line2[0];
  const { latitude: x4, longitude: y4 } = line2[1];

  const det = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
  if (det === 0) {
    return false; // Lines are parallel
  }

  const t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / det;
  const u = -((x1 - x2) * (y1 - y3) - (y1 - y2) * (x1 - x3)) / det;

  if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
    return true; // Intersection detected
  }

  return false; // No intersection
};

export const AppleMap = (props) => {
  const {
    ongoingDumpEvents = {},
    setongoingDumpEvents = () => {},
    polygon = [],
    type = '',
    selectedPoly = {},
    onSelectPoly,
    mode,
    createMode = '',
    selectedtruck = {},
    created,
    materials = [],
    mapRef,
    selectedPoint = {
      lat: 0.0,
      lng: 0.0,
    },
    zoom,
    clearPolygons,
    fitScreen = () => {},
    changeZoom = () => {},
  } = props;
  const [cursor, setCursor] = useState('');
  const [mapId, setMapId] = useState(type);
  const [prevOverlay, setPrevOverlay] = useState(type);
  const [setupCompleted, setSetupCompleted] = useState(false);

  useEffect(() => {
    console.log('$$$$$$$$$$$$$');
    dumpPointPopups.forEach((popup) => {
      popup.visible = false;
    });

    // dumpPointOverlays.forEach((overlay) => {
    //   if (overlay?.visible == true || overlay?.visible == false)
    //     overlay.visible = false;
    // });
  }, [clearPolygons]);

  useEffect(() => {
    async function fetchData() {
      await setupMapKitJs();
      mapkit = window.mapkit;
      setupMap(props);
      polylineStyle = new mapkit.Style({
        lineWidth: 2,
        lineJoin: 'round',
        lineDash: [8, 4],
        strokeColor: '#F0F',
      });
      dumpPointOverlays.forEach((overlay) => {
        overlay.style.fillOpacity = 0.5;
      });
      setSetupCompleted(true);
    }
    fetchData();

    return () => {
      delete window.initMapKit;
    };
  }, []);

  useEffect(() => {
    if (!setupCompleted) return;
    if (!created) {
      resetPolygons();
    }
    if (!createMode) {
      resetPolylines();
      map.isZoomEnabled = true;
      map.isScrollEnabled = true;
    }
    globalCreateMode = createMode;
    globalCreated = created;
  }, [createMode, created, setupCompleted]);

  useEffect(() => {
    resetPolygons();
    dumpPointPopups.forEach((popup) => {
      popup.visible = false;
    });

    dumpPointOverlays.forEach((overlay) => {
      if (overlay?.visible === true || overlay?.visible === false)
        overlay.visible = false;
    });
    console.log(polygon);
    polygon
      ?.filter((dumpPoint) => dumpPoint?.coordinates?.length > 2)
      ?.forEach((data) => {
        let poly = createDumpPointPolygon({
          selectedPoly: selectedPoly,
          data: data,
          selected: false,
          onSelectPoly,
          materials,
        });
        dumpPointOverlays.push(map.addOverlay(poly));
      });
  }, [polygon]);

  useEffect(() => {
    console.log(selectedPoint);
    selectedPoints.forEach((point) => {
      point.visible = false;
    });
    if (mapkit) {
      let { dumpEventMarker } = createPoint(
        new mapkit.Coordinate(
          selectedPoint?.coordinate?.lat,
          selectedPoint?.coordinate?.lng,
        ),
        zoom,
      );
      selectedPoints.push(map.addAnnotation(dumpEventMarker));
    }
  }, [selectedPoint]);

  useEffect(() => {
    dumpPointPopups.forEach((popup) => {
      popup.visible = false;
    });

    dumpPointOverlays.forEach((overlay) => {
      if (overlay?.visible == true || overlay?.visible == false)
        overlay.visible = false;
    });

    polygon
      ?.filter((dumpPoint) => dumpPoint?.coordinates?.length > 2)
      ?.forEach((data) => {
        let poly = createDumpPointPolygon({
          selectedPoly: selectedPoly,
          data: data,
          selected: false,
          onSelectPoly,
          materials,
        });
        dumpPointOverlays.push(map.addOverlay(poly));
      });

    if (
      selectedPoly?.coordinates?.length > 2 &&
      typeof selectedPoly?.coordinates[0]?.lat == 'number' &&
      selectedPoly?.isIntersecting != false
    ) {
      const mapCenter = new mapkit.Coordinate(
        selectedPoly?.coordinates[0].lat,
        selectedPoly?.coordinates[0].lng,
      );
      map.setCenterAnimated(mapCenter);

      let poly = createDumpPointPolygon({
        selectedPoly: selectedPoly,
        data: selectedPoly,
        selected: selectedPoly?.visible == false ? false : true,
        onlyHighlight: selectedPoly?.onlyHighlight,
        onSelectPoly,
        materials,
      });
      dumpPointOverlays.push(map.addOverlay(poly));
    }

    if (selectedPoly.popup == false)
      dumpPointPopups.forEach((popup) => {
        popup.visible = false;
      });
  }, [selectedPoly]);

  const vehicleStates = (key) => {
    vehicleEventPopups.forEach((popup) => {
      popup.visible = false;
    });
    vehicleEventOverlays.forEach((overlay) => {
      if (overlay?.visible == true || overlay?.visible == false)
        overlay.visible = false;
    });
    if (!setupCompleted) return;
    let temp = [];
    // console.log(ongoingDumpEvents);
    if (type === 'dump') {
      if (selectedtruck?.visible) {
        ongoingDumpEvents?.forEach((truck) => {
          truck.allLoads.forEach((dumpPoint) => {
            if (dumpPoint?.name === selectedtruck?.name) {
              dumpPoint.loads.forEach((dumpEvent) => {
                temp.push({ lat: dumpEvent.lat, lng: dumpEvent.lng });
                let { loadMarker } = createDumpEventMarker(
                  new mapkit.Coordinate(dumpEvent.lat, dumpEvent.lng),
                  truck?.name,
                  dumpEvent,
                  dumpPoint?.name,
                  dumpEvent?.material,
                  selectedtruck?.pointVisible
                    ? { visible: false }
                    : selectedtruck,
                  materials,
                  mapRef,
                  zoom,
                );

                vehicleEventOverlays.push(map.addAnnotation(loadMarker));
              });
            }
          });
        });
      } else {
        ongoingDumpEvents?.forEach((truck) => {
          truck.allLoads.forEach((dumpPoint) => {
            dumpPoint.loads.forEach((dumpEvent) => {
              temp.push({ lat: dumpEvent.lat, lng: dumpEvent.lng });

              let { loadMarker } = createDumpEventMarker(
                new mapkit.Coordinate(dumpEvent.lat, dumpEvent.lng),
                truck?.name,
                dumpEvent,
                dumpPoint?.name,
                dumpEvent?.material,
                selectedtruck,
                materials,
                mapRef,
                zoom,
              );

              vehicleEventOverlays.push(map.addAnnotation(loadMarker));
            });
          });
        });
      }

      if (temp.length > 1 && key == true) {
        const bounds = new LatLngBounds(
          temp.map(({ lat, lng }) => new LatLng(lat, lng)),
        );
        const center = new mapkit.Coordinate(
          bounds.getCenter()?.lat,
          bounds.getCenter()?.lng,
        );
        map.setCenterAnimated(center);
      }
    }
  };

  useEffect(() => {
    vehicleStates(true);
  }, [setupCompleted, type, selectedtruck]);

  useEffect(() => {
    vehicleStates(false);
    if (zoom) {
      const mapCenter = new mapkit.Coordinate(-37.354439, 174.71803);
      const mapRegion = new mapkit.CoordinateRegion(
        mapCenter,
        new mapkit.CoordinateSpan(
          approximateRegionValue(zoom),
          approximateRegionValue(zoom),
        ),
      );
      map.cameraBoundary = mapRegion?.toMapRect();
    }
  }, [zoom]);

  useEffect(() => {
    if (selectedtruck?.fitScreen === true) {
      changeZoom(14);
      fitScreen();
    }
  }, [selectedtruck]);

  return (
    <>
      <section
        onMouseOver={(event) => {
          event.target.style.cursor = cursor;
        }}
        id={mapId}
        style={{ height: '100%', width: '100%' }}
      />
    </>
  );
};
