import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

const LegendDropDown = ({ data, align, style }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (isOpen) => {
    setIsOpen(isOpen);
  };

  return (
    <Dropdown
      show={isOpen}
      onToggle={handleToggle}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
      className=""
    >
      <Dropdown.Toggle
        className=""
        align={{ lg: 'start' }}
        style={{
          background: isOpen
            ? style?.backgroundColor
              ? style?.backgroundColor
              : '#377FF2'
            : '#2B2D2F',
          height: '1.875rem',
          width: '7.5rem',
          borderRadius: '0.3333rem',
          border: isOpen
            ? style?.backgroundColor
              ? style?.backgroundColor
              : '1px solid white'
            : 'none',
        }}
        bsPrefix="custom-toggle"
        id=""
      >
        <div
          style={{
            color: 'white',
            font: ' normal normal 600  0.7rem/1.063 Open Sans',
          }}
        >
          Legend
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          borderRadius: '0.3rem',
          border: 0,
        }}
        className="p-0 pr-1 pb-1 pt-1"
        bsPrefix="dropdown-menu asm-legend-dropdown"
      >
        {data?.map((section, sectionIndex) => (
          <div key={sectionIndex}>
            {section?.title && (
              <Dropdown.Item className="my-0 py-0">
                <span style={{ fontWeight: 600, fontSize: '0.8rem' }}>
                  {section?.title}
                </span>{' '}
                <span
                  style={{
                    fontStyle: 'italic',
                    color: '#707070',
                    fontSize: '0.8rem',
                  }}
                >
                  {section?.subtitle}
                </span>
              </Dropdown.Item>
            )}
            {section?.legends?.map((legend, legendIndex) => (
              <Dropdown.Item
                className="d-flex align-items-center"
                style={{ lineHeight: 1 }}
                key={legendIndex}
                disabled
              >
                <div className="" style={{ marginRight: 10 }}>
                  {legend?.icon}
                </div>
                <div style={{ fontSize: '0.7rem', color: '#000' }}>
                  {legend?.label}
                </div>
              </Dropdown.Item>
            ))}
            {sectionIndex !== data.length - 1 && (
              <div className="d-flex justify-content-center">
                <hr
                  className="mt-2 mb-1"
                  style={{
                    color: 'black',
                    width: '80%',
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LegendDropDown;
