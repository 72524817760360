import React from 'react';
import { getSignalValue } from '../../AssetTracking/utils/helper';

const SignalBars = ({ Signal }) => {
  const Bars = [];
  const baseFontSize = 18;
  const signalRatio = getSignalValue(Signal);

  const barStyle = (height) => {
    return {
      width: '0.188rem', // Updated width to 0.188rem
      height: height,
      marginRight: '0.063rem',
    };
  };

  if (signalRatio > 0 && signalRatio < 1) {
    // All bars grey
    for (let i = 0; i < 5; i++) {
      const height = `${(7 + i * 3) / baseFontSize}rem`;
      const style = {
        ...barStyle(height),
        backgroundColor: '#707070',
      };
      Bars.push(<div key={i} style={style}></div>);
    }
  } else if (signalRatio >= 1 && signalRatio < 2) {
    // 1 gray bar, then grey
    for (let i = 0; i < 5; i++) {
      const height = `${(7 + i * 3) / baseFontSize}rem`;
      const style = {
        ...barStyle(height),
        backgroundColor: i === 0 ? '#3880F5' : '#707070',
      };
      Bars.push(<div key={i} style={style}></div>);
    }
  } else if (signalRatio >= 2 && signalRatio < 3) {
    // 2 gray bars, then grey
    for (let i = 0; i < 5; i++) {
      const height = `${(7 + i * 3) / baseFontSize}rem`;
      const style = {
        ...barStyle(height),
        backgroundColor: i < 2 ? '#3880F5' : '#707070',
      };
      Bars.push(<div key={i} style={style}></div>);
    }
  } else if (signalRatio >= 3 && signalRatio < 4) {
    // 3 gray bars, then grey
    for (let i = 0; i < 5; i++) {
      const height = `${(7 + i * 3) / baseFontSize}rem`;
      const style = {
        ...barStyle(height),
        backgroundColor: i < 3 ? '#3880F5' : '#707070',
      };
      Bars.push(<div key={i} style={style}></div>);
    }
  } else if (signalRatio >= 4 && signalRatio < 5) {
    // 4 gray bars, then grey
    for (let i = 0; i < 5; i++) {
      const height = `${(7 + i * 3) / baseFontSize}rem`;
      const style = {
        ...barStyle(height),
        backgroundColor: i < 4 ? '#3880F5' : '#707070',
      };
      Bars.push(<div key={i} style={style}></div>);
    }
  } else {
    // All bars gray
    for (let i = 0; i < 5; i++) {
      const height = `${(7 + i * 3) / baseFontSize}rem`;
      const style = {
        ...barStyle(height),
        backgroundColor: '#3880F5',
      };
      Bars.push(<div key={i} style={style}></div>);
    }
  }

  return (
    <div className="d-flex justify-content-center align-items-end">{Bars}</div>
  );
};

export default SignalBars;
