import moment from 'moment';
import ReactDOM from 'react-dom';

export const EndPopup = ({ data }) => {
  const div = document.createElement('div');
  const popup = (
    <div>
      <div
        className="end_popup "
        style={{ position: 'relative', border: '2px solid #e87c6d' }}
      >
        <img
          style={{
            height: '1.5rem',
            width: '1.5rem',
            position: 'absolute',
            right: '-0.6rem',
            top: '-0.6rem',
          }}
          src="./assets/images/close.svg"
        />

        <div style={{ lineHeight: 1 }}>
          <div
            className="row m-0 p-0 py-1 w-100 d-flex"
            style={{ alignItems: 'start' }}
          >
            <div style={{ color: '#707070' }} className="col-4">
              End:
            </div>
            <div style={{ fontWeight: 600 }} className="col-8">
              <span>{moment(data?.timestamp * 1000).format('hh:mm a')}</span>
              <br></br>
              <span>{moment(data?.timestamp * 1000).format('DD/MM/yyyy')}</span>
            </div>
          </div>
          <div className="row m-0 p-0 py-1 w-100  d-flex">
            <div style={{ color: '#707070' }} className="col-4">
              Duration:
            </div>
            <div className="col-8" style={{ fontWeight: 600 }}>
              {data?.activity_duration}
            </div>
          </div>
          <div className="row m-0 p-0 py-1 w-100  d-flex">
            <div style={{ color: '#707070' }} className="col-4">
              Distance:
            </div>
            <div className="col-8" style={{ fontWeight: 600 }}>
              {data?.activity_distance}
            </div>
          </div>
        </div>
        {data?.align === 'left' ? (
          <div className="asm_popup-tip-container_end_popup_border_left" />
        ) : (
          <div className="asm_popup-tip-container_end_popup_border_right" />
        )}
        {data?.align === 'left' ? (
          <div className="asm_popup-tip-container_end_popup_left" />
        ) : (
          <div className="asm_popup-tip-container_end_popup_right" />
        )}
      </div>
    </div>
  );

  ReactDOM.render(popup, div);
  return div;
};
