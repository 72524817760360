// @ts-nocheck
import moment from 'moment';
import { PDFDocument } from 'pdf-lib';
import { useContext, useEffect, useState, ChangeEvent } from 'react';
import { Image } from 'react-bootstrap';
import SubHeader from '../pages/components/SubHeader';
import * as toast from '../pages/components/toast';
import { API_METHODS, ENDPOINTS } from '../pages/utils/constants';
import { UserContext } from '../pages/service/userContext';
import { withAccessControl } from '../pages/service/with-access-control';
import CategoryWidget from './Components/CategoryWidget';
import PdfModal from './Components/PdfModal';
import SummaryWidget from './Components/SummaryWidget';
import Report1 from './Components/reports/report-1';
import Summary from './Summary';
import TableComponent from './Components/Table/TableComponent';
import ArrowDown from './assets/icons/arrow-white.svg';
import { arrayofPreCheckEvaluationResult } from '../pages/utils/helper';
import { PreCheckEvaluationResult, SortBy } from './models/enums';
import { getCategoryName, getDeptname, getFailedCategories } from './helper';
import { PreStartCheck } from '../pages/utils/types';
// import { forEach } from "lodash";
import {
  InitialPrestartcheckDefectCountType,
  initialPrestartcheckDefectCount,
} from './helper/constants';
import { values } from 'lodash';
import { SelectedPrestartModal } from './helper/types';

const PreStartChecks = () => {
  const { ApiHandler, departments, licenses } = useContext(UserContext);
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.DEPARTMENT);
  const [rowData, setRowData] = useState<SelectedPrestartModal>({
    showModal: false,
  });
  const [showCharts, setShowCharts] = useState(true);

  const allDepartments: number[] = departments?.map(
    (department) => department.code,
  );

  const allCategories: number[] = licenses?.map((l) => {
    return l.code;
  });

  const [deptType, setDeptType] = useState(allDepartments);
  const [categoryType, setCategoryType] = useState(allCategories);

  const [statusType, setStatusType] = useState<PreCheckEvaluationResult[]>(
    arrayofPreCheckEvaluationResult,
  );
  const [summaryModal, setSummayModal] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [loading, setLoading] = useState(false);
  const [reApplyFilter, setReApplyFilter] = useState(1);
  const [selectedDate, setSelectedDate] = useState(
    moment().format('yyyy-MM-DD'),
  );
  const [preStartData, setPreStartData] = useState<PrestartCheckForTable[]>([]);
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState<InitialPrestartcheckDefectCountType>({
    ...initialPrestartcheckDefectCount,
  });
  const [categoryData, setCategoryData] = useState<string[]>([]);
  const handleSortChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSortBy(event.target.value);
  };

  const handleChange = async (
    prestartChecks: PreStartCheck[],
    source: boolean,
  ) => {
    const summaryCounts = {
      [PreCheckEvaluationResult.CATEGORY_A_FAILURE]: 0,
      [PreCheckEvaluationResult.CATEGORY_B_FAILURE]: 0,
      [PreCheckEvaluationResult.CATEGORY_C_FAILURE]: 0,
      [PreCheckEvaluationResult.PASS]: 0,
      [PreCheckEvaluationResult.INCOMPLETE]: 0,
    };

    const prestartCountForCategory: {
      name: string;
      data: typeof initialPrestartcheckDefectCount;
    }[] = [];
    const updatedPrestartchecks = prestartChecks.map((prestartcheck) => {
      summaryCounts[prestartcheck.status] += 1;
      const categoryName = getCategoryName(prestartcheck.truck?.type, licenses);

      const categoryIndex = prestartCountForCategory.findIndex(
        (category) => category.name === categoryName,
      );
      // If the category doesn't exist, create it and add to prestartCountForCategory
      if (categoryIndex === -1) {
        prestartCountForCategory.push({
          name: categoryName,
          data: { ...initialPrestartcheckDefectCount },
        });
      }

      // Update data in prestartCountForCategory
      const updatedCategoryIndex = prestartCountForCategory.findIndex(
        (cat) => cat.name === categoryName,
      );
      prestartCountForCategory[updatedCategoryIndex].data[
        prestartcheck.status
      ]++;

      const prestartcheckDepartment: { name: string; code: number } =
        getDeptname(prestartcheck?.truck?.type, departments, licenses);
      const defectCategories: string = getFailedCategories(
        prestartcheck.checkpoints,
      );
      return {
        ...prestartcheck,
        assetID: prestartcheck.truck?.name,
        failedChecks: defectCategories,
        supervisorSignature: prestartcheck.supervisor?.signedName,
        department: prestartcheckDepartment.name,
        deptCode: prestartcheckDepartment.code,
        category: getCategoryName(prestartcheck.truck?.type, licenses),
      };
    });

    const updatedCategoryData: {
      data: number[];
      name: string;
    }[] = prestartCountForCategory.map((category) => ({
      ...category,
      data: values(category.data),
    }));
    setSummary(summaryCounts);
    setCategoryData(updatedCategoryData);

    if (!source) {
      setPreStartData(updatedPrestartchecks);
      setData(updatedPrestartchecks);
    }
  };

  const fetch = async (applyFilter) => {
    try {
      const response = await ApiHandler({
        endPoint: ENDPOINTS.getPrestartChecks,
        method: API_METHODS.PUT,
        reqParam: {
          date: selectedDate,
        },
      });
      await handleChange(response.data);
      if (applyFilter) {
        setReApplyFilter(reApplyFilter + 1);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    }
  };

  const filterStatusData = (data) => {
    if (statusType && statusType.length > 0) {
      const filteredData = data.filter((d) => statusType?.includes(d.status));
      return filteredData;
    } else {
      return data;
    }
  };

  const filterCategoryData = (data) => {
    if (categoryType && categoryType.length > 0) {
      const filteredData = data.filter(
        (d) => categoryType?.includes(d?.truck?.type),
      );
      return filteredData;
    } else {
      return data;
    }
  };

  const filterDeptData = (data) => {
    if (deptType && deptType.length > 0) {
      const filteredData = data.filter((d) => deptType?.includes(d?.deptCode));
      return filteredData;
    } else {
      return data;
    }
  };

  const filterData = (data, searchQuery) => {
    return data.filter(
      (p) =>
        p.assetID.includes(searchQuery) ||
        (p.operator?.first_name + ' ' + p.operator?.last_name)
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()),
    );
  };

  useEffect(() => {
    const filteredData = filterData(
      filterCategoryData(filterDeptData(filterStatusData(data))),
      searchQuery,
    );

    const handleChangeAsync = async (data) => {
      await handleChange(data, true);
    };

    handleChangeAsync(filteredData);
  }, [deptType, statusType, categoryType, searchQuery, reApplyFilter]);

  useEffect(() => {
    const filteredData = filterData(
      filterCategoryData(filterDeptData(filterStatusData(data))),
      searchQuery,
    );

    setPreStartData(filteredData);
  }, [deptType, statusType, data, searchQuery, categoryType]);

  useEffect(() => {
    setData([]);
    setPreStartData([]);
    fetch(true);
    setCategoryData([]);
  }, [selectedDate]);

  const onCreate = async (id) => {
    try {
      setLoading(true);
      const response = await ApiHandler({
        endPoint: ENDPOINTS.getReportPdf,
        method: API_METHODS.PUT,
        reqParam: {
          date: selectedDate,
          id: id,
        },
        blobType: true,
      });

      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
      setLoading(false);
    }
  };

  const onSummaryDownload = async (id) => {
    try {
      setLoading(true);
      const response = await ApiHandler({
        endPoint: ENDPOINTS.getsummarypdf,
        method: API_METHODS.PUT,
        reqParam: {
          date: selectedDate,
          dept: deptType,
          category: categoryType,
          status: statusType,
          search: searchQuery,
          sortBy: sortBy,
        },
        blobType: true,
      });
      const pdfBytes = response?.data;
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const lastPageIndex = pdfDoc.getPageCount() - 1;
      pdfDoc.removePage(lastPageIndex);
      const modifiedPdfBytes = await pdfDoc.save();
      const url = window.URL.createObjectURL(new Blob([modifiedPdfBytes]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'summary.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.message);
    }
  };

  return (
    <div style={{ height: '92vh', overflow: 'hidden' }}>
      <SubHeader
        hideSearch
        SubHeaderVisible={true}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        onDeptChange={(v) => {
          setDeptType(v);
        }}
        onCategoryChange={(v) => {
          setCategoryType(v);
        }}
        showDownload
        onDownloadClick={() => {
          setSummayModal(true);
        }}
        status
        onStatusChange={(v) => {
          setStatusType(v);
        }}
        date
        department
        category
        outerClassName="mb-2"
      />
      {showCharts && (
        <div className="d-flex overflow-scroll ">
          <div className="flex-grow-0 ">
            <SummaryWidget summary={summary} showLabel={false} />
          </div>
          <div className="flex-grow-1">
            <CategoryWidget
              closeWidgets={() => {
                setShowCharts(false);
              }}
              data={categoryData}
            />
          </div>
        </div>
      )}
      {!showCharts && (
        <div
          className="w-100 widget-container px-3 d-flex justify-content-between align-items-center p-0"
          style={{
            height: '45px',
            background: '#F7F7F7 0% 0% no-repeat padding-box',
          }}
          onClick={() => setShowCharts(true)}
        >
          <p className="text-2 fw-bold">VIEW SUMMARY</p>
          <div
            className="d-flex me-1 align-items-center justify-content-center"
            style={{
              width: '22px',
              height: '16px',
              backgroundColor: '#2B2D2F',
            }}
          >
            <Image className="" src={ArrowDown} alt="down" />
          </div>
        </div>
      )}
      {rowData.showModal && (
        <PdfModal
          onDownload={() => {
            onCreate(rowData._id);
          }}
          closeModal={() =>
            setRowData({
              showModal: false,
            })
          }
          loading={loading}
        >
          <div className="report-outer-view">
            <Report1 id={rowData._id} date={selectedDate} fetchParent={fetch} />
          </div>
        </PdfModal>
      )}
      {summaryModal && (
        <PdfModal
          onDownload={() => {
            onSummaryDownload('print-summary');
          }}
          loading={loading}
          closeModal={() => setSummayModal(false)}
        >
          <div className="report-outer-view">
            <Summary
              selectedDate={selectedDate}
              searchq={searchQuery}
              dept={deptType}
              sortBy={sortBy}
              category={categoryType}
              status={statusType}
            ></Summary>
          </div>
        </PdfModal>
      )}

      <div className="align-padding">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center me-3 ">
              <input
                type="radio"
                value="department"
                className="radio-sort"
                checked={sortBy === 'department'}
                onChange={handleSortChange}
              />
              <div style={{ color: '#092344' }} className="ms-2 text-1">
                Sort by department
              </div>
            </div>
            <div className="d-flex align-items-center">
              <input
                type="radio"
                value="category"
                className="radio-sort"
                checked={sortBy === 'category'}
                onChange={handleSortChange}
              />
              <div style={{ color: '#092344' }} className="ms-2 text-1">
                Sort by category
              </div>
            </div>
          </div>
          <div className="mb-2">
            {' '}
            <SubHeader
              onSearchChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              SubHeaderVisible={true}
            />
          </div>
        </div>

        <TableComponent
          onRowClick={(_id: string) => {
            setRowData({ _id, showModal: true });
          }}
          sortBy={sortBy}
          prestartChecks={[...preStartData]}
          showCharts={showCharts}
          selectedPrestartId={rowData._id}
        />
      </div>
    </div>
  );
};

export default withAccessControl(PreStartChecks);
