import moment from 'moment';
import { useContext } from 'react';
import { UserContext } from '../pages/service/userContext';
import UserModal from './UserModal';
import StatusDot from '../pages/components/StatusDot';
import {
  convertCodeListToNames,
  convertUserStatusToColor,
} from '../pages/utils/helper';
import { UserType, userType } from '../pages/utils/constants';

const UserDetail = ({
  modalType,
  modalToggle,
  selectedUser,
  handleDropdownOptions,
}) => {
  const { departments, licenses } = useContext(UserContext);
  return (
    <div>
      <UserModal
        closeButton
        hideHeaderButton={true}
        selectedUser={selectedUser}
        modalOpen={modalType === 'view'}
        modalToggle={modalToggle}
        headerText="Edit"
        handleDropdownOptions={handleDropdownOptions}
      >
        <div className="d-flex flex-column user-detail-body">
          <hr />
          <div className="mt-3">
            <label className="user-label">User Name:</label>
            <span className="user-detail-text">
              {`${selectedUser.first_name} ${selectedUser.last_name}`}
            </span>
          </div>
          <div className="d-flex flex-row">
            <label className="user-label">Status:</label>
            <StatusDot
              className={convertUserStatusToColor(selectedUser.status)}
            >
              <span className="user-detail-text">{selectedUser.status}</span>
            </StatusDot>
          </div>
          <div>
            <label className="user-label">User Type:</label>
            <span className="user-detail-text">
              {userType[selectedUser.user_type]}
            </span>
          </div>
          {selectedUser.user_type === UserType.admin.value ? null : (
            <div>
              <label className="user-label">Department:</label>
              <span className="user-detail-text">
                {convertCodeListToNames(
                  departments.filter(({ is_restricted }) => is_restricted),
                  selectedUser.departments,
                )}
              </span>
            </div>
          )}
          <div>
            {selectedUser.pin_number && (
              <>
                <label className="user-label">Pin Number:</label>{' '}
                <span className="user-detail-text">
                  {selectedUser.pin_number}
                </span>
              </>
            )}
          </div>
          <div>
            {selectedUser.email && (
              <>
                <label className="user-label">Email:</label>{' '}
                <span className="user-detail-text">{selectedUser.email}</span>
              </>
            )}
          </div>

          {selectedUser.user_type === UserType.admin.value ? null : (
            <div>
              <label className="user-label">Equipment License:</label>
              <span className="user-detail-text">
                {convertCodeListToNames(licenses, selectedUser.licenses)}
              </span>
            </div>
          )}

          <div>
            {selectedUser.added_by && (
              <>
                <label className="user-label">Added By:</label>
                <span className="user-detail-text">
                  {`${selectedUser.added_by.first_name} ${
                    selectedUser.added_by.last_name
                  }, ${moment(selectedUser.added_by.added_at).format(
                    'DD/MM/YYYY',
                  )}`}
                </span>
              </>
            )}
          </div>
        </div>
      </UserModal>
    </div>
  );
};

export default UserDetail;
