import { useEffect, useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import { BsVectorPen } from 'react-icons/bs';

import { RiArrowDropDownLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import ConfirmationPopup from './ConfirmationPopup';
import { FetchDumpPointsData } from './DumpPointsFetching';
import History from './History/History';
import { AppleMap } from './Map/AppleMap';
import MaterialBreakdown from './MaterialBreakdown';
import MessagePopup from './MessagePopup';
import PolygonInfo from './PolygonInfo';
import AllPolygons from './SourcePointsModal/AllPolygons';
import TruckLoadsModal from './SourcePointsModal/TruckLoadsModal';
import {
  GET_DUMP_EVENTS,
  GET_MATERIALS,
  GET_SOURCE_DUMPS,
  SAVE_SOURCE_DUMP,
} from './action/apiPath';
import minusIcon from './assets/svgs/minus.svg';
import plusIcon from './assets/svgs/plus.svg';
import AuthLayout from './components/AuthLayout';
import Button from './components/Button';
import { CallWithAuth } from '../assetTracking/action/apiActions';

const SOURCE_DUMP_TEMPLATE = {
  name: '',
  description: '',
  coordinates: [],
  materials: [],
};

const materials = [
  {
    material: 'Material',
    loads: 50,
  },
  {
    material: 'Material',
    loads: 50,
  },
  {
    material: 'Material',
    loads: 50,
  },
  {
    material: 'Material',
    loads: 50,
  },
  {
    material: 'Material',
    loads: 50,
  },
];
const MP_TEMPLATE = {
  name: '',
  description: '',
  coordinates: [],
  materials: [],
};

const PolygonComponent = () => {
  const dumpPointsData = FetchDumpPointsData();
  const {
    setIsDetailPageOpen,
    triggerFetch,
    setSelectedDumpPoint,
    setpointType,

    setSelectedVehicle,
  } = dumpPointsData;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    if (token) localStorage.setItem('accessToken', token);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [requiredMesage, setRequiredMessage] = useState('');
  const [showCreateSourcePointModal, setShowCreateSourcePointModal] =
    useState(false);
  const [selectedtruck, setSelectedTruck] = useState({});
  const [created, setCreated] = useState(false);
  const [dumpPoint, setDumpPoint] = useState(MP_TEMPLATE);
  const [toggledropdown, setToggleDropDown] = useState(false);
  const [renderId, setRenderId] = useState(false);
  const [zoom, setZoom] = useState(false);
  const [type, setType] = useState('source');
  const [polygons, setPolygons] = useState([]);
  const [clearPolygons, setClearPolygons] = useState(false);
  const [selectedPolygon, setSelectedPolygon] = useState({
    coordinates: [],
    name: '',
  });

  useEffect(() => {
    if (!showCreateSourcePointModal) {
      setCreated(false);
      setDumpPoint(MP_TEMPLATE);
    }
  }, [showCreateSourcePointModal]);

  const handleCursor = (key) => {
    const htmlElement = document.getElementById('polygon');

    if (key === 1) {
      htmlElement.classList.add('mm-custom-cursor');
    } else if (key === 2) {
      htmlElement.classList.remove('mm-custom-cursor');
    }
  };
  const [popupConfirmation, setPopupConfirmation] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [showtruckModal, setShowTruckModal] = useState(false);
  const [showSourcePointsModal, setshowSourcePointsModal] = useState(false);
  const [showhistoryModal, setshowhistoryModal] = useState(false);
  const mapRef = useRef(undefined);
  const [showVehiclesModal, setShowVehiclesModal] = useState(false);
  const [messagefooter, setMessageFooter] = useState('');
  const [material, setMaterial] = useState([]);
  const [isDumpPoints, setIsDumpPoints] = useState(true);
  const [showEditButton, setShowEditButton] = useState(false);
  const [showSourcePointsButton, setshowSourcePointsButton] = useState(false);
  const [closeEditModal, setCloseEditModal] = useState(false);

  const [point, setPoint] = useState({
    coordinate: {
      lat: 0.0,
      lng: 0.0,
    },
    index: 0,
  });
  const [mode, setMode] = useState('');
  const [tableState, setTableState] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  const submitHandler = async (values) => {
    console.log(values?.materials?.length, 'values?.materials?.length ');
    if (!values.name || values?.materials?.length === 0 || !values?.materials) {
      // setPopupMessage('Fill in all fields of the form.');
      setRequiredMessage('*Please, fill up all the obligatory fields');
      return;
    }
    try {
      values.type = type;
      const response = await CallWithAuth('POST', SAVE_SOURCE_DUMP, values);
      if (response.res.status === 201) {
        handleCursor(2);
        // triggerFetch();
        setShowCreateSourcePointModal(false);
        setPopupMessage(
          `${
            type === 'source' ? 'Source' : 'Dump'
          } Point created successfully.`,
        );
        fetchDumpEvents();
        fetchPolygon();
      } else throw response.res;
    } catch (error) {
      // console.log(error.message);
      toast.error('Server Error', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  const [ongoingDumpEvents, setongoingDumpEvents] = useState([]);

  const fetchDumpEvents = async () => {
    const response = await CallWithAuth('GET', GET_DUMP_EVENTS);
    if (response?.res?.data?.status === 200) {
      setongoingDumpEvents(response?.res?.data?.data[0]);
    } else throw response?.res;
  };

  useEffect(() => {
    if (isDumpPoints) {
      setSelectedVehicle({});
    } else {
      setSelectedDumpPoint({});
    }
  }, [isDumpPoints]);
  const fetchPolygon = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      let response;
      if (type === 'source') {
        response = await CallWithAuth(
          'GET',
          `${GET_SOURCE_DUMPS}?type=${type}&status=${
            tableState ? 'Active' : 'Inactive'
          }`,
        );
      } else {
        response = await CallWithAuth(
          'GET',
          `${GET_DUMP_EVENTS}?status=${tableState ? 'Active' : 'Inactive'}`,
        );
      }

      if (response?.res?.data?.status === 200) {
        if (type === 'source') {
          setPolygons(response?.res?.data?.data);
        } else {
          setPolygons(response?.res?.data?.data[0]);
        }
        triggerFetch();
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw error;
    }
  };
  useEffect(() => {
    fetchDumpEvents();
    fetchPolygon();
  }, [tableState, type]);

  const deletePolygon = async (pl) => {};

  const fetchMaterials = async () => {
    const response = await CallWithAuth('GET', GET_MATERIALS);
    if (response?.res?.data?.status === 200) {
      setMaterial(response?.res?.data?.data);
    } else throw response?.res;
  };
  useEffect(() => {
    fetchMaterials();
  }, []);

  return (
    <AuthLayout pageName="Source & Dump Points">
      <div
        className="mm-top_header-1 mx-2 px-1"
        style={{
          paddingBottom: '',
          position: '-webkit-sticky',
          background: 'white',
        }}
      >
        <div className="mm-top_heading">
          <div className="d-flex">
            <Button
              img={'source-icon.svg'}
              onClick={() => {
                if (isLoading) {
                  return;
                }
                setDumpPoint({
                  name: '',
                  description: '',
                  coordinates: [],
                  materials: [],
                });
                setshowhistoryModal(false);
                setType('source');
                setRequiredMessage('');
                setShowTruckModal(false);
                setShowCreateSourcePointModal(false);
                setshowSourcePointsModal(false);
                setshowSourcePointsButton(false);
                setSearchQuery('');
                setPolygons([]);
                handleCursor(2);
                setSelectedPolygon({});
              }}
              disabled={isLoading}
              title={'Source Points'}
              style={{
                font:
                  type === 'source'
                    ? ' normal normal bold 12px/18px Open Sans'
                    : 'normal normal normal 12px/18px Open Sans',
                marginLeft: '0px',

                background:
                  type === 'source'
                    ? '#05795F'
                    : isLoading
                    ? '#2B2D2FAA'
                    : '#2B2D2F',
              }}
            />
            <Button
              img={'dump-icon.svg'}
              onClick={() => {
                if (isLoading) {
                  return;
                }
                setDumpPoint({
                  name: '',
                  description: '',
                  coordinates: [],
                  materials: [],
                });
                setshowhistoryModal(false);
                setRequiredMessage('');
                setType('dump');
                setShowTruckModal(false);
                setShowCreateSourcePointModal(false);
                setshowSourcePointsModal(false);
                setshowSourcePointsButton(false);
                setSearchQuery('');
                setPolygons([]);
                handleCursor(2);
                setSelectedPolygon({});
              }}
              title={'Dump Points'}
              disabled={isLoading}
              style={{
                background:
                  type === 'dump'
                    ? '#05795F'
                    : isLoading
                    ? '#2B2D2FAA'
                    : '#2B2D2F',
                font:
                  type === 'dump'
                    ? ' normal normal bold 12px/18px Open Sans'
                    : 'normal normal normal 12px/18px Open Sans',

                marginLeft: '20px',
              }}
            />
            <Button
              onClick={() => {
                setDumpPoint({
                  name: '',
                  description: '',
                  coordinates: [],
                  materials: [],
                });
                setshowhistoryModal(true);
                setRequiredMessage('');
                setType('history');
                setShowTruckModal(false);
                setShowCreateSourcePointModal(false);
                setshowSourcePointsModal(false);
                setshowSourcePointsButton(false);
                setSearchQuery('');
                setPolygons([]);
                handleCursor(2);
                setSelectedPolygon({});
              }}
              title={'History'}
              style={{
                background: type === 'history' ? '#05795F' : '#2B2D2F',
                color: type === 'history' ? '#FFF' : 'rgb(229, 229, 231)',
                font:
                  type === 'history'
                    ? ' normal normal bold 12px/18px Open Sans'
                    : 'normal normal normal 12px/18px Open Sans',
                marginLeft: '20px',
              }}
              img={type === 'history' ? 'history.svg' : 'historyu.svg'}
            ></Button>
          </div>
        </div>
      </div>

      <div className="mm-right_sidebar_body">
        <div
          style={{
            alignItems: 'center',
            backgroundColor: '#DDDDDD',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflow: 'none',
            height: '100%',
            objectFit: 'cover',
            position: 'relative',
          }}
        >
          {type !== 'history' ? (
            <AppleMap
              ongoingDumpEvents={ongoingDumpEvents}
              setongoingDumpEvents={setongoingDumpEvents}
              selectedtruck={selectedtruck}
              createMode={showCreateSourcePointModal}
              type={type}
              materials={material}
              created={created}
              polygon={polygons}
              mapRef={mapRef}
              mode={mode}
              selectedPoint={point}
              setSelectPoint={(v) => {
                setMode('edit');
                setPoint({ ...v, mode: 'edit' });
              }}
              selectedPoly={selectedPolygon}
              onSelectPoly={(poly) => {
                setSelectedPolygon(poly);
              }}
              fitScreen={() => {
                mapRef.current._impl.zoomLevel = 1;
              }}
              zoom={zoom}
              changeZoom={setZoom}
              clearPolygons={clearPolygons}
            />
          ) : null}

          <div
            className="col-lg-12 col-md-12 col-sm-12 mx-4 "
            style={{
              alignSelf: 'flex-start',
              display: 'flex',
              padding: '0.9rem 0rem',
              font: 'normal normal 600 12px/18px Open Sans',
              color: '#000000',
              position: 'absolute',
              zIndex: 1,
            }}
          >
            {isDumpPoints ? (
              <>
                {type !== 'history' ? (
                  <div className="col-lg-12 row d-flex justify-content-space-between">
                    <div className="row   d-flex">
                      <div className="col-12 p-0 d-flex " style={{}}>
                        <div
                          className="mm-btn-main"
                          style={{
                            border:
                              showSourcePointsButton == false
                                ? ' '
                                : showSourcePointsModal
                                ? '1px solid white'
                                : '',

                            background:
                              showSourcePointsButton == false
                                ? '#FFFFFF' + ' 0% 0% no-repeat padding-box'
                                : showSourcePointsModal
                                ? 'black' + ' 0% 0% no-repeat padding-box'
                                : '#FFFFFF' + ' 0% 0% no-repeat padding-box',

                            color:
                              showSourcePointsButton == false
                                ? 'black '
                                : showSourcePointsModal
                                ? 'white'
                                : 'black',
                          }}
                          onClick={() => {
                            setRequiredMessage('');
                            setshowSourcePointsModal(false);
                            setshowSourcePointsModal(true);
                            setshowSourcePointsButton(true);
                            setShowCreateSourcePointModal(false);
                            setShowTruckModal(false);
                            handleCursor(2);
                            setShowEditButton(false);
                            setTableState(true);
                            setCloseEditModal((prev) => !prev);
                          }}
                        >
                          {type === 'source'
                            ? ' Show All Source Points'
                            : ' Show All Dump Points'}
                        </div>

                        {type === 'dump' ? (
                          <div
                            className="mm-btn-main"
                            style={{
                              border: showtruckModal ? '1px solid white' : '',

                              background: showtruckModal
                                ? 'black' + ' 0% 0% no-repeat padding-box'
                                : '#FFFFFF' + ' 0% 0% no-repeat padding-box',

                              color: showtruckModal ? 'white' : 'black',

                              marginLeft: '0.85rem',
                            }}
                            onClick={() => {
                              setRequiredMessage('');
                              setShowTruckModal(true);
                              setShowCreateSourcePointModal(false);
                              setshowSourcePointsModal(false);
                              setshowSourcePointsButton(false);
                              setSelectedPolygon({});
                              handleCursor(2);
                              setShowEditButton(false);
                            }}
                          >
                            {'Show Trucks Loads'}
                          </div>
                        ) : null}

                        <div
                          className="mm-btn-main"
                          style={{
                            border:
                              showCreateSourcePointModal || showEditButton
                                ? '1px solid white'
                                : '',
                            background:
                              showCreateSourcePointModal || showEditButton
                                ? 'black' + ' 0% 0% no-repeat padding-box'
                                : '#FFFFFF' + ' 0% 0% no-repeat padding-box',
                            color:
                              showCreateSourcePointModal || showEditButton
                                ? 'white'
                                : 'black',
                            marginLeft: '0.9rem',
                          }}
                          onClick={() => {
                            setRequiredMessage('');
                            setTableState(true);
                            setShowCreateSourcePointModal(true);
                            setshowSourcePointsModal(false);
                            setshowSourcePointsButton(false);
                            setShowTruckModal(false);
                            handleCursor(1);
                            !tableState && setPolygons([]);
                            setSelectedPolygon({});
                          }}
                        >
                          <BsVectorPen style={{ marginRight: '0.2rem' }} />

                          {type === 'source'
                            ? ' Create Source Point'
                            : ' Create Dump Point'}
                        </div>
                      </div>
                      {type !== 'history' ? (
                        <div className="col-lg-3 ">
                          <div
                            style={{
                              font: 'normal normal 600 12px/27px Open Sans',
                              height: '2.5rem',
                              width: 'max-content',
                              padding: '0.5rem 0px',
                              background:
                                '#FFFFFF' + ' 0% 0% no-repeat padding-box',
                              boxShadow: '0px 3px 4px #00000029',
                              borderTopLeftRadius: toggledropdown
                                ? '0.85rem'
                                : '4px',
                              borderTopRightRadius: toggledropdown
                                ? '0.85rem'
                                : '4px',
                              color: 'black',
                              // borderRadius: toggledropdown ? "" : "8px",
                              borderBottomRightRadius: toggledropdown
                                ? ''
                                : '4px',
                              borderBottomLeftRadius: toggledropdown
                                ? ''
                                : '4px',
                              cursor: 'pointer',
                              marginLeft: '10px',
                              position: 'absolute',
                              right: '20px',
                              top: '15px',
                            }}
                            className="mx-3 px-1"
                          >
                            {/* <FcFactoryBreakdown color='white' style={{ marginRight: '0.5rem', marginLeft: '0.9rem', }} /> */}

                            {toggledropdown ? (
                              <>
                                <div
                                  style={{ height: '100%' }}
                                  className="d-flex align-items-center "
                                >
                                  <Image
                                    style={{
                                      marginRight: '0.5rem',
                                      marginLeft: '0.9rem',
                                      height: '0.9rem',
                                    }}
                                    src={
                                      './assets/images/material_breakdown.svg'
                                    }
                                  />
                                  <div style={{ fontSize: '0.9rem' }}>
                                    {' '}
                                    MATERIAL BREAKDOWN{' '}
                                  </div>

                                  <RiArrowDropDownLine
                                    onClick={() =>
                                      setToggleDropDown(!toggledropdown)
                                    }
                                    size={27}
                                    style={{
                                      marginRight: '0.9rem',
                                      marginLeft: '2rem',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </div>

                                <MaterialBreakdown
                                  ongoingDumpEvents={ongoingDumpEvents}
                                  materials={materials}
                                />
                              </>
                            ) : (
                              <>
                                <div
                                  style={{ height: '100%' }}
                                  className="d-flex align-items-center "
                                >
                                  <Image
                                    style={{
                                      marginRight: '0.5rem',
                                      marginLeft: '0.9rem',
                                      height: '0.9rem',
                                    }}
                                    src={
                                      './assets/images/material_breakdown.svg'
                                    }
                                  />
                                  <div style={{ fontSize: '0.9rem' }}>
                                    {' '}
                                    MATERIAL BREAKDOWN{' '}
                                  </div>

                                  <RiArrowDropDownLine
                                    onClick={() =>
                                      setToggleDropDown(!toggledropdown)
                                    }
                                    size={27}
                                    style={{
                                      marginRight: '0.9rem',
                                      marginLeft: '2rem',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              <div
                style={{
                  width: 'max-content',
                  padding: '8px 20px',
                  background: showVehiclesModal
                    ? '#E3EEFF'
                    : '#FFFFFF' + ' 0% 0% no-repeat padding-box',
                  // background: '#FFFFFF 0% 0% no-repeat padding-box',
                  boxShadow: '0px 3px 4px #00000029',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
                onClick={() => setShowVehiclesModal(true)}
              >
                Show Vehicles
              </div>
            )}
          </div>
          {type !== 'history' ? (
            <>
              <div
                style={{
                  bottom: 40,
                  marginBottom: '2rem',
                  position: 'absolute',
                  right: 20,
                  backgroundColor: 'white',
                  borderRadius: '4px ',
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    width: 37,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 44,
                  }}
                  onClick={(e) => {
                    e.preventDefault();

                    let counter = 0;
                    let x;

                    x = setInterval(() => {
                      mapRef.current._impl.zoomLevel += 0.05;
                      counter += 0.05;
                      if (counter >= 1) {
                        clearInterval(x);
                      }
                    }, 20);

                    setZoom(mapRef.current._impl.zoomLevel);
                  }}
                >
                  <img
                    src={plusIcon}
                    style={{ width: 25, height: 25 }}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    borderTop: '1px solid black',
                    cursor: 'pointer',
                    width: 37,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 43,
                  }}
                  onClick={(e) => {
                    e.preventDefault();

                    let counter = 0;
                    let x;

                    x = setInterval(() => {
                      mapRef.current._impl.zoomLevel -= 0.05;
                      counter += 0.05;
                      if (counter >= 1) {
                        clearInterval(x);
                      }
                    }, 20);
                    setZoom(mapRef.current._impl.zoomLevel);
                  }}
                >
                  <img
                    src={minusIcon}
                    style={{ width: 15, height: 15 }}
                    alt=""
                  />
                </div>
              </div>
              <div
                style={{
                  bottom: 40,
                  marginBottom: '2rem',
                  position: 'absolute',
                  right: 75,
                  backgroundColor: 'white',
                  borderRadius: '4px ',
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    // width: 37,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 30,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    mapRef.current._impl.zoomLevel = 1;
                    setZoom(1);
                  }}
                >
                  <div style={{ fontSize: '12px' }} className="p-2 px-2">
                    Fit Screen
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {showSourcePointsModal && (
            <AllPolygons
              setPolygons={setPolygons}
              fetchPolygon={fetchPolygon}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              triggerFetch={fetchPolygon}
              close={() => {
                setshowSourcePointsModal(false);
                setshowSourcePointsButton(false);
              }}
              setRequiredMessage={setRequiredMessage}
              setPopupConfirmation={setPopupConfirmation}
              setPopupMessage={setPopupMessage}
              type={type}
              tableState={tableState}
              setTableState={setTableState}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              data={polygons}
              onSelect={setSelectedPolygon}
              selectedPoly={selectedPolygon}
              materials={material}
              clearPolygons={() => setClearPolygons(!clearPolygons)}
              pointSelected={point}
              setSelectPoint={({ coordinate, index }) => {
                setPoint({ coordinate: coordinate, index: index });
              }}
              onShowEditModal={(v) => {
                setshowSourcePointsButton(false);
                console.log(v);
                setShowEditButton(v);
              }}
              closeEditModal={closeEditModal}
            />
          )}

          {showtruckModal && (
            <TruckLoadsModal
              type={type}
              selectedtruck={selectedtruck}
              setSelectedTruck={setSelectedTruck}
              setPopupMessage={setPopupMessage}
              setPopupConfirmation={setPopupConfirmation}
              close={() => setShowTruckModal(false)}
              materials={material}
            />
          )}
          {showCreateSourcePointModal && (
            <PolygonInfo
              onSubmit={submitHandler}
              setRequiredMessage={setRequiredMessage}
              requiredMesage={requiredMesage}
              type={type}
              created={created}
              setDumpPoint={setDumpPoint}
              dumpPoint={dumpPoint}
              close={() => setShowCreateSourcePointModal(false)}
              //////////////x
              setSelectedPolygon={setSelectedPolygon}
              polygon={selectedPolygon}
              onChange={(v) => {
                console.log(v);
                setSelectedPolygon(v);
              }}
              clearPolygon={() => setClearPolygons(!clearPolygons)}
              onDelete={deletePolygon}
              setMode={setMode}
              pointSelected={point}
              setSelectPoint={({ coordinate, index }) => {
                setPoint({ coordinate: coordinate, index: index });
              }}
              handleCursor={(v) => {
                handleCursor(v);
              }}
            />
          )}

          {showhistoryModal ? <History /> : null}
        </div>
        {popupConfirmation && <ConfirmationPopup {...popupConfirmation} />}
        {popupMessage && (
          <MessagePopup
            message={popupMessage}
            messagefooter={messagefooter}
            setMessageFooter={setMessageFooter}
            setPopupMessage={setPopupMessage}
          />
        )}
      </div>
    </AuthLayout>
  );
};

export default PolygonComponent;
