import React from 'react';

interface SvgIconProps {
  width?: string | number;
  height?: string | number;
}

const IdleTimeSVG: React.FC<SvgIconProps> = ({ width = 15, height = 15 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 132.244 132.244"
  >
    <g
      id="Group_70078"
      data-name="Group 70078"
      transform="translate(15.43 -2.096)"
    >
      <g id="clock_4052107" transform="translate(-15.43 2.096)">
        <path
          id="Path_34974"
          data-name="Path 34974"
          d="M67.122,1A65.766,65.766,0,0,0,1,67.122a65.766,65.766,0,0,0,66.122,66.122,65.766,65.766,0,0,0,66.122-66.122A65.766,65.766,0,0,0,67.122,1Zm23,82.8a5.556,5.556,0,0,1-8.05.575l-18.4-14.949a7.1,7.1,0,0,1-2.3-4.6v-32.2a5.762,5.762,0,0,1,5.75-5.75,5.432,5.432,0,0,1,5.75,5.75v29.9l16.1,13.224a5.682,5.682,0,0,1,1.15,8.05Z"
          transform="translate(-1 -1)"
          fill="#4e4949"
        />
      </g>
    </g>
  </svg>
);

export default IdleTimeSVG;
