import React from 'react';

interface IconProps {
  height?: string | number;
  width?: string | number;
  color?: string;
}

const ParkedSVG: React.FC<IconProps> = ({
  height = '11',
  width = '11',
  color = 'currentColor',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 11 11"
    fill={color}
  >
    <path
      id="parking-svgrepo-com"
      d="M9.821,32H1.179A1.179,1.179,0,0,0,0,33.179v8.643A1.179,1.179,0,0,0,1.179,43H9.821A1.179,1.179,0,0,0,11,41.821V33.179A1.179,1.179,0,0,0,9.821,32ZM5.893,39.071H4.714V40.25a.394.394,0,0,1-.393.393H3.536a.394.394,0,0,1-.393-.393v-5.5a.394.394,0,0,1,.393-.393H5.893a2.357,2.357,0,0,1,0,4.714Zm0-3.143H4.714V37.5H5.893a.786.786,0,0,0,0-1.571Z"
      transform="translate(0 -32)"
    />
  </svg>
);

export default ParkedSVG;
