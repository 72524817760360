import { Modal } from 'react-bootstrap';
import TimeSelect from '../TimeSelect';
import '../styles.scss';
import { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import TimeFilter from './SettingsComponent/TimeFilter';
import BasicButton from '../BasicButton';
import {
  GET_HISTORY_DATA_SETTINGS,
  SAVE_HISTORY_DATA_SETTINGS,
} from './../../../../action/apiPath';
import { CallWithAuth } from './../../../../action/apiActions';
import LoadingScreen from '../../../../component/LoadingScreen';
import { UserContext } from '../../../../../pages/service/userContext';
const SettingsModal = (props) => {
  const { modalOpen, modalToggle } = props;
  const { minIdleTime, setMinIdleTime } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState(minIdleTime);
  const [value, setValue] = useState({
    hour: '',
    minutes: '',
    seconds: '',
  });

  function convertToSeconds(value) {
    let { hour = '0', minutes = '0', seconds = '0' } = value ?? {};
    if (hour === '') {
      hour = '0';
    }
    if (minutes === '') {
      minutes = '0';
    }
    if (seconds === '') {
      seconds = '0';
    }

    const totalSeconds =
      parseInt(hour, 10) * 3600 +
      parseInt(minutes, 10) * 60 +
      parseInt(seconds, 10);
    return totalSeconds;
  }

  function convertToTime(seconds) {
    const hour = Math.floor(seconds / 3600);
    const remainingSecondsAfterHours = seconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const remainingSeconds = remainingSecondsAfterHours % 60;

    // Function to pad single digits with leading zeros
    const padWithZero = (num) => {
      return num < 10 ? '0' + num : num.toString();
    };

    // Convert values to strings and pad with zeros if necessary
    const hourStr = padWithZero(hour);
    const minutesStr = padWithZero(minutes);
    const secondsStr = padWithZero(remainingSeconds);

    return {
      hour: hour.toString(),
      minutes: minutes.toString(),
      seconds: remainingSeconds.toString(),
    };
  }

  // useEffect(() => {
  //   const tempDigitValue = convertToTime(value);
  //   setDigitValue(tempDigitValue);
  // }, [value]);
  const onApply = () => {
    ApplySettings();
  };

  const ApplySettings = async () => {
    try {
      setLoading(true);
      const response = await CallWithAuth('POST', SAVE_HISTORY_DATA_SETTINGS, {
        min_idle_time: filterData,
      });
      await getSettings();
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      toast.error(error);
    }
  };
  const getSettings = async () => {
    try {
      const response = await CallWithAuth('GET', GET_HISTORY_DATA_SETTINGS);
      setFilterData(response?.res?.data?.data?.min_idle_time);
      setMinIdleTime(response?.res?.data?.data?.min_idle_time);
      const tempIdleTime = convertToTime(
        response?.res?.data?.data?.min_idle_time,
      );
      setValue(tempIdleTime);
    } catch (error) {
      console.log('error', error);
      toast.error(error);
    }
  };
  useEffect(() => {
    getSettings();
  }, []);

  return (
    <div className="test-class" style={{ borderRadius: '0px 0px 0px 0px' }}>
      {modalOpen && (
        <Modal
          show={modalOpen}
          onHide={modalToggle}
          // backdrop="static"
          backdropClassName={'asm-settings-modal-backgrop-style'}
          keyboard={false}
          size="lg"
          dialogClassName="asm-settings-modal-position"
          className="asm-settings-modal-transparent fixed"
        >
          <Modal.Header>
            <div className="d-flex justify-content-between w-100 align-items-center ms-4 me-2  font-13 mb-3">
              <b
                className="ps-0 px-3 mb-4"
                style={{ font: 'normal normal 600 1rem/1.375rem Open Sans' }}
              >
                Settings
              </b>
            </div>
            <p
              style={{
                borderBottom: '0.063rem solid #EBEBEB',
                position: 'fixed',
                width: '23.5rem',
                overflowX: 'visible',
                marginTop: '0.844rem',
              }}
            ></p>
          </Modal.Header>
          <Modal.Body>
            <div
              className="font-13 d-flex flex-column justify-content-between h-100 ms-4 me-5 my-3 font-13 mb-3"
              style={{
                font: 'normal normal 0.875rem/1.1875rem Open Sans',
                borderRadius: '0.625rem 0 0 0',
              }}
            >
              <div className="d-flex flex-column gap-2">
                <p className="font-weight-bold text-normal font-italic 0.75rem lh-17">
                  Account for idle time after…
                </p>
                <div className=" mb-1">
                  {loading ? (
                    <LoadingScreen />
                  ) : (
                    <TimeFilter
                      value={
                        value
                          ? value
                          : {
                              hour: '00',
                              minutes: '00',
                              seconds: '00',
                            }
                      }
                      setValue={setValue}
                      onChange={(value) => {
                        const seconds = convertToSeconds(value);
                        setFilterData(seconds);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ paddingLeft: '0rem' }}>
            <div
              className="d-flex align-items-center justify-content-between pt-3 p-4 px-3"
              style={{
                backgroundColor: '#FAFAFA',
                backgroundRepeat: 'no-repeat',
                padding: 'padding-box',
              }}
            >
              <div className=" d-flex">
                <div className=" px-4">
                  <BasicButton
                    className="px-4 btn-focus"
                    style={{
                      border: '0.0625rem solid black',
                      marginBottom: '1.25rem',
                      maxWidth: '5rem',
                    }}
                    onClick={() => modalToggle(false)}
                    variant="white"
                  >
                    <div
                      style={{
                        font: 'normal normal bold 0.75rem/1.063rem Open Sans',
                      }}
                    >
                      {' '}
                      Cancel
                    </div>
                  </BasicButton>
                </div>
                <div>
                  <BasicButton
                    className="px-4 btn-focus"
                    onClick={onApply}
                    variant="dark"
                    style={{ marginBottom: '1.25rem' }}
                  >
                    <div
                      style={{
                        font: 'normal normal bold 0.75rem/1.063rem Open Sans',
                      }}
                    >
                      {' '}
                      Apply
                    </div>
                  </BasicButton>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
export default SettingsModal;
