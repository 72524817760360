import React, { useState, useEffect, useRef } from 'react';

export default function TimeFilter({ onChange, value }) {
  const hourRef = useRef(null);
  const minutesRef = useRef(null);
  const secondsRef = useRef(null);
  const [digitValue, setDigitValue] = useState({
    hour: '',
    minutes: '',
    seconds: '',
  });
  useEffect(() => {
    setDigitValue({
      hour: value?.hour,
      minutes: value?.minutes,
      seconds: value?.seconds,
    });
  }, [value]);

  const renderTimeInput = (label, max, min, ref, nextInputRef) => (
    <div className="d-flex flex-column align-items-start ps-0">
      <span
        className="py-2"
        style={{
          marginLeft: '1rem',
          font: 'normal normal normal 0.75rem/1.063rem Open Sans',
          color: '#707070',
        }}
      >
        {label}
      </span>
      <input
        ref={ref}
        max={max}
        min={min}
        value={digitValue[label.toLowerCase()]}
        className="px-3"
        onChange={(e) => {
          const newValue = e.target.value;
          if (newValue.length === 2 && nextInputRef) {
            nextInputRef.current.focus();
          }
          if (
            (e.target.value &&
              label == 'Hour' &&
              (0 > e.target.value || e.target.value > 12)) ||
            (label == 'Minute' &&
              (0 > e.target.value || e.target.value > 59)) ||
            (label == 'Second' && (0 > e.target.value || e.target.value > 59))
          )
            return;
          setDigitValue((prev) => ({
            ...prev,
            [label.toLowerCase()]: newValue,
          }));
          onChange({
            ...digitValue,
            [label.toLowerCase()]: newValue,
          });
        }}
        type="number"
        style={{
          border: '0.0625rem solid #EBEBEB',
          borderRadius: '0.688rem',
          backgroundColor: 'white',
          width: '5.25rem',
          height: '3.563rem',
        }}
      />
    </div>
  );

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between pb-3 mt-1 pe-3">
        {renderTimeInput('Hour', 12, 0, hourRef, minutesRef)}
        <div className="px-3 pt-3 mt-3">:</div>
        {renderTimeInput('Minutes', 59, 0, minutesRef, secondsRef)}
        <div className="px-3 pt-3 mt-3">:</div>
        {renderTimeInput('Seconds', 59, 0, secondsRef)}
      </div>
    </div>
  );
}
