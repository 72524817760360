import React, { Dispatch, SetStateAction } from 'react';
import styles from './AssetList.module.css';
import { useAssetTrackingContext } from '../../pages/service/AssetTrackingContext';
import SignalBars from '../AssetTrackingLive/Signal/Signal';
import Button from '../../pages/components/Button/Button';
import { AssetStatus } from '../utils/constants';
import { Asset, Equipment } from '../models/models';

type AssetTableProps = {
  search: string;
  filteredAssets: Asset[];
  setFilteredAssets: React.Dispatch<React.SetStateAction<Asset[]>>;
  selectedStatus: string;
  setSelectedStatus: React.Dispatch<React.SetStateAction<string>>;
  showAssetTablePopUp: boolean;
  selectedAssets: string[];
  setSelectedAssets: Dispatch<SetStateAction<string[]>>;
};

type AssetTrackingContextType = {
  assetsData: Asset[];
  allEquipments: Equipment[];
};

export const getColorByStatus = (status: string | undefined) => {
  if (status === AssetStatus.Active || status === AssetStatus.Idling) {
    return '#8EE600';
  } else if (status === AssetStatus.Disconnected) {
    return '#ED3738';
  } else if (status === AssetStatus.Parked) {
    return '#CECECE';
  } else {
    return '#06273F';
  }
};

const AssetTable: React.FC<AssetTableProps> = ({
  search,
  filteredAssets,
  selectedStatus,
  setSelectedStatus,
  showAssetTablePopUp,
  selectedAssets,
  setSelectedAssets,
}) => {
  const { assetsData, allEquipments } =
    useAssetTrackingContext() as AssetTrackingContextType;

  let headers: { label: string; key: string | undefined; width: string }[] = [];

  if (showAssetTablePopUp) {
    headers = [
      { label: 'Asset ID', key: 'title', width: '20%' },
      { label: 'Category', key: 'Category', width: '20%' },
      { label: 'Trips', key: 'Trips Today', width: '30%' },
      { label: 'Distance', key: 'KM Today', width: '20%' },
      { label: 'Signal', key: 'Signal', width: '10%' },
    ];
  } else {
    headers = [
      { label: 'Asset ID', key: 'title', width: '10%' },
      { label: 'Category', key: 'Category', width: '10%' },
      { label: 'Trips (Today)', key: 'Trips Today', width: '10%' },
      { label: 'Distance (Today)', key: 'KM Today', width: '30%' },
      { label: 'Operator', key: 'Operator', width: '35%' },
      { label: 'Signal', key: 'Signal', width: '5%' },
    ];
  }

  const getCellValue = (row: Record<string, any>, key: string): any => {
    if (key === 'Operator') {
      return getOperator(row.title);
    } else if (key === 'Department') {
      return getDepartment(row.title);
    } else if (key === 'Signal') {
      return <SignalBars Signal={row?.notFound ? 0 : row?.Signal} />;
    }
    const value = key.split('.').reduce((acc: any, part: string) => {
      return acc && typeof acc === 'object' ? acc[part] : undefined;
    }, row);
    if (key === 'KM Today') {
      return Number(isNaN(value) ? 0 : value).toFixed(2);
    } else {
      return value;
    }
  };

  const getStatusData = (status: string) =>
    assetsData
      .filter((asset: Asset) =>
        asset.title.toLowerCase().includes(search.toLowerCase()),
      )
      .filter((asset: Asset) => status === '' || asset.Status === status);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedAssets(filteredAssets.map((asset) => asset.title));
    } else {
      setSelectedAssets([]);
    }
  };

  const handleRowClick = (title: string) => {
    if (selectedAssets.includes(title)) {
      setSelectedAssets(selectedAssets.filter((item) => item !== title));
    } else {
      setSelectedAssets([...selectedAssets, title]);
    }
  };

  const handleSelectItem = (
    e: React.ChangeEvent<HTMLInputElement>,
    title: string,
  ) => {
    e.stopPropagation(); // Prevent the row click event from firing when clicking directly on the checkbox
    if (e.target.checked) {
      setSelectedAssets([...selectedAssets, title]);
    } else {
      setSelectedAssets(selectedAssets.filter((item) => item !== title));
    }
  };

  const statusFilterButtons = [
    {
      title: `All (${getStatusData('').length})`,
      onClick: () => setSelectedStatus(''),
      active: selectedStatus === '',
      color: selectedStatus === '' ? getColorByStatus('') : 'transparent',
      style: {
        opacity: selectedStatus === '' ? 1 : 0.8,
        color: selectedStatus === '' ? '' : '#000000',
        border: selectedStatus === '' ? '' : '1px solid #E2E2E2',
      },
    },
    {
      title: `Active (${
        getStatusData(AssetStatus.Active).length +
        getStatusData(AssetStatus.Idling).length
      })`,
      onClick: () => setSelectedStatus(AssetStatus.Active),
      active: selectedStatus === AssetStatus.Active,
      color:
        selectedStatus === '' || selectedStatus === AssetStatus.Active
          ? getColorByStatus(AssetStatus.Active)
          : 'transparent',
      style: {
        opacity: selectedStatus === AssetStatus.Active ? 1 : 0.8,
        color:
          selectedStatus === '' || selectedStatus === AssetStatus.Active
            ? '#1A1A1A'
            : '#418810',
        border:
          selectedStatus === '' || selectedStatus === AssetStatus.Active
            ? ''
            : '1px solid #E2E2E2',
      },
    },
    {
      title: `Disconnected (${getStatusData(AssetStatus.Disconnected).length})`,
      onClick: () => setSelectedStatus(AssetStatus.Disconnected),
      active: selectedStatus === AssetStatus.Disconnected,
      color:
        selectedStatus === '' || selectedStatus === AssetStatus.Disconnected
          ? getColorByStatus(AssetStatus.Disconnected)
          : 'transparent',
      style: {
        opacity: selectedStatus === AssetStatus.Disconnected ? 1 : 0.8,
        color:
          selectedStatus === '' || selectedStatus === AssetStatus.Disconnected
            ? ''
            : '#EC3736',
        border:
          selectedStatus === '' || selectedStatus === AssetStatus.Disconnected
            ? ''
            : '1px solid #EC3736',
      },
    },
    {
      title: `Parked (${getStatusData(AssetStatus.Parked).length})`,
      onClick: () => setSelectedStatus(AssetStatus.Parked),
      active: selectedStatus === AssetStatus.Parked,
      color:
        selectedStatus === '' || selectedStatus === AssetStatus.Parked
          ? getColorByStatus(AssetStatus.Parked)
          : 'transparent',
      style: {
        opacity: selectedStatus === AssetStatus.Parked ? 1 : 0.8,
        color:
          selectedStatus === '' || selectedStatus === AssetStatus.Parked
            ? '#1A1A1A'
            : '#585858',
        border:
          selectedStatus === '' || selectedStatus === AssetStatus.Parked
            ? ''
            : '1px solid #E2E2E2',
      },
    },
  ];

  const getOperator = (title: string): string => {
    const equipment = allEquipments.find(
      (equipment) => equipment.name === title,
    );
    if (equipment?.operator) {
      return (
        equipment.operator.first_name +
        ' ' +
        (equipment.operator?.last_name ?? '')
      );
    }
    return '';
  };

  const getDepartment = (title: string): string => {
    const equipment = allEquipments.find(
      (equipment) => equipment.name === title,
    );
    if (equipment?.Department) {
      return equipment.Department;
    }
    return '';
  };

  return (
    <div className={styles.tableContainer}>
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>
              <input
                className={styles.checkbox}
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedAssets.length === filteredAssets.length}
              />
            </th>
            {headers.map((header, index) => {
              return (
                <th key={index} style={{ width: header.width }}>
                  {header.label}
                </th>
              );
            })}
          </tr>
        </thead>
      </table>
      <div className={styles.statusFilters}>
        {statusFilterButtons.map((button, index) => {
          return (
            <Button
              key={index}
              title={button.title}
              onClick={button.onClick}
              active={button.active}
              color={button.color}
              style={button.style}
            />
          );
        })}
      </div>
      <div
        className={styles.tableBodyContainer}
        style={{ height: showAssetTablePopUp ? 'calc(60vh)' : '' }}
      >
        <table>
          <tbody>
            {filteredAssets.map((row, index) => {
              const isChecked = selectedAssets.includes(row.title);

              return (
                <tr key={index} onClick={() => handleRowClick(row.title)}>
                  <td>
                    <input
                      className={styles.checkbox}
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e) => handleSelectItem(e, row.title)}
                    />
                  </td>
                  {headers.map((header, headerIndex) => (
                    <td key={headerIndex} style={{ width: header.width }}>
                      {header.key === 'title' && (
                        <span
                          className={styles.statusBall}
                          style={{
                            backgroundColor: getColorByStatus(row.Status),
                          }}
                        />
                      )}
                      {getCellValue(row, header.key ?? '')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AssetTable;
