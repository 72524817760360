import moment from 'moment';
import { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { UserContext } from '../../../../pages/service/userContext';
import { DropdownData } from '../DropdownData';
import BasicButton from './BasicButton';
import DropdownField from './DropdownField';
import './styles.scss';

const HistoryFilterModal = (props) => {
  const {
    modalOpen,
    modalToggle,
    filteredData = {},
    setFilteredData = () => {},
  } = props;
  const { userInfo } = useContext(UserContext);
  const [tempFilteredData, setTempFilteredData] = useState(filteredData);
  const queryParams = new URLSearchParams(window.location.search);
  const [assets, setAssets] = useState(
    userInfo?.licensesDropDownData?.options?.filter(({ code }) =>
      filteredData?.departments?.includes(code),
    ),
  );

  const onCheckboxClick = (fieldName, value, multiselect, isAll = false) => {
    if (isAll) {
      setTempFilteredData((prev) => ({ ...prev, [fieldName]: value ?? [] }));
    } else {
      if (multiselect) {
        const filters = { ...tempFilteredData };
        if (filters[fieldName]?.includes(value)) {
          filters[fieldName] = filters[fieldName].filter(
            (selectedValue) => selectedValue !== value,
          );
        } else {
          filters[fieldName] = [...(filters[fieldName] || []), value];
        }

        if (fieldName === 'departments') {
          const filteredCodes = userInfo?.licensesDropDownData?.options?.filter(
            ({ code }) => filters[fieldName]?.includes(code),
          );
          filters['asset_category'] = filteredCodes?.map(({ name }) => name);
          setAssets(filteredCodes);
        }
        setTempFilteredData(filters);
      } else {
        setTempFilteredData((prev) => ({ ...prev, [fieldName]: value }));
      }
    }
  };

  const clearFilter = () => {
    const resetData = {
      date: moment().format('YYYY-MM-DD'),
      searchQuery: queryParams.get('asset') ?? '',
      categoryType: DropdownData.categoryType.defaultValues,
      activityType: DropdownData.activityType.defaultValues,
      eventType: DropdownData.eventType.defaultValues,
      statusType: DropdownData.statusType.defaultValues,
      from: { hour: '00', minute: '00', second: '00', isAM: true },
      to: { hour: '11', minute: '59', second: '59', isAM: false },
      asset_category: userInfo?.licensesDropDownData?.defaultValues ?? [],
      departments: userInfo?.departMentsDropDownData?.defaultValues ?? [],
    };
    setTempFilteredData(resetData);
    setFilteredData(resetData);
  };

  const onApply = () => {
    setFilteredData(tempFilteredData);
    modalToggle();
  };

  return (
    <div className="test-class" style={{ borderRadius: '4px 0px 0px 0px' }}>
      {modalOpen && (
        <Modal
          show={modalOpen}
          onHide={modalToggle}
          // backdrop="static"
          backdropClassName={'asm-modal-backgrop-style'}
          keyboard={false}
          size="lg"
          dialogClassName="asm-filter-modal-position "
          className="modal-transparent"
        >
          <Modal.Header style={{ borderBottom: '1px solid #EBEBEB' }}>
            <div className="d-flex justify-content-between w-100 align-items-center ms-4 me-2 my-1 font-13">
              <b
                className="ps-0 px-3 mb-3"
                style={{ font: 'normal normal 600 16px/22px Open Sans' }}
              >
                FILTER BY
              </b>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div
              className="font-13 d-flex flex-column justify-content-between h-100 ms-4 me-2 my-3 font-13 mb-3"
              style={{
                font: 'normal normal 14px/19px Open Sans',
                borderRadius: '10px 0px 0px 0px',
                padding: '0rem 1rem',
              }}
            >
              <div className="d-flex flex-column gap-2">
                <div className=" mb-1">
                  <DropdownField
                    name={'Department'}
                    key={'departments'}
                    fieldKey={'departments'}
                    multiselect
                    style={{ fontWeight: 'normal' }}
                    selectedOptions={tempFilteredData['departments']}
                    allOptions={userInfo?.departMentsDropDownData?.options}
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    labelClass="ps-0"
                    handleChange={(e) =>
                      onCheckboxClick(
                        e?.fieldKey,
                        e?.optionValue,
                        e?.multiselect,
                        e?.isAll,
                      )
                    }
                  />
                </div>
                <div className="mb-1">
                  <DropdownField
                    name={'Asset category'}
                    key={'asset_category'}
                    fieldKey={'asset_category'}
                    multiselect
                    style={{ fontWeight: 'normal' }}
                    selectedOptions={tempFilteredData['asset_category']}
                    // allOptions={DropdownData.asset_category.options}
                    allOptions={assets}
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    labelClass="ps-0"
                    handleChange={(e) => {
                      onCheckboxClick(
                        e?.fieldKey,
                        e?.optionValue,
                        e?.multiselect,
                        e?.isAll,
                      );
                    }}
                  />
                </div>

                <div className="mb-1">
                  <DropdownField
                    name={'Status'}
                    key={'statusType'}
                    fieldKey={'statusType'}
                    multiselect
                    selectedOptions={tempFilteredData['statusType']}
                    allOptions={DropdownData.statusType.options}
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    labelClass="ps-0"
                    handleChange={(e) =>
                      onCheckboxClick(
                        e?.fieldKey,
                        e?.optionValue,
                        e?.multiselect,
                        e?.isAll,
                      )
                    }
                  />
                </div>

                <div className="mb-1">
                  <DropdownField
                    name={'Activity type'}
                    key={'activityType'}
                    fieldKey={'activityType'}
                    multiselect
                    style={{ fontWeight: 'normal' }}
                    selectedOptions={tempFilteredData['activityType']}
                    allOptions={DropdownData.activityType.options}
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    labelClass="ps-0"
                    handleChange={(e) =>
                      onCheckboxClick(
                        e?.fieldKey,
                        e?.optionValue,
                        e?.multiselect,
                        e?.isAll,
                      )
                    }
                  />
                </div>
                <div className="mb-1 pb-5">
                  <DropdownField
                    name={'Alerts'}
                    key={'eventType'}
                    fieldKey={'eventType'}
                    multiselect
                    style={{ fontWeight: 'normal' }}
                    selectedOptions={tempFilteredData['eventType']}
                    allOptions={DropdownData.eventType.options}
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    labelClass="ps-0"
                    handleChange={(e) =>
                      onCheckboxClick(
                        e?.fieldKey,
                        e?.optionValue,
                        e?.multiselect,
                        e?.isAll,
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <div
            className="d-flex align-items-center justify-content-between pt-3 p-4"
            style={{
              backgroundColor: '#FAFAFA',
              backgroundRepeat: 'no-repeat',
              padding: 'padding-box',
            }}
          >
            <div>
              <button
                className="border border-0 bg-transparent px-3 btn-focus"
                onClick={clearFilter}
                style={{ color: '#707070', marginBottom: '20px' }}
              >
                <u>Clear all</u>
              </button>
            </div>
            <div className=" d-flex">
              <div className=" px-3">
                <BasicButton
                  style={{ border: '1px solid black', marginBottom: '20px' }}
                  onClick={() => modalToggle(false)}
                  variant="white"
                >
                  <div style={{ fontSize: '0.8rem' }}> Cancel</div>
                </BasicButton>
              </div>
              <div>
                <BasicButton
                  className="px-4 btn-focus"
                  onClick={onApply}
                  variant="dark"
                  style={{ marginBottom: '20px' }}
                >
                  <div style={{ fontSize: '0.8rem' }}> Apply</div>
                </BasicButton>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default HistoryFilterModal;
