export enum Category {
  CATEGORY_A = 'Category A',
  CATEGORY_B = 'Category B',
  CATEGORY_C = 'Category C',
}

export enum CategoryDefect {
  CATEGORY_A_DEFECT = 'Cat A defects',
  CATEGORY_B_DEFECT = 'Cat B defects',
  CATEGORY_C_DEFECT = 'Cat C defects',
}

export enum PreCheckEvaluationResult {
  CATEGORY_A_FAILURE = 'cat a failure',
  CATEGORY_B_FAILURE = 'cat b failure',
  CATEGORY_C_FAILURE = 'cat c failure',
  PASS = 'passed',
  INCOMPLETE = 'incomplete',
}

export enum ExtraNoteDefect {
  EXTRA_NOTE_DEFECT = 'Defects and other notes',
}

export enum SortBy {
  DEPARTMENT = 'department',
  CATEGORY = 'category',
}
