import React, { CSSProperties, ReactNode } from 'react';
import styles from './Tooltip.module.css';

type TooltipProps = {
  tooltipContent: ReactNode;
  child?: ReactNode;
  toolTipStyle?: CSSProperties;
};

const Tooltip: React.FC<TooltipProps> = ({
  tooltipContent,
  child = '',
  toolTipStyle = {},
}) => {
  return (
    <div className={styles.tooltip}>
      {child}
      <span style={{ ...toolTipStyle }} className={styles.tooltipContent}>
        {tooltipContent}
        <div className={styles.triangle}></div>
      </span>
    </div>
  );
};

export default Tooltip;
