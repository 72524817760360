import { FunctionComponent } from 'react';
import { SelectedAsset, TruckType } from '../model';
import styles from './cards.module.css';
import DisplayCard from './displayCard';
import { useAssetContext } from '../../pages/service/assetContext';
import LoaderSpinner from '../assets/LoaderSpinner';
import useMediaQuery from '../../hooks/useMediaQuery';
import { maxHeightBreakPoint } from '../constants';

const TruckCard: FunctionComponent = () => {
  const { assets, isLoading, setSelectedAssets, selectedAssets } =
    useAssetContext();

  const handleTruckClick = (type: TruckType, name: string) => {
    if (type === TruckType.DMU) return;
    setSelectedAssets([{ name: name, type: type }]);
  };
  const { height } = useMediaQuery();

  return (
    <div
      className={styles.truckCardsContainer}
      style={{ justifyContent: assets.length > 4 ? '' : 'space-between' }}
    >
      {isLoading && (
        <LoaderSpinner
          containerHeight={height <= maxHeightBreakPoint ? 140 : 160}
          height={50}
          width={50}
        />
      )}
      {assets.map(
        (
          {
            calculatedEngineMinutes,
            type,
            status,
            currentSpeed,
            totalPushes,
            name,
            totalTonnesProcessed,
          },
          index,
        ) => {
          return (
            <DisplayCard
              key={index}
              handleTruckClick={handleTruckClick}
              status={status}
              type={type}
              totalTonnesProcessed={totalTonnesProcessed}
              totalPush={totalPushes}
              activeTime={calculatedEngineMinutes}
              speed={currentSpeed}
              name={name}
              isSelected={
                selectedAssets.findIndex(
                  (as: SelectedAsset) => as.name === name,
                ) !== -1
              }
            />
          );
        },
      )}
    </div>
  );
};

export default TruckCard;
