import React, { useEffect, useState, ReactNode } from 'react';
import styles from './WaterfallModal.module.css';
import { FaChevronRight } from 'react-icons/fa';

interface WaterfallModalProps {
  isOpen?: boolean;
  triggerContent: ReactNode;
  content: ReactNode;
  isDisabled?: boolean;
  contentContainerHeight?: string | number;
  onClick?: Function;
}

const WaterfallModal: React.FC<WaterfallModalProps> = ({
  isOpen = false,
  onClick,
  triggerContent,
  content,
  isDisabled = false,
  contentContainerHeight,
}) => {
  const [showContent, setShowContent] = useState<boolean>(isOpen);

  useEffect(() => {
    setShowContent(isOpen);
  }, [isOpen]);

  const toggleContent = () => {
    setShowContent((prev) => !prev);
  };

  return (
    <div className={styles.waterfallModal}>
      <div
        style={isDisabled ? { backgroundColor: '#bcbcbc' } : {}}
        className={`d-flex align-items-center ${styles.trigger}`}
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            toggleContent();
          }
        }}
      >
        <div
          style={{ userSelect: 'none' }}
          className="d-flex align-items-center w-100 justify-content-between"
        >
          {triggerContent}
          <span className="d-flex align-items-center justify-content-center">
            <FaChevronRight
              size={12}
              style={{
                transition: 'transform 0.5s ease',
                transform: `rotateZ(${showContent ? -90 : 90}deg)`,
              }}
            />
          </span>
        </div>
      </div>
      <div
        className={`${!showContent ? 'd-none' : 'px-3'}`}
        style={{
          overflowY: 'auto',
          maxHeight: contentContainerHeight ?? 'fit-content',
        }}
      >
        {content}
      </div>
    </div>
  );
};

export default WaterfallModal;
