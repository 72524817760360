/* eslint-disable no-console */
import React, { createContext, useState, useContext, ReactNode } from 'react';

type GlobalContextType = {
  showLiveAssetTracking: boolean;
  setShowLiveAssetTracking: React.Dispatch<React.SetStateAction<boolean>>;
};

const GlobalContext: React.Context<GlobalContextType | undefined> =
  createContext<GlobalContextType | undefined>(undefined);

export const useGlobalContext = (): GlobalContextType => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within an GlobalProvider');
  }
  return context;
};

export const GlobalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [showLiveAssetTracking, setShowLiveAssetTracking] =
    useState<boolean>(false);

  return (
    <GlobalContext.Provider
      value={{
        showLiveAssetTracking,
        setShowLiveAssetTracking,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
