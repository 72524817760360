import { makeAutoObservable } from 'mobx';
import { CallWithAuth } from '../action/apiActions';
import { ALL_EQUIPMENTS } from '../action/apiPath';

class Equipment {
  equipments = [];
  constructor() {
    makeAutoObservable(this);
  }
  fetch = async function (props) {
    if (!this.equipments.length) {
      try {
        const response = await CallWithAuth('GET', ALL_EQUIPMENTS, null);
        this.equipments = response?.res?.data?.data;
      } catch (err) {
        this.error = err;
      }
    }
  };
}

const EquipmentService = new Equipment();
export default EquipmentService;
