import React from 'react';

const LocationIconSVG = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1rem"
      height="1rem"
      viewBox="0 0 25.594 36.471"
    >
      <path
        id="Icon_material-location-on"
        data-name="Icon material-location-on"
        d="M20.047,3A12.538,12.538,0,0,0,7.5,15.547c0,9.41,12.547,23.3,12.547,23.3s12.547-13.891,12.547-23.3A12.538,12.538,0,0,0,20.047,3Zm0,17.028a4.481,4.481,0,1,1,4.481-4.481A4.483,4.483,0,0,1,20.047,20.028Z"
        transform="translate(-7.25 -2.75)"
        fill={color}
        stroke="#000"
        stroke-width="0.5"
      />
    </svg>
  );
};

export default LocationIconSVG;
