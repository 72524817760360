import { isUndefined } from 'lodash';
import { useState } from 'react';
import SearchIcon from '../../assets/SearchIcon';
import styles from './Search.module.css';

type Props = {
  query: string | number;
  onChange: (value: string | number) => void;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
};

const Search = ({
  query = '',
  onChange,
  containerStyle = {},
  inputStyle = {},
}: Props) => {
  const [searchStr, setSearchStr] = useState<string | number>(query);

  const handleOnChange = (value: string | number): void => {
    if (!isUndefined(value)) {
      setSearchStr(value);
      onChange(value);
    } else {
      setSearchStr('');
      onChange('');
    }
  };

  return (
    <div
      className={styles.container}
      style={{
        ...containerStyle,
      }}
    >
      <input
        className={styles.input}
        type="text"
        placeholder="Search"
        value={searchStr}
        onChange={(e) => handleOnChange(e.target.value)}
        style={{
          borderRadius: '15px',
          background: 'transparent',
          ...inputStyle,
        }}
      />
      <SearchIcon />
    </div>
  );
};

export default Search;
