import { LatLng, LatLngBounds } from 'leaflet';
import React, { useEffect, useState, useRef } from 'react';
import minusIcon from '../assets/svgs/minus.svg';
import plusIcon from '../assets/svgs/plus.svg';
import { TitlePopup } from './TitlePopup';
import TimelineGraphModal from '../Components/TimelineGraph/TimelineGraphModal';

const CAMERA_DISTANCE = 1300; // increasing will take view more farther from ground
const MAP_CENTER_LAT = -37.352101;
const MAP_CENTER_LNG = 174.709186;

let map;
let mapkit = undefined;

let markerAnnotations = [];
let currentassetsFlyout;
let polylineStyle;
let polylineBorderStyle;
var polylineOverlays = [];
var initialMarkers = [];
let currentMarker = null;
let currentMarkerAnnotation = null;
let selectedPolylineCoordinate = null;

export const Map = ({
  isTrip,
  selectedMarker = {},
  mapRef,
  markers = [],
  onMarkerClick = () => {},
  showDefaultMarkers = true,
  endpoints = [],
  onSelectMarker = () => {},
  isFullScreenMode = false,
  allCoordinates = [],
  showTimelineGraphToggle,
  showTimelineGraph,
}) => {
  const [selectedSpeed, setSelectedSpeed] = useState(null);
  const [speedUnit, setSpeedUnit] = useState('km/h');
  const [selectedTime, setSelectedTime] = useState(null);
  const [setupCompleted, setSetupCompleted] = useState(false);
  const [markersRendered, setMarkersRendered] = useState(false);
  const isFullScreenModeRef = useRef(isFullScreenMode);
  const allCoordinateRef = useRef(allCoordinates);
  useEffect(() => {
    allCoordinateRef.current = allCoordinates;
  }, [allCoordinates]);

  const handleAnnotation = (
    marker,
    coordinates,
    popup,
    type,
    selected,
    remove = false,
    showPopupInitially,
  ) => {
    const removeAnnotation = () => {
      if (currentassetsFlyout?.visible) {
        currentassetsFlyout.visible = false;
        map.removeAnnotation(currentassetsFlyout);
      }
    };

    const createAnnotation = (selected) => {
      removeAnnotation();
      const markerCoord = new mapkit.Coordinate(
        coordinates?.latitude,
        coordinates?.longitude,
      );
      let markerUrl = marker?.url;

      if (selected) {
        if (type === 'idle') {
          markerUrl = `./assets/images/selectedIdleMarker.svg`;
        } else if (type === 'speeding') {
          if (marker.maxSpeed < 40) {
            markerUrl = './assets/images/selected-trip-icon-speeding-30.svg';
          } else if (marker.maxSpeed >= 40 && marker.maxSpeed < 55) {
            markerUrl = './assets/images/selected-trip-icon-speeding-40.svg';
          } else {
            markerUrl = './assets/images/selected-trip-icon-speeding-55.svg';
          }
        }
      }

      const findDOMAnchorX = () => {
        if (!isFullScreenMode && type === 'end') {
          if (marker.align === 'left') {
            if (type === 'start') {
              return 127;
            } else {
              return 136;
            }
          } else {
            return 0;
          }
        } else if (!isFullScreenMode && type === 'start') {
          if (marker.align === 'left') {
            if (type === 'start') {
              return 127;
            } else {
              return 136;
            }
          } else {
            return 0;
          }
        } else if (
          !isFullScreenMode &&
          (type === 'idle' || type === 'speeding')
        ) {
          return -17;
        } else if (showPopupInitially) {
          if (marker.align === 'left') {
            if (type === 'start') {
              return 127;
            } else {
              return 136;
            }
          } else {
            return 0;
          }
        } else if (selected) {
          return -17;
        } else {
          return 0;
        }
      };

      const findDOMAnchorY = () => {
        if (!isFullScreenMode && type === 'end') {
          return 130;
        } else if (!isFullScreenMode && type === 'start') {
          return 110;
        } else if (
          !isFullScreenMode &&
          (type === 'idle' || type === 'speeding')
        ) {
          return 30;
        } else if (showPopupInitially) {
          if (type === 'end') {
            return 130;
          } else {
            return 110;
          }
        } else if (selected) {
          return 30;
        } else {
          return 50;
        }
      };

      const options = {
        title: 'title',
        data: markerUrl,
        visible: false,
        anchorOffset: new DOMPoint(findDOMAnchorX(), findDOMAnchorY()),
        clusteringIdentifier: null,
      };
      let annotation;
      switch (type) {
        case 'start':
        case 'park':
        case 'end':
        case 'speeding':
        case 'idle':
        case 'checkpoint':
          annotation = new mapkit.Annotation(markerCoord, popup, options);
          break;
        default:
          annotation = new mapkit.Annotation(markerCoord, TitlePopup, options);
      }

      // map.setCenterAnimated(markerCoord);
      map.addAnnotation(annotation);
      if (showPopupInitially) {
        initialMarkers.push(annotation);
      } else {
        currentassetsFlyout = annotation;
      }
      annotation.visible = true;
    };
    if (remove) {
      removeAnnotation();
    } else {
      createAnnotation(selected);
    }
  };
  const createMarker = ({ marker, onMarkerClick, isSelected }) => {
    const { coordinates, url, popup, type } = marker;
    const markerCoord = new mapkit.Coordinate(
      coordinates?.latitude,
      coordinates?.longitude,
    );

    let markerUrl;
    if (isSelected) {
      if (type === 'idle') {
        markerUrl = `./assets/images/selectedIdleMarker.svg`;
      } else if (type === 'speeding') {
        if (marker.maxSpeed < 40) {
          markerUrl = './assets/images/selected-trip-icon-speeding-30.svg';
        } else if (marker.maxSpeed >= 40 && marker.maxSpeed < 55) {
          markerUrl = './assets/images/selected-trip-icon-speeding-40.svg';
        } else {
          markerUrl = './assets/images/selected-trip-icon-speeding-55.svg';
        }
      }
    }

    const markerAnnotation = new mapkit.ImageAnnotation(markerCoord, {
      url: {
        1: markerUrl ?? url ?? `./assets/images/idle.svg`,
      },
      anchorOffset: new DOMPoint(0, 0),
    });
    let urlForIconBeforeSelection = marker.url;

    markerAnnotation.addEventListener(
      'invertMarkerColorWhenSelected',
      (event) => {
        markerAnnotation.url = {
          1:
            marker?.type === 'idle'
              ? `./assets/images/selectedIdleMarker.svg`
              : marker?.type === 'speeding'
              ? marker.maxSpeed < 40
                ? './assets/images/selected-trip-icon-speeding-30.svg'
                : marker.maxSpeed >= 40 && marker.maxSpeed < 55
                ? './assets/images/selected-trip-icon-speeding-40.svg'
                : './assets/images/selected-trip-icon-speeding-55.svg'
              : marker.url,
        };
      },
    );

    markerAnnotation.addEventListener(
      'invertMarkerColorWhenDeSelected',
      (event) => {
        markerAnnotation.url = {
          1: urlForIconBeforeSelection,
        };
      },
    );

    markerAnnotation.addEventListener('select', () => {
      onMarkerClick({ marker, select: true, fromMap: true });
      if (marker?.type === 'idle' || marker?.type === 'speeding') {
        currentMarkerAnnotation = markerAnnotation;
        const invertEvent = new CustomEvent(
          'invertMarkerColorWhenSelected',
          {},
        );
        markerAnnotation.dispatchEvent(invertEvent);
        handleAnnotation(marker, coordinates, popup, type, true, false, false);
      }
    });

    markerAnnotation.addEventListener('deselect', () => {
      onMarkerClick({ marker, select: false });
      if (marker?.type === 'idle' || marker?.type === 'speeding') {
        markerAnnotation.url = {
          1: urlForIconBeforeSelection,
        };
      }
      handleAnnotation(marker, coordinates, popup, type, true, true, false);
    });

    if (marker?.showPopupInitially && isFullScreenMode) {
      handleAnnotation(marker, coordinates, popup, type, false, false, true);
    } else if (isSelected) {
      handleAnnotation(marker, coordinates, popup, type, true, false, false);
      currentMarker = markerAnnotation;
    }

    markerAnnotations.push(markerAnnotation);
    map.addAnnotation(markerAnnotation);
  };

  const handleZoom = (delta) => {
    let counter = 0;
    const interval = setInterval(() => {
      mapRef.current._impl.zoomLevel += delta;
      counter += 0.05;
      if (counter >= 1) {
        clearInterval(interval);
      }
    }, 20);
  };

  const createGraphMarker = () => {
    const myCoord = allCoordinates.filter((a) => a.timestamp == selectedTime);
    const removeAnnotation = () => {
      if (selectedPolylineCoordinate) {
        selectedPolylineCoordinate.visible = false;
        map.removeAnnotation(selectedPolylineCoordinate);
      }
    };

    removeAnnotation();
    if (!myCoord?.length) {
      return;
    }
    setSelectedSpeed(myCoord[0].spd);
    const markerCoord = new mapkit.Coordinate(
      myCoord[0].latitude,
      myCoord[0].longitude,
    );

    const graphSelectionMarker = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style="stop-color:#8EB9FF;" />
        <stop offset="100%" style="stop-color:#004AC1;" />
      </linearGradient>
    </defs>
    <circle cx="8" cy="8" r="8" style="fill:url(#grad); opacity:1;" />
  </svg>`;

    selectedPolylineCoordinate = new mapkit.ImageAnnotation(markerCoord, {
      url: {
        1: `data:image/svg+xml;base64,${btoa(graphSelectionMarker)}`,
      },
      anchorOffset: new DOMPoint(0, 0),
    });
    map.addAnnotation(selectedPolylineCoordinate);
    selectedPolylineCoordinate.visible = true;
  };

  const setupMapKitJs = async () => {
    if (!window.mapkit || window.mapkit.loadedLibraries?.length === 0) {
      await new Promise((resolve) => {
        window.initMapKit = resolve;
      });
      delete window.initMapKit;
    }

    const jwt = process.env.REACT_APP_APPLE_MAP_TOKEN;
    window.mapkit.init({
      authorizationCallback: (done) => {
        done(jwt);
      },
      language: 'en',
    });
  };

  const setupMap = ({ mapRef }) => {
    const mapCenter = new mapkit.Coordinate(MAP_CENTER_LAT, MAP_CENTER_LNG);
    const mapRegion = new mapkit.CoordinateRegion(
      mapCenter,
      new mapkit.CoordinateSpan(0.06, 0.06),
    );
    map = new mapkit.Map('map-container', {
      center: mapCenter,
      mapType: mapkit.Map.MapTypes.Satellite,
      region: mapRegion,
      showsCompass: mapkit.FeatureVisibility.Hidden,
    });
    mapRef.current = map;
    map.cameraZoomRange = new mapkit.CameraZoomRange(100, 8000);
    map.cameraBoundary = mapRegion.toMapRect();
    map.cameraDistance = CAMERA_DISTANCE;
    map.addEventListener('single-tap', () => {
      if (currentassetsFlyout?.visible) {
        currentassetsFlyout.visible = false;
        map.removeAnnotation(currentassetsFlyout);
      }
      if (currentMarker != null) {
        map.removeAnnotation(currentMarker);
        currentMarker = null;
        onSelectMarker(null);
      }
      onMarkerClick({ marker: {}, select: false });
    });

    const tileOverlay = new mapkit.TileOverlay(
      (x, y, z, scale) => {
        return `/api/o/map-tile?x=${x}&y=${y}&z=${z}&scale=${scale}&image=large`;
      },
      {
        minimumZ: 0,
        maximumZ: 30,
        opacity: 1,
        data: {},
      },
    );
    map.addTileOverlay(tileOverlay);

    //   const tileOverlay1 = new mapkit.TileOverlay(
    //     (x, y, z, scale, image) => {
    //       return `/api/o/map-tile?x=${x}&y=${y}&z=${z}&scale=${scale}&image=small`;
    //     },
    //     {
    //       minimumZ: 0,
    //       maximumZ: 30,
    //       opacity: 1,
    //       data: {},
    //     },
    //   );
    //   map.addTileOverlay(tileOverlay1);
  };

  useEffect(() => {
    if (showDefaultMarkers && markersRendered && endpoints?.length) {
      for (let i = 0; i < endpoints.length; i++) {
        let marker = endpoints[i];
        handleAnnotation(
          marker,
          marker.coordinates,
          marker?.popup,
          marker?.type,
          true,
          false,
          false,
        );
      }
    }
  }, [showDefaultMarkers, markersRendered, endpoints]);

  const setUpMap = async () => {
    await setupMapKitJs();
    mapkit = window.mapkit;
    setupMap({ mapRef });
    polylineBorderStyle = new mapkit.Style({
      lineWidth: 3,
      strokeColor: '#000',
    });
    polylineStyle = new mapkit.Style({
      lineWidth: 1,
      strokeColor: '#faeb4f',
    });
    setSetupCompleted(true);
    return () => {
      delete window.initMapKit;
    };
  };

  useEffect(() => {
    setUpMap();
  }, []);

  useEffect(() => {
    createGraphMarker();
  }, [selectedTime]);

  useEffect(() => {
    if (!map) return;
    if (!setupCompleted) return;

    map.isZoomEnabled = true;
    map.isScrollEnabled = true;

    markerAnnotations.forEach((annotation) => {
      map.removeAnnotation(annotation);
      markerAnnotations = [];
    });

    polylineOverlays.forEach((overlay) => {
      map.removeOverlay(overlay);
    });

    initialMarkers.forEach((annotation) => {
      map.removeAnnotation(annotation);
      initialMarkers = [];
    });

    markers.forEach((marker, index) => {
      const { coordinates } = marker;
      if (coordinates?.latitude && coordinates?.longitude) {
        createMarker({ marker: marker, onMarkerClick: onMarkerClick });
      }
    });

    const boundCoordinates = allCoordinates?.map((coordinates) => {
      return new mapkit.Coordinate(
        coordinates?.latitude,
        coordinates?.longitude,
      );
    });

    if (boundCoordinates && boundCoordinates?.length > 0) {
      const north = Math.max(
        ...boundCoordinates.map((coord) => coord.latitude),
      );
      const south = Math.min(
        ...boundCoordinates.map((coord) => coord.latitude),
      );
      const east = Math.max(
        ...boundCoordinates.map((coord) => coord.longitude),
      );
      const west = Math.min(
        ...boundCoordinates.map((coord) => coord.longitude),
      );

      const centerLat = (north + south) / 2;
      const centerLng = (east + west) / 2;
      const center = new mapkit.Coordinate(centerLat, centerLng);

      const spanLat = Math.abs(north - south);
      const spanLng = Math.abs(east - west);
      const span = new mapkit.CoordinateSpan(spanLat, spanLng);

      const region = new mapkit.CoordinateRegion(center, span);

      // Set the region with animation
      map.setRegionAnimated(region);
    }

    const polylineCoordinates = [];
    allCoordinates?.forEach((myCoordinate, index) => {
      if (myCoordinate?.latitude && myCoordinate?.longitude) {
        const markerCoord = new mapkit.Coordinate(
          myCoordinate?.latitude,
          myCoordinate?.longitude,
        );
        polylineCoordinates?.push(markerCoord);
      }
    });

    if (polylineCoordinates?.length >= 2) {
      const polylineBorder = new mapkit.PolylineOverlay(polylineCoordinates, {
        style: polylineBorderStyle,
      });
      const overlayPolylineBorder = map.addOverlay(polylineBorder);
      polylineOverlays.push(overlayPolylineBorder);

      const polyline = new mapkit.PolylineOverlay(polylineCoordinates, {
        style: polylineStyle,
      });
      const overlay = map.addOverlay(polyline);
      polylineOverlays.push(overlay);
    }
  }, [setupCompleted, markers, isFullScreenMode]);

  useEffect(() => {
    // console.log({ selectedMarker });
    if (currentassetsFlyout?.visible) {
      currentassetsFlyout.visible = false;
      map.removeAnnotation(currentassetsFlyout);
    }
    if (currentMarker) {
      map.removeAnnotation(currentMarker);
      currentMarker = null;
    }
    initialMarkers.forEach((annotation) => {
      map.removeAnnotation(annotation);
      initialMarkers = [];
    });
    if (selectedMarker && Object.keys(selectedMarker).length > 0) {
      if (!selectedMarker.fromMap) {
        if (currentMarkerAnnotation) {
          const invertEvent = new CustomEvent(
            'invertMarkerColorWhenDeSelected',
            {},
          );
          currentMarkerAnnotation.dispatchEvent(invertEvent);
          currentMarkerAnnotation = null;
        }
      }
      createMarker({
        marker: selectedMarker,
        isSelected: !selectedMarker?.select ? false : true,
        onMarkerClick: onMarkerClick,
      });
      if (
        selectedMarker?.coordinates?.latitude &&
        selectedMarker?.coordinates?.longitude
      ) {
        const mapCenter = new mapkit.Coordinate(
          selectedMarker?.coordinates?.latitude,
          selectedMarker?.coordinates?.longitude,
        );
        // map.setCenterAnimated(mapCenter);
      }
    }
    setMarkersRendered(true);
  }, [selectedMarker]);

  useEffect(() => {
    isFullScreenModeRef.current = isFullScreenMode;
  }, [isFullScreenMode]);

  return (
    <>
      <section id="map-container" style={{ height: '100%', width: '100%' }} />

      {/* <div className='d-flex flex-column'> */}
      <div>
        {/* Zoom in/out buttons */}
        <div
          style={{
            bottom: '-1rem',
            marginBottom: '2rem',
            position: 'absolute',
            zIndex: 1,
            right: '1rem',
            backgroundColor: 'white',
            borderRadius: '0.3rem ',
          }}
        >
          <div
            className="d-flex align-items-center justify-content-center cursor-pointer p-2"
            onClick={() => handleZoom(0.05)}
          >
            <img
              src={plusIcon}
              alt="Zoom In"
              style={{ width: '1.2rem', height: '1.2rem' }}
            />
          </div>
          <div
            className="d-flex align-items-center justify-content-center cursor-pointer p-2"
            onClick={() => handleZoom(-0.05)}
          >
            <img
              src={minusIcon}
              alt="Zoom Out"
              style={{ width: '0.8rem', height: '0.8rem' }}
            />
          </div>
        </div>

        {/* Fit screen button */}
        <div
          style={{
            bottom: '-1rem',
            marginBottom: '2rem',
            marginRight: '0.8rem',
            position: 'absolute',
            zIndex: 1,
            right: '3.7rem',
            backgroundColor: 'white',
            borderRadius: '0.3rem ',
          }}
        >
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '2rem',
            }}
            onClick={(e) => {
              e.preventDefault();
              mapRef.current._impl.zoomLevel = 1;
            }}
          >
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: '0.75rem',
                height: '1.875rem',
                width: '6.25rem',
                borderRadius: '0.125rem',
              }}
            >
              Fit Screen
            </div>
          </div>
        </div>

        {/* Show Time Progression button */}
        {isFullScreenMode && !showTimelineGraph && isTrip && (
          <div
            style={{
              bottom: '-1.1rem',
              marginBottom: '2rem',
              position: 'absolute',
              zIndex: 1,
              right: '11.7rem',
              backgroundColor: 'white',
              borderRadius: '0.3rem ',
            }}
          >
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '2rem',
              }}
              onClick={() => showTimelineGraphToggle(true)}
            >
              <div
                className="d-flex justify-content-center align-items-center px-2"
                style={{
                  fontSize: '0.75rem',
                  height: '1.875rem',
                  width: 'fit-content',
                  borderRadius: '0.125rem',
                }}
              >
                Time Progression
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        {isFullScreenMode && showTimelineGraph && (
          <div>
            <TimelineGraphModal
              allCoordinates={allCoordinates}
              title="TIME PROGRESSION"
              modalOpen={showTimelineGraph}
              modalToggle={(v) => showTimelineGraphToggle(v)}
              selectedSpeed={selectedSpeed}
              speedUnit={speedUnit}
              selectedTime={selectedTime}
              setSelectedTime={(time) => setSelectedTime(time)}
            />
          </div>
        )}
      </div>
      {/* </div> */}
    </>
  );
};
