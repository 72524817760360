import moment from 'moment';
import { dayShiftData, nightShiftData } from './constants';

export const formatEpochTimeToNZ = (epochTime, isTimeFirst = true) => {
  const date = new Date(epochTime * 1000);
  const time = date.toLocaleTimeString('en-US', {
    timeZone: 'Pacific/Auckland',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  const dateString = date.toLocaleDateString('en-US', {
    timeZone: 'Pacific/Auckland',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  if (isTimeFirst) return `${time}`;
  else return `${dateString}, ${time}`;
};

export const formatData = (data, type, excavator) => {
  let graphData = type == 'Day' ? [...dayShiftData] : [...nightShiftData];
  let result = [];
  // console.log("result it", Object.keys(excavator?.hourlyData).length, graphData);
  if (
    (excavator?.hourlyData &&
      Object?.keys(excavator?.hourlyData)?.length === 0) ||
    !data
  ) {
    return graphData;
  }
  let last = 0;
  result = graphData?.map((key, i) => {
    if (data[key?.quarter] ? data[key?.quarter] : key?.earnings) last = i;
    return {
      quarter: key?.quarter?.toLowerCase(),
      earnings: data[key?.quarter] ? data[key?.quarter] : key?.earnings,
      last: false,
      label: data[key?.quarter] ? data[key?.quarter] : key?.earnings,
    };
  });
  result[last].last = true;
  return result;
};

export const getHourlyTarget = (productiveHours, targetLoads) => {
  // const startDate = new Date(startTime);
  // const endDate = new Date(endTime);

  // const hoursPerShift = new Date(endDate - startDate).getTime() / 3600000;
  // console.log('====================================');
  // console.log(endDate,startDate);
  // console.log('====================================');
  const targetPerHour = parseInt(targetLoads) / productiveHours;

  return Math.round(targetPerHour);
};

export const getAlert = (
  timeElapsed,
  productiveHours,
  actualLoads,
  targetLoads,
) => {
  const targetPerHour = getHourlyTarget(productiveHours, targetLoads);
  return (
    parseInt(actualLoads) <
      parseInt(targetPerHour) *
        Math.min(Math.floor(timeElapsed / 3600), productiveHours) &&
    parseInt(actualLoads) < parseInt(targetLoads)
  );
};

// export const getAlertHourly = (startTime, endTime, currLoads, hourlyTarget) => {
//   const date =
//     new Date(endTime).getTime() > utcToNZTime().date.getTime()
//       ? utcToNZTime().date
//       : new Date(new Date(endTime).getTime() - 60000);
//   if (hourlyTarget == 0) return false;
//   const timeSections = Math.round(60 / hourlyTarget);
//   date.setHours(date.getHours(), 0, 0, 0);
//   const timeElapsed = timeDiff(date);
//   return (
//     parseInt(currLoads ? currLoads : 0) <
//     Math.floor(Math.min(Math.floor(timeElapsed / 60), 60) / timeSections) &&
//     parseInt(currLoads ? currLoads : 0) < parseInt(hourlyTarget)
//   );
// };

export function utcToNZTime(utcTime = new Date().toUTCString()) {
  if (!utcTime) return '';
  const here = new Date(utcTime);
  const invdate = new Date(
    here.toLocaleString('en-US', {
      timeZone: 'Pacific/Auckland',
    }),
  );

  const diff = here.getTime() - invdate.getTime();
  const date = new Date(here.getTime() - diff);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  let formattedTime = `${hours % 12 || 12}:${
    minutes < 10 ? '0' : ''
  }${minutes}`;
  formattedTime += hours >= 12 ? ' PM' : ' AM';
  return { formattedTime, date };
}

export function timeDiff(nzTime) {
  const currentTime = utcToNZTime().date;
  // console.log(nzTime);
  const startingTime = new Date(nzTime);
  return (currentTime.getTime() - startingTime.getTime()) / 1000;
}

export function isCurrentBar(currTime, shiftEndTime) {
  const nzTime = utcToNZTime().date;
  const shiftEndDate = new Date(shiftEndTime);
  const [time, meridian] = currTime.split(' ');
  const barHours = Number(time) + (meridian === 'am' ? 0 : 12);
  if (
    nzTime.getHours() === barHours &&
    nzTime.getTime() <= shiftEndDate.getTime()
  ) {
    return true;
  } else {
    return false;
  }
}

export const isValidNumber = (str) => {
  const num = parseFloat(str);
  return !isNaN(num) && isFinite(num);
};

export function secondsToHhMm(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  if (hours > 0) {
    const hoursText = hours < 10 ? `0${hours}` : `${hours}`;
    const minutesText = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondsText =
      remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    return `${hoursText}:${minutesText}:${secondsText} hrs`;
  } else if (minutes > 0) {
    const minutesText = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondsText =
      remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    return `${minutesText}:${secondsText} mins`;
  } else if (remainingSeconds > 0) {
    const secondsText =
      remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    return `${secondsText} secs`;
  } else {
    return `00 hr`;
  }
}

export function getDurationOfCurrentDayInSeconds(
  endTime,
  startTime,
  currentDate,
) {
  const startMoment = moment.unix(startTime);
  const endMoment = moment.unix(endTime);

  // Set the time to 00:00:00 for the current date
  const currentDateStart = moment(currentDate).startOf('day');

  if (endMoment.isBefore(currentDateStart)) {
    return endTime - startTime; // Return duration in seconds
  }
  // If startMoment is before the currentDateStart, use currentDateStart as the start point
  const effectiveStartMoment = startMoment.isBefore(currentDateStart)
    ? currentDateStart
    : startMoment;

  // If endMoment is after the end of the current day, use the end of the current day as the end point
  const effectiveEndMoment = endMoment.isAfter(
    currentDateStart.clone().endOf('day'),
  )
    ? currentDateStart.clone().endOf('day')
    : endMoment;

  const duration = effectiveEndMoment.diff(effectiveStartMoment, 'seconds'); // Calculate duration in seconds

  return duration;
}

export function getDurationOfCurrentDayInHHMMSS(
  endTime,
  startTime,
  currentDate,
) {
  const startMoment = moment.unix(startTime);
  const endMoment = moment.unix(endTime);

  // Set the time to 00:00:00 for the current date
  const currentDateStart = moment(currentDate).startOf('day');

  if (endMoment.isBefore(currentDateStart)) {
    return moment
      .utc(moment.duration(endTime - startTime, 'seconds').asMilliseconds())
      .format('HH:mm:ss');
  }
  // If startMoment is before the currentDateStart, use currentDateStart as the start point
  const effectiveStartMoment = startMoment.isBefore(currentDateStart)
    ? currentDateStart
    : startMoment;

  // If endMoment is after the end of the current day, use the end of the current day as the end point
  const effectiveEndMoment = endMoment.isAfter(
    currentDateStart.clone().endOf('day'),
  )
    ? currentDateStart.clone().endOf('day')
    : endMoment;

  const duration = moment.duration(
    effectiveEndMoment.diff(effectiveStartMoment),
  );

  // Format the duration as HH:MM:SS
  const formattedDuration = moment
    .utc(duration.asMilliseconds())
    .format('HH:mm:ss');

  return formattedDuration;
}

export function calculateDistance(lat1, lon1, lat2, lon2) {
  const earthRadius = 6371; // Radius of the Earth in kilometers

  // Convert latitude and longitude from degrees to radians
  const radLat1 = (Math.PI * lat1) / 180;
  const radLon1 = (Math.PI * lon1) / 180;
  const radLat2 = (Math.PI * lat2) / 180;
  const radLon2 = (Math.PI * lon2) / 180;

  // Haversine formula
  const dLat = radLat2 - radLat1;
  const dLon = radLon2 - radLon1;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(radLat1) *
      Math.cos(radLat2) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Calculate the distance in kilometers
  const distance = earthRadius * c;
  return parseFloat(distance?.toFixed(1));
}

export function getDayStartTime(date) {
  const inputDate = new Date(date); // Use your desired date
  inputDate.setHours(0, 0, 0, 0);
  return Math.floor(inputDate.getTime() / 1000);
}

export function getDayEndTime(date) {
  const inputDate = new Date(date); // Use your desired date
  inputDate.setHours(23, 59, 59, 59);
  return Math.floor(inputDate.getTime() / 1000);
}

export function addMetersToLatLon(lat, lon, distance) {
  const earthRadius = 6378137; // Earth's radius in meters

  // Convert latitude and longitude from degrees to radians
  const latRad = (lat * Math.PI) / 180;
  const lonRad = (lon * Math.PI) / 180;

  // Calculate the new latitude
  const newLat = lat + (distance / earthRadius) * (180 / Math.PI);

  // Calculate the new longitude
  const newLon =
    lon + ((distance / earthRadius) * (180 / Math.PI)) / Math.cos(latRad);

  return {
    latitude: newLat,
    longitude: newLon,
  };
}

export const getDegFromSpeed = (value) => {
  const inputMin = 0;
  const inputMax = 110;
  const outputMin = -45;
  const outputMax = 225;

  if (isNaN(value) || value < inputMin) {
    return outputMin;
  } else if (value > inputMax) {
    return outputMax;
  }

  const mappedValue =
    outputMin +
    ((value - inputMin) * (outputMax - outputMin)) / (inputMax - inputMin);

  return mappedValue;
};

export const padNumber = (value, digit) => {
  // Round the value
  const roundedValue = Math.round(value);

  // Convert the rounded value to a string and pad with zeroes
  const paddedValue = roundedValue.toString().padStart(digit, '0');

  return paddedValue;
};

export const roundOffHHMMSSToHours = (timeString) => {
  const timeParts = timeString.match(/(\d{2}):(\d{2}):(\d{2})/);

  if (!timeParts) {
    return 0;
  }

  // Convert the captured parts to integers
  const hours = parseInt(timeParts[1], 10);
  const minutes = parseInt(timeParts[2], 10);
  const seconds = parseInt(timeParts[3], 10);

  // Calculate the total time in hours
  const totalHours = hours + minutes / 60 + seconds / 3600;

  // Return the rounded-off value
  return Math.round(totalHours);
};

export const findTripIdleTime = (idleMoments, minIdleTime) => {
  let idleSeconds = 0;
  if (!idleMoments.length) {
    return idleSeconds;
  }
  const dayStart = getDayStartTime(moment().format('YYYY-MM-DD'));
  const dayEnd = getDayEndTime(moment().format('YYYY-MM-DD'));
  for (let i = 0; i < idleMoments.length; i++) {
    const element = idleMoments[i];
    const { firstLocation, lastLocation } = element ?? {};
    if (
      firstLocation?.timestamp &&
      lastLocation?.timestamp &&
      (!minIdleTime
        ? true
        : lastLocation.timestamp - firstLocation.timestamp >= minIdleTime)
    ) {
      let idleStartTime = null;
      let idleEndTime = null;
      if (dayStart > firstLocation?.timestamp) {
        idleStartTime = dayStart;
      } else {
        idleStartTime = firstLocation?.timestamp;
      }
      if (dayEnd < lastLocation?.timestamp) {
        idleEndTime = dayEnd;
      } else {
        idleEndTime = lastLocation?.timestamp;
      }
      if (idleEndTime - idleStartTime > 0)
        idleSeconds = idleSeconds + idleEndTime - idleStartTime;
    }
  }
  return idleSeconds;
};

export const findTripEngineHours = (lastTime, firstTime) => {
  let seconds = 0;
  if (!lastTime || !firstTime) {
    return 0;
  }
  const dayStart = getDayStartTime(moment().format('YYYY-MM-DD'));
  const dayEnd = getDayEndTime(moment().format('YYYY-MM-DD'));
  if (firstTime && lastTime) {
    let StartTime = null;
    let EndTime = null;
    if (dayStart > firstTime) {
      StartTime = dayStart;
    } else {
      StartTime = firstTime;
    }
    if (dayEnd < lastTime) {
      EndTime = dayEnd;
    } else {
      EndTime = lastTime;
    }
    if (EndTime - StartTime > 0) seconds = seconds + EndTime - StartTime;
  }
  return seconds;
};

export const formatSecondstoHHMMSS = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  // Pad the hours, minutes, and seconds with leading zeros if necessary
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(secs).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const formatUnixDateToTodayHHMMSS = (unixTimestamp) => {
  const date = moment.unix(unixTimestamp);
  const now = moment();

  let formattedDate;
  if (date.isSame(now, 'day')) {
    formattedDate = 'Today';
  } else if (date.isSame(now.subtract(1, 'days'), 'day')) {
    formattedDate = 'Yesterday';
  } else {
    formattedDate = date.format('DD/MM/YYYY');
  }

  const formattedTime = date.local().format('hh:mm:ss A');

  return `${formattedDate} ${formattedTime}`;
};

export const calculateTotalIdleTime = (idleMoments) => {
  const totalIdleTime = idleMoments.reduce((total, location) => {
    const firstTimestamp = new Date(location.firstLocation.edt).getTime();
    const lastTimestamp = new Date(location.lastLocation.edt).getTime();

    const idleTime = lastTimestamp - firstTimestamp;

    return total + idleTime;
  }, 0);

  // Convert total idle time from milliseconds to seconds
  return Math.round(totalIdleTime / 1000);
};
