import { FC, MouseEvent, forwardRef } from 'react';

import { Dropdown } from 'react-bootstrap';
import ActionIcon from '../../pages/assets/Actions.png';

type ForwardRefProps = {
  onClick: (input: MouseEvent<HTMLImageElement, globalThis.MouseEvent>) => void;
};
type Ref = HTMLImageElement;

const CustomToggle = forwardRef<Ref, ForwardRefProps>((props, ref) => (
  <img
    className="image-click"
    alt="action button"
    src={ActionIcon}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      props.onClick(e);
    }}
  />
));

type ActionDropdownProps = {
  handleDropdownOptions: () => void;
};
const ActionDropdown: FC<ActionDropdownProps> = (props) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu>
        <Dropdown.Item as="button" onClick={props.handleDropdownOptions}>
          <span className="dropdown-text">Edit engine hours</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionDropdown;
