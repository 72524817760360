const messages = {
  DMU: {
    DASHBOARD: {
      SUMMARY: 'SUMMARY',
      PRODUCTIVITY: 'Productivity',
      TOTAL_TIME_ON_SITE: 'Total time on site:',
      HRS: 'hrs',
      THAN_YESTERDAY: 'than yesterday',
      AVERAGE_SPEED: 'Average Speed',
      SPEED_UNIT: 'km/h',
      CONTACT_TIME: 'contact time',
      NEARBY_TIME: 'nearby time',
      UTILIZATION: 'Utilization',
      TRANSMISSION_TIME: 'Transmission time',
      IDLE_TIME: 'Idle time',
      INACTIVE_TIME: 'Inactive/Off',
      ACTIVE: 'active',
      ENGINE_HOURS: 'Engine On hours',
      FUEL: 'Fuel Used',
      TOTAL: 'Total',
      MACHINE_HOURS: 'Machine hours',
      THAN_PREVIOUS_DAY: 'than previous day',
      THAN_PREVIOUS_MONTH: 'than previous month',
    },
  },
  PRESTART: {
    TABLE: {
      ASSET_ID: 'Asset ID',
      DEPARTMENT: 'Department',
      STATUS: 'Status',
      REF_NO: 'Ref No.',
      OPERATOR: 'Operator',
      LOGIN_ENGINE_ON: 'Login/ Engine On',
      CHECK_TIME: 'Check time',
      FAILED_CHECKS: 'Failed Checks',
      ENGINE_HOURS: 'Engine Hours',
      SIGNATURE: 'Supervisor’s signature',
    },
    FAILURE: 'failure',
  },
};

export default messages;
