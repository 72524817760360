import React, { createContext } from 'react';
import io from 'socket.io-client';

let connection = '/';

const socket = io(connection, {
  query: {
    room: 'admin',
  },
});

const socketAssetTracking = io(connection, {
  path: '/socket/asset-tracking',
});

export const SocketContext = createContext(socket, socketAssetTracking);
export const SocketProvider = ({ children }) => {
  return (
    <SocketContext.Provider value={{ socket, socketAssetTracking }}>
      {children}
    </SocketContext.Provider>
  );
};
