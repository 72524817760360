import React, { useEffect, useState } from 'react';
import styles from './AssetDetailCard.module.css';
import Draggable from 'react-draggable';
import { getColorByStatus } from '../../AssetList/AssetTable';
import { AssetDetailsSections, AssetStatus } from '../../utils/constants';
import WaterfallModal from '../WaterfallModal/WaterfallModal';
import {
  Alert,
  Asset,
  Equipment,
  IdleMoment,
  SpeedAlert,
} from '../../models/models';
import CloseIcon from '../../../pages/assets/CloseIcon';
import SpeedingIcon from '../../assets/svgs/Speeding';
import TripSVG from '../../assets/svgs/TripSVG';
import ParkedSVG from '../../assets/svgs/ParkedSVG';
import {
  calculateTotalIdleTime,
  formatSecondstoHHMMSS,
  formatUnixDateToTodayHHMMSS,
  padNumber,
} from '../../utils/helper';
import moment from 'moment';
import { useAssetTrackingContext } from '../../../pages/service/AssetTrackingContext';
import IdleTimeSVG from '../../assets/svgs/IdleTimeSVG';

interface AssetDetailCardProps {
  asset: Asset | undefined;
  equipment: Equipment | undefined;
  isSelected: boolean;
  onClick?: (asset: Asset | undefined) => void | undefined;
  index: number;
  onClose?: (title: string | undefined) => void | undefined;
  left?: number | undefined;
}

const AssetDetailCard: React.FC<AssetDetailCardProps> = ({
  asset,
  isSelected,
  onClick,
  index,
  onClose,
  left = 29,
  equipment,
}) => {
  const { currentDateUnix } = useAssetTrackingContext();
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [openSection, setOpenSection] = useState<string>(
    AssetDetailsSections.Summary,
  );

  useEffect(() => {
    const idleMoments: IdleMoment[] = asset?.activity[0]?.idleMoments ?? [];
    const speedAlerts: SpeedAlert[] = asset?.activity[0]?.speedAlerts ?? [];

    const Alerts: Alert[] = [
      ...idleMoments.map(
        (idleMoment): Alert => ({
          ...idleMoment,
          alertType: 'idle' as const,
          edt: idleMoment?.lastLocation?.edt,
        }),
      ),
      ...speedAlerts.map(
        (speedAlert): Alert => ({
          ...speedAlert,
          alertType: 'speed' as const,
          edt: speedAlert?.startEventTime,
        }),
      ),
    ].sort((a, b) => {
      const dateA = a.edt ? new Date(a.edt).getTime() : Infinity; // Treat undefined as Infinity
      const dateB = b.edt ? new Date(b.edt).getTime() : Infinity; // Treat undefined as Infinity
      return dateA - dateB;
    });

    setAlerts(Alerts);
  }, [asset]);

  return (
    <Draggable>
      <div
        className={styles.draggable}
        onClick={() => {
          if (onClick) {
            onClick(asset);
          }
        }}
        style={{
          zIndex: isSelected ? 1001 : 1000,
          filter: isSelected ? 'brightness(100%)' : 'brightness(80%)',
          top: index === 0 ? 29 : 29 + 47 * index,
          left: left,
        }}
      >
        <div
          className={styles.header}
          style={{ backgroundColor: getColorByStatus(asset?.Status) }}
        >
          <div
            className={styles.dot}
            style={{
              backgroundColor:
                asset?.Status === AssetStatus.Disconnected
                  ? '#ffffff'
                  : '#3E4DFF',
            }}
          ></div>
          <div className={styles.title}>{asset?.title}</div>
          <div
            className={styles.pill}
            style={{ backgroundColor: getColorByStatus(asset?.Status) }}
          >
            {asset?.Status === AssetStatus.Idling
              ? AssetStatus.Active
              : asset?.Status}
          </div>
          <div
            onClick={() => {
              if (onClose) {
                onClose(asset?.title);
              }
            }}
            className={styles.close}
          >
            <CloseIcon height={14} />
          </div>
        </div>
        <div className={styles.container}>
          <WaterfallModal
            isOpen={isSelected && openSection === AssetDetailsSections.Summary}
            onClick={() =>
              setOpenSection((prev) => {
                if (prev === AssetDetailsSections.Summary) {
                  return '';
                } else {
                  return AssetDetailsSections.Summary;
                }
              })
            }
            triggerContent="Summary"
            contentContainerHeight="280px"
            content={
              <>
                {asset?.activity.map((activity, index) => {
                  return (
                    <div className={styles.AlertContent} key={index}>
                      <div>
                        {activity?.activity_type === 'Trip' ? (
                          <TripSVG />
                        ) : (
                          <ParkedSVG />
                        )}
                      </div>
                      <div className={styles.detailsContainer}>
                        <div style={{ paddingBottom: '5px' }}>
                          {activity?.activity_type}
                        </div>
                        <div>
                          <span className={styles.key}>From:</span>
                          {activity?.firstLocation.longitude},{' '}
                          {activity?.firstLocation.latitude}
                        </div>
                        <div>
                          <span className={styles.key}>To:</span>
                          {activity?.lastLocation.longitude},{' '}
                          {activity?.lastLocation.latitude}
                        </div>
                        <div>
                          <span className={styles.key}>Distance:</span>
                          {padNumber(
                            activity?.lastLocation.odo -
                              activity?.firstLocation.odo,
                            2,
                          )}{' '}
                          km
                        </div>
                        <div>
                          <span className={styles.key}>Duration:</span>
                          {index === 0
                            ? 'In Progress'
                            : formatSecondstoHHMMSS(
                                activity?.endTime - activity?.startTime,
                              ) + ' h'}
                        </div>
                        <div>
                          <span className={styles.key}>Max Speed:</span>
                          {activity?.maxSpeed} km/h
                        </div>
                        <div>
                          <span className={styles.key}>Total Idle Time:</span>
                          {formatSecondstoHHMMSS(
                            calculateTotalIdleTime(activity?.idleMoments) ?? 0,
                          )}{' '}
                          h
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            }
          />
          <WaterfallModal
            isOpen={isSelected && openSection === AssetDetailsSections.Status}
            onClick={() =>
              setOpenSection((prev) => {
                if (prev === AssetDetailsSections.Status) {
                  return '';
                } else {
                  return AssetDetailsSections.Status;
                }
              })
            }
            triggerContent="Status"
            contentContainerHeight="280px"
            content={
              <>
                <div className={styles.StatusContent}>
                  <div
                    className={styles.engineStatus}
                    style={{
                      backgroundColor:
                        equipment?.currentEngineStatus === 'OFF'
                          ? getColorByStatus(AssetStatus.Parked)
                          : '',
                      color:
                        equipment?.currentEngineStatus === 'OFF' ? 'black' : '',
                    }}
                  >
                    Engine {equipment?.currentEngineStatus}
                  </div>
                  <div className={styles.details}>
                    <span className={styles.label}>Since:</span>
                    <span>
                      {formatUnixDateToTodayHHMMSS(
                        moment.utc(equipment?.lastUpdatedTime).unix(),
                      )}
                    </span>
                  </div>
                  <div className={styles.details}>
                    <span className={styles.label}>Duration:</span>
                    <span>
                      {asset &&
                        formatSecondstoHHMMSS(
                          currentDateUnix - asset?.activity[0]?.startTime,
                        )}{' '}
                      h
                    </span>
                  </div>
                  <div className={styles.details}>
                    <span className={styles.label}>
                      Time Since Last Updated:
                    </span>
                    <span>
                      {asset && asset.lastSocketTime
                        ? formatSecondstoHHMMSS(
                            (currentDateUnix ?? moment().unix()) -
                              asset.lastSocketTime >
                              0
                              ? (currentDateUnix ?? moment().unix()) -
                                  asset.lastSocketTime
                              : 0,
                          )
                        : asset && asset.Current?.tripLastUpdate
                        ? formatSecondstoHHMMSS(
                            (currentDateUnix ?? moment().unix()) -
                              asset.Current?.tripLastUpdate >
                              0
                              ? (currentDateUnix ?? moment().unix()) -
                                  asset.Current?.tripLastUpdate
                              : 0,
                          )
                        : '00:00:00'}{' '}
                      h
                    </span>
                  </div>
                </div>
              </>
            }
          />
          <WaterfallModal
            isOpen={isSelected && openSection === AssetDetailsSections.Alerts}
            onClick={() =>
              setOpenSection((prev) => {
                if (prev === AssetDetailsSections.Alerts) {
                  return '';
                } else {
                  return AssetDetailsSections.Alerts;
                }
              })
            }
            triggerContent={`Alerts (${alerts.length})`}
            contentContainerHeight="280px"
            content={
              <>
                {alerts.map((alert) => {
                  if (alert.alertType === 'idle') {
                    return (
                      <div className={styles.AlertContent}>
                        <div>
                          <IdleTimeSVG />
                        </div>
                        <div className={styles.detailsContainer}>
                          <div style={{ paddingBottom: '5px' }}>
                            Idling -{' '}
                            {formatSecondstoHHMMSS(
                              moment(alert.lastLocation.edt).unix() -
                                moment(alert.firstLocation.edt).unix(),
                            )}
                          </div>
                          <div>
                            <span className={styles.key}>From:</span>
                            {alert?.firstLocation.longitude
                              ? alert?.firstLocation.longitude + ','
                              : ''}{' '}
                            {alert?.firstLocation.latitude}
                          </div>
                          <div>
                            <span className={styles.key}>To:</span>
                            {alert?.lastLocation.longitude
                              ? alert?.lastLocation.longitude + ','
                              : ''}{' '}
                            {alert?.firstLocation.latitude}
                          </div>
                          <div>
                            <span className={styles.key}>Duration:</span>
                            {formatSecondstoHHMMSS(
                              moment(alert.lastLocation.edt).unix() -
                                moment(alert.firstLocation.edt).unix(),
                            )}{' '}
                            h
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className={styles.AlertContent}>
                        <div>
                          <SpeedingIcon />
                        </div>
                        <div className={styles.detailsContainer}>
                          <div style={{ paddingBottom: '5px' }}>
                            Speeding -{' '}
                            {formatSecondstoHHMMSS(
                              alert?.durationInSeconds ?? 0,
                            )}
                          </div>
                          <div>
                            <span className={styles.key}>Speed:</span>
                            {alert?.speedLimit
                              ? '> ' + alert?.speedLimit + ' km/h'
                              : '-'}
                          </div>
                          <div>
                            <span className={styles.key}>From:</span>
                            {alert?.from?.long
                              ? alert?.from?.long + ','
                              : ''}{' '}
                            {alert?.from?.lat}
                          </div>
                          <div>
                            <span className={styles.key}>To:</span>
                            {alert?.to?.long ? alert?.to?.long + ',' : ''}{' '}
                            {alert?.to?.lat}
                          </div>
                          <div>
                            <span className={styles.key}>Duration:</span>
                            {formatSecondstoHHMMSS(
                              alert?.durationInSeconds ?? 0,
                            )}{' '}
                            h
                          </div>
                          <div>
                            <span className={styles.key}>Distance:</span>
                            {padNumber(alert.distance ?? 0, 2)} km
                          </div>
                          <div>
                            <span className={styles.key}>Max Speed:</span>
                            {padNumber(alert.maxSpeed ?? 0, 2)} km/h
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </>
            }
          />
        </div>
      </div>
    </Draggable>
  );
};

export default AssetDetailCard;
