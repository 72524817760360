export const DropdownData = {
  categoryType: {
    options: [
      { name: 'Excavator', value: 'Excavator' },
      { name: 'Truck', value: 'Truck' },
    ],
    defaultValues: ['Excavator', 'Truck'],
  },
  activityType: {
    options: [
      { name: 'Trip', value: 'Trip' },
      { name: 'Park', value: 'Park' },
    ],
    defaultValues: ['Trip', 'Park'],
  },
  eventType: {
    options: [
      { name: 'Speeding', value: 'Speeding' },
      { name: 'Idle Time', value: 'Idle Time' },
    ],
    defaultValues: ['Speeding', 'Idle Time'],
  },
  statusType: {
    options: [
      { name: 'Active', value: 'Active' },
      { name: 'Disconnected', value: 'Disconnected' },
      { name: 'Parked', value: 'Parked' },
    ],
    defaultValues: ['Active', 'Disconnected', 'Parked'],
  },
  departments: {
    options: [
      { name: 'Dust Control', value: 'Dust Control' },
      { name: 'Material Movements', value: 'Material Movements' },
      { name: 'Maintenance', value: 'Maintenance' },
      { name: 'Scrap Bins', value: 'Scrap Bins' },
    ],
    defaultValues: [
      'Dust Control',
      'Scrap Bins',
      'Maintenance',
      'Material Movements',
    ],
  },
  asset_category: {
    options: [
      { name: 'Water Cart', value: 'Water Cart' },
      { name: 'Excavator', value: 'Excavator' },
      { name: 'Misc', value: 'Misc' },
      { name: 'Bin Truck', value: 'Bin Truck' },
      { name: 'Loader', value: 'Loader' },
    ],
    defaultValues: ['Water Cart', 'Excavator', 'Misc', 'Bin Truck', 'Loader'],
  },

  //timeline dropdown data

  timeline: {
    event: {
      options: [
        { name: 'Ignition ON', value: 'Ignition ON' },
        { name: 'Ignition OFF', value: 'Ignition OFF' },
        { name: 'Engine ON', value: 'Engine ON' },
        { name: 'Engine OFF', value: 'Engine OFF' },
        { name: 'Dump Switch HIGH', value: 'Dump Switch HIGH' },
        { name: 'Dump Switch LOW', value: 'Dump Switch LOW' },
      ],
      defaultvalues: [
        'Ignition ON',
        'Ignition OFF',
        'Engine ON',
        'Engine OFF',
        'Dump Switch HIGH',
        'Dump Switch LOW',
      ],
    },
    speed: {
      options: [
        { name: 'Speeding (00 - 30 km/h)', value: '0-30' },
        {
          name: 'Speeding (30 - 40 km/h)',
          icon: (
            <div
              className="asm-legend-box1 me-1"
              style={{ background: '#73bf63', width: '0.5rem' }}
            />
          ),
          value: '30-40',
        },
        {
          name: 'Speeding (40 - 55 km/h)',
          icon: (
            <div
              className="asm-legend-box1 me-1"
              style={{ background: '#181cf5', width: '0.5rem' }}
            />
          ),
          value: '40-55',
        },
        {
          name: 'Speeding (> 55km/h)',
          icon: (
            <div
              className="asm-legend-box1 me-1"
              style={{ background: '#e23123', width: '0.5rem' }}
            />
          ),
          value: '55',
        },
      ],
      defaultvalues: ['0-30', '30-40', '40-55', '55'],
    },
    alerts: {
      options: [
        { name: 'Speeding', value: 'Speeding' },
        { name: 'Idle Time', value: 'Idling' },
      ],
      defaultValues: ['Speeding', 'Idling'],
    },
    signal: {
      options: [
        { name: '0 bar', value: '0 bar' },
        { name: '1 bar', value: '1 bar' },
        { name: '2 bars', value: '2 bars' },
        { name: '3 bars', value: '3 bars' },
        { name: '4 bars', value: '4 bars' },
        { name: '5 bars', value: '5 bars' },
      ],
      defaultValues: ['0 bar', '1 bar', '2 bars', '3 bars', '4 bars', '5 bars'],
    },
  },
};

export const TripHistoryDropdowns = [
  {
    title: 'Asset Categories',
    type: 'categoryType',
  },
  {
    title: 'Activities',
    type: 'activityType',
  },
  {
    title: 'Alerts',
    type: 'eventType',
  },
  {
    title: 'Status',
    type: 'statusType',
  },
];
