import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  GET_ALL_OPERATORS,
  GET_ALL_SOURCE_AND_DUMPS,
  GET_ALL_TRUCKS_EXCAVATORS,
  GET_MATERIALS,
} from '../../../action/apiPath';
import BasicButton from '../../BasicButton';
import DropdownField from '../../FormDropdown';
import { CallWithAuth } from '../../../../assetTracking/action/apiActions';

const HistoryFilterModal = (props) => {
  const {
    modalOpen,
    modalToggle,
    onSaveClick,
    filteredData = {},
    setFilteredData,
    saveHandler,
    fetchTrucks,
  } = props;

  // const [initialFilteredData, setFilteredData] = useState(initialFilteredData);
  const [optionsForTrucks, setOptionsforTrucks] = useState([]);
  const [optionsForExcavators, setOptionsforExcavators] = useState([]);
  const [optionsForOperator, setOptionForOperator] = useState([]);
  const [optionsfordump, setOptionForDump] = useState([]);
  const [optionsforsource, setOptionForSource] = useState([]);
  const [materials, setMaterials] = useState([]);

  const fetchData = async () => {
    try {
      const response = await CallWithAuth(
        'GET',
        `${GET_ALL_TRUCKS_EXCAVATORS}`,
      );
      if (response?.res?.data?.status === 200) {
        setOptionsforTrucks(response?.res?.data?.data[0]);
        setOptionsforExcavators(response?.res?.data?.data[1]);
      } else throw response?.res;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllPoints = async () => {
    try {
      const response = await CallWithAuth('GET', `${GET_ALL_SOURCE_AND_DUMPS}`);

      console.log(
        response?.res?.data?.data,
        'response?.res?.data?.data Lin e 32dum',
      );
      if (response?.res?.data?.status === 200) {
        setOptionForDump(response?.res?.data?.data[0]);
        setOptionForSource(response?.res?.data?.data[1]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchOperatorData = async () => {
    try {
      const response = await CallWithAuth('GET', `${GET_ALL_OPERATORS}`);
      if (response?.res?.data?.status === 200) {
        console.log(response?.res?.data?.data, 'response?.res?.data?.data');
        setOptionForOperator(response?.res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMaterials = async () => {
    try {
      const response = await CallWithAuth('GET', GET_MATERIALS);
      if (response?.res?.data?.status === 200) {
        setMaterials(response?.res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchOperatorData();
    fetchData();
    fetchAllPoints();
    fetchMaterials();
  }, []);

  const zoneOptions = useCallback(
    () => [
      {
        name: 'Select date range',
        field: 'dateRange',
        type: 'dateRange',
        value: {
          startDate: '',
          endDate: '',
        },
      },
      {
        name: 'Zones',
        field: 'zones',
        type: 'select',
        options: [
          {
            value: 1,
            displayedValue: 'Zone 1',
            name: 'zone',
          },
          {
            value: 2,
            displayedValue: 'Zone 2',
            name: 'zone',
          },
          {
            value: 3,
            displayedValue: 'Zone 3',
            name: 'zone',
          },
          {
            value: 4,
            displayedValue: 'Zone 4',
            name: 'zone',
          },
          {
            value: 5,
            displayedValue: 'Zone 5',
            name: 'zone',
          },
          {
            value: 6,
            displayedValue: 'Zone 6',
            name: 'zone',
          },
        ],
        value: [],
      },
      {
        name: 'Trucks',
        field: 'trucks',
        type: 'select',
        options: [
          {
            value: '627',
            name: 'truck',
          },
          {
            value: '721',
            name: 'truck',
          },
          {
            value: '819',
            name: 'truck',
          },
        ],
        value: [],
      },
    ],
    [],
  );

  const onCheckboxClick = (fieldName, value, multiselect) => {
    console.log(fieldName, value, multiselect, 'fieldName, value, multiselect');
    if (multiselect) {
      setFilteredData((prev) => {
        const filters = { ...prev };
        if (filters[fieldName]?.includes(value))
          filters[fieldName] = filters[fieldName]?.filter(
            (selectedValue) => selectedValue !== value,
          );
        else filters[fieldName] = [...filters[fieldName], value];
        return filters;
      });
    } else {
      setFilteredData((prev) => ({ ...prev, [fieldName]: value }));
    }

    console.log(filteredData, 'filteredData');
  };

  const clearFilter = () => {
    fetchTrucks();
    setFilteredData({});
  };

  return (
    <div className="test-class" style={{ borderRadius: '4px 0px 0px 0px' }}>
      {modalOpen && (
        <Modal
          show={modalOpen}
          onHide={modalToggle}
          // backdrop="static"
          keyboard={false}
          size="lg"
          dialogClassName="mm-modal-position "
          className="modal-transparent"
        >
          <Modal.Header>
            <div
              style={{ borderBottom: '1px solid #EBEBEB' }}
              className="d-flex justify-content-between w-100 align-items-center ms-4 me-2 my-3 font-13 mb-4"
            >
              <b
                className="ps-1 px-3 mb-4"
                style={{ font: 'normal normal 600 16px/22px Open Sans' }}
              >
                FILTER BY
              </b>
            </div>
          </Modal.Header>
          <div
            className="pt-0 font-13 d-flex flex-column justify-content-between h-100"
            style={{
              font: 'normal normal 14px/19px Open Sans',
              borderRadius: '10px 0px 0px 0px',
            }}
          >
            <div className="mx-4 px-2 d-flex flex-column gap-2">
              <div className=" mb-4">
                <DropdownField
                  name={'Materials'}
                  key={'materials'}
                  fieldKey={'materials'}
                  style={{ fontWeight: 'normal' }}
                  selectedOptions={filteredData['materials']}
                  allOptions={materials?.map((e) => {
                    return { name: e?.MaterialType, value: e?.MaterialType };
                  })}
                  upperLevelClassName="d-flex flex-row justify-content-between"
                  handleChange={(e) => {
                    console.log(e, 'Inisfe materials dropdown');
                    onCheckboxClick(
                      e?.fieldKey,
                      e?.optionValue,
                      e?.multiselect,
                    );
                  }}
                />
              </div>
              <div className=" mb-4">
                <DropdownField
                  name={'Source Point'}
                  key={'sourcepoint'}
                  fieldKey={'sourcepoint'}
                  selectedOptions={filteredData['sourcepoint']}
                  allOptions={optionsforsource?.map((e) => {
                    return { name: e?.name, value: e?.name };
                  })}
                  upperLevelClassName="d-flex flex-row justify-content-between"
                  handleChange={(e) => {
                    console.log(e, 'Inisfe materials dropdown');
                    onCheckboxClick(
                      e?.fieldKey,
                      e?.optionValue,
                      e?.multiselect,
                    );
                  }}
                />
              </div>
              {/* <div className=" mb-4">
                <DropdownField
                  name={"Dump Point"}
                  key={"dumppoint"}
                  fieldKey={"dumppoint"}
                  selectedOptions={filteredData["dumppoint"]}
                  allOptions={dumpPoints?.filter((e) => {
                    return e?.type === 'dump' && { name: e?.name, value: e?.name }
                  })}

                  upperLevelClassName="d-flex flex-row justify-content-between"
                  handleChange={(e) => {
                    console.log(e, "Inisfe materials dropdown")
                    onCheckboxClick(e?.fieldKey, e?.optionValue, e?.multiselect)
                  }}
                />
              </div> */}
              <div className=" mb-4">
                <DropdownField
                  name={'Dump Points'}
                  key={'dumppoint'}
                  fieldKey={'dumppoint'}
                  selectedOptions={filteredData['dumppoint']}
                  allOptions={optionsfordump?.map((e) => {
                    return { name: e?.name, value: e?.name };
                  })}
                  upperLevelClassName="d-flex flex-row justify-content-between"
                  handleChange={(e) =>
                    onCheckboxClick(e?.fieldKey, e?.optionValue, e?.multiselect)
                  }
                />
              </div>

              <div className=" mb-4">
                <DropdownField
                  name={'Trucks'}
                  key={'trucks'}
                  fieldKey={'trucks'}
                  selectedOptions={filteredData['trucks']}
                  allOptions={optionsForTrucks?.map((e) => {
                    return { name: e?.Unit, value: e?.Unit };
                  })}
                  upperLevelClassName="d-flex flex-row justify-content-between"
                  handleChange={(e) =>
                    onCheckboxClick(e?.fieldKey, e?.optionValue, e?.multiselect)
                  }
                />
              </div>
              <div className=" mb-4">
                <DropdownField
                  name={'Excavators'}
                  key={'excavators'}
                  fieldKey={'excavators'}
                  selectedOptions={filteredData['excavators']}
                  allOptions={optionsForExcavators?.map((e) => {
                    return { name: e?.Unit, value: e?.Unit };
                  })}
                  upperLevelClassName="d-flex flex-row justify-content-between"
                  handleChange={(e) =>
                    onCheckboxClick(e?.fieldKey, e?.optionValue, e?.multiselect)
                  }
                />
              </div>
              <div className=" mb-4">
                <DropdownField
                  name={'Operators'}
                  key={'operators'}
                  fieldKey={'operators'}
                  selectedOptions={filteredData['operators']}
                  allOptions={optionsForOperator?.map((e) => {
                    return { name: e?.firstName, value: e?.firstName };
                  })}
                  upperLevelClassName="d-flex flex-row justify-content-between"
                  handleChange={(e) =>
                    onCheckboxClick(e?.fieldKey, e?.optionValue, e?.multiselect)
                  }
                />
              </div>
            </div>
            <div
              className="d-flex align-items-center justify-content-between pt-3 p-4"
              style={{
                backgroundColor: '#FAFAFA',
                backgroundRepeat: 'no-repeat',
                padding: 'padding-box',
              }}
            >
              <div>
                <button
                  className="border border-0 bg-transparent px-3 btn-focus"
                  onClick={clearFilter}
                  style={{ color: '#707070', marginBottom: '20px' }}
                >
                  <u>Clear all</u>
                </button>
              </div>
              <div className=" d-flex">
                <div className=" px-3">
                  <BasicButton
                    style={{ border: '1px solid black', marginBottom: '20px' }}
                    onClick={() => modalToggle(false)}
                    variant="white"
                  >
                    Cancel
                  </BasicButton>
                </div>
                <div>
                  <BasicButton
                    className="px-4 btn-focus"
                    onClick={saveHandler}
                    variant="dark"
                    style={{ marginBottom: '20px' }}
                  >
                    Apply
                  </BasicButton>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default HistoryFilterModal;
