import { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { IoMdTrash } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { DELETE_SOURCE_DUMP } from '../action/apiPath';
import info from '../assets/images/info.svg';
import { CallWithAuth } from '../../assetTracking/action/apiActions';

const Settings = ({
  show,
  dumpPoint = {},
  setIsLoading = () => {},
  fetchPolygon = () => {},
  setShowEditModal = () => {},
  triggerFetch = () => {},
  onSelect = () => {},
  setUpdateDumpPoint = () => {},
  setPopupConfirmation = () => {},
  setshowSourcePointsModal = () => {},
  tableState,
  submitHandler = () => {},
  setSelectedDumpPoint = () => {},
  type,
}) => {
  useEffect(() => {}, [show]);
  return (
    <ul style={{ fontSize: '1rem', borderRadius: '10px', padding: '0px 0px' }}>
      <li
        style={{
          cursor: 'pointer',
          padding: '5px 10px',
          font: 'normal normal normal 11px/18px Open Sans',
          letterSpacing: ' 0px',
          color: '#2B2D2F',
          opacity: '1',
        }}
        className="mm-dropdown-setting mb-0 d-flex align-items-center "
        onClick={() => {
          onSelect({ ...dumpPoint, popup: false });
          setShowEditModal(true);
          setUpdateDumpPoint(dumpPoint);
        }}
      >
        <MdEdit style={{ fontSize: '1rem', marginRight: '0.75rem' }} />
        <div style={{ fontSize: '1rem' }}> Edit</div>
      </li>
      {dumpPoint?.name === 'Unknown' && type === 'dump' ? null : (
        <li
          style={{
            cursor: 'pointer',
            fontSize: '1rem',
            padding: '5px 10px',
            font: 'normal normal normal 11px/18px Open Sans',
            letterSpacing: ' 0px',
            color: '#2B2D2F',
            opacity: '1',
          }}
          className="mm-dropdown-setting mb-0"
          onClick={() => {
            console.log('Inisde onClick');
            setPopupConfirmation({
              title: `Are you sure you want to ${
                tableState ? 'disable' : 'enable'
              } ${dumpPoint.name}?`,
              description: tableState
                ? 'This geofence will not be visible on the map and its activity will not be accounted for. You can still find it under inactive dump points.'
                : '',
              cancelText: 'Cancel',
              confirmText: tableState ? 'Disable' : 'Enable',
              onCancel: () => {
                setPopupConfirmation('');
                setshowSourcePointsModal(false);
              },
              onConfirm: () => {
                if (tableState) {
                  submitHandler(dumpPoint, 'Inactive');
                } else {
                  console.log('Inisde');
                  submitHandler(dumpPoint, 'Active');
                }
                setPopupConfirmation('');
              },
            });
          }}
        >
          {tableState ? (
            <AiOutlineEyeInvisible
              size={'1rem'}
              v
              style={{
                font: 'normal normal normal 11px/18px Open Sans',
                letterSpacing: ' 0px',
                size: '1rem',

                color: '#2B2D2F',
                opacity: '1',
                fontSize: '1rem',
                marginRight: '0.75rem',
              }}
            />
          ) : (
            <AiOutlineEye
              size={'1rem'}
              style={{
                fontSize: '1.5rem',
                size: '1rem',
                marginRight: '0.75rem',
              }}
            />
          )}

          {type === 'source'
            ? tableState
              ? 'Disable Source Point'
              : 'Enable Source Point'
            : tableState
            ? 'Disable Dump Point'
            : 'Enable Dump Point'}
        </li>
      )}

      <li
        style={{
          cursor: 'pointer',
          fontSize: '1rem',
          padding: '5px 10px',
          font: 'normal normal normal 11px/18px Open Sans',
          letterSpacing: ' 0px',
          color: '#2B2D2F',
          opacity: '1',
        }}
        className="mm-dropdown-setting mb-0"
        onClick={() => {
          // setSelectedDumpPoint()

          dumpPoint.details = true;
          onSelect({ ...dumpPoint, details: true });
        }}
      >
        <Image
          style={{
            size: '1rem',
            height: '1rem',
            width: '1rem',
            marginRight: '5px',
            marginRight: '0.85rem',
          }}
          src={info}
        />
        {type === 'source' ? `Source Point Details` : `Dump Point Details`}
      </li>

      {tableState === false ? (
        <li
          style={{
            font: 'normal normal normal 11px/18px Open Sans',
            letterSpacing: ' 0px',
            color: '#2B2D2F',
            opacity: '1',
            cursor: 'pointer',
            fontSize: '1rem',
            padding: '5px 10px',
          }}
          className="mm-dropdown-setting mb-0"
          onClick={() => {
            setPopupConfirmation({
              title: `Are you sure you want to delete ${dumpPoint.name}?`,
              description: '',
              cancelText: 'Cancel',
              confirmText: 'Delete',
              onCancel: () => {
                setPopupConfirmation('');
                setshowSourcePointsModal(false);
              },
              onConfirm: async () => {
                try {
                  const response = await CallWithAuth(
                    'POST',
                    DELETE_SOURCE_DUMP,
                    { _id: dumpPoint._id },
                  );
                  if (response.res.status === 201) {
                    setSelectedDumpPoint({});
                    fetchPolygon();
                    toast.success('Dump point deleted successfully', {
                      autoClose: 3000,
                      hideProgressBar: false,
                    });
                    setshowSourcePointsModal(false);
                    setIsLoading(false);
                  }
                } catch (error) {
                  console.log(error, 'error');
                  setIsLoading(false);
                  toast.error('Server Error', {
                    position: 'bottom-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                  });
                }
                setPopupConfirmation('');
              },
            });
          }}
        >
          <IoMdTrash
            style={{
              font: 'normal normal normal 11px/18px Open Sans',
              letterSpacing: ' 0px',
              color: '#2B2D2F',
              opacity: '1',
              fontSize: '1rem',
              marginRight: '0.75rem',
            }}
          />
          Delete
        </li>
      ) : null}
    </ul>
  );
};

export default Settings;
