export const dayShiftData = [
  { quarter: '6 AM', earnings: 0 },
  { quarter: '7 AM', earnings: 0 },
  { quarter: '8 AM', earnings: 0 },
  { quarter: '9 AM', earnings: 0 },
  { quarter: '10 AM', earnings: 0 },
  { quarter: '11 AM', earnings: 0 },
  { quarter: '12 PM', earnings: 0 },
  { quarter: '1 PM', earnings: 0 },
  { quarter: '2 PM', earnings: 0 },
  { quarter: '3 PM', earnings: 0 },
  { quarter: '4 PM', earnings: 0 },
];

export const nightShiftData = [
  { quarter: '5 PM', earnings: 0 },
  { quarter: '6 PM', earnings: 0 },
  { quarter: '7 PM', earnings: 0 },
  { quarter: '8 PM', earnings: 0 },
  { quarter: '9 PM', earnings: 0 },
  { quarter: '10 PM', earnings: 0 },
  { quarter: '11 PM', earnings: 0 },
  { quarter: '12 AM', earnings: 0 },
  { quarter: '1 AM', earnings: 0 },
  { quarter: '2 AM', earnings: 0 },
  { quarter: '3 AM', earnings: 0 },
];
export const ENDPOINTS = {
  login: 'auth/login',
  forgotPassword: 'auth/forgot-password',
  resetPassword: 'users/reset-password',
  getZones: 'zone/',
  getDailyZones: 'jobs/get-zones',
  getActiveOperators: 'users/active-operators',
  logout: 'auth/logout',
  editHazards: 'zone/edit-hazard',
  getTrucks: 'trucks',
  changeZoneStatus: 'zone/change-status',
  getAllUsers: 'users/users-list',
  addUser: 'users/add-user',
  resendInvitation: 'users/resend-invitation',
  cancelInvitation: 'users/cancel-invitation',
  editUser: 'users/edit-user',
  deactivateDeleteUsers: 'users/deactivate-delete',
  getDepartments: 'departments/',
  getLicenses: 'users/licenses',
  getNewToken: 'auth/refresh-token',
  setTimer: (zone, reminderTime) =>
    `zone/set-timer?zone=${zone}&timer=${reminderTime}`,
  getWaterConsumption: 'water-usage',
  changePassword: 'users/change-password',
  authChangePassword: 'auth/change-password',
  dismissAlert: (zone) => `zone/dismiss-alert?zone=${zone}`,
  getJobs: 'jobs/jobs-list',
  getJob: 'jobs/get-job',
  getJobDailyReport: 'reports/jobs-daily-report',
  getZonesReport: 'reports/zone-daily-report',
  getSingleJob: 'jobs/single-job',
  getRefills: 'waterRefill/',
  validateToken: 'auth/validate-token',
  generatePin: 'users/generate-pin',
  getPrestartChecks: 'prestart/',
  getsummarypdf: 'prestart/summary-pdf',
  getMaterialPdf: 'prestart/material-pdf',
  getImage: 'prestart/image',
  getReportPdf: 'prestart/report-pdf',
  signPrestartCheck: 'prestart/sign',
  getCheckPoints: 'checkpoints/',
  getEngineHoursMulti: 'engine-hours/calculate/multiple-trucks',
};

export const AssetTrackingSections = {
  AssetList: 'Asset List',
  TripHistory: 'Trip History',
};

export const AssetStatus = {
  Idling: 'Idling',
  Active: 'Active',
  Parked: 'Parked',
  Disconnected: 'Disconnected',
};

export const AssetDetailsSections = {
  Summary: 'Summary',
  Status: 'Status',
  Alerts: 'Alerts',
};
