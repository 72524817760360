import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import BasicButton from '../BasicButton';
import moment from 'moment';
import { formatDateToISO } from '../../utils/helper';
const Delays = {
  Breakdown: 'breakdown',
  Refill: 'refill',
  Break: 'break',
  'Health and safety': 'safety',
};
const trucksData = ['627', '721', '819'];

const SelectItem = (props) => {
  const { onCheckboxClick, selected, item, value, col } = props;
  return (
    <div
      className={`checkbox-container d-flex align-items-center pb-2 col-${col}`}
    >
      <input
        type="checkbox"
        checked={selected}
        onChange={onCheckboxClick}
        className="custom-control-input"
        value={value}
      />
      <p className="custom-control-label m-0 ps-2">{item}</p>
    </div>
  );
};

const FilterModal = (props) => {
  const { modalOpen, modalToggle, onSaveClick, initialFilteredData } = props;
  const [filteredData, setFilteredData] = useState(initialFilteredData);
  const onCheckboxClick = (e, type) => {
    let tempFilter = filteredData;
    let value = type !== 'zones' ? e.target.value : parseInt(e.target.value);

    if (e.target.checked && !tempFilter[type]?.includes(value)) {
      tempFilter[type].push(value);
    } else {
      tempFilter[type].splice(tempFilter[type].indexOf(value), 1);
    }

    setFilteredData({ ...tempFilter });
  };

  const clearFilter = () => {
    setFilteredData(() => {
      return {
        trucks: [],
        delays: [],
        zones: [],
        endDate: '',
        startDate: '',
        status: [],
        types: [],
      };
    });
    onSaveClick({
      trucks: [],
      delays: [],
      zones: [],
      endDate: '',
      startDate: '',
      status: [],
      types: [],
    });
  };

  const fromDateHandler = (v) => {
    const { value } = v.target;
    if (moment(value) > moment()) return;
    if (moment(value) > moment(filteredData.endDate)) return;

    setFilteredData({
      ...filteredData,
      startDate: formatDateToISO(value),
    });
  };

  const toDateHandler = (v) => {
    const { value } = v.target;
    if (moment(filteredData.startDate) > moment(value)) return;
    if (moment(value) > moment()) return;

    setFilteredData({
      ...filteredData,
      endDate: formatDateToISO(value),
    });
  };

  const saveHandler = () => {
    const copyFilteredData = {
      ...filteredData,
      startDate: filteredData.startDate || undefined,
      endDate: filteredData.endDate || undefined,
    };
    onSaveClick(copyFilteredData);
    setFilteredData({ ...filteredData });
  };

  return (
    <div>
      {modalOpen && (
        <Modal
          show={modalOpen}
          onHide={modalToggle}
          backdrop="static"
          keyboard={false}
          size="lg"
          dialogClassName="modal-position"
        >
          <Modal.Header>
            <div className="justify-content-end modal-header-container ">
              <div className="mt-2">
                <div className=" d-flex justify-content-end mb-3 align-self-end">
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => modalToggle(false)}
                  ></button>
                </div>
                <div className="d-flex gap-2 button-container me-4">
                  <BasicButton
                    outlined
                    onClick={clearFilter} //clear filter and close modal
                    variant="outline-dark"
                  >
                    Clear All
                  </BasicButton>
                  <BasicButton onClick={saveHandler} variant="dark">
                    Save
                  </BasicButton>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="pt-0 mx-4">
            <div className="filter-header">Filter by</div>
            <div>
              <div className="filter-main-label">Date</div>
              <div className="d-flex ms-3">
                <div className="filter-container d-flex align-items-center w-50">
                  <div className="filter-label me-3">From</div>
                  <input
                    type="date"
                    value={filteredData.startDate}
                    onChange={fromDateHandler}
                  />
                </div>
                <div className="filter-container d-flex align-items-center w-50">
                  <div className="filter-label me-3">To</div>
                  <input
                    type="date"
                    value={filteredData.endDate}
                    onChange={toDateHandler}
                  />
                </div>
              </div>
              {/* trucks data */}
              <div className="border-bottom">
                <div className="filter-main-label mt-2 mb-2">Truck</div>
                <div className="filter-container d-flex align-items-center ms-3">
                  {trucksData?.map((truck, i) => (
                    <SelectItem
                      key={i}
                      item={truck}
                      value={truck}
                      col={4}
                      onCheckboxClick={(e) => onCheckboxClick(e, 'trucks')}
                      selected={filteredData.trucks.includes(truck)}
                    />
                  ))}
                </div>
              </div>
              {/* zone data */}
              {
                <div className="border-bottom">
                  <div className="filter-main-label mt-2 mb-2">Zone</div>
                  <div className="filter-container ms-3 d-flex align-items-center flex-wrap">
                    {[1, 2, 3, 4, 5, 6].map((zone) => (
                      <SelectItem
                        key={zone}
                        item={'zone ' + zone}
                        value={zone}
                        col={4}
                        onCheckboxClick={(e) => onCheckboxClick(e, 'zones')}
                        selected={filteredData.zones.includes(zone)}
                      />
                    ))}
                  </div>
                </div>
              }
              <div className="border-bottom">
                <div className="filter-main-label mt-2 mb-2">Delays</div>
                <div className="filter-container d-flex align-items-center ms-3 flex-wrap">
                  {Object.keys(Delays).map((delay) => (
                    <SelectItem
                      key={delay}
                      item={delay}
                      value={Delays[delay]}
                      col={6}
                      onCheckboxClick={(e) => onCheckboxClick(e, 'delays')}
                      selected={filteredData.delays.includes(Delays[delay])}
                    />
                  ))}
                </div>
              </div>
              <div className="border-bottom">
                <div className="filter-main-label mt-2 mb-2">Job Status</div>
                <div className="filter-container d-flex align-items-center ms-3">
                  {['Complete', 'Incomplete'].map((status) => (
                    <SelectItem
                      key={status}
                      item={status}
                      value={status.toLowerCase()}
                      col={6}
                      onCheckboxClick={(e) => onCheckboxClick(e, 'status')}
                      selected={filteredData.status.includes(
                        status.toLowerCase(),
                      )}
                    />
                  ))}
                </div>
              </div>
              <div className="border-bottom">
                <div className="filter-main-label mt-2 mb-2">Job Type</div>
                <div className="filter-container d-flex align-items-center ms-3">
                  {['Scheduled', 'Unscheduled'].map((type) => (
                    <SelectItem
                      key={type}
                      item={type}
                      value={type.toLowerCase()}
                      col={6}
                      onCheckboxClick={(e) => onCheckboxClick(e, 'types')}
                      selected={filteredData.types.includes(type.toLowerCase())}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default FilterModal;
