import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { toast } from 'react-toastify';
import { CallWithAuth } from '../../../../action/apiActions';
import { TRUCK_EVENTS } from '../../../../action/apiPath';
import LoadingScreen from '../../../../component/LoadingScreen';
import LegendDropDown from '../../../Components/LegendDropDown';
import { legendDropDownData } from '../../../constData';
import {
  downloadReportFromServer,
  formatTimelineReport,
  getFilteredTimestamp,
  timestampToHoursMinsSec,
} from '../../../utils/helper';
import { DropdownData } from '../../DropdownData';
import Downloadforviewreport from '../../components/Downloadfotviewreport';
import TimelineFilter from '../../components/TimelineFilter';
import DownloadIcon from '../../components/download.svg';
import emailIcon from '../../components/email.svg';
import ReportDownloadModal from './ReportDownloadModal';

export default function Report({
  date,
  setIsReportModalOpen,
  asset_ID,
  tripStartTime,
  tripEndTime,
  trip_id,
  setSelectedRowForMenu,
}) {
  const [loading, setLoading] = useState(false);
  const source = useRef(axios.CancelToken.source());
  const [reportData, setReportData] = useState([]);
  const [reportDataFiltered, setReportDataFiltered] = useState([]);
  const [filterReportDataValues, setFilterReportDataValues] = useState({
    from: timestampToHoursMinsSec(tripStartTime),
    to: timestampToHoursMinsSec(tripEndTime),
    speed: DropdownData.timeline.speed.defaultvalues,
    event: DropdownData.timeline.event.defaultvalues,
    alerts: DropdownData.timeline.alerts.defaultValues,
    signal: DropdownData.timeline.signal.defaultValues,
  });
  const [isReportDownloadMenuOpen, setIsReportDownloadMenuOpen] =
    useState(false);
  const [apiDataLoading, setApiDataLoading] = useState(false);
  const [reportDownloadFilterModal, setReportDownloadFilterModal] =
    useState(false);
  const [toShowFilter, setShowFilter] = useState(false);
  const [selectedOptionNo, setSelectedOptionNo] = useState(0);

  const tripTimelineReportOptions = [
    { displayName: 'Download Report (CSV)', icon: DownloadIcon },
    { displayName: 'Email Report (CSV)', icon: emailIcon },
  ];

  useEffect(() => {
    source?.current?.cancel();
    source.current = axios.CancelToken.source();
    fetchTripshistoryReport(source.current.token);
  }, []);

  const fetchTripshistoryReport = async (cancelToken) => {
    try {
      setLoading(true);
      const payload = {
        asset_ID: asset_ID,
        activity_start_time: !filterReportDataValues?.from?.hours
          ? tripStartTime
          : getFilteredTimestamp(filterReportDataValues?.from, date),
        activity_end_time: !filterReportDataValues?.to?.hours
          ? tripEndTime
          : getFilteredTimestamp(filterReportDataValues?.to, date),
        date,
      };
      const response = await CallWithAuth(
        'POST',
        TRUCK_EVENTS,
        payload,
        cancelToken,
      );

      if (response?.res?.status == 200) {
        const events = formatTimelineReport(response?.res?.data?.data);
        setReportData(events);
        setReportDataFiltered(events);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error);
      setLoading(false);
      setIsReportModalOpen(false);
    }
  };

  const filterByEvent = (unFilteredData) => {
    return unFilteredData.filter((subitem) =>
      filterReportDataValues?.event?.length === 6
        ? true
        : filterReportDataValues?.event?.includes(subitem?.event),
    );
  };
  const filterBySpeed = (unFilteredData) => {
    return unFilteredData.filter((subitem) => {
      // filterReportDataValues?.speed?.includes(subitem?.speed)
      const numericSpeed = parseFloat(subitem?.speed.match(/\d+/)[0]) ?? 0;
      return filterReportDataValues?.speed.some((range) => {
        const [start, end] = range.toString().split('-').map(Number);

        if (end !== undefined) {
          return numericSpeed >= start && numericSpeed < end;
        } else {
          return numericSpeed >= start;
        }
      });
    });
  };
  const filterByAlert = (unFilteredData) => {
    return unFilteredData.filter((subitem) =>
      filterReportDataValues?.alerts?.length === 2
        ? true
        : filterReportDataValues?.alerts?.includes(subitem?.alert),
    );
  };
  const filterBySignal = (unFilteredData) => {
    return unFilteredData.filter((subitem) =>
      filterReportDataValues?.signal?.includes(subitem?.signal),
    );
  };

  useEffect(() => {
    const filterData = () => {
      let filteredData = filterByTime(reportData);
      filteredData = filterByEvent(filteredData);
      filteredData = filterByAlert(filteredData);
      filteredData = filterBySpeed(filteredData);
      filteredData = filterBySignal(filteredData);
      setReportDataFiltered(filteredData);
    };
    filterData();
  }, [filterReportDataValues]);

  const filterByTime = (unFilteredData) => {
    const startTime = getFilteredTimestamp(filterReportDataValues?.from, date);
    const endTime = getFilteredTimestamp(filterReportDataValues?.to, date);
    return unFilteredData.filter(
      (subitem) =>
        (!startTime || subitem.timestamp >= startTime) &&
        (!endTime || subitem.timestamp <= endTime),
    );
  };

  const reportDownloadMenuSelectHandler = async (
    optionNo,
    filterReportDataValues,
  ) => {
    if (optionNo == 0) {
      setApiDataLoading(true);
      source?.current?.cancel();
      source.current = axios.CancelToken.source();
      await downloadReportFromServer(
        date,
        getFilteredTimestamp(filterReportDataValues.from, date),
        getFilteredTimestamp(filterReportDataValues.to, date),
        trip_id,
        source.current.token,
        [asset_ID],
        '',
        'TRIP_TIMELINE',
        false,
      );
      setApiDataLoading(false);
    } else if (optionNo == 1) {
      setApiDataLoading(true);
      source?.current?.cancel();
      source.current = axios.CancelToken.source();
      await downloadReportFromServer(
        date,
        getFilteredTimestamp(filterReportDataValues.from, date),
        getFilteredTimestamp(filterReportDataValues.to, date),
        trip_id,
        source.current.token,
        [asset_ID],
        '',
        'TRIP_TIMELINE',
        true,
      );
      setApiDataLoading(false);
    }
  };

  const onApply = useCallback((filter, optionNo) => {
    setReportDownloadFilterModal(false);
    setShowFilter(false);
    reportDownloadMenuSelectHandler(optionNo, filter);
  }, []);

  const onCancel = useCallback(() => {
    setReportDownloadFilterModal(false);
    setShowFilter(false);
  }, []);

  return (
    <>
      {apiDataLoading && <LoadingScreen />}
      <div
        className={'full-screen-modal'}
        style={{ right: 0, backgroundColor: '#FBFDFF', position: 'fixed' }}
      >
        <div className="modal-heading">
          <div className="px-3 d-flex w-100 justify-content-between align-items-center">
            <div
              className="justify-content-between align-items-center"
              style={{ marginLeft: '1.875rem' }}
            >
              <span
                style={{
                  font: 'normal normal bold 12px/15px Open Sans',
                  color: '#1A1A1A',
                }}
              >
                {asset_ID} |{' '}
                {new Date(date).toLocaleDateString('en-NZ', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  timeZone: 'Pacific/Auckland',
                })}
              </span>
            </div>
            <CgClose
              style={{ justifyContent: 'flex-end' }}
              onClick={() => {
                setIsReportModalOpen(false);
                setReportDataFiltered([]);
                setSelectedRowForMenu('');
              }}
              size={'2.3rem'}
              className="px-2"
            />
          </div>
        </div>
        <div
          style={{
            height: 'calc(100vh - 4.4rem)',
            // width: '5000px',
            padding: '1rem 8rem',
            overflowY: 'scroll',
          }}
        >
          <div className="d-flex justify-content-between">
            <div style={{ font: 'normal normal bold 1rem/2rem Open Sans' }}>
              Trip Report
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center">
                  <LegendDropDown data={legendDropDownData} />
                  <TimelineFilter
                    toShowFilter={true}
                    setFilteredData={setFilterReportDataValues}
                    filteredData={filterReportDataValues}
                    from={timestampToHoursMinsSec(tripStartTime)}
                    to={timestampToHoursMinsSec(tripEndTime)}
                  />
                  <div
                    className="ml-1 mx-1"
                    style={{
                      height: '100%',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <Downloadforviewreport
                      disabled={reportData.length === 0}
                      uniqueName={'reportDownload'}
                      menuOptions={tripTimelineReportOptions}
                      color={'#E2ECFC'}
                      menuVisible={isReportDownloadMenuOpen}
                      setMenuVisible={(value) =>
                        setIsReportDownloadMenuOpen(value)
                      }
                      onMenuSelect={(optionNo) => {
                        setSelectedOptionNo(optionNo);
                        onApply(filterReportDataValues, optionNo);
                        // setReportDownloadFilterModal(true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <ReportDownloadModal
                optionNo={selectedOptionNo}
                onApply={onApply}
                onCancel={onCancel}
                filterData={filterReportDataValues}
                visible={reportDownloadFilterModal}
              />
            </div>
          </div>
          <table
            className="trips-report-table"
            style={{
              margiTop: '1rem',
              backgroundColor: '#FFFFFF',
              border: '1px solid #EBEBEB',
              borderRadius: '4px',
              width: '100%',
              height: 'calc(100vh -2rem)',
              borderCollapse: 'collapse',
              fontSize: '0.7rem',
            }}
          >
            <thead>
              <tr style={{ borderLeft: '1.375' }}>
                <th style={{ width: '3%' }}></th>
                <th style={{ width: '9%' }}>Time</th>
                <th style={{ width: '9%' }}>Event</th>
                <th style={{ width: '7%' }}>Message No.</th>
                <th style={{ width: '10%' }}>Location</th>
                <th style={{ width: '7%' }}>Speed</th>
                <th style={{ width: '8%' }}>Alert</th>
                <th style={{ width: '7%' }}>Heading</th>
                <th style={{ width: '7%' }}>Altitude</th>
                <th style={{ width: '7%' }}>Satellite</th>
                <th style={{ width: '8%' }}>Signal Strength</th>
              </tr>
            </thead>
            {loading ? (
              <LoadingScreen />
            ) : (
              <tbody>
                {reportDataFiltered.map((row) => (
                  <tr
                    style={{
                      position: 'relative',
                      height: '2.5rem',
                      font: 'normal normal normal 0.915rem/1.125rem Open Sans',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        marginLeft: '10px',
                        width: '7%',
                      }}
                    >
                      {row.alertType == 0 && (
                        <div
                          className="asm-legend-box1"
                          style={{
                            left: 0,
                            position: 'absolute',
                            background: '#ea9336',
                            width: '0.6rem',
                            height: '1rem',
                          }}
                        />
                      )}
                      {row.alertType == 1 ? (
                        <div
                          className="asm-legend-box1"
                          style={{
                            position: 'absolute',
                            background: '#73bf63',
                            width: '0.6rem',
                            left: '1.2rem',
                            height: '1rem',
                          }}
                        />
                      ) : row.alertType == 2 ? (
                        <div
                          className="asm-legend-box1"
                          style={{
                            position: 'absolute',
                            background: '#181cf5',
                            width: '0.6rem',
                            left: '1.2rem',
                            height: '1rem',
                          }}
                        />
                      ) : row.alertType == 3 ? (
                        <div
                          className="asm-legend-box1"
                          style={{
                            position: 'absolute',
                            background: '#e23123',
                            width: '0.6rem',
                            left: '1.2rem',
                            height: '1rem',
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <td style={{ width: '7%' }}>
                      <div
                        style={{
                          font: 'normal normal normal 12px/18px Open Sans',
                          color: '#707070',
                        }}
                      >
                        {row.time || '-'}
                      </div>
                    </td>

                    <td style={{ width: '7%' }}>
                      <div
                        className=""
                        style={{
                          font: 'normal normal normal 11px/15px Open Sans',
                          // color: '#707070'
                        }}
                      >
                        {row.event || '-'}
                      </div>
                    </td>

                    <td style={{ width: '7%' }}>
                      <div
                        className=""
                        style={{
                          font: 'normal normal normal 11px/15px Open Sans',
                        }}
                      >
                        {row.messageNo || '-'}
                      </div>
                    </td>
                    <td style={{ width: '7%' }}>
                      <div
                        style={{
                          textDecoration: 'underline',
                          textDecorationColor: '#3080F1',
                          font: 'normal normal normal 11px/15px Open Sans',
                        }}
                        className=""
                      >
                        {row.location.latitude}, {row.location.longitude}
                      </div>
                    </td>
                    <td style={{ width: '7%' }}>
                      <div
                        className=""
                        style={{
                          font: 'normal normal normal 11px/15px Open Sans',
                        }}
                      >
                        {row.speed || '-'}
                      </div>
                    </td>
                    <td style={{ width: '7%' }}>
                      <div
                        style={{
                          font: 'italic normal normal 11px/15px Open Sans',
                        }}
                        className=""
                      >
                        {row.alert || '-'}
                      </div>
                    </td>
                    <td style={{ width: '7%' }}>
                      <div
                        className=""
                        style={{
                          font: 'normal normal normal 11px/15px Open Sans',
                        }}
                      >
                        {row.heading || '-'}
                      </div>
                    </td>
                    <td style={{ width: '7%' }}>
                      <div
                        className=""
                        style={{
                          font: 'normal normal normal 11px/15px Open Sans',
                        }}
                      >
                        {row.altitude || '-'}
                      </div>
                    </td>
                    <td style={{ width: '7%' }}>
                      <div
                        className=""
                        style={{
                          font: 'normal normal normal 11px/15px Open Sans',
                        }}
                      >
                        {row.satellites || '-'}
                      </div>
                    </td>
                    <td style={{ width: '7%' }}>
                      <div
                        className=""
                        style={{
                          font: 'normal normal normal 11px/15px Open Sans',
                        }}
                      >
                        {row.signal || '-'}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
}
