import React, { useRef, useState } from 'react';
import UserFilterModal from './HistoryFilterModal/HistoryFilterModal';
import FilterIcon from '../../assets/icons/Filter.svg';
import SearchIcon from '../../assets/icons/Search.svg';
import DownloadIcon from '../../assets/icons/download.svg';
import { Image } from 'react-bootstrap';

const Search = (props) => {
  const inputRef = useRef(null);
  const { onChange = () => {}, value = '', type = '', style = {} } = props;
  return (
    <div className="d-flex align-items-center">
      <input
        style={style}
        type={type}
        value={value}
        ref={inputRef}
        placeholder="Search"
        className="mm-search-input"
        onChange={onChange}
      />
      <img
        src={SearchIcon}
        className="mm-search-icon"
        onClick={() => inputRef.current.focus()}
        alt="search"
      />
    </div>
  );
};

const Filter = (props) => {
  const { toShowFilter, openModal } = props;

  return (
    <div
      onClick={openModal}
      className={`${
        toShowFilter ? 'd-flex' : 'd-none'
      } align-items-center  filter-div cursor-pointer`}
    >
      <img style={{ height: '2.7rem' }} src={FilterIcon} alt="filters" />
      <span style={{ color: '#000', fontSize: '1.1rem' }} className=" p-1 ps-2">
        Filter
      </span>
    </div>
  );
};

// const Download = (props) => {
//   {showDownload,onDownloadClick}=props;
//   return (
//     <div>

//     </div>
//   )
// };

const SubHeader = (props) => {
  const {
    downloadFields,
    setDownloadFields,
    setDateChanged = () => {},
    fetchTrucks,
    waterTowerFilter,
    userFilter,
    toShowFilter,
    SubHeaderVisible,
    onSearchChange,
    setFilteredData,
    filteredData,
    onSaveClick,
    showDownload,
    selectedDate = '',
    onDownloadClick,
    hideSearch,
    handleSearch = () => {},
    setSelectedDate = () => {},
    searchQuery = '',
    saveHandler = () => {},
  } = props;

  const [openFilterModal, setOpenFilterModal] = useState(false);

  const saveAndClose = (filterData, hasFiltered) => {
    setOpenFilterModal(false);
    onSaveClick(filterData, hasFiltered);
  };

  return (
    <div
      className={`${
        SubHeaderVisible ? 'd-flex' : 'd-none'
      } align-items-center justify-content-center  subheader`}
      style={{ marginBottom: 0, marginTop: 0 }}
    >
      {!hideSearch && (
        <div style={{ position: 'relative', left: '50px' }}>
          <Search
            style={{ padding: '0px', width: '90%' }}
            type="text"
            value={searchQuery}
            onChange={(e) => handleSearch(e)}
          />
        </div>
      )}
      <div className="mm-date-input d-flex align-items-center justify-content-center ">
        <label
          style={{
            padding: 0,
            font: 'normal normal normal 12px/14px Open Sans',
            fontSize: '1rem',
          }}
          className=" mx-2"
          htmlFor="dateInput"
        >
          Date:
        </label>
        <input
          type="date"
          id="dateInput"
          style={{
            height: '2rem',
            borderRadius: '15px',
            margin: '0px',
            width: '9.2rem',
            backgroundColor: '#FAFAFA',
            borderColor: '#EBEBEB',
          }}
          className="form-control"
          value={selectedDate}
          onChange={(e) => {
            setSelectedDate(e.target.value);
            setDateChanged(false);
            const obj = [...downloadFields];
            obj[0].value = e.target.value;
            setDownloadFields(obj);
          }}
        />
      </div>
      <div style={{ marginLeft: '6px' }} className="mt-2">
        <Filter
          userFilter={props.userFilter}
          filteredData={filteredData}
          toShowFilter={toShowFilter}
          openModal={() => setOpenFilterModal(true)}
        />
      </div>
      {showDownload && (
        <div style={{ marginLeft: '15px' }} className=" mt-2">
          <div
            onClick={onDownloadClick}
            className={`${
              true ? 'd-flex' : 'd-none'
            } align-items-center filter-div cursor-pointer`}
          >
            <Image
              src={DownloadIcon}
              alt="download"
              style={{ height: '1.1rem' }}
              className=""
            />

            <span
              style={{ color: '#000', fontSize: '1.1rem' }}
              className=" p-1 ps-2 me-2"
            >
              Download
            </span>
          </div>
        </div>
      )}

      {userFilter && openFilterModal ? (
        <div className="test-class">
          <UserFilterModal
            fetchTrucks={fetchTrucks}
            saveHandler={saveHandler}
            modalOpen={openFilterModal}
            modalToggle={() => setOpenFilterModal(false)}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            onSaveClick={saveAndClose}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SubHeader;
