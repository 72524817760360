import React from 'react';
import { Logo } from '../../../pages/assets/logo';
import { capitaliseFirstLetter, currentDate } from '../../utils/helper';
import moment from 'moment';

function Header(props) {
  const formatMonthYear = (date) => {
    if (date === 'month') return 'MMMM';
    else return 'YYYY';
  };
  return (
    <div className="header mt-2">
      <div className="report-logo">
        <Logo />
      </div>
      <div className="pdf-header">
        {props.date === 'week' ? (
          <div>
            {currentDate(props?.data)} - {currentDate(props?.endDate)}
          </div>
        ) : props.date === 'month' ? (
          <>
            <div>
              {capitaliseFirstLetter(props.date)}:{' '}
              {moment(props.data).format(formatMonthYear(props.date))}-
              {`${props.data.split('-')[0].substring(2, 4)}`}
            </div>
          </>
        ) : props.date === 'year' ? (
          <>
            <div>
              {capitaliseFirstLetter(props.date)}:{' '}
              {moment(props.data).format(formatMonthYear(props.date))}
            </div>
          </>
        ) : (
          <>
            <div>{currentDate(props?.data ?? new Date())}</div>
            <div>12AM - 11:59PM</div>
          </>
        )}
      </div>
    </div>
  );
}

export default Header;
