import './index.css';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { AssetStatus } from '../../utils/constants';
import close from '../../../pages/assets/CloseWithBg.svg';
import Button from '../../../pages/components/Button/Button';
import { getColorByStatus } from '../../AssetList/AssetTable';
import { formatSecondstoHHMMSS } from '../../utils/helper';
import { useEffect, useState } from 'react';

const MarkerFlyout = ({ data }) => {
  const [currentUnixTime, setCurrentUnixTime] = useState(moment().unix());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentUnixTime(moment().unix());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      style={{
        height: '168px',
        width: '235px',
        boxShadow: '0px 0px 45px #38383833',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          backgroundColor: getColorByStatus(data.Status),
          backgroundBlendMode: 'lighten',
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6))',
          borderRadius: '15px 15px 0px 0px',
          display: 'flex',
          alignItems: 'center',
          padding: '15px',
          gap: '8px',
          fontSize: '12px',
          fontWeight: '600',
          color: '#000000',
        }}
      >
        <div
          style={{
            backgroundColor:
              data.Status === AssetStatus.Disconnected ? '#ffffff' : '#3E4DFF',
            padding: '6px',
            borderRadius: '100%',
          }}
        ></div>
        <div>{data['Asset ID']}</div>
        <img
          style={{ cursor: 'pointer', marginLeft: 'auto', height: '19px' }}
          src={close}
          alt="close"
        />
      </div>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '0px 0px 15px 15px',
          flexGrow: 1,
          padding: '15px',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '2px',
            margin: '3px 0px',
            fontSize: '10px',
            fontWeight: '600',
            color: 'black',
            marginBottom: '3px',
          }}
        >
          <span style={{ color: '#707070', fontWeight: 'normal' }}>
            Status:
          </span>
          <span>
            {data.Status} (
            {data.Status === AssetStatus.Idling
              ? data.statusTime
                ? formatSecondstoHHMMSS(
                    (currentUnixTime ?? moment().unix()) - data.statusTime > 0
                      ? (currentUnixTime ?? moment().unix()) - data.statusTime
                      : 0,
                  )
                : '00:00:00'
              : formatSecondstoHHMMSS(
                  currentUnixTime - data?.activity[0]?.startTime,
                )}{' '}
            h)
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '3px',
            margin: '3px 0px',
            fontSize: '10px',
            fontWeight: '600',
            color: 'black',
            marginBottom: '11px',
          }}
        >
          <span style={{ color: '#707070', fontWeight: 'normal' }}>
            Time Since Last Update:
          </span>
          <span>
            {data.lastSocketTime
              ? formatSecondstoHHMMSS(
                  (currentUnixTime ?? moment().unix()) - data.lastSocketTime > 0
                    ? (currentUnixTime ?? moment().unix()) - data.lastSocketTime
                    : 0,
                )
              : data.Current?.tripLastUpdate
              ? formatSecondstoHHMMSS(
                  (currentUnixTime ?? moment().unix()) -
                    data.Current?.tripLastUpdate >
                    0
                    ? (currentUnixTime ?? moment().unix()) -
                        data.Current?.tripLastUpdate
                    : 0,
                )
              : '00:00:00'}{' '}
            h
          </span>
        </div>
        <Button
          title={'View Trips'}
          onClick={() => {
            window.location.href = `/asset-tracking?type=history&asset=${data?.['Asset ID']}`;
          }}
        />
      </div>
    </div>
  );
};

export default observer(MarkerFlyout);
