import React from 'react';

const SearchIcon = ({ height = 20, width = 20, color = '#6f7070' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
  >
    <g id="Group_496" data-name="Group 496" transform="translate(0.326)">
      <rect
        id="Rectangle_40"
        data-name="Rectangle 40"
        width="20"
        height="20"
        transform="translate(-0.326)"
        fill="none"
      />
      <path
        id="Path_741"
        data-name="Path 741"
        d="M693.585,60.6l-3.121-3.121a5.122,5.122,0,1,0-.92.859l3.152,3.151Zm-10.979-6.474a3.931,3.931,0,1,1,3.931,3.931A3.936,3.936,0,0,1,682.606,54.126Z"
        transform="translate(-677.703 -45.214)"
        fill={color}
      />
    </g>
  </svg>
);

export default SearchIcon;
