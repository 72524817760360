import { FC, ReactNode } from 'react';
import './styles.css';

type ModalProps = {
  isOpen: boolean;
  header?: ReactNode;
  onClose: () => void;
  noDownloadIcon?: boolean;
  children: ReactNode;
  headerRightChild?: ReactNode;
  removeHeaderMargin?: boolean;
  width?: string;
};
const PopupModal: FC<ModalProps> = (props) => {
  if (!props.isOpen) return null;
  return (
    <div className="popUp-container">
      <div
        className="bg-light rounded p-4 flex justify-content-evenly"
        style={props.width ? { width: props.width } : {}}
      >
        <div className="d-flex justify-content-between flex-row h-auto ">
          <div className="fw-bold">{props.header ?? null}</div>
          <div className="d-flex align-align-items-center">
            {props.headerRightChild}
            <button
              className={`frameless-button ${
                props.removeHeaderMargin ? '' : 'mb-3'
              }`}
              onClick={props.onClose}
            >
              <div>X</div>
            </button>
          </div>
        </div>
        <div
          className="text-center d-flex flex-column"
          style={{ fontSize: '14px' }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default PopupModal;
