import { useEffect } from 'react';
import DownloadIcon from './download.svg';

export default function Downloadforviewreport({
  menuVisible,
  setMenuVisible,
  onMenuSelect,
  menuOptions,
  uniqueName,
  color,
  disabled,
  icon,
}) {
  const nameWithoutWhiteSpace = uniqueName
    ? uniqueName.split(' ').join('')
    : '';

  useEffect(() => {
    const clickListener = (e) => {
      if (e.target.id !== `keepDropdownOpen${nameWithoutWhiteSpace}`) {
        setMenuVisible(false);
      }
    };
    document.addEventListener('mousedown', clickListener);

    return () => {
      document.removeEventListener('mousedown', clickListener);
    };
  }, [nameWithoutWhiteSpace]);

  return (
    <div
      id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
      style={{ position: 'relative' }}
    >
      <div
        id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
        className={`px-2 d-flex align-items-center justify-content-center rounded`}
        onClick={(e) => {
          e.stopPropagation();
          setMenuVisible(!menuVisible);
        }}
        style={{
          cursor: 'pointer',
          height: '1.5rem',
          width: '6rem',
          // backgroundColor: disabled ? 'grey' : color ? color : 'transparent',
        }}
      >
        <img
          style={{ width: '1.2rem', height: '1.2rem', objectFit: 'contain' }}
          src={icon ? icon : DownloadIcon}
          alt="download"
          className="subheader-download ms-2 cursor-pointer"
        />
        <div className="fw-bold" style={{ fontSize: '12px' }}>
          Download
        </div>
        {/* <img id={`keepDropdownOpen${nameWithoutWhiteSpace}`} style={{ width: '1.2rem', height: '1.2rem', objectFit: 'contain' }} src={icon ? icon : DownloadIcon} alt="" /> */}
      </div>
      {menuVisible && !disabled && (
        <div
          id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
          className="d-flex flex-column py-2 mt-2"
          style={{
            right: '0',
            backgroundColor: 'white',
            position: 'absolute',
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
            border: '1px solid #E1E8F4',
            borderRadius: '0.5rem',
            cursor: 'pointer',
            zIndex: '2000',
            minWidth: '200px',
            padding: '10px 15px',
          }}
        >
          {menuOptions?.map((option, idx) => (
            <div
              id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
              key={idx}
              className="asm-hover-blue d-flex align-items-center p-1 py-2"
              onClick={(e) => {
                e.stopPropagation();
                setMenuVisible(false);
                console.log('hey');
                onMenuSelect(idx);
              }}
            >
              <img
                id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                style={{
                  height: '0.6rem',
                  width: '1rem',
                  objectFit: 'contain',
                }}
                src={option.icon}
                alt=""
              />
              <span
                id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                style={{
                  textAlign: 'left',
                  fontWeight: '600',
                  fontSize: '0.7rem',
                  paddingLeft: '.6rem',
                }}
              >
                {option.displayName}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
