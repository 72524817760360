import React, { useCallback, useEffect, useState } from 'react';
import HistoryFilterModal from './HistoryFilterModal';
import FilterIcon from '../../../assets/icons/Filter.svg';
import SelectedHistoryFilterIcon from '../../../assets/icons/SelectedHistoryFilterIcon.svg';
import './styles.scss';
const Filter = (props) => {
  const { toShowFilter, openModal, openFilterModal } = props;
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={openModal}
      className={`${
        toShowFilter ? 'd-flex' : 'd-none'
      } align-items-center  filter-div cursor-pointer`}
    >
      <img
        src={
          isHovered
            ? SelectedHistoryFilterIcon
            : openFilterModal
            ? SelectedHistoryFilterIcon
            : FilterIcon
        }
        alt="filters"
      />
      <span
        className="font-13 p-1 asm-history-filter"
        style={{
          color: isHovered ? '#377FF2' : openFilterModal ? '#377FF2' : 'black',
        }}
      >
        Filter
      </span>
    </div>
  );
};
const HistoryFilter = (props) => {
  const {
    toShowFilter,
    modalOpen,
    modalToggle,
    filteredData = {},
    setFilteredData = () => {},
    saveHandler,
    fetchTrucks,
    userFilter,
    onSaveClick = () => {},
  } = props;

  const [openFilterModal, setOpenFilterModal] = useState(false);

  const saveAndClose = (filterData, hasFiltered) => {
    setOpenFilterModal(false);
    onSaveClick(filterData, hasFiltered);
  };

  return (
    <div className="test-class" style={{ borderRadius: '4px 0px 0px 0px' }}>
      <Filter
        userFilter={props.userFilter}
        filteredData={filteredData}
        toShowFilter={toShowFilter}
        openModal={() => setOpenFilterModal(true)}
        openFilterModal={openFilterModal}
      />

      {openFilterModal && HistoryFilterModal ? (
        <HistoryFilterModal
          modalOpen={openFilterModal}
          modalToggle={() => setOpenFilterModal(false)}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          saveHandler={saveAndClose}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default HistoryFilter;
