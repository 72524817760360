import { useEffect, useRef, useState } from 'react';
import LoadingScreen from '../component/LoadingScreen';
import './index.css';
import { useAssetTrackingContext } from '../../pages/service/AssetTrackingContext';
import { Map } from './map';
import AssetDetailCard from '../component/AssetDetailCard/AssetDetailCard';
import AssetsTableModal from './AssetsTableModal';
import Speedometer from '../component/Speedometer/Speedometer';
import MoreThan3AssetsLayout from './MoreThan3AssetsLayout';
import LiveTimer from '../component/LiveTimer/LiveTimer';

const AssetTrackingLive = () => {
  const [markers, setMarkers] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState();
  const mapRef = useRef(null);
  const {
    assetsData,
    loading,
    selectedAssets,
    setSelectedAssets,
    allEquipments,
  } = useAssetTrackingContext();
  const [showAssetTablePopUp, setShowAssetTablePopUp] = useState(false);

  const handleMarkerClick = ({ marker, select }) => {
    if (select) {
      setSelectedAsset(marker);
    }
  };

  useEffect(() => {
    const filteredAssets = assetsData.filter((asset) =>
      selectedAssets.includes(asset.title),
    );
    if (
      selectedAsset &&
      filteredAssets.findIndex(
        (asset) => asset.title === selectedAsset.title,
      ) === -1
    ) {
      // If the asset is already but that selected asset is not available in the updated selection of assets from the table then we remove it and update it with the 1st asset available in the new selection of assets
      setSelectedAsset(
        filteredAssets.length > 0 ? filteredAssets[0] : undefined,
      );
    }
    setMarkers(filteredAssets);
  }, [assetsData, selectedAssets]);

  const handleCardClick = (asset) => {
    setSelectedAsset(asset);
  };

  const handleOnClose = (title) => {
    if (selectedAsset && selectedAsset.title === title) {
      setSelectedAsset(undefined);
    }
    setSelectedAssets((prev) => prev.filter((asset) => asset !== title));
  };

  return (
    <>
      {loading && <LoadingScreen />}
      <div
        className="d-flex flex-column position-relative"
        style={{
          height: 'calc(100%)',
          backgroundColor: '#DDDDDD',
          overflowY: 'hidden',
          objectFit: 'cover',
        }}
      >
        <div
          className={`AssetsBtn ${showAssetTablePopUp ? 'ActiveBtn' : ''}`}
          onClick={() => setShowAssetTablePopUp(!showAssetTablePopUp)}
        >
          {`Assets (${selectedAssets.length})`}
        </div>

        {showAssetTablePopUp && (
          <AssetsTableModal
            setShowAssetTablePopUp={setShowAssetTablePopUp}
            showAssetTablePopUp={showAssetTablePopUp}
          />
        )}

        {markers.length > 3 ? (
          <MoreThan3AssetsLayout
            markers={markers}
            setSelectedAsset={setSelectedAsset}
            selectedAsset={selectedAsset}
            allEquipments={allEquipments}
          />
        ) : (
          markers.toReversed().map((asset, index) => {
            return (
              <AssetDetailCard
                key={index}
                asset={asset}
                isSelected={
                  selectedAsset && asset.title === selectedAsset.title
                }
                equipment={allEquipments.find(
                  (equipment) => equipment.name === asset.title,
                )}
                onClick={handleCardClick}
                index={index}
                onClose={handleOnClose}
              />
            );
          })
        )}

        {selectedAsset && (
          <Speedometer
            asset={markers.find(
              (marker) => marker.title === selectedAsset.title,
            )}
            equipment={allEquipments.find(
              (equipment) => equipment.name === selectedAsset.title,
            )}
          />
        )}

        {selectedAsset && (
          <LiveTimer
            asset={markers.find(
              (marker) => marker.title === selectedAsset.title,
            )}
          />
        )}

        <Map
          markers={markers}
          onMarkerClick={handleMarkerClick}
          mapRef={mapRef}
          setSelectedAsset={setSelectedAsset}
          selectedAsset={selectedAsset}
        />
      </div>
    </>
  );
};

export default AssetTrackingLive;
