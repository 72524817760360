import React, { createContext, useEffect, useState } from 'react';
import {
  GET_USER_ACCOUNT_DETAILS,
  SAVE_LOGIN_LOGOUT_TIME,
} from '../action/apiPath';

import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
// import openSocket from 'socket.io-client';
import { CallWithOutAuth } from '../action/apiActions';
import axiosInstance from '../utils/axios';
import { CallWithAuth } from '../../assetTracking/action/apiActions';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [status, setStatus] = useState(0);
  const [shiftData, setShiftData] = useState(null);

  const [auth, setAuth] = useState({
    isAuthenticated: false,
    isInitialized: false,
    userId: '',
    roles: [],
    userData: {},
    // socketData: {},
    ipAddress: '',
  });

  let logout_time;

  const send_availabe_time = async (logout_time) => {
    if (auth.userData.email && logout_time && auth.userId) {
      const payload = {
        Id: auth.userId,
        Email: auth.userData.email,

        LogoutTime: logout_time,
      };

      const response = await CallWithOutAuth(
        'POST',
        SAVE_LOGIN_LOGOUT_TIME,
        payload,
      );

      if (response.res.data.status === 200) {
        // console.log(payload);
        //   setEquimentsList(response.res.data.data);
      }
      return;
    }
  };
  const logout = async () => {
    try {
      // todo remove below usage of third party apis
      let res = {};
      try {
        res = await axios.get('https://geolocation-db.com/json/');
      } catch (e) {}
      // console.log(res.data);
      // auth?.socketData?.current?.emit('disconnection', {
      //   id: auth?.userId,
      //   ip: res?.data?.IPv4,
      // });
      // auth?.socketData?.current?.on('disconnect', () => {
      //   // console.log(auth?.socketData?.current.connected); // false
      // });
      // delete axiosInstance.defaults.headers.common.Authorization;
      // localStorage.clear();
      setAuth({
        isAuthenticated: false,
        isInitialized: false,
        userId: '',
        roles: '',
        userData: {},
        // socketData: {},
        ipAddress: '',
      });

      logout_time = new Date();
      send_availabe_time(logout_time);
      toast.success('User logout Successfully', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }
    const decoded = jwt_decode(accessToken);
    const currentTime = Date.now() / 1000;
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // console.log("decoded:---", decoded);
    // LogoutTime=new Date();
    //    console.log(auth.userData);

    // console.log("Logout Time--> ", new Date());
    console.log('currentTime:--', Math.ceil(currentTime));

    return decoded.exp > Math.ceil(currentTime);
  };

  //need to get userData at the time of page reload
  //until loding screen will mount here.

  useEffect(() => {
    async function fetchData() {
      if (
        localStorage.getItem('accessToken') &&
        isValidToken(localStorage.getItem('accessToken'))
      ) {
        try {
          CallWithAuth('GET', GET_USER_ACCOUNT_DETAILS).then(
            async (response) => {
              // console.log("userDetails api response:--\n", response)
              // todo remove below usage of third party apis
              if (response?.res?.data?.status === 200) {
                let res = {};
                try {
                  res = await axios.get('https://geolocation-db.com/json/');
                } catch (e) {}

                // let socket = {};
                // // console.log(API_END_POINT_SOCKET)
                // // '/chat/socket.io'
                // socket.current = openSocket(
                //   process.env.REACT_APP_API_ENDPOINT,
                //   { path: '' },
                //   { query: { data: 'web' } },
                // );
                // socket.current.on('connect', () => {
                //   console.log(
                //     'socket.current.connected--',
                //     socket.current.connected,
                //   ); // true
                //   if (socket.current.connected) {
                //     // alert(response.res.data.data.result.userId)
                //     socket.current.emit('useradd', {
                //       id: response.res.data.data?._id,
                //       from: 'web',
                //       ip: res?.data?.IPv4,
                //     });
                //     socket.current.on('useradded', (data) => {
                //       // console.log(data);
                //     });
                //     // localStorage.setItem('socket', socket);
                //   }
                // });

                setAuth({
                  ...auth,
                  isAuthenticated: true,
                  isInitialized: false,
                  userId: response.res.data.data?._id,
                  roles: response.res.data.data?.isAdmin ? ['Admin'] : [''],
                  userData: response.res.data?.data,
                  // socketData: socket,
                  ipAddress: res?.data?.IPv4,
                });
              } else {
                console.log('=--------------=');
                // logout();
              }
            },
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        if (localStorage.getItem('accessToken')) {
          const headers = {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',
            // Add any other custom headers as needed
          };
          // try {
          //   const res = await axios.get(
          //     "/dust-control/api/auth/refresh-token",
          //     {
          //       headers,
          //     }
          //   );
          //   if (res.status === 200) {
          //     const userData = res.data;
          //     const [token, departments] = userData.accessToken?.split("||");
          //     axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;

          //     localStorage.setItem("accessToken", token);
          //     localStorage.setItem("refreshToken", userData.refreshToken);
          //   } else {
          //     //  logout();
          //   }
          // } catch (error) {
          //   console.log(error);
          // }
        }
        setAuth({
          ...auth,
          isAuthenticated: false,
          isInitialized: false,
          userId: '',
          roles: '',
          userData: {},
        });
      }
    }
    fetchData();
  }, []);

  // if (!localStorage.getItem("accessToken")) {
  //   return (
  //     <h1
  //       style={{
  //         position: "absolute",
  //         top: "40%",
  //         left: "40%",
  //       }}
  //     >
  //       Loading screen......
  //     </h1>
  //   );
  // }

  // const setSocketData = (data) => {
  //   setAuth({
  //     ...auth,
  //     socketData: data,
  //   });
  // };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        logout,
        // setSocketData,
        status,
        shiftData,
        setStatus,
        setShiftData,
      }}
    >
      {/* <NetworkDetector /> */}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
