import CryptoJS from 'crypto-js';
import moment from 'moment';
// import { REACT_APP_AES_KEY } from '../../pages/service/apiHandler';

export const convertMins = (minutes) => {
  const hrs = Math.floor(minutes / 60);
  const mins = minutes % 60;
  let timeString = '';
  if (hrs === 1) timeString = '1 hour ';
  else if (hrs) timeString = `${hrs} hours `;
  if (mins === 1) timeString += '1 min';
  else if (mins) timeString += `${mins} mins`;
  return timeString;
};

export const convertTruckStatusToColor = (status) => {
  switch (status) {
    case 'Pumping':
      return 'green';
    case 'Inactive':
      return 'red';
    default:
    case 'Active':
      return 'orange';
  }
};

export const convertUserStatusToColor = (status) => {
  switch (status) {
    case 'Invited':
      return 'orange';
    case 'Inactive':
      return 'red';
    default:
    case 'Active':
      return 'green';
  }
};

export const getFirstLetter = (name) => {
  return name?.substring(0, 1).toUpperCase();
};

// export const encrypt = (message) => {
//   return CryptoJS.AES.encrypt(message, REACT_APP_AES_KEY).toString();
// };

// export const decrypt = (cipherMessage) => {
//   const bytes = CryptoJS.AES.decrypt(cipherMessage, REACT_APP_AES_KEY);
//   const descryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//   return descryptedData;
// };

export const startOfDay = (date) => {
  const parts = Intl.DateTimeFormat('en-NZ', {
    timeZone: 'Pacific/Auckland',
    hourCycle: 'h23',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).formatToParts(new Date(date));

  const hour = parseInt(parts?.find((i) => i?.type === 'hour')?.value);
  const minute = parseInt(parts?.find((i) => i.type === 'minute')?.value);
  const second = parseInt(parts?.find((i) => i.type === 'second')?.value);
  return new Date(
    1000 *
      Math.floor(
        (new Date(date).getTime() -
          hour * 3600000 -
          minute * 60000 -
          second * 1000) /
          1000,
      ),
  );
};

export const endOfDay = (...args) =>
  new Date(startOfDay(args[0]).getTime() + 86399999);

export const groupBy = (list, key) => {
  return list.reduce((prev, curr) => {
    return {
      ...prev,
      [curr[key]]: [...(prev[key] || []), curr],
    };
  }, {});
};

export function getOrdinal(n) {
  if (typeof n === 'string') {
    n = parseInt(n);
  }
  let ord = ['st', 'nd', 'rd'];
  let exceptions = [11, 12, 13];
  let nth =
    ord[(n % 10) - 1] === undefined || exceptions.includes(n % 100)
      ? 'th'
      : ord[(n % 10) - 1];
  return n + nth;
}

export const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatDateToISO = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const groupByDate = (list, key) => {
  return list.reduce((prev, curr) => {
    return {
      ...prev,
      [formatDate(curr[key])]: [...(prev[formatDate(curr[key])] || []), curr],
    };
  }, {});
};

export const getTime = (time) => {
  return new Date(time).toLocaleTimeString('en-NZ', {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  let res = '';

  if (hours > 0 || minutes > 0) {
    if (hours === 1) {
      res += '1 hour ';
    } else if (hours > 0) {
      res += hours + ' hour ';
    }
    if (minutes > 0) {
      res += minutes + ' min';
    }
  } else {
    res += '0 min';
  }

  return res;
};

export const emailValidation = (email = '') => {
  const trimmed = email.trim();
  if (trimmed === '') {
    return 'Please enter your registered email address';
  } else if (
    !new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ).test(trimmed)
  ) {
    return 'Please enter a valid email address';
  }
  return '';
};

export const passwordValidation = (pword) => {
  const trimmed = pword.trim();
  if (trimmed === '') {
    return 'Please enter password';
  }
  return '';
};

export const lengthValidation = (value, fieldName) => {
  const trimmed = value.trim();
  if (trimmed === '') {
    return `Please enter ${fieldName}`;
  } else if (trimmed.length < 3) {
    return `${getFirstLetter(fieldName).toUpperCase()}${fieldName.slice(
      1,
    )} must have at least 3 letters`;
  }
  return '';
};

export const newPasswordValidation = (pword, confirmPword) => {
  const trimmedPword = pword.trim();
  const trimmedConfirm = confirmPword.trim();
  const errors = { password: '', confirmPassword: '' };
  if (!trimmedPword) errors.password = 'Please enter password';
  else if (trimmedPword.length < 6) {
    errors.password = 'Password must be at least 6 characters';
  }

  if (!trimmedConfirm) errors.confirmPassword = 'Please enter confirm password';
  else if (trimmedPword && trimmedConfirm !== trimmedPword) {
    errors.confirmPassword = 'Passwords do not match';
  }
  return errors;
};

export const generatePin = () => {
  var numbers = Math.floor(1000 + Math.random() * 9000);
  return numbers;
};
export const currentDate = (date) => {
  let dayName = new Date(date).toLocaleDateString('en-NZ', { weekday: 'long' });
  let monthName = new Date(date).toLocaleDateString('en-NZ', { month: 'long' });
  let day = new Date(date).toLocaleDateString('en-NZ', {
    day: 'numeric',
  });
  let dayFormatTh = getOrdinal(day);

  return dayName + ' ' + dayFormatTh + ' ' + monthName;
};

export const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const minuteToHour = (minutes) => Math.round(minutes / 60);

export const formatDateToDDMMYYYY = (date) => {
  if (!date) return '';
  const convertedToDate = new Date(date);
  const day = String(convertedToDate.getDate()).padStart(2, '0');
  const month = String(convertedToDate.getMonth() + 1).padStart(2, '0');
  const year = convertedToDate.getFullYear();
  return `${day}/${month}/${year}`;
};

export const getZones = (job, filteredZones) => {
  const zones = new Set(job.pump_events.map((e) => e.zone).filter(Boolean));
  if (job?.zone?.zone) {
    zones.add(job.zone.zone);
  }
  let filteredZonesArray = filteredZones.filter((zone) =>
    [...zones].includes(zone),
  );
  if (!filteredZonesArray.length) filteredZonesArray = [...zones];
  if (filteredZonesArray.length > 1) {
    return `Zones ${filteredZonesArray.join(', ')}`;
  }
  if (filteredZonesArray.length === 1) {
    return `Zone ${filteredZonesArray[0]}`;
  }
  return '-';
};

export const capitaliseFirstLetter = (word) => {
  if (!word) {
    return '';
  }
  const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
  return capitalized;
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const convertCodeListToNames = (entireList, selected) => {
  if (!selected) return 'None';
  if (selected.length === 0) return 'None';

  const filteredList = entireList
    .filter(({ value, code }) => selected.includes(value ?? code))
    .map(({ name }) => name);
  if (filteredList.length === 0) return 'None';
  if (filteredList.length === entireList.length) return 'All';
  else {
    return filteredList.join(', ');
  }
};
