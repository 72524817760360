import React, { useEffect, useState } from 'react';
import PieChartGraph from '../PieChart';
import { PreCheckEvaluationResult } from '../../models/enums';

const CategoryCard = ({ closeWigets, data }) => {
  const [categoryData, setCategoryData] = useState([]);

  const mapCategoryData = (data) => {
    const categoryMap = {};
    data?.forEach((item) => {
      categoryMap[item.name] = item.data;
    });
    setCategoryData(categoryMap);
  };

  useEffect(() => {
    mapCategoryData(data);
  }, [data]);

  const renderPieChart = (chartData, title) => {
    const total = chartData.reduce((sum, value) => sum + value, 0);
    return (
      total > 0 && (
        <div
          key={title}
          style={{ width: '33%' }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div style={{ height: '114px' }} className="pie-container">
            <PieChartGraph
              showLabel={true}
              key={title + title}
              data={chartData}
              outerRadius={58}
              chartData={[
                {
                  name: PreCheckEvaluationResult.CATEGORY_A_FAILURE,
                  value: chartData[0],
                },
                {
                  name: PreCheckEvaluationResult.CATEGORY_B_FAILURE,
                  value: chartData[1],
                },
                {
                  name: PreCheckEvaluationResult.CATEGORY_C_FAILURE,
                  value: chartData[2],
                },
                { name: PreCheckEvaluationResult.PASS, value: chartData[3] },
              ]}
            />
          </div>
          <div>{title}</div>
        </div>
      )
    );
  };

  return (
    <div
      className="widget-container3 white-background  mt-2"
      style={{ boxShadow: '0px 0px 0px' }}
    >
      <div className="body d-flex w-100">
        {Object.entries(categoryData).map(([categoryName, categoryData]) =>
          renderPieChart(categoryData, categoryName),
        )}
      </div>
    </div>
  );
};

export default CategoryCard;
