import Draggable from 'react-draggable';
import AssetDetailCard from '../component/AssetDetailCard/AssetDetailCard';
import WaterfallModal from '../component/WaterfallModal/WaterfallModal';
import { getColorByStatus } from '../AssetList/AssetTable';
import { AssetStatus } from '../utils/constants';
import { Asset, Equipment } from '../models/models';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type Props = {
  markers: Asset[];
  setSelectedAsset: Dispatch<SetStateAction<undefined | Asset>>;
  selectedAsset: Asset | undefined;
  allEquipments: Equipment[];
};

const MoreThan3AssetsLayout = ({
  markers,
  setSelectedAsset,
  selectedAsset,
  allEquipments,
}: Props) => {
  const [openStatus, setOpenStatus] = useState<string>(
    markers.filter(
      (marker) =>
        marker.Status === AssetStatus.Active ||
        marker.Status === AssetStatus.Idling,
    ).length > 0
      ? AssetStatus.Active
      : AssetStatus.Parked,
  );

  useEffect(() => {
    if (selectedAsset) {
      if (
        (selectedAsset.Status === AssetStatus.Idling ||
          selectedAsset.Status === AssetStatus.Active) &&
        openStatus !== AssetStatus.Active
      ) {
        setOpenStatus(AssetStatus.Active);
      } else if (selectedAsset.Status !== openStatus) {
        setOpenStatus(selectedAsset?.Status);
      }
    }
  }, [selectedAsset]);

  return (
    <>
      <Draggable>
        <div id="selected-multi">
          <WaterfallModal
            isOpen={openStatus === AssetStatus.Active}
            onClick={() =>
              setOpenStatus((prev) => {
                if (prev === AssetStatus.Active) {
                  return '';
                } else {
                  return AssetStatus.Active;
                }
              })
            }
            triggerContent={
              <div
                className={'pill'}
                style={{
                  backgroundColor: getColorByStatus(AssetStatus.Active),
                }}
              >
                {AssetStatus.Active} (
                {
                  markers.filter(
                    (marker) =>
                      marker.Status === AssetStatus.Active ||
                      marker.Status === AssetStatus.Idling,
                  ).length
                }
                )
              </div>
            }
            content={markers
              .filter(
                (marker) =>
                  marker.Status === AssetStatus.Active ||
                  marker.Status === AssetStatus.Idling,
              )
              .map((marker) => {
                return (
                  <div
                    className="selected-multi-title"
                    style={{
                      backgroundColor:
                        marker.title === selectedAsset?.title
                          ? '#EFEFEF'
                          : '#FAFAFA',
                    }}
                    onClick={() => setSelectedAsset(marker)}
                  >
                    {marker.title}
                  </div>
                );
              })}
            contentContainerHeight={24 * 4}
          />
          <WaterfallModal
            isOpen={openStatus === AssetStatus.Disconnected}
            onClick={() =>
              setOpenStatus((prev) => {
                if (prev === AssetStatus.Disconnected) {
                  return '';
                } else {
                  return AssetStatus.Disconnected;
                }
              })
            }
            triggerContent={
              <div
                className={'pill'}
                style={{
                  backgroundColor: getColorByStatus(AssetStatus.Disconnected),
                  color: 'white',
                }}
              >
                {AssetStatus.Disconnected} (
                {
                  markers.filter(
                    (marker) => marker.Status === AssetStatus.Disconnected,
                  ).length
                }
                )
              </div>
            }
            content={markers
              .filter((marker) => marker.Status === AssetStatus.Disconnected)
              .map((marker) => {
                return (
                  <div
                    className="selected-multi-title"
                    style={{
                      backgroundColor:
                        marker.title === selectedAsset?.title
                          ? '#EFEFEF'
                          : '#FAFAFA',
                    }}
                    onClick={() => setSelectedAsset(marker)}
                  >
                    {marker.title}
                  </div>
                );
              })}
            contentContainerHeight={24 * 4}
          />
          <WaterfallModal
            isOpen={openStatus === AssetStatus.Parked}
            onClick={() =>
              setOpenStatus((prev) => {
                if (prev === AssetStatus.Parked) {
                  return '';
                } else {
                  return AssetStatus.Parked;
                }
              })
            }
            triggerContent={
              <div
                className={'pill'}
                style={{
                  backgroundColor: getColorByStatus(AssetStatus.Parked),
                }}
              >
                {AssetStatus.Parked} (
                {
                  markers.filter(
                    (marker) => marker.Status === AssetStatus.Parked,
                  ).length
                }
                )
              </div>
            }
            content={markers
              .filter((marker) => marker.Status === AssetStatus.Parked)
              .map((marker) => {
                return (
                  <div
                    className="selected-multi-title"
                    style={{
                      backgroundColor:
                        marker.title === selectedAsset?.title
                          ? '#EFEFEF'
                          : '#FAFAFA',
                    }}
                    onClick={() => setSelectedAsset(marker)}
                  >
                    {marker.title}
                  </div>
                );
              })}
            contentContainerHeight={24 * 4}
          />
        </div>
      </Draggable>
      {selectedAsset && (
        <AssetDetailCard
          index={0}
          asset={markers?.find((asset) => asset.title === selectedAsset.title)}
          equipment={allEquipments?.find(
            (equipment) => equipment.name === selectedAsset.title,
          )}
          isSelected={true}
          onClose={() => setSelectedAsset(undefined)}
          left={233 + 29 + 10}
        />
      )}
    </>
  );
};

export default MoreThan3AssetsLayout;
