import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import ArrowDown from '../../../assets/icons/arrowDown.png';
import SearchIcon from '../../../assets/icons/Search.svg';
import { convertCodeListToNames } from './helper';
// import { convertCodeListToNames } from "../utils/helper";
import './styles.scss';
const DropdownField = ({
  name, // name displayed on form
  fieldKey, // accessor key for values/filter object
  selectedOptions, // will be an array if multiselect is true, else will be the value
  allOptions, // {value: string | number, name: string}[]
  multiselect, // boolean
  handleChange,
  labelClass = '',
  upperLevelClassName = 'd-flex flex-row justify-content-between asm-field-container',
  errorMessage,
}) => {
  const [isDropdownVisible, setDropdown] = useState(false);
  const [dropdownSearch, setDropdownSearch] = useState('');

  const nameWithoutWhiteSpace = name ? name.split(' ').join('') : '';
  const allChosenOptions = multiselect
    ? allOptions?.filter(({ value }) => selectedOptions?.includes(value))
    : []; // doesn't include selectedOptions that are not displayed to user (i.e. hidden department)

  useEffect(() => {
    const clickListener = (e) => {
      if (e.target.id !== `keepDropdownOpen${nameWithoutWhiteSpace}`) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', clickListener);

    return () => {
      document.removeEventListener('mousedown', clickListener);
    };
  }, [nameWithoutWhiteSpace]);

  const selectAll = () => {
    if (allChosenOptions?.length === allOptions?.length) {
      handleChange({
        fieldKey,
        optionValue: [],
        multiselect: true,
        isAll: true,
      });
    } else {
      handleChange({
        fieldKey,
        optionValue: allOptions?.map(({ value }) => value),
        multiselect: true,
        isAll: true,
      });
    }
  };

  return (
    <div className={upperLevelClassName}>
      {name ? (
        <label
          className={labelClass}
          style={{
            font: 'normal normal normal 0.8rem Open Sans',
            color: '#1A1A1A',
            flex: 0.3,
          }}
        >
          {name}
        </label>
      ) : null}

      <div
        className="d-flex flex-column asm-add-user-input"
        style={{ flex: 0.7 }}
      >
        <div className="position-relative">
          {/* open dropdown field */}
          <button
            className="asm-rounded-borders-and-padding"
            id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
            type="button"
            onClick={() => {
              setDropdown(!isDropdownVisible);
            }}
            style={{
              paddingTop: '0.7rem',
              paddingBottom: '0.7rem',
            }}
          >
            <div
              className="d-flex justify-content-between px-1"
              id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
            >
              <div>
                {multiselect
                  ? convertCodeListToNames(allOptions, selectedOptions)
                  : allOptions.find(({ value }) => value === selectedOptions)
                      ?.name}
              </div>
              <div id={`keepDropdownOpen${nameWithoutWhiteSpace}`}>
                <Image
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                  src={ArrowDown}
                  alt="down"
                  style={{
                    height: '0.4rem',
                    width: '0.5rem',
                  }}
                />
              </div>
            </div>
          </button>
          {isDropdownVisible && (
            <div
              className="asm-hover-card asm-dropdown-position"
              id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
            >
              {/* Search field */}
              <div className="border-bottom p-2 pb-1 mb-2">
                <img
                  src={SearchIcon}
                  alt="search"
                  className="ms-1"
                  style={{ height: '1rem', width: '1rem' }}
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                />
                <input
                  placeholder="Search"
                  style={{ fontSize: '0.8rem' }}
                  className="asm-no-focus-border border border-0 ps-1 w-75"
                  onChange={({ target }) => setDropdownSearch(target.value)}
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                  value={dropdownSearch}
                />
              </div>

              {/* select all checkbox */}
              {multiselect && !dropdownSearch && (
                <div
                  className="asm_option-row"
                  style={{ alignItems: 'center' }}
                  onClick={selectAll}
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                >
                  {multiselect && (
                    <input
                      type="checkbox"
                      checked={allChosenOptions?.length === allOptions?.length}
                      readOnly
                      id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                      className="styled-checkbox"
                    />
                  )}
                  <label id={`keepDropdownOpen${nameWithoutWhiteSpace}`}>
                    Select All
                  </label>
                </div>
              )}
              <div className="mm-options">
                {allOptions
                  ?.filter(({ name: n }) =>
                    n?.toLowerCase().includes(dropdownSearch?.toLowerCase()),
                  )
                  ?.map(
                    ({ name: optionName, value: optionValue, icon = null }) => (
                      <div
                        className="asm_option-row"
                        style={{ alignItems: 'center' }}
                        key={optionValue}
                        onClick={() => {
                          handleChange({ fieldKey, optionValue, multiselect });
                          if (!multiselect) setDropdown(false);
                        }}
                        id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                      >
                        {multiselect && (
                          <input
                            type="checkbox"
                            checked={selectedOptions?.includes(optionValue)}
                            readOnly
                            className="styled-checkbox"
                            id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                          />
                        )}
                        <label id={`keepDropdownOpen${nameWithoutWhiteSpace}`}>
                          {icon ? icon : null} {optionName}
                        </label>
                      </div>
                    ),
                  )}
              </div>
              {/* dropdown options */}
            </div>
          )}
        </div>
        {errorMessage && (
          <p className="error-msg-manage-operator">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default DropdownField;
