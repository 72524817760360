import React from 'react';

interface IconProps {
  height?: string | number;
  width?: string | number;
  color?: string;
}

const TripSVG: React.FC<IconProps> = ({
  height = '20.029',
  width = '11.027',
  color = '#2448ad',
}) => (
  <svg
    id="Group_62131"
    data-name="Group 62131"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20.029 11.027"
  >
    <path
      id="Path_36478"
      data-name="Path 36478"
      d="M1402.982,1172.9a1.145,1.145,0,1,0,1.145,1.145A1.145,1.145,0,0,0,1402.982,1172.9Zm0,1.676a.531.531,0,1,1,.531-.531A.531.531,0,0,1,1402.982,1174.58Z"
      transform="translate(-1385.975 -1164.167)"
      fill={color}
    />
    <path
      id="Path_36479"
      data-name="Path 36479"
      d="M851.148,1172.9a1.146,1.146,0,0,0-1.148,1.138v.006a1.148,1.148,0,0,0,2.3,0v-.006A1.146,1.146,0,0,0,851.148,1172.9Zm.532,1.145a.532.532,0,0,1-1.064,0v-.006a.532.532,0,0,1,1.064,0Z"
      transform="translate(-844.443 -1164.167)"
      fill={color}
    />
    <path
      id="Path_36480"
      data-name="Path 36480"
      d="M507.9,787.616h-4.911c-.114,0-.206-.139-.206-.308s.092-.308.206-.308H507.9c.114,0,.206.139.206.308S508.01,787.616,507.9,787.616Z"
      transform="translate(-500.279 -785.469)"
      fill={color}
    />
    <path
      id="Path_36481"
      data-name="Path 36481"
      d="M1304.373,878H1301v6.349h1.609a1.452,1.452,0,0,1,2.838,0h1.6v-3.068Zm-2.439,3.212v-2.259h2l1.856,2.259Z"
      transform="translate(-1287.021 -874.769)"
      fill={color}
    />
    <path
      id="Path_36482"
      data-name="Path 36482"
      d="M380.655,904.616h-5.865c-.121,0-.22-.139-.22-.308s.1-.308.22-.308h5.865c.121,0,.22.139.22.308S380.777,904.616,380.655,904.616Z"
      transform="translate(-374.571 -900.284)"
      fill={color}
    />
    <g id="Group_62132" data-name="Group 62132" transform="translate(1.062)">
      <g id="Group_62131-2" data-name="Group 62131" transform="translate(0)">
        <path
          id="Path_36483"
          data-name="Path 36483"
          d="M733,705v.915h2.954a.931.931,0,0,1,0,1.849H733v.336h1.606a.931.931,0,0,1,0,1.849H733v.691h2.8a.931.931,0,0,1,0,1.849H733v2.091h1.7a1.264,1.264,0,0,1,2.516,0h4.648V705Z"
          transform="translate(-729.566 -705)"
          fill={color}
        />
        <path
          id="Path_36484"
          data-name="Path 36484"
          d="M448.327,1040.616h-6.138c-.126,0-.227-.138-.227-.308s.1-.308.227-.308h6.138c.126,0,.227.139.227.308S448.453,1040.616,448.327,1040.616Z"
          transform="translate(-441.962 -1033.744)"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default TripSVG;
