import { useState, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import ChangePasswordModal from './change-password-modal';
import { AuthContext } from '../../service/auth';
import { EditProfile } from './EditProfile';
import { UserContext } from '../../service/userContext';
import ViewAccount from './view-account';
import EditPin from './edit-pin';
import ViewPin from './view-pin';

// toggleHeaderModal, setHeaderZoneModal]

function ProfileModal({ setVisible }) {
  const [modalShow, setModalShow] = useState({
    editProfile: false,
    editPassword: false,
    viewProfile: false,
    editPin: false,
    viewPin: false,
  });
  const { userInfo: user } = useContext(UserContext);
  const { logOut } = useContext(AuthContext);

  const logOutHandler = async () => {
    await logOut();
    setVisible(false);
  };
  return (
    <div className="profile-div" style={{ zIndex: 5000 }}>
      <Card className="text-left py-2" id="profileCard">
        <button
          className="hover-style py-1 px-4 border-0"
          onClick={() => {
            setModalShow((prev) => ({ ...prev, viewProfile: true }));
          }}
        >
          My account
        </button>
        <button
          className="hover-style py-1 px-4 border-0"
          onClick={logOutHandler}
        >
          Log out
        </button>

        {modalShow.editPassword && (
          <ChangePasswordModal
            onHide={() => {
              setVisible(false);
            }}
          />
        )}
        {modalShow.editProfile && (
          <EditProfile
            onHide={() => {
              setVisible(false);
            }}
            user={user}
          />
        )}
        {modalShow.viewProfile && (
          <ViewAccount
            modalShow={modalShow}
            setModalShow={setModalShow}
            setVisible={setVisible}
          />
        )}
        {modalShow.editPin && (
          <EditPin
            modalShow={modalShow}
            setModalShow={setModalShow}
            setVisible={setVisible}
          />
        )}
        {modalShow.viewPin && (
          <ViewPin
            modalShow={modalShow}
            setModalShow={setModalShow}
            setVisible={setVisible}
          />
        )}
      </Card>
    </div>
  );
}

export default ProfileModal;
