import styles from '../Summary.module.css';
import bulldozer from '../../../assets/bulldozer.png';
import colours from '../../../constants/colours';
import { TruckStatus } from '../../truckCard/truckImageContainer';

type AssesCardProps = {
  status: TruckStatus;
};

const LiveAssetCard = ({ status }: AssesCardProps) => {
  return (
    <div className={styles.liveAsset}>
      <div className={styles.liveAssetTop}>
        <div
          className={styles.liveAssetStatus}
          style={{
            background:
              status === TruckStatus.active ? colours.green : colours.lightGray,
          }}
        ></div>{' '}
        Asset ID
      </div>
      <div className={styles.liveAssetBottom}>
        <div className={styles.liveAssetLabel}>
          <div>Loads:</div>
          <div>Active time:</div>
          <div>Start time:</div>
        </div>
        <div className={styles.liveAssetValue}>
          <div>000</div>
          <div>000 hr</div>
          <div>00:00 am</div>
        </div>
      </div>
    </div>
  );
};

const LoaderSummary = () => {
  return (
    <div
      className={styles.summaryContainer}
      style={{
        flexDirection: 'row',
        padding: '0px',
        gap: '0px',
      }}
    >
      <div className={styles.loaders}>
        <div>
          <div className={styles.summaryTitle}>LOADERS</div>
          <div
            className={styles['summary-section-title']}
            style={{ paddingTop: '10px' }}
          >
            Summary
          </div>
        </div>
        <div className={styles.loaderMetric}>
          <div className={styles.loaderMetricVal}>000</div>
          <div className={styles.loaderMetricSub}>Total Loads</div>
        </div>
        <div className={styles.loaderMetric}>
          <div className={styles.loaderMetricVal}>000</div>
          <div className={styles.loaderMetricSub}>Tonnes</div>
        </div>
      </div>
      <div className={styles.liveData}>
        <div className={styles.liveTop}>
          <div className={styles.liveTopLeft}>
            <div className={styles.summaryTitle}>LIVE DATA</div>
            <div className={styles.liveTitleSub}>3 loaders on site</div>
          </div>
          <div className={styles.liveTopRight}>
            <div className={styles.liveRightSub}>
              <img src={bulldozer} alt="" /> Overburden Handling
            </div>
            <div className={styles.liveStatusCont}>
              <div className={styles.liveStatus}>
                <div
                  className={styles.liveStatusDot}
                  style={{ background: colours.green }}
                ></div>
                Active
              </div>
              <div className={styles.liveStatus}>
                <div
                  className={styles.liveStatusDot}
                  style={{ background: colours.lightGray }}
                ></div>
                Inactive
              </div>
            </div>
          </div>
        </div>
        <div className={styles.liveBottom}>
          <LiveAssetCard status={TruckStatus.active} />
          <div className={styles.divider}></div>
          <LiveAssetCard status={TruckStatus.inactive} />
          <div className={styles.divider}></div>
          <LiveAssetCard status={TruckStatus.inactive} />
        </div>
      </div>
    </div>
  );
};

export default LoaderSummary;
