import { useContext, useState } from 'react';
import { UserContext } from '../../service/userContext';

import * as toast from '../../components/toast';
import { useNavigate } from 'react-router-dom';
import { capitaliseFirstLetter, generatePin } from '../../utils/helper';
import { API_METHODS, ENDPOINTS } from '../../utils/constants';
import FloatingInput from '../../components/FloatingInput';
import BasicButton from '../../components/BasicButton';
import { FormModal } from '../../components/FormModal';
const EditPin = ({ setVisible }) => {
  const navigate = useNavigate();
  const { userInfo, ApiHandler } = useContext(UserContext);
  const [pin, setPin] = useState(userInfo.pin_number);
  const [errMsg, setErrMsg] = useState('');
  const [pinRadioButton, setPinRadioButton] = useState('autogenerate');
  const isInputDisabled = pinRadioButton === 'autogenerate' ? true : false;
  const handlePinChange = () => {
    const newPin = generatePin();
    setPin(newPin);
  };

  const radioButons = [{ value: 'custom' }, { value: 'autogenerate' }];
  const handleSave = async () => {
    if (!pin) return setErrMsg('Pin number cannot be empty');
    if (pin < 0) return setErrMsg('Pin number cannot be negative');
    if (pin.length < 3 || pin.length > 4)
      return setErrMsg('Pin number must be four digits');
    try {
      const res = await ApiHandler({
        reqParam: {
          pin,
          user_id: userInfo._id,
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
          status: userInfo.status,
          user_type: userInfo.type,
        },
        method: API_METHODS.PUT,
        endPoint: ENDPOINTS.editUser,
      });
      if (res.status === 200) {
        setTimeout(() => {
          navigate(0);
        }, 4000);
        toast.success('Details successfully updated');
        setVisible(false);
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };
  return (
    <FormModal header="Your PIN number">
      <div className="w-100 d-flex align-items-center gap-4">
        <p className="ml-4">PIN number</p>
        {radioButons.map(({ value }) => (
          <label className="p-2" key={value}>
            <input
              type="radio"
              value={value}
              checked={pinRadioButton === value}
              onChange={() => setPinRadioButton(value)}
              className="me-2"
            />
            {capitaliseFirstLetter(value)}
          </label>
        ))}
      </div>

      <div className="d-flex gap-2 flex-column w-100 align-items-center flex-sm-row">
        <FloatingInput
          disabled={isInputDisabled}
          style={{ width: '100%' }}
          value={pin}
          setValue={setPin}
          label="Current PIN"
          type="number"
          isError={errMsg ? true : false}
        />

        {pinRadioButton === 'autogenerate' && (
          <div className="h-100">
            <BasicButton outlined onClick={handlePinChange}>
              Generate New Pin
            </BasicButton>
          </div>
        )}
      </div>
      <p className="error-msg-2 text-start">{errMsg}</p>
      <div className="d-flex gap-2 justify-content-end w-100 mt-3 text-center">
        <BasicButton onClick={() => setVisible(false)} outlined>
          Cancel
        </BasicButton>
        <BasicButton variant="outline-dark" onClick={handleSave} outlined>
          Save
        </BasicButton>
      </div>
    </FormModal>
  );
};

export default EditPin;
