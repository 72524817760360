const colours = {
  purple: '#7638D9',
  darkGray: '#B5B5B5',
  navyBlue: '#3863D9',
  lightCyan: '#C1EAED',
  idleTime: '#FFD122',
  inactiveTime: '#E3E3E3',
  transmissionTime: '#78D89F',
  green: '#3CC6B3',
  orange: '#F9AB02',
  darkBlue: '#1C4E80',
  lightGray: '#E3E3E3',
  lightBlue: '#69D8E3',
  dirtyOrange: '#E5805A',
};

export default colours;
