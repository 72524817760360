import { useEffect, useState } from 'react';
import { ReportModal } from '../../../Components/ReportModal';
import BasicButton from '../../components/BasicButton';
import DropdownField from '../../components/FormDropdown';
import TimeSelect from '../../components/TimeSelect';
// import DropdownField from '../../components/FormDropdown';

function ReportDownloadModal({
  onApply,
  onCancel,
  filterData,
  visible,
  allEquipments = [],
  optionNo,
}) {
  const [filter, setFilter] = useState(filterData);
  const [assetDropDownData, setAssetDropDownData] = useState({
    options: [],
    defaultValues: [],
  });

  useEffect(() => {
    setFilter((prev) => ({ ...prev, ...filterData }));
  }, [filterData, visible]);

  useEffect(() => {
    if (allEquipments?.length && visible) {
      setAssetDropDownData({
        options: allEquipments?.map(({ name, Unit }) => ({
          name,
          value: Unit,
        })),
        defaultValues: allEquipments?.map(({ name, Unit }) => Unit),
      });
      setFilter((prev) => ({
        ...prev,
        assets: allEquipments?.map(({ name, Unit }) => Unit),
      }));
    }
  }, [allEquipments, visible]);

  const applyFilter = () => onApply(filter, optionNo);

  return (
    <>
      {visible ? (
        <ReportModal>
          <p className="report-modal-header text-start w-100 mb-4">
            <strong>Create Report</strong>
          </p>
          <p className="report-modal-subheader w-100 border-bottom border-1 mb-3">
            <strong>Select time range</strong>
          </p>
          <div
            className="d-flex align-items-center border border-1 py-1 px-2 rounded-1 align-self-start justify-content-between"
            style={{ width: '180px' }}
          >
            <span
              style={{
                font: 'normal normal normal 10px/20px Open Sans',
                color: 'black',
                width: '50px',
              }}
              className=" mx-1"
            >
              From:{' '}
            </span>
            <TimeSelect
              defaultValue={filter?.from}
              uniqueName={'from'}
              onChange={(value) => {
                setFilter((prev) => ({ ...prev, from: value }));
              }}
            />
          </div>
          <div
            className="d-flex align-items-center border border-1 mt-4 py-1 px-2 rounded-1 align-self-start justify-content-between"
            style={{ width: '180px' }}
          >
            <span
              style={{
                font: 'normal normal normal 10px/20px Open Sans',
                color: 'black',
                width: '50px',
              }}
              className="mx-1"
            >
              To:{' '}
            </span>
            <TimeSelect
              defaultValue={filter?.to}
              uniqueName={'to'}
              onChange={(value) => {
                setFilter((prev) => ({ ...prev, to: value }));
              }}
            />
          </div>
          {allEquipments?.length ? (
            <>
              <p className="report-modal-subheader w-100 border-bottom border-1 mb-3 mt-4">
                <strong>Include assets</strong>
              </p>
              <div className="d-flex w-100">
                <DropdownField
                  name="Assets"
                  key={'assets'}
                  fieldKey={'assets'}
                  multiselect
                  style={{ fontWeight: 'normal' }}
                  selectedOptions={filter?.['assets'] ?? []}
                  allOptions={assetDropDownData?.options}
                  handleChange={(e) => {
                    setFilter((prev) => ({ ...prev, assets: e }));
                  }}
                  showSelectAll={true}
                />
              </div>
            </>
          ) : null}

          <div className="d-flex gap-2 justify-content-end w-100 mt-5 text-center">
            <BasicButton
              style={{
                paddingRight: '1rem',
                paddingLeft: '1rem',
              }}
              outlined
              variant="outline-dark"
              onClick={onCancel}
              className="btn btn-sm"
              size="sm"
            >
              Cancel
            </BasicButton>
            <BasicButton
              style={{
                paddingRight: '1.5rem',
                paddingLeft: '1.5rem',
              }}
              onClick={applyFilter}
              variant="dark"
              className="btn btn-sm"
              size="sm"
            >
              Apply
            </BasicButton>
          </div>
        </ReportModal>
      ) : null}
    </>
  );
}

export default ReportDownloadModal;
