import { AxiosError, AxiosResponse } from 'axios';
import { NZSTEEL_API_BASE_URL, ENDPOINTS } from '../pages/utils/constants';
import { DateRange } from 'react-day-picker';
import {
  Asset,
  ContactNearbySummary,
  CurrentTonnage,
  CurrentTrafficLightStatus,
  DMU,
  EngineHours,
  RequestConfig,
  TrafficLightColor,
} from './model';
import {
  TimelineData,
  TimelineGranularity,
  TimelineTimeUseAPIData,
} from './timeline/models';
import { areSameDate, currentDateNZ, getEndTime, getStartTime } from './helper';
import {
  addDays,
  addThreeMonths,
  containsSixMonths,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  isDateRangeLessThan18Days,
  subtractDays,
  subtractThreeMonths,
} from './timeline/helper';
import { API } from '../pages/service/apiHandler';

export const getAuthToken = (): string =>
  localStorage.getItem('accessToken') ?? '';

export const fetchAssets = async (): Promise<Asset[]> => {
  const requestConfig: {
    headers: {
      Authorization: string;
    };
  } = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };
  console.log({ requestConfig });
  const response: AxiosResponse = await API.get(
    NZSTEEL_API_BASE_URL + ENDPOINTS.getBulldozers,
    requestConfig,
  );
  if (response.status === 200) {
    return response.data.data;
  } else {
    console.log('Error fetching bulldozers');
    console.log({ response });
    return [];
  }
};

export const fetchDMU = async (logout: () => void): Promise<Asset> => {
  try {
    const requestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    };
    console.log({ requestConfig });
    const getTrafficLightData = () =>
      API.get<{
        data: CurrentTrafficLightStatus;
      }>(NZSTEEL_API_BASE_URL + ENDPOINTS.getTrafficLight, requestConfig);

    const getTonnage = () =>
      API.get<{ data: CurrentTonnage }>(
        NZSTEEL_API_BASE_URL + ENDPOINTS.getTonnage,
        requestConfig,
      );

    const [trafficLightRes, tonnageRes] = await Promise.allSettled([
      getTrafficLightData(),
      getTonnage(),
    ]);

    if (trafficLightRes.status === 'fulfilled') {
      if (trafficLightRes.value.status === 200) {
        if (trafficLightRes.value.data.data.status === TrafficLightColor.GREEN)
          DMU.status = true;
      }
    } else {
      if (
        trafficLightRes.reason instanceof AxiosError &&
        trafficLightRes.reason.response?.status === 403
      ) {
        logout();
      }
    }
    if (tonnageRes.status === 'fulfilled') {
      if (tonnageRes.value.status === 200) {
        DMU.totalTonnesProcessed = Math.round(
          tonnageRes.value.data.data.tonnage ?? 0,
        );
      }
    } else {
      if (
        tonnageRes.reason instanceof AxiosError &&
        tonnageRes.reason.response?.status === 403
      ) {
        logout();
      }
    }
    return DMU;
  } catch (err) {
    if (err instanceof AxiosError && err.response) {
      if (err.response?.status === 403) {
        logout();
        return DMU;
      }
    }
    return DMU;
  }
};

export const getPushes = async (
  finalRange: DateRange | undefined,
  assetName: string,
  abortSignal: AbortSignal,
  logout: () => void,
): Promise<number | undefined> => {
  const requestConfig: RequestConfig = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    signal: abortSignal,
  };

  if (finalRange && finalRange.from && finalRange.to === undefined) {
    //CurrentDate or singleDate
    const response: AxiosResponse = await API.post(
      NZSTEEL_API_BASE_URL + ENDPOINTS.getSummaryPushes,
      {
        truckName: assetName,
        startTime: getStartTime(finalRange.from),
        endTime: getEndTime(finalRange.from),
      },
      requestConfig,
    );
    if (response.status === 200) {
      return response.data.data;
    } else if (response.status === 403) {
      logout();
      return undefined;
    } else {
      return undefined;
    }
  }
  if (finalRange && finalRange.from && finalRange?.to) {
    //Range and Month
    const response: AxiosResponse = await API.post(
      NZSTEEL_API_BASE_URL + ENDPOINTS.getSummaryPushes,
      {
        truckName: assetName,
        startTime: getStartTime(finalRange.from),
        endTime: getEndTime(finalRange.to),
      },
      requestConfig,
    );
    if (response.status === 200) {
      return response.data.data;
    } else if (response.status === 403) {
      logout();
      return undefined;
    } else {
      return undefined;
    }
  }
};

export const getMaxPushes: () => Promise<number> = () => {
  return new Promise((resolve) => {
    resolve(1000);
  });
};

export const getSpeed = async (
  finalRange: DateRange | undefined,
  assetName: string,
  abortSignal: AbortSignal,
  logout: () => void,
): Promise<number | undefined> => {
  const requestConfig: RequestConfig = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    signal: abortSignal,
  };

  if (finalRange && finalRange.from && finalRange.to === undefined) {
    //CurrentDate or singleDate
    const response: AxiosResponse = await API.post(
      NZSTEEL_API_BASE_URL + ENDPOINTS.getSummarySpeed,
      {
        truckName: assetName,
        startTime: getStartTime(finalRange.from),
        endTime: getEndTime(finalRange.from),
      },
      requestConfig,
    );
    if (response.status === 200) {
      return response.data.data;
    } else if (response.status === 403) {
      logout();
      return undefined;
    } else {
      return undefined;
    }
  }
  if (finalRange && finalRange.from && finalRange?.to) {
    //Range and Month
    const response: AxiosResponse = await API.post(
      NZSTEEL_API_BASE_URL + ENDPOINTS.getSummarySpeed,
      {
        truckName: assetName,
        startTime: getStartTime(finalRange.from),
        endTime: getEndTime(finalRange.to),
      },
      requestConfig,
    );
    if (response.status === 200) {
      return response.data.data;
    } else if (response.status === 403) {
      logout();
      return undefined;
    } else {
      return undefined;
    }
  }
};

export const getContactNearby = async (
  finalRange: DateRange | undefined,
  assetName: string,
  abortSignal: AbortSignal,
  logout: () => void,
): Promise<ContactNearbySummary | undefined> => {
  const requestConfig: RequestConfig = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    signal: abortSignal,
  };

  if (finalRange && finalRange.from) {
    //Range and Month
    const response: AxiosResponse = await API.post(
      NZSTEEL_API_BASE_URL + ENDPOINTS.getSummaryContactNearby,
      {
        truckName: assetName,
        startTime: getStartTime(finalRange.from),
        endTime: getEndTime(finalRange.to ?? finalRange.from),
      },
      requestConfig,
    );
    if (response.status === 200 && response.data.data.length) {
      const contactNearbyData: ContactNearbySummary = { contact: 0, nearby: 0 };
      response.data.data.forEach(
        (duration: {
          total_duration: number;
          proximity: keyof ContactNearbySummary;
        }) => {
          contactNearbyData[duration.proximity] = duration.total_duration / 60;
        },
      );
      return contactNearbyData;
    } else if (response.status === 403) {
      logout();
      return undefined;
    } else {
      return undefined;
    }
  }
};

export const getEngineHours = async (
  finalRange: DateRange | undefined,
  assetName: string,
  abortSignal: AbortSignal,
  logout: () => void,
): Promise<EngineHours | undefined> => {
  const requestConfig: RequestConfig = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    signal: abortSignal,
  };
  if (finalRange && finalRange.from && finalRange.to === undefined) {
    //CurrentDate or singleDate
    if (areSameDate(finalRange.from, currentDateNZ)) {
      //Current
      const response: AxiosResponse = await API.post(
        NZSTEEL_API_BASE_URL + ENDPOINTS.getEngineHours,
        {
          requestingApplication: 'NZSteel',
          truckName: assetName,
        },
        requestConfig,
      );
      if (response.status === 200) {
        return JSON.parse(response.data.message);
      } else if (response.status === 403) {
        logout();
        return undefined;
      } else {
        return undefined;
      }
    } else {
      const response: AxiosResponse = await API.post(
        NZSTEEL_API_BASE_URL + ENDPOINTS.getEngineHours,
        {
          requestingApplication: 'NZSteel',
          truckName: assetName,
          timestampWindow: {
            startTimestamp: getStartTime(finalRange.from),
            endTimestamp: getEndTime(finalRange.from),
          },
        },
        requestConfig,
      );
      if (response.status === 200) {
        return JSON.parse(response.data.message);
      } else if (response.status === 403) {
        logout();
        return undefined;
      } else {
        return undefined;
      }
    }
  }
  if (finalRange && finalRange.from && finalRange?.to) {
    //Range and Month
    const response: AxiosResponse = await API.post(
      NZSTEEL_API_BASE_URL + ENDPOINTS.getEngineHours,
      {
        requestingApplication: 'NZSteel',
        truckName: assetName,
        timestampWindow: {
          startTimestamp: getStartTime(finalRange.from),
          endTimestamp: getEndTime(finalRange.to),
        },
      },
      requestConfig,
    );
    if (response.status === 200) {
      return JSON.parse(response.data.message);
    } else if (response.status === 403) {
      logout();
      return undefined;
    } else {
      return undefined;
    }
  }
};

export const getFuel: () => Promise<number> = () => {
  return new Promise((resolve) => {
    resolve(220);
  });
};

export const getTimelinePushes = async (
  assetName: string,
  granularity: TimelineGranularity,
  finalRange: DateRange | undefined,
  abortSignal: AbortSignal,
  logout: () => void,
): Promise<TimelineData[] | []> => {
  let start: Date = finalRange?.from ?? new Date();
  let end: Date = finalRange?.from ?? new Date();
  if (finalRange?.to) {
    end = finalRange.to;
  }

  if (granularity === TimelineGranularity.Daily) {
    if (getStartTime(start) === getStartTime(end)) {
      start = getFirstDayOfMonth(start);
      end = getLastDayOfMonth(end);
    } else if (isDateRangeLessThan18Days(start, end)) {
      start = subtractDays(start, 6);
      end = addDays(end, 6);
    }
  } else if (granularity === TimelineGranularity.Monthly) {
    if (containsSixMonths(start, end)) {
      start = getFirstDayOfMonth(start);
      end = getLastDayOfMonth(end);
    } else {
      start = getFirstDayOfMonth(subtractThreeMonths(start));
      end = getLastDayOfMonth(addThreeMonths(end));
    }
  }

  const requestConfig: RequestConfig = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    signal: abortSignal,
  };

  const response: AxiosResponse = await API.post(
    NZSTEEL_API_BASE_URL + ENDPOINTS.calculatePushesForTimeline,
    {
      truckName: assetName,
      granularity: granularity,
      startTime:
        granularity === TimelineGranularity.Hourly
          ? getStartTime(end)
          : granularity === TimelineGranularity.Daily
          ? getStartTime(start)
          : getStartTime(start),
      endTime:
        granularity === TimelineGranularity.Hourly
          ? getEndTime(end)
          : granularity === TimelineGranularity.Daily
          ? getEndTime(end)
          : getEndTime(end),
    },
    requestConfig,
  );
  if (response.status === 200) {
    return response.data.data.data;
  } else if (response.status === 403) {
    logout();
    return [];
  } else {
    return [];
  }
};

export const getTimelineSpeed = async (
  assetName: string,
  granularity: TimelineGranularity,
  finalRange: DateRange | undefined,
  abortSignal: AbortSignal,
  logout: () => void,
): Promise<TimelineData[] | []> => {
  let start: Date = finalRange?.from ?? new Date();
  let end: Date = finalRange?.from ?? new Date();
  if (finalRange?.to) {
    end = finalRange.to;
  }
  if (granularity === TimelineGranularity.Daily) {
    if (getStartTime(start) === getStartTime(end)) {
      start = getFirstDayOfMonth(start);
      end = getLastDayOfMonth(end);
    } else if (isDateRangeLessThan18Days(start, end)) {
      start = subtractDays(start, 6);
      end = addDays(end, 6);
    }
  } else if (granularity === TimelineGranularity.Monthly) {
    if (containsSixMonths(start, end)) {
      start = getFirstDayOfMonth(start);
      end = getLastDayOfMonth(end);
    } else {
      start = getFirstDayOfMonth(subtractThreeMonths(start));
      end = getLastDayOfMonth(addThreeMonths(end));
    }
  }

  const requestConfig: RequestConfig = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    signal: abortSignal,
  };

  const response: AxiosResponse = await API.post(
    NZSTEEL_API_BASE_URL + ENDPOINTS.calculateTimelineSpeed,
    {
      truckName: assetName,
      granularity: granularity,
      startTime:
        granularity === TimelineGranularity.Hourly
          ? getStartTime(end)
          : granularity === TimelineGranularity.Daily
          ? getStartTime(start)
          : getStartTime(start),
      endTime:
        granularity === TimelineGranularity.Hourly
          ? getEndTime(end)
          : granularity === TimelineGranularity.Daily
          ? getEndTime(end)
          : getEndTime(end),
    },
    requestConfig,
  );
  if (response.status === 200) {
    return response.data.data.data;
  } else if (response.status === 403) {
    logout();
    return [];
  } else {
    return [];
  }
};

export const getTimelineTimeUse = async (
  assetName: string,
  granularity: TimelineGranularity,
  finalRange: DateRange | undefined,
  abortSignal: AbortSignal,
  logout: () => void,
): Promise<TimelineTimeUseAPIData[] | []> => {
  let start: Date = finalRange?.from ?? new Date();
  let end: Date = finalRange?.from ?? new Date();
  if (finalRange?.to) {
    end = finalRange.to;
  }
  if (granularity === TimelineGranularity.Daily) {
    if (getStartTime(start) === getStartTime(end)) {
      start = getFirstDayOfMonth(start);
      end = getLastDayOfMonth(end);
    } else if (isDateRangeLessThan18Days(start, end)) {
      start = subtractDays(start, 6);
      end = addDays(end, 6);
    }
  } else if (granularity === TimelineGranularity.Monthly) {
    if (containsSixMonths(start, end)) {
      start = getFirstDayOfMonth(start);
      end = getLastDayOfMonth(end);
    } else {
      start = getFirstDayOfMonth(subtractThreeMonths(start));
      end = getLastDayOfMonth(addThreeMonths(end));
    }
  }

  const requestConfig: RequestConfig = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    signal: abortSignal,
  };
  const response: AxiosResponse = await API.post(
    NZSTEEL_API_BASE_URL + ENDPOINTS.calculateTimeUseProgression,
    {
      truckName: assetName,
      granularity: granularity,
      startTime:
        granularity === TimelineGranularity.Hourly
          ? getStartTime(end)
          : granularity === TimelineGranularity.Daily
          ? getStartTime(start)
          : getStartTime(start),
      endTime:
        granularity === TimelineGranularity.Hourly
          ? getEndTime(end)
          : granularity === TimelineGranularity.Daily
          ? getEndTime(end)
          : getEndTime(end),
    },
    requestConfig,
  );
  if (response.status === 200) {
    return response.data.data.data;
  } else if (response.status === 403) {
    logout();
    return [];
  } else {
    return [];
  }
};

export const getIdleTime = async (
  finalRange: DateRange | undefined,
  assetName: string,
  abortSignal: AbortSignal,
  logout: () => void,
): Promise<number | undefined> => {
  const requestConfig: RequestConfig = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    signal: abortSignal,
  };

  if (finalRange && finalRange.from && finalRange.to === undefined) {
    //CurrentDate or singleDate
    if (areSameDate(finalRange.from, currentDateNZ)) {
      //Current
      const response: AxiosResponse = await API.post(
        NZSTEEL_API_BASE_URL + ENDPOINTS.getIdleTime,
        {
          truckName: assetName,
          requestingApplication: 'NZSteel',
        },
        requestConfig,
      );

      if (response.status === 200) {
        return JSON.parse(response.data.message).idleTimeInMinutes;
      } else if (response.status === 403) {
        logout();
        return undefined;
      } else {
        return undefined;
      }
    } else {
      const response: AxiosResponse = await API.post(
        NZSTEEL_API_BASE_URL + ENDPOINTS.getIdleTime,
        {
          requestingApplication: 'NZSteel',
          truckName: assetName,
          timestampWindow: {
            startTimestamp: getStartTime(finalRange.from),
            endTimestamp: getEndTime(finalRange.from),
          },
        },
        requestConfig,
      );

      if (response.status === 200) {
        return JSON.parse(response.data.message).idleTimeInMinutes;
      } else if (response.status === 403) {
        logout();
        return undefined;
      } else {
        return undefined;
      }
    }
  } else if (finalRange && finalRange.from && finalRange?.to) {
    //Range and Month
    const response: AxiosResponse = await API.post(
      NZSTEEL_API_BASE_URL + ENDPOINTS.getIdleTime,
      {
        requestingApplication: 'NZSteel',
        truckName: assetName,
        timestampWindow: {
          startTimestamp: getStartTime(finalRange.from),
          endTimestamp: getEndTime(finalRange.to),
        },
      },
      requestConfig,
    );

    if (response.status === 200) {
      return JSON.parse(response.data.message).idleTimeInMinutes;
    } else if (response.status === 403) {
      logout();
      return undefined;
    } else {
      return undefined;
    }
  }
  return undefined;
};

export const getTimelineEngineHours = async (
  assetName: string,
  granularity: TimelineGranularity,
  finalRange: DateRange | undefined,
  abortSignal: AbortSignal,
  logout: () => void,
): Promise<TimelineData[] | []> => {
  let start: Date = finalRange?.from ?? new Date();
  let end: Date = finalRange?.from ?? new Date();
  if (finalRange?.to) {
    end = finalRange.to;
  }

  if (granularity === TimelineGranularity.Daily) {
    if (getStartTime(start) === getStartTime(end)) {
      start = getFirstDayOfMonth(start);
      end = getLastDayOfMonth(end);
    } else if (isDateRangeLessThan18Days(start, end)) {
      start = subtractDays(start, 6);
      end = addDays(end, 6);
    }
  } else if (granularity === TimelineGranularity.Monthly) {
    if (containsSixMonths(start, end)) {
      start = getFirstDayOfMonth(start);
      end = getLastDayOfMonth(end);
    } else {
      start = getFirstDayOfMonth(subtractThreeMonths(start));
      end = getLastDayOfMonth(addThreeMonths(end));
    }
  }

  const requestConfig: RequestConfig = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    signal: abortSignal,
  };

  const response: AxiosResponse = await API.post(
    NZSTEEL_API_BASE_URL + ENDPOINTS.calculateEngineHoursForTimeline,
    {
      truckName: assetName,
      granularity: granularity,
      startTime:
        granularity === TimelineGranularity.Hourly
          ? getStartTime(end)
          : granularity === TimelineGranularity.Daily
          ? getStartTime(start)
          : getStartTime(start),
      endTime:
        granularity === TimelineGranularity.Hourly
          ? getEndTime(end)
          : granularity === TimelineGranularity.Daily
          ? getEndTime(end)
          : getEndTime(end),
    },
    requestConfig,
  );
  if (response.status === 200) {
    return response.data.data.data;
  } else if (response.status === 403) {
    logout();
    return [];
  } else {
    return [];
  }
};

export const getTimelineIdleTime = async (
  assetName: string,
  granularity: TimelineGranularity,
  finalRange: DateRange | undefined,
  abortSignal: AbortSignal,
  logout: () => void,
): Promise<TimelineData[] | []> => {
  let start: Date = finalRange?.from ?? new Date();
  let end: Date = finalRange?.from ?? new Date();
  if (finalRange?.to) {
    end = finalRange.to;
  }

  if (granularity === TimelineGranularity.Daily) {
    if (getStartTime(start) === getStartTime(end)) {
      start = getFirstDayOfMonth(start);
      end = getLastDayOfMonth(end);
    } else if (isDateRangeLessThan18Days(start, end)) {
      start = subtractDays(start, 6);
      end = addDays(end, 6);
    }
  } else if (granularity === TimelineGranularity.Monthly) {
    if (containsSixMonths(start, end)) {
      start = getFirstDayOfMonth(start);
      end = getLastDayOfMonth(end);
    } else {
      start = getFirstDayOfMonth(subtractThreeMonths(start));
      end = getLastDayOfMonth(addThreeMonths(end));
    }
  }

  const requestConfig: RequestConfig = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    signal: abortSignal,
  };

  const response: AxiosResponse = await API.post(
    NZSTEEL_API_BASE_URL + ENDPOINTS.calculateIdleTimeForTimeline,
    {
      truckName: assetName,
      granularity: granularity,
      startTime:
        granularity === TimelineGranularity.Hourly
          ? getStartTime(end)
          : granularity === TimelineGranularity.Daily
          ? getStartTime(start)
          : getStartTime(start),
      endTime:
        granularity === TimelineGranularity.Hourly
          ? getEndTime(end)
          : granularity === TimelineGranularity.Daily
          ? getEndTime(end)
          : getEndTime(end),
    },
    requestConfig,
  );
  if (response.status === 200) {
    return response.data.data.data;
  } else if (response.status === 403) {
    logout();
    return [];
  } else {
    return [];
  }
};

export const getDmuTonnageForTimeline = async (
  granularity: TimelineGranularity,
  finalRange: DateRange | undefined,
  abortSignal: AbortSignal,
  logout: () => void,
): Promise<TimelineData[]> => {
  let start: Date = finalRange?.from ?? new Date();
  let end: Date = finalRange?.from ?? new Date();
  if (finalRange?.to) {
    end = finalRange.to;
  }

  if (granularity === TimelineGranularity.Daily) {
    if (getStartTime(start) === getStartTime(end)) {
      start = getFirstDayOfMonth(start);
      end = getLastDayOfMonth(end);
    } else if (isDateRangeLessThan18Days(start, end)) {
      start = subtractDays(start, 6);
      end = addDays(end, 6);
    }
  } else if (granularity === TimelineGranularity.Monthly) {
    if (containsSixMonths(start, end)) {
      start = getFirstDayOfMonth(start);
      end = getLastDayOfMonth(end);
    } else {
      start = getFirstDayOfMonth(subtractThreeMonths(start));
      end = getLastDayOfMonth(addThreeMonths(end));
    }
  } else {
    /* Hourly granularity only works if a single day is passed in
    so choose the end date of the date range and get the hours for that 
    date range
    */
    start = end;
  }

  const requestConfig: RequestConfig = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    signal: abortSignal,
  };

  const response: AxiosResponse<{ data: { data: TimelineData[] } }> =
    await API.post(
      NZSTEEL_API_BASE_URL + ENDPOINTS.getTonnageForTimeline,
      {
        granularity: granularity,
        startTime: getStartTime(start),
        endTime: getEndTime(end),
      },
      requestConfig,
    );
  if (response.status === 200) {
    return response.data.data.data;
  } else if (response.status === 403) {
    logout();
    return [];
  } else {
    return [];
  }
};
