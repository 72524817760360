/* eslint-disable no-console */
// @ts-nocheck
import moment from 'moment';
import {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import EquipmentService from '../../assetTracking/service/EquipmentService';
import axios from 'axios';
import { SocketContext } from '../../assetTracking/AssetTracking/utils/socket';
import { toast } from 'react-toastify';
import { findTripEngineHours } from '../../assetTracking/utils/helper';
import { CallWithAuth } from '../../assetTracking/action/apiActions';
import { TRIP_HISTORY_DATA } from '../../assetTracking/action/apiPath';
import { AuthContext } from './auth';
import { Asset } from '../../assetTracking/models/models';
import { useSocket } from '../../hooks/useSocket';
import { EngineHoursMessage } from './assetContext';
import { fetchEngineHours } from '../../assetTracking/service/Services';

const AssetTrackingContext = createContext(undefined);

export const getImageName = (asset) => {
  switch (true) {
    case asset?.activity_status === 'Parked':
      return 'marker-gray.svg';
    case asset?.activity_status === 'Idling':
      return 'marker-green.svg';
    case asset?.activity_status === 'Active':
      return 'marker-green-i.svg';
    default:
      return 'marker-red.svg';
  }
};

export const useAssetTrackingContext = () => {
  const context = useContext(AssetTrackingContext);
  if (!context) {
    throw new Error(
      'useAssetTrackingContext must be used within an AssetTrackingProvider',
    );
  }
  return context;
};

export const compare = (a, b) => {
  if (a.Status < b.Status) return -1;
  if (a.Status > b.Status) return 1;
  return 0;
};

export const AssetTrackingProvider = ({ children }) => {
  const [assetsData, setAssetsData] = useState<Asset[]>([]);
  const { socketAssetTracking } = useContext(SocketContext);
  const source = useRef(axios.CancelToken.source());
  const [allEquipments, setAllEquipments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const { logOut } = useContext(AuthContext);
  const [currentDateUnix, setCurrentDateUnix] = useState<number>(
    moment().unix(),
  );
  const [APIRefresh, setAPIRefresh] = useState<number>(0); // Increase it by 1 everytime you want to refresh the API

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateUnix(moment().unix());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const getTotalTrips = (activity) => {
    try {
      return activity.filter((activity) => activity?.activity_type === 'Trip')
        ?.length;
    } catch (error) {
      return 0;
    }
  };

  const fetchAllEquipments = async () => {
    try {
      await EquipmentService.fetch();
      const equipments = JSON.parse(
        JSON.stringify(EquipmentService.equipments),
      ); // Proxy Object from Mobx being converted to normal Object
      const updatedEquipmentsWithEngineHours =
        await fetchEngineHoursData(equipments);
      setAllEquipments(updatedEquipmentsWithEngineHours);
    } catch (error) {
      console.log('error', error);
      toast.error(error);
    }
  };

  const fetchEngineHoursData = async (equipments) => {
    const engineHourData = await fetchEngineHours(
      equipments.map((equipment) => equipment.name),
      undefined,
      logOut,
    );

    const engineHourMap = new Map(
      engineHourData.map((item) => [item.truckName, item]),
    );

    // Update the equipments array
    const updatedEquipments = equipments.map((equipment) => {
      const engineData = engineHourMap.get(equipment.name);

      if (engineData) {
        // Update the equipment object with the corresponding engine data
        return {
          ...equipment,
          calculatedEngineMinutes: engineData.calculatedEngineMinutes,
          totalEngineHoursInMinutes: engineData.totalEngineHoursInMinutes,
          currentEngineStatus: engineData.currentEngineStatus,
          lastUpdatedTime: engineData.lastUpdatedTime,
        };
      }

      // Return the equipment as is if no corresponding engine data is found
      return equipment;
    });

    return updatedEquipments;
  };

  //EngineHour Socket
  const handleEngineHoursMessage = useCallback(
    (message: EngineHoursMessage) => {
      setAllEquipments((prevEquipments) =>
        prevEquipments.map((equipment) =>
          equipment.name === message.truckName
            ? {
                ...equipment,
                currentEngineStatus: message.engineStatus,
                calculatedEngineMinutes:
                  message.engineHours?.calculatedEngineMinutes ??
                  equipment.calculatedEngineMinutes,
                totalEngineHoursInMinutes:
                  message.engineHours?.totalEngineHoursInMinutes ??
                  equipment.totalEngineHoursInMinutes,
                lastUpdatedTime: new Date().toISOString(),
              }
            : equipment,
        ),
      );
    },
    [],
  );

  useSocket<EngineHoursMessage>('engine_hours', handleEngineHoursMessage);

  const fetchTrucksLiveLocation = async ({
    withoutLoading,
    cancelToken = '',
  }) => {
    withoutLoading ? console.log(null) : setLoading(true);
    try {
      const response = await CallWithAuth(
        'POST',
        TRIP_HISTORY_DATA + '?live=true',
        {
          date: moment().format('YYYY-MM-DD'),
        },
        cancelToken,
      );
      if (response?.res?.data.status === 200) {
        if (response?.res?.data?.data?.length > 0) {
          const assets = response?.res?.data?.data?.map((asset, index) => {
            let latestTripIndex = asset?.activity?.findIndex(
              (activity) => activity.activity_type === 'Trip',
            );
            const totalDistance =
              asset.activity.at(0).lastLocation.odo -
              asset.activity.at(-1).firstLocation.odo;

            return {
              fromApi: true,
              marker_id: asset._id,
              'Asset ID': asset?.asset_ID,
              coordinate: {
                latitude: asset?.latitude,
                longitude: asset?.longitude,
              },
              Category: asset?.category || 'Truck',
              title: asset?.asset_ID,
              notFound: asset?.notFound ?? false,
              url: `./assets/images/${getImageName(asset)}`,
              'Trips Today': getTotalTrips(asset.activity ?? []),
              'KM Today': totalDistance ? totalDistance?.toFixed(2) : 0,
              Signal: asset?.activity[0]?.lastLocation?.css,
              Status: asset?.activity_status ?? 'Disconnected',
              statusTime: asset?.status_time ?? 0,
              odometer: asset?.activity[0]?.lastLocation?.odo,
              Current: {
                speed: asset.activity[0]?.lastLocation?.spd,
                tripMaxSpeed: asset.activity[latestTripIndex]?.maxSpeed,
                tripEngineHours: findTripEngineHours(
                  asset.activity[latestTripIndex]?.lastLocation?.timestamp,
                  asset.activity[latestTripIndex]?.firstLocation?.timestamp,
                ),
                tripOdoStart:
                  asset.activity[latestTripIndex]?.firstLocation?.odo,
                tripOdoEnd: asset.activity[latestTripIndex]?.lastLocation?.odo,
                tripStartTime: asset.activity[latestTripIndex]?.startTime,
                tripEndTime: asset.activity[latestTripIndex]?.endTime,
                tripLastUpdate:
                  asset.activity[latestTripIndex]?.lastLocation?.timestamp,
                tripFrom: {
                  latitude:
                    asset.activity[latestTripIndex]?.firstLocation?.latitude,
                  longitude:
                    asset.activity[latestTripIndex]?.firstLocation?.longitude,
                },
                tripDate: asset.activity[latestTripIndex]?.date,
                batteryHealth: asset.activity[0]?.lastLocation?.bath,
                batteryCharge: asset.activity[0]?.lastLocation?.batc,
                batteryStarlinkVoltage: asset.activity[0]?.lastLocation?.vbat,
                vehicleVoltage: asset.activity[0]?.lastLocation?.vin,
              },
              activity: asset.activity,
              lastSocketTime: asset.activity[0]?.lastLocation?.timestamp,
            };
          });
          if (JSON.stringify(assetsData) !== JSON.stringify(assets)) {
            if (assets?.length) assets.sort(compare);
            setAssetsData(assets);
          }
        }
      } else {
        setLoading(true);
      }
    } catch (error) {
      setLoading(true);
      console.log('error', error);
      toast.error(error);
    }
    withoutLoading ? console.log(null) : setLoading(false);
  };

  const fetchData = () => {
    source?.current?.cancel();
    source.current = axios.CancelToken.source();
    fetchTrucksLiveLocation({
      withoutLoading: true,
      cancelToken: source.current.token,
    });
  };

  const fetchDataAfterSocket = (data: AssetSocketResponse) => {
    const isTrip = data.trip.activity_type === 'Trip';

    setAssetsData((prev) => {
      return prev
        .map((localeData) => {
          if (localeData['Asset ID'] !== data.trip.asset_no) {
            return localeData;
          }
          let activity = [...localeData.activity];
          if (data.isNew) {
            activity.unshift(data.trip);
          } else {
            activity[0] = data.trip;
          }
          const totalDistance =
            activity.at(0).lastLocation.odo - activity.at(-1).firstLocation.odo;

          const statusTime =
            localeData.Status === data.activity_status
              ? localeData.statusTime
              : moment().unix();
          return {
            ...localeData,
            odometer: data.trip?.lastLocation?.odo,
            Current: {
              // This object contains the data of the latest trip or the last trip so "isTrip" flag is required to check whether it is a trip or not. In case if the current activity is "Park" then this object will act like LastTrip data. Some parameters will be of any Latest activity regardless of a Trip or Park, for eg: speed and battery related data
              speed: data.trip?.lastLocation.spd ?? 0,
              tripMaxSpeed: isTrip
                ? data.trip?.maxSpeed
                : localeData.Current.tripMaxSpeed,
              tripEngineHours: isTrip
                ? findTripEngineHours(
                    data.trip?.lastLocation?.timestamp,
                    data.trip?.firstLocation?.timestamp,
                  )
                : localeData.Current.tripEngineHours,
              tripOdoStart: isTrip
                ? data.trip?.firstLocation?.odo
                : localeData.Current.tripOdoStart,
              tripOdoEnd: isTrip
                ? data.trip?.lastLocation?.odo
                : localeData.Current.tripOdoEnd,
              tripStartTime: isTrip
                ? data.trip?.startTime
                : localeData.Current.tripStartTime,
              tripEndTime: isTrip
                ? data.trip?.endTime
                : localeData.Current.tripEndTime,
              tripLastUpdate: isTrip
                ? data.trip?.lastLocation?.timestamp
                : localeData.Current.tripLastUpdate,
              tripFrom: isTrip
                ? {
                    latitude: data.trip?.firstLocation?.latitude,
                    longitude: data.trip?.firstLocation?.longitude,
                  }
                : localeData.Current.tripFrom,
              tripDate: isTrip ? data.trip?.date : localeData.Current.tripDate,
              batteryHealth: data.trip?.lastLocation?.bath,
              batteryCharge: data.trip?.lastLocation?.batc,
              batteryStarlinkVoltage: data.trip?.lastLocation?.vbat,
              vehicleVoltage: data.trip?.lastLocation?.vin,
            },
            fromApi: false,
            fromSocket: true,
            Status: data.activity_status,
            statusTime: statusTime,
            Signal: data.trip?.lastLocation?.css,
            coordinate: {
              latitude: data.trip.lastLocation?.latitude,
              longitude: data.trip.lastLocation?.longitude,
            },
            activity: activity,
            'Trips Today': getTotalTrips(activity ?? []),
            'KM Today': totalDistance ? totalDistance?.toFixed(2) : 0,
            lastSocketTime: moment().unix(),
          };
        })
        .sort(compare);
    });
  };

  useEffect(() => {
    fetchAllEquipments();
    fetchData();
  }, [APIRefresh]);

  useEffect(() => {
    socketAssetTracking.on('TRIP_UPDATE', (data: AssetSocketResponse) => {
      fetchDataAfterSocket(data);
    });

    return () => {
      socketAssetTracking.off('TRIP_UPDATE');
    };
  }, [socketAssetTracking, assetsData]);

  return (
    <AssetTrackingContext.Provider
      value={{
        assetsData,
        setAssetsData,
        allEquipments,
        loading,
        setLoading,
        selectedAssets,
        setSelectedAssets,
        currentDateUnix,
        setAPIRefresh,
      }}
    >
      {children}
    </AssetTrackingContext.Provider>
  );
};
