import { FunctionComponent } from 'react';

import { PreCheckEvaluationResult } from '../../models/enums';
import PieChartGraph from '../PieChart';
import { DisplaySummaryPieChartProps } from '../../helper/types';
import { entries, values } from 'lodash';
import {
  DefectColor,
  prestartcheckLabelsWithoutIncomplete,
} from '../../helper/constants';
import { messages } from '../../../pages/utils/messages';

const DisplaySummaryPieChart: FunctionComponent<DisplaySummaryPieChartProps> = (
  props,
) => {
  return (
    <div className="d-flex flex-row">
      <div className="d-flex flex-column" style={{ minWidth: '240px' }}>
        <PieChartGraph
          key={1}
          showLabel={props.showLabel ?? true}
          data={values(props.summary)}
          chartData={[
            {
              name: PreCheckEvaluationResult.CATEGORY_A_FAILURE,
              value: props.summary[PreCheckEvaluationResult.CATEGORY_A_FAILURE],
            },
            {
              name: PreCheckEvaluationResult.CATEGORY_B_FAILURE,
              value: props.summary[PreCheckEvaluationResult.CATEGORY_B_FAILURE],
            },
            {
              name: PreCheckEvaluationResult.CATEGORY_C_FAILURE,
              value: props.summary[PreCheckEvaluationResult.CATEGORY_C_FAILURE],
            },
            {
              name: PreCheckEvaluationResult.PASS,
              value: props.summary[PreCheckEvaluationResult.PASS],
            },
          ]}
        />
        <div className="text-size text-center" style={{ color: '#707070' }}>
          {messages.prestartCheck.pieChartDescription}
        </div>
      </div>
      {props.showKey ? (
        <div className="align-self-end">
          {entries(prestartcheckLabelsWithoutIncomplete).map(
            ([defectType, label]) => (
              <div className="align-self-end d-flex flex-row align-items-center gap-2">
                <div
                  className="rounded-circle"
                  style={{
                    width: '8px',
                    height: '8px',
                    backgroundColor:
                      DefectColor[defectType as PreCheckEvaluationResult],
                  }}
                ></div>
                <div style={{ fontSize: '10px' }}>{label}</div>
              </div>
            ),
          )}
        </div>
      ) : null}
    </div>
  );
};

export default DisplaySummaryPieChart;
