import moment from 'moment';
import React from 'react';
import { secondsToHhMm } from '../../../utils/helper';
import './dropdowns.css';

const Details = ({ data = {} }) => {
  return (
    <div className="asm-details-dropdown" style={{ marginBottom: '1.313rem' }}>
      <div className="d-flex row m-0 p-0 w-100">
        <div className="asm-details-key col-3 m-0 p-0 ">START:</div>
        <div className="col-9 details-value ">
          {moment(data?.firstLocation?.timestamp * 1000).format('hh:mm A')}
        </div>
      </div>

      <div className="d-flex row m-0 p-0 w-100 ">
        <div className="asm-details-key col-3 m-0 p-0  ">FINISH: </div>{' '}
        <span className=" col-9 details-value">
          {moment(data?.lastLocation?.timestamp * 1000).format('hh:mm A')}
        </span>
      </div>

      <div className="  ">
        <div className="asm-details-key " style={{ marginTop: '1rem' }}>
          DURATION:
        </div>
        <div className=" details-value " style={{ marginBottom: '1rem' }}>
          {secondsToHhMm(
            data?.lastLocation?.timestamp - data?.firstLocation?.timestamp,
          ) + '  '}
        </div>
      </div>

      {data?.activity_type === 'Trip' && (
        <div className="d-flex row m-0 p-0 w-100">
          <div className="asm-details-key col-3 m-0 p-0 ">FROM: </div>
          <span className="details-value col-9">
            {' '}
            {data?.firstLocation?.latitude}, {data?.firstLocation?.longitude}
          </span>
        </div>
      )}
      {data?.activity_type === 'Trip' && (
        <div className="d-flex row m-0 p-0 w-100">
          <div className="asm-details-key col-3 m-0 p-0 ">TO: </div>{' '}
          <span className="details-value col-9">
            {data?.lastLocation?.latitude}, {data?.lastLocation?.longitude}
          </span>
        </div>
      )}
      {data?.activity_type === 'Park' && (
        <div className="d-flex row m-0 p-0 w-100 mt-2">
          <div className="col-3 m-0 p-0  asm-details-key">AT : </div>{' '}
          <span className="col-9 details-value">
            {data?.firstLocation?.latitude}, {data?.firstLocation?.longitude}
          </span>
        </div>
      )}

      {data?.activity_type === 'Trip' && (
        <div>
          <div className="asm-details-key" style={{ marginTop: '1rem' }}>
            DISTANCE
          </div>
          <div className="details-value">
            {(data?.lastLocation?.odo - data?.firstLocation?.odo).toFixed(2)} km
          </div>
          <div className="asm-details-key" style={{ marginTop: '1rem' }}>
            MAX SPEED
          </div>
          <div className="details-value">{data?.maxSpeed} km/h</div>
          <div className="asm-details-key" style={{ marginTop: '1rem' }}>
            TOTAL IDLE TIME
          </div>
          <div className="details-value">
            {secondsToHhMm(data?.activity_idle_time ?? 0) + '  '}
          </div>
        </div>
      )}
    </div>
  );
};
export default Details;
