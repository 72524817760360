import moment from 'moment';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { DropdownData } from '../DropdownData';
import BasicButton from './BasicButton';
import DropdownField from './DropdownField';
import TimeSelect from './TimeSelect';

const TimelineFilterModal = (props) => {
  const {
    modalOpen,
    modalToggle,
    filteredData = {},
    setFilteredData = () => {},
    saveHandler,
    from,
    to,
  } = props;

  const [tempFilteredData, setTempFilteredData] = useState(filteredData);

  const onCheckboxClick = (fieldName, value, multiselect, isAll = false) => {
    if (isAll) {
      setTempFilteredData((prev) => ({ ...prev, [fieldName]: value ?? [] }));
    } else {
      if (multiselect) {
        const filters = { ...tempFilteredData };
        if (filters[fieldName]?.includes(value)) {
          filters[fieldName] = filters[fieldName].filter(
            (selectedValue) => selectedValue !== value,
          );
        } else {
          filters[fieldName] = [...(filters[fieldName] || []), value];
        }
        setTempFilteredData(filters);
      } else {
        setTempFilteredData((prev) => ({ ...prev, [fieldName]: value }));
      }
    }
  };

  const clearFilter = () => {
    const resetData = {
      date: moment().format('YYYY-MM-DD'),
      from: from,
      to: to,
      speed: DropdownData.timeline.speed.defaultvalues,
      event: DropdownData.timeline.event.defaultvalues,
      alerts: DropdownData.timeline.alerts.defaultValues,
      signal: DropdownData.timeline.signal.defaultValues,
    };
    setTempFilteredData(resetData);
    setFilteredData(resetData);
    saveHandler(resetData, false);
  };

  const onApply = () => {
    setFilteredData(tempFilteredData);
    saveHandler(tempFilteredData, true);
  };

  return (
    <div className="test-class" style={{ borderRadius: '4px 0px 0px 0px' }}>
      {modalOpen && (
        <Modal
          show={modalOpen}
          onHide={modalToggle}
          // backdrop="static"
          keyboard={false}
          size="lg"
          dialogClassName="asm-report-filter-modal-position"
          className="modal-transparent"
        >
          <Modal.Header>
            <div
              style={{ borderBottom: '1px solid #EBEBEB' }}
              className="d-flex justify-content-between w-100 align-items-center ms-4 me-2 my-3 font-13 mb-3"
            >
              <b
                className="ps-0 px-2 mb-4"
                style={{ font: 'normal normal 600 16px/22px Open Sans' }}
              >
                FILTER BY
              </b>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div
              className="font-13 d-flex flex-column justify-content-between h-100 ms-4 me-2 my-3 font-13 mb-3"
              style={{
                font: 'normal normal 14px/19px Open Sans',
                borderRadius: '10px 0px 0px 0px',
                padding: '0rem 1rem',
              }}
            >
              <div className="d-flex flex-column gap-2">
                <div className="mb-1 d-flex flex-row justify-content-between asm-field-container">
                  <label
                    className="px-0 w-100"
                    style={{
                      font: 'normal normal normal 0.8rem Open Sans',
                      color: '#1A1A1A',
                      flex: 0.3,
                    }}
                  >
                    {' '}
                    Date{' '}
                  </label>
                  <div
                    className="d-flex asm-add-user-input"
                    style={{ flex: 0.7 }}
                  >
                    <div className="mr-3 border border-1 rounded-1  d-flex w-50 align-items-center justify-content-between px-1">
                      <span
                        style={{
                          font: 'normal normal normal 0.6rem Open Sans',
                          color: 'black',
                        }}
                        className="mr-2 mx-1"
                      >
                        From{' '}
                      </span>
                      <TimeSelect
                        background="#FFF"
                        dropDownStyle={{ right: 10 }}
                        showBorder={false}
                        defaultValue={tempFilteredData?.from}
                        uniqueName={'from'}
                        onChange={(value) => {
                          setTempFilteredData((prev) => ({
                            ...prev,
                            from: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="mr-3 border border-1 rounded-1 d-flex w-50 align-items-center justify-content-between px-1 ms-2">
                      <span
                        style={{
                          font: 'normal normal normal 0.6rem Open Sans',
                          color: 'black',
                        }}
                        className=" mr-2 mx-1"
                      >
                        To{' '}
                      </span>
                      <TimeSelect
                        background="#FFF"
                        dropDownStyle={{ right: 10 }}
                        showBorder={false}
                        defaultValue={tempFilteredData?.to}
                        uniqueName={'to'}
                        onChange={(value) => {
                          setTempFilteredData((prev) => ({
                            ...prev,
                            to: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className=" mb-1">
                  <DropdownField
                    name={'Event'}
                    key={'event'}
                    fieldKey={'event'}
                    multiselect
                    style={{ fontWeight: 'normal' }}
                    selectedOptions={tempFilteredData['event']}
                    allOptions={DropdownData.timeline.event.options}
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    labelClass="px-0"
                    handleChange={(e) => {
                      onCheckboxClick(
                        e?.fieldKey,
                        e?.optionValue,
                        e?.multiselect,
                        e?.isAll,
                      );
                    }}
                  />
                </div>

                <div className=" mb-1">
                  <DropdownField
                    name={'Speed'}
                    key={'speed'}
                    fieldKey={'speed'}
                    multiselect
                    selectedOptions={tempFilteredData['speed']}
                    allOptions={DropdownData.timeline.speed.options}
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    labelClass="px-0"
                    handleChange={(e) =>
                      onCheckboxClick(
                        e?.fieldKey,
                        e?.optionValue,
                        e?.multiselect,
                        e?.isAll,
                      )
                    }
                  />
                </div>

                <div className=" mb-1">
                  <DropdownField
                    name={'Alert'}
                    key={'alerts'}
                    fieldKey={'alerts'}
                    multiselect
                    style={{ fontWeight: 'normal' }}
                    selectedOptions={tempFilteredData['alerts']}
                    allOptions={DropdownData.timeline.alerts.options}
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    labelClass="px-0"
                    handleChange={(e) =>
                      onCheckboxClick(
                        e?.fieldKey,
                        e?.optionValue,
                        e?.multiselect,
                        e?.isAll,
                      )
                    }
                  />
                </div>

                <div className="mb-1 pb-5 ">
                  <DropdownField
                    name={'Signal strength'}
                    key={'signal'}
                    fieldKey={'signal'}
                    multiselect
                    style={{ fontWeight: 'normal' }}
                    selectedOptions={tempFilteredData['signal']}
                    allOptions={DropdownData.timeline.signal.options}
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    labelClass="px-0"
                    handleChange={(e) =>
                      onCheckboxClick(
                        e?.fieldKey,
                        e?.optionValue,
                        e?.multiselect,
                        e?.isAll,
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <div
            className="d-flex align-items-center justify-content-between pt-3 p-4"
            style={{
              backgroundColor: '#FAFAFA',
              backgroundRepeat: 'no-repeat',
              padding: 'padding-box',
            }}
          >
            <div>
              <button
                className="border border-0 bg-transparent px-3 btn-focus"
                onClick={clearFilter}
                style={{ color: '#707070', marginBottom: '20px' }}
              >
                <u>Clear all</u>
              </button>
            </div>
            <div className=" d-flex">
              <div className=" px-3">
                <BasicButton
                  style={{ border: '1px solid black', marginBottom: '20px' }}
                  onClick={() => modalToggle(false)}
                  variant="white"
                >
                  <div style={{ fontSize: '0.8rem' }}> Cancel</div>
                </BasicButton>
              </div>
              <div>
                <BasicButton
                  className="px-4 btn-focus"
                  onClick={onApply}
                  variant="dark"
                  style={{ marginBottom: '20px' }}
                >
                  <div style={{ fontSize: '0.8rem' }}> Apply</div>
                </BasicButton>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TimelineFilterModal;
