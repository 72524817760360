import ReactDOM from 'react-dom';
import IdleIcon from './assets/idle.svg';
import moment from 'moment';

export const IdlePopup = ({ data }) => {
  const div = document.createElement('div');

  const popup = (
    <>
      <div>
        <div className="flyout-asset-gray">
          <div className="d-flex  flex-column align-items-start">
            <div
              className="mb-2"
              style={{
                borderBottom: '1px solid #beb6b6',
                color: '#000000',
                width: '100%',
              }}
            >
              IDLE TIME
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                width={18}
                height={14}
                style={{ objectFit: 'contain' }}
                src={IdleIcon}
                alt="Idle"
                className="me-2"
              />
              <div className=""> {data?.event_duration ?? 0}</div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                className="mt-1"
                style={{ opacity: 0.7, fontSize: '0.7rem' }}
              >
                {moment(data?.event_start_time_timestamp * 1000).format(
                  'hh:mm:ss a',
                )}
                -
                {moment(data?.event_end_time_timestamp * 1000).format(
                  'hh:mm:ss a',
                )}
                {/* {moment(data.timestamp * 1000).isSame(moment(), 'day')
                  ? moment(data?.timestamp * 1000).format('hh:mm A')
                  : moment(data?.timestamp * 1000).format(
                    'hh:mm A, DD/MM/yyyy',
                  )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="asm_popup-tip-container-gray"></div>
    </>
  );

  ReactDOM.render(popup, div);
  return div;
};
