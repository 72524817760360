import { FunctionComponent } from 'react';

type RenderSeparatorProps = {
  index: number;
  arrayLength: number;
};

const RenderSeparator: FunctionComponent<RenderSeparatorProps> = (props) => {
  if (props.index !== props.arrayLength - 1) {
    return <strong> - </strong>;
  }
  return null;
};

export default RenderSeparator;
