import { API } from '../../pages/service/apiHandler';

export function CallWithAuth(method, url, body = {}, cancelToken = '') {
  const axiosRequest = {
    POST: API.post,
    PUT: API.put,
    PATCH: API.patch,
    GET: API.get,
    DELETE: API.delete,
  };

  const axiosMethod = axiosRequest[method];

  if (!axiosMethod) {
    return Promise.reject(new Error(`Unsupported method: ${method}`));
  }

  const headercustom = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      ...(cancelToken ? { cancelToken } : {}),
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      if (method === 'POST') {
        const response = await API.post(url, body, headercustom);
        if (response.status === 200 || response.status === 201) {
          resolve({ status: true, res: response });
        }
      }
      if (method === 'PUT') {
        const response = await API.put(url, body, headercustom);
        if (response.status === 200 || response.status === 201) {
          resolve({ status: true, res: response });
        }
      }
      if (method === 'PATCH') {
        const response = await API.patch(url, body, headercustom);
        if (response.status === 200 || response.status === 201) {
          resolve({ status: true, res: response });
        }
      }
      if (method === 'GET') {
        const response = await API.get(url, headercustom);
        if (response.status === 200 || response.status === 201) {
          resolve({ status: true, res: response });
        }
      }
    } catch (err) {
      if (err.response?.status === 403) {
        localStorage.clear();
        window.location.href = `/`;
      } else {
        resolve({ status: false, res: err.response });
      }
    }
  });
}
