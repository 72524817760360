import React, { useEffect, useState } from 'react';
import { GET_MATERIALS } from './action/apiPath';
import { CallWithAuth } from '../assetTracking/action/apiActions';

const MaterialBreakdown = ({ ongoingDumpEvents }) => {
  let sum = 0;
  const [optionsForMaterials, setoptionsForMaterials] = useState([]);

  const getNumberOfLoadsByMaterial = (data, m) => {
    // Create a map to store the number of loads for each material
    const materialLoadsMap = new Map();

    // Iterate through each entry in the data
    data.forEach((entry) => {
      // Iterate through each load in the entry
      entry.allLoads.forEach((loadEntry) => {
        loadEntry.loads.forEach((load) => {
          // Get the material type for the load
          const materialType = load.material;

          // Check if the material type is already in the map
          if (materialLoadsMap.has(materialType)) {
            // If it exists, increment the load count for that material
            materialLoadsMap.set(
              materialType,
              materialLoadsMap.get(materialType) + 1,
            );
          } else {
            // If it doesn't exist, add it to the map with a load count of 1
            materialLoadsMap.set(materialType, 1);
          }
        });
      });
    });

    // Convert the map to an array of objects for easier rendering
    const materialLoadsArray = Array.from(materialLoadsMap.entries()).map(
      ([materialType, count]) => ({
        materialType,
        count,
      }),
    );

    // // Sort the array by the materialType
    // materialLoadsArray.sort((a, b) =>
    //   a.materialType.localeCompare(b.materialType)
    // );

    const combinedArray = m.map((item) => {
      const { MaterialType, color } = item;
      const entry = materialLoadsArray.find(
        (e) => e.materialType === MaterialType,
      );
      return {
        ...entry,
        materialType: MaterialType,
        color: color,
      };
    });

    return combinedArray;
  };

  const getMaterialLength = (material) => {
    let temp;
    material.map((m) => {
      if (m?.count > 0) {
        temp++;
      }
    });

    return temp;
  };
  const fetchMaterials = async () => {
    try {
      const response = await CallWithAuth('GET', GET_MATERIALS);
      if (response?.res?.data?.status === 200) {
        setoptionsForMaterials(
          getNumberOfLoadsByMaterial(
            ongoingDumpEvents,
            response?.res?.data?.data,
          ),
        );
      } else throw response?.res;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchMaterials();
  }, []);

  sum = ongoingDumpEvents?.reduce(
    (accumulator, obj) => accumulator + obj?.totalLoads,
    0,
  );

  console.log(optionsForMaterials);

  return (
    <div
      style={{
        background: 'white',
        height: 'max-content',
        margin: 0,
        width: '103%',
        borderRadius: '0px 0px 0.8rem 0.8rem',
        paddingBottom: '2.5rem',
        marginTop: '-0.9rem',
        marginLeft: '-0.3rem',
        fontSize: '0.85rem',
      }}
    >
      <div
        className="pt-3 px-4"
        style={{
          borderBottom: '1px solid #EBEBEB',
        }}
      >
        <div className=" mt-2 d-flex" style={{ marginBottom: '' }}>
          <div
            style={{
              font: 'normal normal normal 12px/27px Open Sans',
              fontSize: '0.9rem',
            }}
          >
            Total Loads:
          </div>
          <div
            style={{
              font: 'normal normal normal 12px/27px Open Sans',
              fontSize: '0.9rem',
              padding: '  0px 2.8rem',
            }}
          >
            {sum === 0 ? '-' : sum}
          </div>
        </div>
        <div className="d-flex mb-2">
          <div
            className="mt-1"
            style={{
              font: 'normal normal normal 12px/27px Open Sans',
              fontSize: '0.9rem',
            }}
          >
            Material Types:
          </div>
          <div
            style={{
              font: 'normal normal normal 12px/27px Open Sans',
              fontSize: '0.9rem',
              padding: '0.33rem 0px 0.85rem 1.5rem',
            }}
          >
            {sum === 0 ? '-' : getMaterialLength(optionsForMaterials)}
          </div>
        </div>
      </div>

      <div className="px-4 mt-2">
        <ul>
          {optionsForMaterials?.map((item) =>
            item?.count > 0 ? (
              <li
                style={{
                  font: 'normal normal bold 11px/12px Open Sans',
                  fontSize: '0.9rem',
                  padding: '0.7rem 0rem',
                }}
              >
                <span
                  className={
                    item?.MaterialType === 'Coal' ? 'mm-dot-1' : 'mm-dot-2'
                  }
                  style={{ marginRight: '6px', backgroundColor: item?.color }}
                ></span>
                {item?.materialType}{' '}
                <span
                  style={{
                    font: 'normal normal normal 11px/12px Open Sans',
                    fontSize: '0.9rem',
                  }}
                >
                  ({item?.count > 0 ? item?.count : 0} loads)
                </span>
              </li>
            ) : (
              <div></div>
            ),
          )}
        </ul>
      </div>
    </div>
  );
};

export default MaterialBreakdown;
