import React from 'react';
export const Logo = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="60"
      viewBox="0 0 396 294.09"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="none"
            d="M0 0H376.734V147.89H0z"
            data-name="Rectangle 11659"
          ></path>
        </clipPath>
      </defs>
      <g data-name="NZ STEEL LOGO" transform="translate(1)">
        <g data-name="Group 51226" transform="translate(14694.633 17027)">
          <g data-name="Group 51225" transform="translate(-14686 -17027)">
            <g clipPath="url(#clip-path)" data-name="Group 51224">
              <path
                fill={color ?? '#1749b4'}
                d="M243.08 23.714c-17.139 23.82-47.8 37.786-75.48 46.169-16.382 4.3-33.359 7.374-48.3 15.113-19.924 9.18-39.4 20.504-54.5 36.694l-8.336 7.891-33.088-18.445C34.751 96.428 49.945 84.479 65 73.553c26.161-16.392 56.045-21.487 85.16-30.475 15.42-4.761 28.912-13.188 43.471-23.706a193.29 193.29 0 0015.256-12.233q17.1 8.291 34.193 16.575"
                data-name="Path 20956"
                transform="translate(55.551 16.965)"
              ></path>
              <path
                fill={color ?? '#1749b4'}
                d="M11.8 105.318c22.551-34.777 63.244-59.242 103.663-67.609 27.893-7.408 56.7-15.322 79.376-34.527 1.324-.949 2.654-1.9 3.974-2.853a172.322 172.322 0 0112.071 5.4A172.507 172.507 0 01229.64 16.5a114.69 114.69 0 01-20.576 18.93c-10.815 7.739-20.542 11.453-26.9 14.272-19.464 8.637-41.689 11.726-61.738 18.638-28.639 9.869-47.739 27.21-56.784 34.76a224.032 224.032 0 00-19.313 18.172L11.8 105.318"
                data-name="Path 20957"
                transform="translate(28.042 .781)"
              ></path>
              <path
                fill={color ?? '#1749b4'}
                d="M203.76 13.392c11.122 5.568 21.8 13.138 33.018 19.087-8.09 11.021-20.292 19.86-31.613 27.4-23.554 15.8-50.454 23.368-77.516 29.986-16.7 4.082-34.531 14.512-40.223 17.047-7.145 3.181-13.063 5.9-17.2 7.823l-27-18.067a155.585 155.585 0 0117.229-12.875c29.159-18.786 53.523-20.566 74.73-26.988 17.915-5.423 42.327-16.811 68.575-43.414"
                data-name="Path 20958"
                transform="translate(102.704 31.824)"
              ></path>
              <path
                fill={color ?? '#1749b4'}
                d="M127.909 34.689a205.208 205.208 0 00-48.421 23.159 348.391 348.391 0 00-40.247 35.878q-3.084 3.211-6.01 6.422L0 83.141a246.145 246.145 0 0187.983-65.158A242.239 242.239 0 01152.851 0a116.195 116.195 0 0129.625 17.787c-8.948 3.333-44.63 13.671-54.566 16.9"
                data-name="Path 20959"
              ></path>
              <path
                fill={color ?? '#1749b4'}
                d="M103.662 98.482q-7.87-4.938-16.257-9.9-8.463-4.994-16.663-9.525a32.684 32.684 0 017.236-3.757 39.721 39.721 0 018.509-1.948c3.569-.584 7.138-1.86 14.279-4.406 30.752-10.967 55.583-29.662 55.583-29.662a203.956 203.956 0 0021.305-18.493l30.969 17.372a142.569 142.569 0 01-12.746 14.961c-30.53 31.208-68.73 41.53-92.213 45.359"
                data-name="Path 20960"
                transform="translate(168.113 49.407)"
              ></path>
            </g>
          </g>
        </g>
        <text
          fill={color ?? '#1749b4'}
          data-name="New Zealand Steel"
          fontFamily="MaisonNeue-Bold, Maison Neue"
          fontSize="60"
          fontWeight="700"
          letterSpacing=".03em"
          transform="translate(197 208.09)"
        >
          <tspan x="-197.37" y="0">
            New Zealand{' '}
          </tspan>
          <tspan x="-74.88" y="72">
            Steel
          </tspan>
        </text>
      </g>
    </svg>
  );
};
